define("ember-svg-jar/inlined/admin-group-16", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.375 5.375a1.875 1.875 0 113.75 0 1.875 1.875 0 01-3.75 0zM7.342 9.382A3.75 3.75 0 0114 11.75v.75H2v-.375a3.375 3.375 0 015.342-2.743zM3.5 6.125a1.875 1.875 0 113.75 0 1.875 1.875 0 01-3.75 0z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});