define("admin/routes/admin/groups", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "clarify/utilities/resource"], function (_exports, _route, _object, _service, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Groups = (_class = class Groups extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "resources", _descriptor, this);
    }

    async model() {
      let {
        organization
      } = this.modelFor('admin');

      let resource = _resource.default.create({
        store: this.store,
        type: 'urn:group',
        limit: 20,
        cacheKey: `organizations.${organization.id}.groups`,
        adapterOptions: {
          url: `organizations/${organization.id}/groups`
        },
        query: {
          sort: '-createdAt'
        }
      });

      await resource.load();
      return {
        resource
      };
    }

    setupController(controller, model) {
      this.resources.setResource(model.resource.cacheKey, model.resource);
      (0, _object.set)(controller, 'resource', model.resource);
    }

    resetController(controller, isExiting, transition) {
      super.resetController(controller, isExiting, transition);
      this.resources.deleteResource(controller.resource.cacheKey);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "resources", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = Groups;
});