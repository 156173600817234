define("admin/components/data-grid/cell/-content", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq @descriptor.type "string")}}
    <DataGrid::Fields::String @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "copy")}}
    <DataGrid::Fields::Copy @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "number")}}
    <DataGrid::Fields::Number @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "string-array")}}
    <DataGrid::Fields::StringArray @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "date")}}
    <DataGrid::Fields::Date @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "duration")}}
    <DataGrid::Fields::Duration @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "signal-published-state")}}
    <DataGrid::Fields::SignalPublishedState @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "item-visible-state")}}
    <DataGrid::Fields::ItemVisibleState @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "label")}}
    <DataGrid::Fields::Label @model={{@model}} @descriptor={{@descriptor}} />
  {{else if (eq @descriptor.type "labels")}}
    <DataGrid::Fields::Labels @model={{@model}} @descriptor={{@descriptor}} />
  {{/if}}
  */
  {
    "id": "PUonTKoR",
    "block": "[[[41,[28,[37,1],[[30,1,[\"type\"]],\"string\"],null],[[[1,\"  \"],[8,[39,2],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"copy\"],null],[[[1,\"  \"],[8,[39,3],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"number\"],null],[[[1,\"  \"],[8,[39,4],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"string-array\"],null],[[[1,\"  \"],[8,[39,5],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"date\"],null],[[[1,\"  \"],[8,[39,6],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"duration\"],null],[[[1,\"  \"],[8,[39,7],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"signal-published-state\"],null],[[[1,\"  \"],[8,[39,8],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"item-visible-state\"],null],[[[1,\"  \"],[8,[39,9],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"label\"],null],[[[1,\"  \"],[8,[39,10],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"labels\"],null],[[[1,\"  \"],[8,[39,11],null,[[\"@model\",\"@descriptor\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]]],[\"@descriptor\",\"@model\"],false,[\"if\",\"eq\",\"data-grid/fields/string\",\"data-grid/fields/copy\",\"data-grid/fields/number\",\"data-grid/fields/string-array\",\"data-grid/fields/date\",\"data-grid/fields/duration\",\"data-grid/fields/signal-published-state\",\"data-grid/fields/item-visible-state\",\"data-grid/fields/label\",\"data-grid/fields/labels\"]]",
    "moduleName": "admin/components/data-grid/cell/-content.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});