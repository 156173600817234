define("admin/templates/admin/integrations/signals", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+MoX7cMp",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"integrations-signals-index.browser-title\"],[[\"name\"],[[30,0,[\"integration\",\"name\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@categories\",\"@resource\",\"@integration\",\"@itemKeywords\"],[[30,0,[\"categories\"]],[30,0,[\"resource\"]],[30,0,[\"integration\"]],[30,0,[\"itemKeywords\"]]]],null],[1,\"\\n\"],[8,[39,3],null,null,null]],[],false,[\"page-title\",\"t\",\"routes/integrations/signals\",\"admin-outlet\"]]",
    "moduleName": "admin/templates/admin/integrations/signals.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});