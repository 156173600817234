define("admin/routes/admin/users/show/index", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UsersShowIndexRoute extends _route.default {
    async model() {
      let params = this.paramsFor('admin.users.show');
      let adminModel = this.modelFor('admin');
      let member = await this.store.findRecord('urn:member', params.memberId, {
        adapterOptions: {
          url: `organizations/${adminModel.organization.id}/members/${params.memberId}`
        }
      });
      return {
        member
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'member', model.member);
    }

  }

  _exports.default = UsersShowIndexRoute;
});