define("admin/helpers/alert-label-remove", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AlertLabelRemove extends _alert.default {
    compute([action]) {
      return () => {
        this.showAlert({
          title: this.intl.t('alert-label-remove.title'),
          message: this.intl.t('alert-label-remove.message'),
          primaryButton: {
            title: this.intl.t('alert-label-remove.primary-button'),
            action: action,
            style: 'destructive'
          }
        });
      };
    }

  }

  _exports.default = AlertLabelRemove;
});