define("admin/utilities/to-local-iso-string", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = toLocalISOString;

  function toLocalISOString(date) {
    return (0, _dateFns.format)(date, `yyyy-MM-dd'T'HH:mmXXX`); // ssZZ
  }
});