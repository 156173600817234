define("admin/templates/admin/users/invitations", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "puGn4hWL",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"invitations-new.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@organization\"],[[30,0,[\"organization\"]]]],null]],[],false,[\"page-title\",\"t\",\"modals/members-new\"]]",
    "moduleName": "admin/templates/admin/users/invitations.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});