define("meta-attribute/services/meta-attribute", ["exports", "@ember/service", "ember-concurrency", "ember-concurrency-ts"], function (_exports, _service, _emberConcurrency, _emberConcurrencyTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MetaAttributeService = (_class = class MetaAttributeService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "content", new Map());

      _defineProperty(this, "dirtyAttributes", []);
    }

    /**
     * Replaces an attribute for a given token, if the token doesn't exist
     * insert it at the end.
     *
     * @param [token] Token is a unique identifier for the update
     * @param [attribute] Attribute to render
     */
    add(token, attribute) {
      let attributes = this.content.get(attribute.name);

      if (!attributes) {
        attributes = [];
        this.content.set(attribute.name, attributes);
      }

      let index = attributes.findIndex(attribute => attribute.token === token);

      if (index === -1) {
        attributes.push({
          token,
          attribute
        });
      } else {
        attributes.splice(index, 1, {
          token,
          attribute
        });
      }

      this.dirtyAttributes.push(attribute.name);
      (0, _emberConcurrencyTs.perform)(this.schedule);
    }
    /**
     * Removes a named attribute with a given token.
     */


    remove(name, token) {
      let attributes = this.content.get(name) || [];

      if (attributes.length) {
        let index = attributes.findIndex(attribute => attribute.token === token);

        if (index !== -1) {
          attributes.splice(index, 1);
        }
      }

      this.dirtyAttributes.push(name);
      (0, _emberConcurrencyTs.perform)(this.schedule);
    }

    *schedule() {
      yield (0, _emberConcurrency.waitForQueue)('afterRender');

      for (let name of this.dirtyAttributes) {
        let attributes = this.content.get(name) || [];
        let element = document.querySelector(`meta[name="${name}"]`);

        if (attributes && attributes.length) {
          let content = attributes[attributes.length - 1].attribute.content;

          if (element) {
            element.setAttribute('content', content);
          } else {
            element = document.createElement('meta');
            element.setAttribute('name', name);
            element.setAttribute('content', content);
            document.head.appendChild(element);
          }

          document.querySelector(`meta[name="${name}"]`);
        } else if (element) {
          element.remove();
        }
      }

      this.dirtyAttributes = [];
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "schedule", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "schedule"), _class.prototype)), _class);
  _exports.default = MetaAttributeService;
});