define("admin/routes/admin/items/show/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "clarify/utilities/rpc/label-item-labels", "clarify/utilities/uuid", "clarify-data-layer/utility/belongs-to"], function (_exports, _route, _object, _service, _labelItemLabels, _uuid, _belongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ItemsShow = (_class = class ItemsShow extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "network", _descriptor, this);
    }

    async fetchSignalForItem(integrationId, id) {
      let signals = await this.store.query('urn:signal', {
        filter: {
          item: id
        }
      }, {
        adapterOptions: {
          url: `integrations/${integrationId}/signals`
        }
      });
      return signals.toArray();
    }

    async model() {
      let params = this.paramsFor('admin.items.show');
      let {
        organization
      } = this.modelFor('admin');
      let item = await this.store.findRecord('urn:item', params.itemId);
      let source = (0, _belongsTo.belongsTo)(item, 'source');
      let signals = [];

      if (source.integration) {
        await this.store.findRecord('urn:integration', source.integration);
        signals = await this.fetchSignalForItem(source.integration, params.itemId);
      }

      let request = _labelItemLabels.LabelItemLablesRequestInfo.create((0, _uuid.default)(), {
        organization: organization.id,
        noValues: false
      });

      let [categories] = await this.network.rpc('meta/rpc', {}, request);

      if (categories.type === 'error') {
        return Promise.reject(categories.error);
      }

      return {
        item,
        categories: categories.result,
        signals
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'item', model.item);
      (0, _object.set)(controller, 'signals', model.signals);
      (0, _object.set)(controller, 'categories', model.categories);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ItemsShow;
});