define("admin/components/list-entries/group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="list-entries-group" ...attributes>
    {{#if @link}}
      <LinkToRoute @link={{@link}} class="list-entries-group--name">
        {{@group.name}}
      </LinkToRoute>
    {{else}}
      <div class="list-entries-group--name">
        {{@group.name}}
      </div>
    {{/if}}
    <div class="list-entries-group--info" role="none" aria-label="{{@group.users.length}} members">
      <span aria-hidden="true">
        {{@group.users.length}}
      </span>
      {{svg-jar "#admin-group-16" class="list-entries-group--info-icon" width=16 height=16 aria-hidden="true"}}
    </div>
    {{yield}}
  </li>
  */
  {
    "id": "kob0AwDn",
    "block": "[[[11,\"li\"],[24,0,\"list-entries-group\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,1],[[24,0,\"list-entries-group--name\"]],[[\"@link\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3,[\"name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"list-entries-group--name\"],[12],[1,\"\\n      \"],[1,[30,3,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[14,0,\"list-entries-group--info\"],[14,\"role\",\"none\"],[15,\"aria-label\",[29,[[30,3,[\"users\",\"length\"]],\" members\"]]],[12],[1,\"\\n    \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n      \"],[1,[30,3,[\"users\",\"length\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,2],[\"#admin-group-16\"],[[\"class\",\"width\",\"height\",\"aria-hidden\"],[\"list-entries-group--info-icon\",16,16,\"true\"]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@link\",\"@group\",\"&default\"],false,[\"if\",\"link-to-route\",\"svg-jar\",\"yield\"]]",
    "moduleName": "admin/components/list-entries/group.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});