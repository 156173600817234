define("ember-svg-jar/inlined/admin-group-48", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M25 17a5 5 0 1110 0 5 5 0 01-10 0zm-2.755 10.686A9.98 9.98 0 0130 24c5.523 0 10 4.477 10 10v2H8v-1a9 9 0 0114.245-7.314zM12 19a5 5 0 1110 0 5 5 0 01-10 0z\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});