define("admin/components/list-entries/user", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="list-entries-user" ...attributes>
    <img aria-hidden="true" src={{@user.picture}} class="list-entries-user--avatar" width="40" height="40" />
    {{#if @link}}
      <LinkToRoute @link={{@link}} class="list-entries-user--name">
        {{@user.name}}
      </LinkToRoute>
    {{else}}
      <div class="list-entries-user--name">
        {{@user.name}}
      </div>
    {{/if}}
    <div class="list-entries-user--email">
      {{@user.email}}
    </div>
    {{yield}}
  </li>
  */
  {
    "id": "HgTpQ2s2",
    "block": "[[[11,\"li\"],[24,0,\"list-entries-user\"],[17,1],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"aria-hidden\",\"true\"],[15,\"src\",[30,2,[\"picture\"]]],[14,0,\"list-entries-user--avatar\"],[14,\"width\",\"40\"],[14,\"height\",\"40\"],[12],[13],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,1],[[24,0,\"list-entries-user--name\"]],[[\"@link\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,2,[\"name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"list-entries-user--name\"],[12],[1,\"\\n      \"],[1,[30,2,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[14,0,\"list-entries-user--email\"],[12],[1,\"\\n    \"],[1,[30,2,[\"email\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@user\",\"@link\",\"&default\"],false,[\"if\",\"link-to-route\",\"yield\"]]",
    "moduleName": "admin/components/list-entries/user.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});