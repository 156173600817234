define("admin/services/resources", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Resources extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "resources", new Map());

      _defineProperty(this, "belongsToResouce", new Map());
    }

    getResource(name) {
      return this.resources.get(name);
    }

    setResource(name, resource) {
      this.resources.set(name, resource);
    }

    deleteResource(name) {
      this.resources.delete(name);
    }

    registerBelongsToResource(name, resources) {
      this.belongsToResouce.set(name, resources);
    }

    insert(name, model) {
      let generator = this.belongsToResouce.get(name);

      if (generator) {
        let collections = generator(model);

        for (let collectionName of collections) {
          let resource = this.getResource(collectionName);
          resource === null || resource === void 0 ? void 0 : resource.insert(model);
        }
      }
    }

  }

  _exports.default = Resources;
});