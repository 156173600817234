define("ember-svg-jar/inlined/admin-item-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M5.01 17L4 15.922l3.36-3.587L9.5 14.62 16.64 7 20 10.588l-1.01 1.077-2.35-2.508-7.14 7.62-2.14-2.286L5.01 17z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});