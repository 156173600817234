define("admin/components/forms/transforms/labels", ["exports", "clarify/utilities/compact-map"], function (_exports, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LabelsTransform = void 0;

  class LabelsTransform {
    static fromForm(labels) {
      return labels.reduce((hash, label) => {
        hash[label.name] = label.labels;
        return hash;
      }, {});
    }

    static toForm(labels, categories, keywords = []) {
      let categoriesCache = new Map();

      for (let category of categories) {
        categoriesCache.set(category.key, category);
      }

      let labelKeywords = (0, _compactMap.default)(keywords, keyword => keyword.type === 'labels' && keyword.items ? keyword : null);
      let keywordKeys = new Set(labelKeywords.map(keyword => keyword.key));
      return {
        systemLabels: labelKeywords.map(keyword => {
          let category = categoriesCache.get(keyword.key);
          return {
            name: keyword.key,
            labels: labels[keyword.key] || [],
            category: category ? { ...category,
              title: keyword.title
            } : {
              key: keyword.key,
              title: keyword.title,
              existingValues: []
            }
          };
        }),
        extraLabels: Object.entries(labels).filter(label => !keywordKeys.has(label[0])).map(([name, labels]) => {
          let category = categoriesCache.get(name);
          return {
            name,
            labels,
            category: category ? { ...category,
              title: name
            } : {
              key: name,
              title: name,
              existingValues: []
            }
          };
        })
      };
    }

  }

  _exports.LabelsTransform = LabelsTransform;
});