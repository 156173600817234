define("admin/components/routes/integrations/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object", "clarify/utilities/associated-models", "admin/components/data-grid", "admin/components/data-view", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _service, _tracking, _object, _associatedModels, _dataGrid, _dataView, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PageLayout::Complex>
    <:header>
      <PageLayout::Header @icon="#admin-integration-48" @headline={{t "integrations-index.header.title"}}>
        <:actions>
          <FlatButton::Link
            data-intercom-target="Create integration"
            @route={{route "admin.integrations.index.new"}}
            @text={{t "integrations-index.header.create-button"}}
            @size="medium"
            @icon="#design-system-p-plus-thick-24"
          />
        </:actions>
      </PageLayout::Header>
    </:header>
    <:content>
      <DataView
        class="page-layout-complex--data-view"
        @resource={{@resource}}
        @descriptors={{this.visibleDescriptors}}
        @selectedModelsDidChange={{this.selectedModelsDidChange}}
        @columnsSelectorData={{this.differ.viewModels}}
        @selectionStyle={{hash type="multiple" route="admin.integrations.index.show"}}
      >
        <:toolbar-actions>
          {{#if this.selectedModels.length}}
            <AButtonTertiary::Group>
              <AButtonTertiary
                title={{t "integrations-index.toolbar.delete.tooltip" count=this.selectedModels.length}}
                @size="small"
                @theme="light"
                @icon="#icon-remove-trash-16"
                {{on "click" (alert-integrations-delete this.selectedModels task=(task this.deleteIntegrations))}}
              />
            </AButtonTertiary::Group>
          {{/if}}
        </:toolbar-actions>
      </DataView>
    </:content>
  </PageLayout::Complex>
  */
  {
    "id": "ezNy+PfD",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@headline\"],[\"#admin-integration-48\",[28,[37,2],[\"integrations-index.header.title\"],null]]],[[\"actions\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,\"data-intercom-target\",\"Create integration\"]],[[\"@route\",\"@text\",\"@size\",\"@icon\"],[[28,[37,4],[\"admin.integrations.index.new\"],null],[28,[37,2],[\"integrations-index.header.create-button\"],null],\"medium\",\"#design-system-p-plus-thick-24\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"page-layout-complex--data-view\"]],[[\"@resource\",\"@descriptors\",\"@selectedModelsDidChange\",\"@columnsSelectorData\",\"@selectionStyle\"],[[30,1],[30,0,[\"visibleDescriptors\"]],[30,0,[\"selectedModelsDidChange\"]],[30,0,[\"differ\",\"viewModels\"]],[28,[37,6],null,[[\"type\",\"route\"],[\"multiple\",\"admin.integrations.index.show\"]]]]],[[\"toolbar-actions\"],[[[[1,\"\\n\"],[41,[30,0,[\"selectedModels\",\"length\"]],[[[1,\"          \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,9],[[16,\"title\",[28,[37,2],[\"integrations-index.toolbar.delete.tooltip\"],[[\"count\"],[[30,0,[\"selectedModels\",\"length\"]]]]]],[4,[38,10],[\"click\",[28,[37,11],[[30,0,[\"selectedModels\"]]],[[\"task\"],[[28,[37,12],[[30,0,[\"deleteIntegrations\"]]],null]]]]],null]],[[\"@size\",\"@theme\",\"@icon\"],[\"small\",\"light\",\"#icon-remove-trash-16\"]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@resource\"],false,[\"page-layout/complex\",\"page-layout/header\",\"t\",\"flat-button/link\",\"route\",\"data-view\",\"hash\",\"if\",\"a-button-tertiary/group\",\"a-button-tertiary\",\"on\",\"alert-integrations-delete\",\"task\"]]",
    "moduleName": "admin/components/routes/integrations/index.hbs",
    "isStrictMode": false
  });

  let IntegrationsRoute = (_class = class IntegrationsRoute extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "differ", _descriptor2, this);

      _initializerDefineProperty(this, "selectedModels", _descriptor3, this);

      this.differ = new _associatedModels.default(this.descriptors, _dataView.SelectableViewModel);
    }

    *deleteIntegrations() {
      let selectedItems = Array.from(this.selectedModels);
      let promises = selectedItems.map(integration => integration.destroyRecord());
      yield (0, _emberConcurrency.all)(promises);

      for (let integration of this.selectedModels) {
        integration.unloadRecord();
      }
    }

    selectedModelsDidChange(selectedModels) {
      this.selectedModels = selectedModels;
    }

    get visibleDescriptors() {
      return this.differ.viewModels.filter(viewModel => viewModel.enabled).map(viewModel => viewModel.model);
    }

    get descriptors() {
      let labels = this.args.categories.map(label => {
        return {
          title: this.intl.t('items-index.data-grid-descriptors.label', {
            name: label.key
          }),
          keyPath: `labels.${label.key}`,
          type: 'label',
          visibility: _dataGrid.TableCellColumnVisibility.hidden,
          sortable: false,
          width: _dataGrid.TableCellWidth.default,
          filter: {
            type: 'select-multiple',
            items: label.existingValues.map(key => {
              return {
                id: key,
                parentId: null,
                name: key,
                path: [key],
                filters: null,
                count: 0
              };
            })
          }
        };
      });
      return [{
        type: 'checkbox',
        visibility: _dataGrid.TableCellColumnVisibility.mandatory
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.name'),
        keyPath: 'name',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'input'
        }
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.id'),
        keyPath: 'id',
        width: _dataGrid.TableCellWidth.default,
        type: 'copy',
        sortable: false,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'input'
        }
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.lastInsertAt'),
        keyPath: 'insertStats.lastInsertAt',
        width: _dataGrid.TableCellWidth.default,
        type: 'date',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        sortable: false
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.recentInsertInterval'),
        keyPath: 'insertStats.recentInsertInterval',
        width: _dataGrid.TableCellWidth.default,
        type: 'duration',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        sortable: false
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.trackedInserts'),
        keyPath: 'insertStats.trackedInserts',
        width: _dataGrid.TableCellWidth.default,
        type: 'number',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        sortable: false
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.insertedValues'),
        keyPath: 'insertStats.insertedValues',
        width: _dataGrid.TableCellWidth.default,
        type: 'number',
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        sortable: false
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.firstInsertAt'),
        keyPath: 'insertStats.firstInsertAt',
        width: _dataGrid.TableCellWidth.default,
        type: 'date',
        sortable: false,
        visibility: _dataGrid.TableCellColumnVisibility.hidden
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.trackedSince'),
        keyPath: 'insertStats.trackedSince',
        width: _dataGrid.TableCellWidth.default,
        type: 'date',
        sortable: false,
        visibility: _dataGrid.TableCellColumnVisibility.hidden
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.createdAt'),
        keyPath: 'createdAt',
        width: _dataGrid.TableCellWidth.default,
        type: 'date',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.createdAt'),
        keyPath: 'createdAt',
        width: _dataGrid.TableCellWidth.default,
        type: 'date',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.updatedAt'),
        keyPath: 'updatedAt',
        width: _dataGrid.TableCellWidth.default,
        type: 'date',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.labels'),
        keyPath: 'labels',
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        type: 'labels',
        width: _dataGrid.TableCellWidth.ultraWide,
        sortable: false,
        filter: undefined
      }, {
        title: this.intl.t('integrations-index.data-grid-descriptors.description'),
        keyPath: 'description',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        sortable: false,
        visibility: _dataGrid.TableCellColumnVisibility.hidden
      }, ...labels];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "differ", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedModels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteIntegrations", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteIntegrations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedModelsDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectedModelsDidChange"), _class.prototype)), _class);
  _exports.default = IntegrationsRoute;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IntegrationsRoute);
});