define("ember-router-service-refresh-polyfill/initializers/setup-router-service-refresh-polyfill", ["exports", "@ember/debug", "@ember/application"], function (_exports, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /* eslint-disable ember/no-private-routing-service */
  function initialize(application) {
    const RouterService = application.resolveRegistration('service:router');
    RouterService.reopen({
      /**
       * Refreshes all currently active routes, doing a full transition.
       * If a routeName is provided and refers to a currently active route,
       * it will refresh only that route and its descendents.
       * Returns a promise that will be resolved once the refresh is complete.
       * All resetController, beforeModel, model, afterModel, redirect, and setupController
       * hooks will be called again. You will get new data from the model hook.
       *
       * @method refresh
       * @param {String} [routeName] the route to refresh (along with all child routes)
       * @return Transition
       * @public
       */
      refresh(routeName) {
        if (!routeName) {
          return this._router._routerMicrolib.refresh();
        }

        (true && !(this._router.hasRoute(routeName)) && (0, _debug.assert)(`The route "${routeName}" was not found`, this._router.hasRoute(routeName)));
        (true && !(this.isActive(routeName)) && (0, _debug.assert)(`The route "${routeName}" is currently not active`, this.isActive(routeName)));
        let owner = (0, _application.getOwner)(this);
        let pivotRoute = owner.lookup(`route:${routeName}`);
        return this._router._routerMicrolib.refresh(pivotRoute);
      }

    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});