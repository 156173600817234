define("admin/components/admin-card/status-block", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-card--status-block">
    <div class="admin-card--status-block-content">
      <span class="admin-card--status-block-title">
        {{@title}}
      </span>
      <p class="admin-card--status-block-description">
        {{@description}}
      </p>
    </div>
  </div>
  */
  {
    "id": "PwL5Q853",
    "block": "[[[10,0],[14,0,\"admin-card--status-block\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-card--status-block-content\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"admin-card--status-block-title\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"admin-card--status-block-description\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@title\",\"@description\"],false,[]]",
    "moduleName": "admin/components/admin-card/status-block.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});