define("admin/templates/admin/items/show/index-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uiYA/qO0",
    "block": "[[[8,[39,0],null,null,[[\"menu\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@route\"],[[28,[37,2],[\"admin.items\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@title\"],[[28,[37,4],[\"items-show.states.loading\"],null]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"side-pane\",\"side-pane/menu\",\"route\",\"admin-state/loading\",\"t\"]]",
    "moduleName": "admin/templates/admin/items/show/index-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});