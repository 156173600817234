define("draggable/components/sort-interaction", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "@ember/runloop", "@ember/destroyable", "draggable/helpers/accept", "draggable/helpers/dom"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _object, _runloop, _destroyable, _accept, _dom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stopPropagation = stopPropagation;
  _exports.isPropagationStopped = isPropagationStopped;
  _exports.binarySearch = binarySearch;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{on "dragstart" (fn this.dragStart)}}
    {{on "drop" (fn this.drop)}}
    {{on "dragover" (fn this.dragOver)}}
    {{on "dragend" (fn this.dragEnd)}}
    {{did-insert (fn this.handleInsertContainer)}}
    ...attributes
  >
    {{#each @items as |item index|}}
      {{#if (eq index this.index)}}
        {{#let (component this.placeholder) as |Tag|}}
          <Tag
            {{did-insert (fn this.handleInsertPlaceholder)}}
            class={{or @placeholderClass "placeholder"}}
            data-main-context-provider={{this.isMainModelContextProvider}}
          />
        {{/let}}
      {{/if}}
      {{yield item}}
    {{/each}}
    {{#if this.trailingPlaceholder}}
      {{#let (component this.placeholder) as |Tag|}}
        <Tag
          {{did-insert (fn this.handleInsertPlaceholder)}}
          class={{or @placeholderClass "placeholder"}}
          data-main-context-provider={{this.isMainModelContextProvider}}
        />
      {{/let}}
    {{/if}}
  </div>
  */
  {
    "id": "KG56HIli",
    "block": "[[[11,0],[17,1],[4,[38,0],[\"dragstart\",[28,[37,1],[[30,0,[\"dragStart\"]]],null]],null],[4,[38,0],[\"drop\",[28,[37,1],[[30,0,[\"drop\"]]],null]],null],[4,[38,0],[\"dragover\",[28,[37,1],[[30,0,[\"dragOver\"]]],null]],null],[4,[38,0],[\"dragend\",[28,[37,1],[[30,0,[\"dragEnd\"]]],null]],null],[4,[38,2],[[28,[37,1],[[30,0,[\"handleInsertContainer\"]]],null]],null],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,2]],null]],null],null,[[[41,[28,[37,6],[[30,4],[30,0,[\"index\"]]],null],[[[44,[[50,[30,0,[\"placeholder\"]],0,null,null]],[[[1,\"        \"],[8,[30,5],[[16,0,[28,[37,9],[[30,6],\"placeholder\"],null]],[16,\"data-main-context-provider\",[30,0,[\"isMainModelContextProvider\"]]],[4,[38,2],[[28,[37,1],[[30,0,[\"handleInsertPlaceholder\"]]],null]],null]],null,null],[1,\"\\n\"]],[5]]]],[]],null],[1,\"    \"],[18,8,[[30,3]]],[1,\"\\n\"]],[3,4]],null],[41,[30,0,[\"trailingPlaceholder\"]],[[[44,[[50,[30,0,[\"placeholder\"]],0,null,null]],[[[1,\"      \"],[8,[30,7],[[16,0,[28,[37,9],[[30,6],\"placeholder\"],null]],[16,\"data-main-context-provider\",[30,0,[\"isMainModelContextProvider\"]]],[4,[38,2],[[28,[37,1],[[30,0,[\"handleInsertPlaceholder\"]]],null]],null]],null,null],[1,\"\\n\"]],[7]]]],[]],null],[13]],[\"&attrs\",\"@items\",\"item\",\"index\",\"Tag\",\"@placeholderClass\",\"Tag\",\"&default\"],false,[\"on\",\"fn\",\"did-insert\",\"each\",\"-track-array\",\"if\",\"eq\",\"let\",\"component\",\"or\",\"yield\"]]",
    "moduleName": "draggable/components/sort-interaction.hbs",
    "isStrictMode": false
  });

  const stopPropagationSymbol = Symbol('Sort Interaction Stop Propagation');

  function stopPropagation(target) {
    Object.defineProperty(target, stopPropagationSymbol, {
      value: true,
      enumerable: false
    });
  }

  function isPropagationStopped(target) {
    return target.hasOwnProperty(stopPropagationSymbol);
  }

  function binarySearch(nodes, condition, firstIndex = 0) {
    let index = firstIndex;
    let toIndex = nodes.length - 1;

    while (index !== toIndex) {
      let midIndex = Math.floor((index + toIndex) / 2);

      if (condition(nodes[midIndex])) {
        toIndex = midIndex;
      } else {
        index = index === midIndex ? toIndex : midIndex;
      }
    }

    return index;
  }

  let SortInteraction = (_dec = (0, _object.computed)('args.allow'), _dec2 = (0, _object.computed)('args.items', 'draggedModel'), _dec3 = (0, _object.computed)('draggedIndex', 'placeholderIndex'), _dec4 = (0, _object.computed)('index'), (_class = class SortInteraction extends _component2.default {
    // Properties
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "dragCoordinator", _descriptor, this);

      _initializerDefineProperty(this, "over", _descriptor2, this);

      _initializerDefineProperty(this, "container", _descriptor3, this);

      _initializerDefineProperty(this, "draggedModel", _descriptor4, this);

      _initializerDefineProperty(this, "placeholderIndex", _descriptor5, this);

      _initializerDefineProperty(this, "isMainModelContextProvider", _descriptor6, this);

      _initializerDefineProperty(this, "placeholderElement", _descriptor7, this);

      _defineProperty(this, "dropBubble", () => {
        this.draggedModel = null;
        this.over = false;
      });

      document.addEventListener('drop', this.dropBubble, true);
      (0, _destroyable.registerDestructor)(this, () => {
        document.removeEventListener('drop', this.dropBubble, true);
      });
    }

    get tolerance() {
      return this.args.tolerance ?? 20;
    }

    get placeholder() {
      return this.args.placeholder ?? 'sort-placeholder';
    }

    get allowSet() {
      return (0, _accept.normalizeAllow)(this.args.allow);
    }

    get draggedIndex() {
      let draggedModel = this.draggedModel;

      if (draggedModel) {
        let index = this.args.items.indexOf(draggedModel);
        return index === -1 ? null : index;
      }

      return null;
    }

    get index() {
      let draggedIndex = this.draggedIndex;
      let placeholderIndex = this.placeholderIndex;

      if (placeholderIndex !== null) {
        if (placeholderIndex === draggedIndex || placeholderIndex - 1 === draggedIndex) {
          return null;
        }

        return placeholderIndex;
      }

      return null;
    }

    get trailingPlaceholder() {
      return this.index && this.index >= this.args.items.length ? true : false;
    }

    dragStart(event) {
      if (!this.container) {
        return;
      }

      if (isPropagationStopped(event)) {
        return;
      }

      stopPropagation(event);
      let element = (0, _dom.findParentOfElement)(this.container, event.target);

      if (element === null || element === this.placeholderElement) {
        return;
      }

      let index = [...this.container.children].filter(node => node !== this.placeholderElement).indexOf(element);
      let model = this.args.items.slice(0)[index];
      this.draggedModel = model;
    }

    dragOver(event) {
      if (!this.container) {
        return;
      }

      if (isPropagationStopped(event) === false && (0, _accept.shouldAcceptEvent)(this.allowSet, event)) {
        stopPropagation(event);

        if (this.args.modelContextProvider) {
          let isMain = (0, _accept.shouldAcceptEvent)(new Set([`clarify/${this.args.modelContextProvider}`]), event);
          this.isMainModelContextProvider = isMain;
        }

        event.preventDefault();
        (0, _runloop.debounce)(this, this.onDragLeave, 100);

        if (this.container === event.target) {
          if (this.placeholderIndex != null) {
            return;
          }

          this.over = true;
          let items = [...this.container.children].filter(node => node !== this.placeholderElement);

          if (items.length) {
            let first = binarySearch(items, child => {
              let position = child.getBoundingClientRect();
              return event.clientY < position.top;
            });
            let item = items[first];
            let rect = item.getBoundingClientRect();
            let index = rect.top - event.clientY >= 0 ? first : first + 1;
            this.placeholderIndex = index;
          } else {
            this.placeholderIndex = 0;
          }

          return;
        }

        let element = (0, _dom.findParentOfElement)(this.container, event.target);

        if (element === null || element === this.placeholderElement) {
          return;
        }

        let index = [...this.container.children].filter(node => node !== this.placeholderElement).indexOf(element);
        let mousePosition = event.clientY;
        let rect = element.getBoundingClientRect();

        if (this.over) {
          if (rect.top - mousePosition + this.tolerance >= 0) {
            this.placeholderIndex = index;
          } else if (rect.bottom - mousePosition - this.tolerance <= 0) {
            this.placeholderIndex = index + 1;
          }
        } else {
          this.over = true;
          let delta = rect.height / 2;

          if (rect.top + delta > mousePosition) {
            this.placeholderIndex = index;
          } else {
            this.placeholderIndex = index + 1;
          }
        }
      }
    }

    async drop(event) {
      if (event.defaultPrevented) {
        return;
      }

      let dataTransfer = event.dataTransfer;

      if (dataTransfer === null || (0, _accept.shouldAcceptEvent)(this.allowSet, event) === false) {
        return;
      }

      event.preventDefault();
      let placeholderIndex = this.placeholderIndex;

      if (placeholderIndex === null) {
        return;
      }

      this.cleanup();
      let contextProvider = dataTransfer.getData('clarify/provider');
      let provider = this.dragCoordinator.contextProviderFor(contextProvider);

      if (provider) {
        let data = dataTransfer.getData(`clarify/${contextProvider}`);
        let payload = JSON.parse(data);
        let rawModels = await provider.deserialize(payload, dataTransfer);
        let modelContextProvider = this.args.modelContextProvider || contextProvider;
        let models = provider.convertTo ? await provider.convertTo(modelContextProvider, rawModels) : rawModels;
        let cache = new Set(models);
        let items = this.args.items.slice(0);
        let pre = items.slice(0, placeholderIndex).filter(item => !cache.has(item));
        let after = items.slice(placeholderIndex).filter(item => !cache.has(item));
        this.args.sort([...pre, ...models, ...after]);
      }

      return false;
    }

    dragEnd() {
      this.cleanup();
    }

    onDragLeave() {
      this.placeholderIndex = null;
      this.over = false;
    }

    handleInsertContainer(element) {
      this.container = element;
    }

    handleInsertPlaceholder(element) {
      this.placeholderElement = element;
    } // Private methods


    cleanup() {
      this.placeholderIndex = null;
      this.draggedModel = null;
      this.over = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dragCoordinator", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "over", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "container", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "draggedModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "placeholderIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isMainModelContextProvider", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "placeholderElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "allowSet", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "allowSet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "draggedIndex", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "draggedIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "index", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "index"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trailingPlaceholder", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "trailingPlaceholder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInsertContainer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertContainer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInsertPlaceholder", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertPlaceholder"), _class.prototype)), _class));
  _exports.default = SortInteraction;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SortInteraction);
});