define("admin/components/item-card/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-item-card" ...attributes>
    <h2 class="admin-item-card--name">
      {{@item.name}}
    </h2>
    <span class="admin-item-card--eng-unit" title={{t "item-card.item-pane.tooltip-eng-unit" unit=@item.engUnit}}>
      {{@item.engUnit}}
    </span>
    <ul class="admin-item-card--labels">
      <ItemCard::ListItem
        @icon="#auto-complete-location"
        @text={{join (or @item.labels.location (array)) conjunction=", "}}
        @initialText={{t "item-card.empty-state.location.title"}}
      />
      <ItemCard::ListItem
        @icon="#auto-complete-integration"
        @text={{join (or (get @item.labels "data-source") (array)) conjunction=", "}}
        @initialText="{{t "item-card.empty-state.data-source.title"}}"
      />
    </ul>
  </div>
  */
  {
    "id": "FbyVA1PM",
    "block": "[[[11,0],[24,0,\"admin-item-card\"],[17,1],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"admin-item-card--name\"],[12],[1,\"\\n    \"],[1,[30,2,[\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"admin-item-card--eng-unit\"],[15,\"title\",[28,[37,0],[\"item-card.item-pane.tooltip-eng-unit\"],[[\"unit\"],[[30,2,[\"engUnit\"]]]]]],[12],[1,\"\\n    \"],[1,[30,2,[\"engUnit\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"admin-item-card--labels\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@text\",\"@initialText\"],[\"#auto-complete-location\",[28,[37,2],[[28,[37,3],[[30,2,[\"labels\",\"location\"]],[28,[37,4],null,null]],null]],[[\"conjunction\"],[\", \"]]],[28,[37,0],[\"item-card.empty-state.location.title\"],null]]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@text\",\"@initialText\"],[\"#auto-complete-integration\",[28,[37,2],[[28,[37,3],[[28,[37,5],[[30,2,[\"labels\"]],\"data-source\"],null],[28,[37,4],null,null]],null]],[[\"conjunction\"],[\", \"]]],[29,[[28,[37,0],[\"item-card.empty-state.data-source.title\"],null]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@item\"],false,[\"t\",\"item-card/list-item\",\"join\",\"or\",\"array\",\"get\"]]",
    "moduleName": "admin/components/item-card/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});