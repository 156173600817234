define("admin/templates/admin/users", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8TedgXRU",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"users-index.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@resource\",\"@groups\"],[[30,0,[\"resource\"]],[30,0,[\"groups\"]]]],null],[1,\"\\n\"],[8,[39,3],null,null,null]],[],false,[\"page-title\",\"t\",\"routes/users\",\"admin-outlet\"]]",
    "moduleName": "admin/templates/admin/users.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});