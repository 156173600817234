define("admin/components/item-card/list-item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="admin-item-card-labels--label" data-placeholder={{not @text}}>
    {{svg-jar @icon aria-hidden="true" class="admin-item-card-labels--label--icon" width=16 height=16}}
    <div class="admin-item-card-labels--label--name">
      {{or @text @initialText}}
    </div>
  </li>
  */
  {
    "id": "7K50xbLl",
    "block": "[[[10,\"li\"],[14,0,\"admin-item-card-labels--label\"],[15,\"data-placeholder\",[28,[37,0],[[30,1]],null]],[12],[1,\"\\n  \"],[1,[28,[35,1],[[30,2]],[[\"aria-hidden\",\"class\",\"width\",\"height\"],[\"true\",\"admin-item-card-labels--label--icon\",16,16]]]],[1,\"\\n  \"],[10,0],[14,0,\"admin-item-card-labels--label--name\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,1],[30,3]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@text\",\"@icon\",\"@initialText\"],false,[\"not\",\"svg-jar\",\"or\"]]",
    "moduleName": "admin/components/item-card/list-item.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});