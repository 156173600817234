define("ember-animated/templates/components/animated-beacon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "X877RCWp",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-animated/templates/components/animated-beacon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});