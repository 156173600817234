define("admin/components/metadata/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form class="metadata-container" ...attributes {{on "change" (fn this.onChange)}} {{on "submit" (fn this.onSubmit)}}>
    {{yield}}
  </form>
  */
  {
    "id": "mrspCKbz",
    "block": "[[[11,\"form\"],[24,0,\"metadata-container\"],[17,1],[4,[38,0],[\"change\",[28,[37,1],[[30,0,[\"onChange\"]]],null]],null],[4,[38,0],[\"submit\",[28,[37,1],[[30,0,[\"onSubmit\"]]],null]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"on\",\"fn\",\"yield\"]]",
    "moduleName": "admin/components/metadata/index.hbs",
    "isStrictMode": false
  });

  let Metadata = (_class = class Metadata extends _component2.default {
    onSubmit(event) {
      event.preventDefault();
    }

    onChange(event) {
      event.preventDefault();

      if (this.args.serialize) {
        this.args.serialize();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class);
  _exports.default = Metadata;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Metadata);
});