define("admin/templates/admin/integrations/signals/show/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "L+O/n9ws",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"integrations-signals-show.browser-title\"],[[\"pane\",\"name\"],[[30,0,[\"pane\"]],[30,0,[\"signal\",\"name\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@pane\",\"@signal\",\"@item\",\"@itemKeywords\"],[[30,0,[\"pane\"]],[30,0,[\"signal\"]],[30,0,[\"item\"]],[30,0,[\"itemKeywords\"]]]],null],[1,\"\\n\"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"page-title\",\"t\",\"routes/integrations/signals/show\",\"admin-outlet\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/admin/integrations/signals/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});