define("admin/templates/admin/organization", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cm8s35LP",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"organization-index.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@organization\",\"@members\",\"@integrations\",\"@items\",\"@subscription\"],[[30,0,[\"organization\"]],[30,0,[\"members\"]],[30,0,[\"integrations\"]],[30,0,[\"items\"]],[30,0,[\"subscription\"]]]],null],[1,\"\\n\"],[8,[39,3],null,null,null]],[],false,[\"page-title\",\"t\",\"routes/organization\",\"admin-outlet\"]]",
    "moduleName": "admin/templates/admin/organization.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});