define("admin/components/routes/items/-source-pane", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @signals.length}}
    <HeaderContextual @title={{t "items-show.source-pane.header"}} />
    <Fieldset>
      <:label>
        <Label @title={{t "items-show.source-pane.integration-label"}} />
      </:label>
      <:input>
        <AText::Link
          @text={{t "items-show.source-pane.integration-value" integration=@item.source.name}}
          @route={{route "admin.integrations.index.show" @item.source.id}}
        />
      </:input>
    </Fieldset>
    <Fieldset class="source-pane--signals">
      <:label>
        <Label @title={{t "items-show.source-pane.signal-label"}} />
      </:label>
      <:input>
        {{#each @signals as |signal|}}
          <AText::Link
            @text={{t "items-show.source-pane.signal-value" signal=signal.name}}
            @route={{route "admin.integrations.signals.show" models=(array signal.integration.id signal.id)}}
          />
        {{/each}}
      </:input>
    </Fieldset>
  {{else if @dataset}}
    <p class="source-pane--description">
      {{t "items-show.source-pane.dataset.description"}}
    </p>
  {{else}}
    <NonIdealState class="source-pane--non-ideal-state" @title={{t "items-show.source-pane.not-found"}} />
  {{/if}}
  */
  {
    "id": "aHPoi9Xf",
    "block": "[[[41,[30,1,[\"length\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[[28,[37,2],[\"items-show.source-pane.header\"],null]]],null],[1,\"\\n  \"],[8,[39,3],null,null,[[\"label\",\"input\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@title\"],[[28,[37,2],[\"items-show.source-pane.integration-label\"],null]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[39,5],null,[[\"@text\",\"@route\"],[[28,[37,2],[\"items-show.source-pane.integration-value\"],[[\"integration\"],[[30,2,[\"source\",\"name\"]]]]],[28,[37,6],[\"admin.integrations.index.show\",[30,2,[\"source\",\"id\"]]],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[8,[39,3],[[24,0,\"source-pane--signals\"]],null,[[\"label\",\"input\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@title\"],[[28,[37,2],[\"items-show.source-pane.signal-label\"],null]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[39,5],null,[[\"@text\",\"@route\"],[[28,[37,2],[\"items-show.source-pane.signal-value\"],[[\"signal\"],[[30,3,[\"name\"]]]]],[28,[37,6],[\"admin.integrations.signals.show\"],[[\"models\"],[[28,[37,9],[[30,3,[\"integration\",\"id\"]],[30,3,[\"id\"]]],null]]]]]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,4],[[[1,\"  \"],[10,2],[14,0,\"source-pane--description\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"items-show.source-pane.dataset.description\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,10],[[24,0,\"source-pane--non-ideal-state\"]],[[\"@title\"],[[28,[37,2],[\"items-show.source-pane.not-found\"],null]]],null],[1,\"\\n\"]],[]]]],[]]]],[\"@signals\",\"@item\",\"signal\",\"@dataset\"],false,[\"if\",\"header-contextual\",\"t\",\"fieldset\",\"label\",\"a-text/link\",\"route\",\"each\",\"-track-array\",\"array\",\"non-ideal-state\"]]",
    "moduleName": "admin/components/routes/items/-source-pane.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});