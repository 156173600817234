define("ember-svg-jar/inlined/admin-integration-48", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M28.858 22.878l-3.305 3.304 2.71 2.71 3.304-3.305 2.23 2.23 1.355-1.354c3.386-3.387 3.707-8.68.961-12.427l3.447-3.446-2.122-2.121-3.453 3.453c-3.744-2.71-9.006-2.379-12.38.994l-1.354 1.355 2.86 2.86-3.305 3.304 2.71 2.71 3.304-3.305 3.038 3.038zm-15.893-1.32l1.306-1.307 13.547 13.546-1.355 1.355c-3.387 3.386-8.68 3.707-12.426.961l-3.446 3.446-2.121-2.122 3.452-3.452c-2.71-3.744-2.378-9.006.994-12.38l.049-.047z\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});