define("admin/components/data-grid/fields/item-visible-state", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (get @model @descriptor.keyPath) as |value|}}
    <TextTag
      class="data-grid--item-visibility-state"
      @text={{t "data-grid.content.item-visible-state.status" status=value}}
      @style={{if value "blue" "red"}}
      @icon={{if value "#visibility-show-24" "#visibility-hide-24"}}
    />
  {{/let}}
  */
  {
    "id": "4zFyYQHu",
    "block": "[[[44,[[28,[37,1],[[30,1],[30,2,[\"keyPath\"]]],null]],[[[1,\"  \"],[8,[39,2],[[24,0,\"data-grid--item-visibility-state\"]],[[\"@text\",\"@style\",\"@icon\"],[[28,[37,3],[\"data-grid.content.item-visible-state.status\"],[[\"status\"],[[30,3]]]],[52,[30,3],\"blue\",\"red\"],[52,[30,3],\"#visibility-show-24\",\"#visibility-hide-24\"]]],null],[1,\"\\n\"]],[3]]]],[\"@model\",\"@descriptor\",\"value\"],false,[\"let\",\"get\",\"text-tag\",\"t\",\"if\"]]",
    "moduleName": "admin/components/data-grid/fields/item-visible-state.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});