define("ember-svg-jar/inlined/admin-member-48", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M24 12a5 5 0 100 10 5 5 0 000-10zm0 12.5c-5.523 0-10 4.477-10 10V37h20v-2.5c0-5.523-4.477-10-10-10z\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});