define("ember-svg-jar/inlined/profile-logout-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 4h5v1H8v14h5v1H7V4h1zm5.646 5.354l2.147 2.146H11v1h4.793l-2.147 2.146.708.708 3-3 .353-.354-.353-.354-3-3-.708.708z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});