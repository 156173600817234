define("admin/components/modals/organization-edit/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/controller", "@ember/service", "@ember/object", "ember-concurrency", "ember-concurrency-ts", "form/fields/-form", "form/fields/input"], function (_exports, _component, _templateFactory, _component2, _controller, _service, _object, _emberConcurrency, _emberConcurrencyTs, _form, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OrganizationForm = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{meta-attribute name="theme-color" content="#D6D7DB"}}
  <DialogContainer>
    <form {{on "submit" (prevent-default (fn this.onSubmit))}}>
      <Dialog class="admin-organization-form--container" ...attributes>
        <:header>
          <Dialog::Header @title={{t "organization-index.create.title"}} @handleClose={{fn this.closeModal}} />
        </:header>
        <:content>
          <Metadata::Fieldsets::Textfield
            @size="medium"
            @label={{t "organization-index.create.organization-name"}}
            @placeholder={{t "organization-index.create.organization-placeholder"}}
            @field={{this.form.fields.name}}
            {{set-focus true}}
          />
        </:content>
        <:footer>
          <div class="admin-organization-footer">
            <FlatButton::Link
              @route={{route "admin.organization"}}
              @style="neutral"
              @text={{t "organization-index.create.actions.cancel"}}
            />
            <FlatButton
              @type="submit"
              @text={{t "organization-index.create.actions.submit"}}
              @loading={{not this.editOrganizationName.isIdle}}
            />
          </div>
        </:footer>
      </Dialog>
    </form>
  </DialogContainer>
  */
  {
    "id": "vFrxsNQt",
    "block": "[[[1,[28,[35,0],null,[[\"name\",\"content\"],[\"theme-color\",\"#D6D7DB\"]]]],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[11,\"form\"],[4,[38,2],[\"submit\",[28,[37,3],[[28,[37,4],[[30,0,[\"onSubmit\"]]],null]],null]],null],[12],[1,\"\\n    \"],[8,[39,5],[[24,0,\"admin-organization-form--container\"],[17,1]],null,[[\"header\",\"content\",\"footer\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@title\",\"@handleClose\"],[[28,[37,7],[\"organization-index.create.title\"],null],[28,[37,4],[[30,0,[\"closeModal\"]]],null]]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[39,8],[[4,[38,9],[true],null]],[[\"@size\",\"@label\",\"@placeholder\",\"@field\"],[\"medium\",[28,[37,7],[\"organization-index.create.organization-name\"],null],[28,[37,7],[\"organization-index.create.organization-placeholder\"],null],[30,0,[\"form\",\"fields\",\"name\"]]]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,0],[14,0,\"admin-organization-footer\"],[12],[1,\"\\n          \"],[8,[39,10],null,[[\"@route\",\"@style\",\"@text\"],[[28,[37,11],[\"admin.organization\"],null],\"neutral\",[28,[37,7],[\"organization-index.create.actions.cancel\"],null]]],null],[1,\"\\n          \"],[8,[39,12],null,[[\"@type\",\"@text\",\"@loading\"],[\"submit\",[28,[37,7],[\"organization-index.create.actions.submit\"],null],[28,[37,13],[[30,0,[\"editOrganizationName\",\"isIdle\"]]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&attrs\"],false,[\"meta-attribute\",\"dialog-container\",\"on\",\"prevent-default\",\"fn\",\"dialog\",\"dialog/header\",\"t\",\"metadata/fieldsets/textfield\",\"set-focus\",\"flat-button/link\",\"route\",\"flat-button\",\"not\"]]",
    "moduleName": "admin/components/modals/organization-edit/index.hbs",
    "isStrictMode": false
  });

  let OrganizationForm = _form.default.define({
    name: _input.InputField.required()
  });

  _exports.OrganizationForm = OrganizationForm;
  let OrganizationNewModal = (_class = class OrganizationNewModal extends _component2.default {
    //Services
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "admin", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "alert", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _defineProperty(this, "form", new OrganizationForm());

      this.form.populate({
        name: args.organization.name
      });
    }

    *editOrganizationName(entry) {
      try {
        this.args.organization.name = entry.name;
        yield this.args.organization.save();
        this.router.transitionTo('admin.organization');
      } catch (error) {
        console.error('Failed creating record!', error);
        this.alert.show({
          title: this.intl.t('organization-index.alerts.errors.title'),
          message: this.intl.t('organization-index.alerts.errors.message'),
          actions: [{
            title: this.intl.t('organization-index.alerts.errors.dismiss'),
            action: () => {
              this.alert.dismissAlert();
            }
          }]
        });
      }

      return undefined;
    }

    onSubmit() {
      let data = this.form.serialize();

      if (data.type === 'success') {
        (0, _emberConcurrencyTs.taskFor)(this.editOrganizationName).perform(data.value);
      }
    }

    closeModal() {
      this.router.transitionTo('admin.organization');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "admin", [_controller.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "alert", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editOrganizationName", [_emberConcurrency.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "editOrganizationName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class);
  _exports.default = OrganizationNewModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OrganizationNewModal);
});