define("admin/drag-images/keyword", ["exports", "@ember/object", "clarify/drag-images/item"], function (_exports, _object, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VisualizationDragImage extends _object.default {
    draw(canvasConstructor, models, event) {
      let model = models[0];

      if (!model) {
        return null;
      }

      let clientRect = event.target.getBoundingClientRect();
      let position = {
        x: event.pageX - clientRect.left,
        y: event.pageY - clientRect.top
      };
      let canvas = canvasConstructor(232, 32);
      let context = canvas.getContext('2d');
      (0, _item.roundedRect)(context, 0, 0, 232, 32, 8);
      context.fillStyle = '#ffffff';
      context.fill();
      let titleTypesetter = (0, _item.createTypesetter)(context, {
        fill: '#3c4756',
        font: 'normal 13px roboto'
      });
      context.save();
      context.translate(21, 0);
      titleTypesetter.write((0, _object.get)(model, 'title'), 192, 32, {
        yAlign: 'center'
      });
      context.restore();
      return {
        canvas,
        x: position.x,
        y: 10
      };
    }

  }

  _exports.default = VisualizationDragImage;
});