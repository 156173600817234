define("admin/routes/admin/upgrade", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Upgrade extends _route.default {
    redirect() {
      // @ts-expect-error
      this.transitionToExternal('upgrade');
    }

  }

  _exports.default = Upgrade;
});