define("clarify-data-layer/models/signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SignalType = _exports.DataType = void 0;
  let DataType;
  _exports.DataType = DataType;

  (function (DataType) {
    DataType["float64"] = "float64";
  })(DataType || (_exports.DataType = DataType = {}));

  let SignalType;
  _exports.SignalType = SignalType;

  (function (SignalType) {
    SignalType["numeric"] = "numeric";
    SignalType["enum"] = "enum";
  })(SignalType || (_exports.SignalType = SignalType = {}));
});