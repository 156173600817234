define("clarify-data-layer/services/m3-schema", ["exports", "ember-m3/services/m3-schema", "clarify/models/duration"], function (_exports, _m3Schema, _duration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function dateTransform(value) {
    if (!value) {
      return;
    }

    return new Date(Date.parse(value));
  }

  function durationTransform(value) {
    if (!value) {
      return null;
    }

    if (value instanceof _duration.default) {
      return value;
    }

    return _duration.default.parse(value);
  }

  function computeAttributeReference(key, value, modelName, schemaInterface) {
    switch (key) {
      case 'organization':
        return schemaInterface.reference({
          type: 'urn:organization',
          id: value
        });

      case 'source':
        switch (value.type) {
          case 'integration':
            return schemaInterface.reference({
              type: 'urn:integration',
              id: value.integration
            });

          case 'dataset':
            {
              return schemaInterface.reference({
                type: 'urn:item',
                id: value.item
              });
            }

          default:
            return null;
        }

      case 'item':
        return schemaInterface.reference({
          type: 'urn:item',
          id: value
        });

      case 'signal':
        return schemaInterface.reference({
          type: 'urn:signal',
          id: value
        });

      case 'user':
        return schemaInterface.reference({
          type: 'urn:user',
          id: value
        });

      case 'integration':
        return schemaInterface.reference({
          type: 'urn:integration',
          id: value
        });

      case 'users':
        return schemaInterface.managedArray(value.map(id => {
          return schemaInterface.reference({
            type: 'urn:user',
            id
          });
        }));

      case 'groups':
        return schemaInterface.managedArray(value.map(id => {
          return schemaInterface.reference({
            type: 'urn:group',
            id
          });
        }));

      case 'permissions':
        return schemaInterface.nested({
          id: null,
          type: `${modelName}:permissions`,
          attributes: value
        });

      case 'insertStats':
        return schemaInterface.nested({
          id: null,
          type: `${modelName}:insert-stats`,
          attributes: value
        });

      case 'displayOptions':
        return schemaInterface.nested({
          id: null,
          type: `${modelName}:display-options`,
          attributes: value
        });

      default:
        switch (modelName) {
          case 'urn:item:permissions':
          case 'urn:group:permissions':
          case 'urn:user:permissions':
          case 'urn:organization:permissions':
          case 'urn:integration:permissions':
          case 'urn:signal:permissions':
          case 'urn:member:permissions':
          case 'urn:dataset:permissions':
            return schemaInterface.managedArray(value.map(id => {
              let type = id.includes('|') ? 'urn:user' : 'urn:group';
              return schemaInterface.reference({
                type,
                id
              });
            }));
        }

        return null;
    }
  }

  class _default extends _m3Schema.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "models", {
        'urn:integration:insert-stats': {
          transforms: {
            trackedSince: dateTransform,
            firstInsertAt: dateTransform,
            lastInsertAt: dateTransform,
            recentInsertInterval: durationTransform
          }
        },
        'urn:integration': {
          transforms: {
            createdAt: dateTransform,
            updatedAt: dateTransform,
            expiresAt: dateTransform,
            deletedAt: dateTransform,
            dataUpdateInterval: durationTransform
          }
        },
        'urn:subscription': {
          transforms: {
            createdAt: dateTransform,
            updatedAt: dateTransform,
            trialStart: dateTransform,
            trialEnd: dateTransform,
            currentPeriodStart: dateTransform,
            currentPeriodEnd: dateTransform
          }
        },
        'urn:item': {
          transforms: {
            createdAt: dateTransform,
            updatedAt: dateTransform,
            deletedAt: dateTransform,
            expiresAt: dateTransform,
            sampleInterval: durationTransform,
            deltaInterval: durationTransform
          }
        },
        'urn:signal': {
          transforms: {
            createdAt: dateTransform,
            updatedAt: dateTransform,
            deletedAt: dateTransform,
            expiresAt: dateTransform,
            sampleInterval: durationTransform,
            deltaInterval: durationTransform
          }
        },
        'urn:group': {
          transforms: {
            createdAt: dateTransform,
            updatedAt: dateTransform
          }
        },
        'urn:dataset': {
          transforms: {
            createdAt: dateTransform,
            updatedAt: dateTransform
          }
        },
        'urn:member': {
          transforms: {
            createdAt: dateTransform,
            updatedAt: dateTransform,
            inviteExpiresAt: dateTransform
          }
        },
        'urn:organization': {
          transforms: {
            createdAt: dateTransform,
            updatedAt: dateTransform
          }
        }
      });
    }

    /**
     * Determines whether an attribute is a reference.
     * If it is not, return `null` or `undefined`.
     * Otherwise return an object with properties:
     *  - `id` The id of the referenced model (either m3 or `@ember-data/model`)
     *  - `type` The type of the referenced model (either m3 or `@ember-data/model`)
     * `null` is also a valid type in which case `id` will be looked up in a global cache.
     *
     * Note that attribute references are all treated as synchronous.
     * There is no ember-m3 analogue to `@ember-data/model` async relationships.
     *
     * @param {string} key
     * @param {Object} value
     * @param {string} modelName
     * @param {M3SchemaInterface} schemaInterface
     * @returns {Object}
     */
    computeAttribute(key, value, modelName, schemaInterface) {
      return computeAttributeReference(key, value, modelName, schemaInterface);
    }
    /**
     * Whether or not ember-m3 should handle this `modelName`.
     *
     * @param {string} modelName
     * @returns {boolean}
     */


    includesModel(modelName) {
      switch (modelName) {
        case 'urn:item':
        case 'urn:integration':
        case 'urn:organization':
        case 'urn:user':
        case 'urn:group':
        case 'urn:signal':
        case 'urn:member':
        case 'urn:subscription':
        case 'urn:dataset':
          return true;

        default:
          return false;
      }
    }

    useNativeProperties() {
      return true;
    }

  }

  _exports.default = _default;
});