define("ember-svg-jar/inlined/icon-cross-16", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 9.06l3.97 3.97 1.06-1.06L9.06 8l3.97-3.97-1.06-1.06L8 6.94 4.03 2.97 2.97 4.03 6.94 8l-3.97 3.97 1.06 1.06L8 9.06z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});