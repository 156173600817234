define("ember-svg-jar/inlined/admin-user-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M7.1 17a5.002 5.002 0 019.8 0 7 7 0 10-9.8 0zm4.9 3a8 8 0 100-16 8 8 0 000 16zm2.5-10.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});