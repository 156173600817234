define("draggable/components/drop-zone", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/runloop", "draggable/helpers/accept", "draggable/components/sort-interaction"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _runloop, _accept, _sortInteraction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{on "drop" (fn this.drop)}}
    {{on "dragover" (fn this.dragOver)}}
    data-over={{this.over}}
    data-over-document={{this.overDocument}}
    ...attributes
  >
    {{yield (hash over=this.over types=this.types overDocument=this.overDocument documentTypes=this.documentTypes)}}
  </div>
  */
  {
    "id": "vV9wt9VB",
    "block": "[[[11,0],[16,\"data-over\",[30,0,[\"over\"]]],[16,\"data-over-document\",[30,0,[\"overDocument\"]]],[17,1],[4,[38,0],[\"drop\",[28,[37,1],[[30,0,[\"drop\"]]],null]],null],[4,[38,0],[\"dragover\",[28,[37,1],[[30,0,[\"dragOver\"]]],null]],null],[12],[1,\"\\n  \"],[18,2,[[28,[37,3],null,[[\"over\",\"types\",\"overDocument\",\"documentTypes\"],[[30,0,[\"over\"]],[30,0,[\"types\"]],[30,0,[\"overDocument\"]],[30,0,[\"documentTypes\"]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"on\",\"fn\",\"yield\",\"hash\"]]",
    "moduleName": "draggable/components/drop-zone.hbs",
    "isStrictMode": false
  });

  let DropZone = (_dec = (0, _object.computed)('dragCoordinator.types'), _dec2 = (0, _object.computed)('dragCoordinator.types'), _dec3 = (0, _object.computed)('args.allow'), (_class = class DropZone extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dragCoordinator", _descriptor, this);

      _initializerDefineProperty(this, "over", _descriptor2, this);

      _initializerDefineProperty(this, "types", _descriptor3, this);
    }

    get enabled() {
      return this.args.enabled ?? true;
    }

    get overDocument() {
      return (0, _accept.shouldAcceptTypes)(this.allowSet, this.dragCoordinator.types);
    }

    get documentTypes() {
      return new Set(this.dragCoordinator.types);
    }

    get allowSet() {
      return (0, _accept.normalizeAllow)(this.args.allow);
    }

    async drop(event) {
      if (!this.enabled) {
        return;
      }

      this.over = false;
      this.types = [];
      let dataTransfer = event.dataTransfer;

      if (dataTransfer && (0, _accept.shouldAcceptEvent)(this.allowSet, event)) {
        event.preventDefault();
        event.stopPropagation();
        let contextProvider = dataTransfer.getData('clarify/provider');
        let provider = this.dragCoordinator.contextProviderFor(contextProvider);

        if (provider) {
          let data = dataTransfer.getData(`clarify/${contextProvider}`);
          let payload = JSON.parse(data);
          let rawModels = await provider.deserialize(payload, dataTransfer);
          let modelContextProvider = this.args.modelContextProvider || contextProvider;
          let models = provider.convertTo ? await provider.convertTo(modelContextProvider, rawModels) : rawModels;
          this.args.dropAction(models, dataTransfer);
        }
      }
    }

    end() {
      this.over = false;
    }

    dragOver(event) {
      if (event.dataTransfer) {
        if (this.enabled !== false && (0, _accept.shouldAcceptEvent)(this.allowSet, event)) {
          (0, _sortInteraction.stopPropagation)(event);
          event.preventDefault();
          (0, _runloop.debounce)(this, this.end, 100);

          if (this.over === false) {
            const types = (0, _accept.typesForDataTransfer)(event.dataTransfer);
            this.over = true;
            this.types = types;
          }
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dragCoordinator", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "over", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "types", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "overDocument", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "overDocument"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "documentTypes", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "documentTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allowSet", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "allowSet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype)), _class));
  _exports.default = DropZone;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropZone);
});