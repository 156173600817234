define("admin/components/routes/invitations", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PageLayout::Simple>
    <:header>
      <PageLayout::Header @icon="#admin-invitations-48" @headline={{t "invitations-index.header.title"}}>
        <:actions>
          <FlatButton::Link
            data-intercom-target="Invite member"
            @route={{route "admin.invitations.new"}}
            @text={{t "invitations-index.header.invite-button"}}
            @icon="#design-system-p-plus-thick-24"
            @size="medium"
          />
        </:actions>
      </PageLayout::Header>
    </:header>
    <:content>
      <DataInvitations @organization={{@organization}} @invitations={{@invitations}} @members={{@members}} />
    </:content>
  </PageLayout::Simple>
  */
  {
    "id": "irVrS6Yh",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@headline\"],[\"#admin-invitations-48\",[28,[37,2],[\"invitations-index.header.title\"],null]]],[[\"actions\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,\"data-intercom-target\",\"Invite member\"]],[[\"@route\",\"@text\",\"@icon\",\"@size\"],[[28,[37,4],[\"admin.invitations.new\"],null],[28,[37,2],[\"invitations-index.header.invite-button\"],null],\"#design-system-p-plus-thick-24\",\"medium\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],null,[[\"@organization\",\"@invitations\",\"@members\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n  \"]],[]]]]]],[\"@organization\",\"@invitations\",\"@members\"],false,[\"page-layout/simple\",\"page-layout/header\",\"t\",\"flat-button/link\",\"route\",\"data-invitations\"]]",
    "moduleName": "admin/components/routes/invitations.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});