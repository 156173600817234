define("admin/templates/admin/admin-non-admin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0Fzk0h3f",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"non-admin.description\"],null]]],null]],[],false,[\"non-ideal-state\",\"t\"]]",
    "moduleName": "admin/templates/admin/admin-non-admin.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});