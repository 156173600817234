define("admin/components/metadata/fieldsets/textarea", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Fieldset>
    <:label as |id|>
      <Label @for={{id}} @title={{@label}} @tooltip={{@tooltip}} />
    </:label>
    <:input as |id|>
      <InputTextArea
        maxlength="1000"
        id={{id}}
        disabled={{@disabled}}
        placeholder={{@placeholder}}
        {{setup-field @field}}
      />
    </:input>
  </Fieldset>
  */
  {
    "id": "c7cRmjgI",
    "block": "[[[8,[39,0],null,null,[[\"label\",\"input\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@for\",\"@title\",\"@tooltip\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n  \"]],[1]],[[[1,\"\\n    \"],[8,[39,2],[[24,\"maxlength\",\"1000\"],[16,1,[30,4]],[16,\"disabled\",[30,5]],[16,\"placeholder\",[30,6]],[4,[38,3],[[30,7]],null]],null,null],[1,\"\\n  \"]],[4]]]]]],[\"id\",\"@label\",\"@tooltip\",\"id\",\"@disabled\",\"@placeholder\",\"@field\"],false,[\"fieldset\",\"label\",\"input-text-area\",\"setup-field\"]]",
    "moduleName": "admin/components/metadata/fieldsets/textarea.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});