define("admin/components/metadata/fieldsets/textfield", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Fieldset>
    <:label as |id|>
      <Label @for={{id}} @title={{@label}} @tooltip={{@tooltip}} />
    </:label>
    <:input as |id|>
      <InputText
        ...attributes
        maxlength="255"
        id={{id}}
        disabled={{@disabled}}
        placeholder={{or @placeholder (t "metadata.default-placeholder")}}
        data-validity={{if @field.isInvalid "invalid"}}
        {{setup-field @field}}
      />
      {{#if @description}}
        <AText @text={{@description}} @theme="faded" />
      {{/if}}
    </:input>
  </Fieldset>
  */
  {
    "id": "upgnyusj",
    "block": "[[[8,[39,0],null,null,[[\"label\",\"input\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@for\",\"@title\",\"@tooltip\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n  \"]],[1]],[[[1,\"\\n    \"],[8,[39,2],[[17,5],[24,\"maxlength\",\"255\"],[16,1,[30,4]],[16,\"disabled\",[30,6]],[16,\"placeholder\",[28,[37,3],[[30,7],[28,[37,4],[\"metadata.default-placeholder\"],null]],null]],[16,\"data-validity\",[52,[30,8,[\"isInvalid\"]],\"invalid\"]],[4,[38,6],[[30,8]],null]],null,null],[1,\"\\n\"],[41,[30,9],[[[1,\"      \"],[8,[39,7],null,[[\"@text\",\"@theme\"],[[30,9],\"faded\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[4]]]]]],[\"id\",\"@label\",\"@tooltip\",\"id\",\"&attrs\",\"@disabled\",\"@placeholder\",\"@field\",\"@description\"],false,[\"fieldset\",\"label\",\"input-text\",\"or\",\"t\",\"if\",\"setup-field\",\"a-text\"]]",
    "moduleName": "admin/components/metadata/fieldsets/textfield.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});