define("admin/components/data-organization/about/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminCard>
    <:header>
      <h3 class="data-organization-about--title">
        {{@organization.name}}
      </h3>
      <LinkTo class="data-organization--text-button" @route={{"admin.organization.edit"}} @model={{@organization.id}}>
        {{t "organization-index.about.actions.edit"}}
      </LinkTo>
    </:header>
    <:content>
      <div class="data-organization-about--content-container">
        <AdminCard::StatusBlock
          @title={{@members.pagination.total}}
          @description={{t "organization-index.about.content.members"}}
        />
        <AdminCard::StatusBlock
          @title={{@integrations.pagination.total}}
          @description={{t "organization-index.about.content.integrations"}}
        />
        <AdminCard::StatusBlock
          @title={{@items.pagination.total}}
          @description={{t "organization-index.about.content.items"}}
        />
      </div>
    </:content>
  </AdminCard>
  */
  {
    "id": "hVN2YlUM",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[10,\"h3\"],[14,0,\"data-organization-about--title\"],[12],[1,\"\\n      \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],[[24,0,\"data-organization--text-button\"]],[[\"@route\",\"@model\"],[\"admin.organization.edit\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"organization-index.about.actions.edit\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[10,0],[14,0,\"data-organization-about--content-container\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@title\",\"@description\"],[[30,2,[\"pagination\",\"total\"]],[28,[37,2],[\"organization-index.about.content.members\"],null]]],null],[1,\"\\n      \"],[8,[39,3],null,[[\"@title\",\"@description\"],[[30,3,[\"pagination\",\"total\"]],[28,[37,2],[\"organization-index.about.content.integrations\"],null]]],null],[1,\"\\n      \"],[8,[39,3],null,[[\"@title\",\"@description\"],[[30,4,[\"pagination\",\"total\"]],[28,[37,2],[\"organization-index.about.content.items\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@organization\",\"@members\",\"@integrations\",\"@items\"],false,[\"admin-card\",\"link-to\",\"t\",\"admin-card/status-block\"]]",
    "moduleName": "admin/components/data-organization/about/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});