define("ember-svg-jar/inlined/admin-company-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M20 18.998h-2.112v-2.16H14.67v2.16h-2.137V6.035H20v12.963zM13.6 8.16h2.133V7.151H13.6V8.16zm3.2 0h2.133V7.151H16.8V8.16zm-3.2 2.16h2.133V9.313H13.6v1.008zm3.2 0h2.133V9.313H16.8v1.008zm-3.2 2.161h2.133v-1.009H13.6v1.009zm3.2 0h2.133v-1.009H16.8v1.009zm-3.2 2.16h2.133v-1.008H13.6v1.009zm3.2 0h2.133v-1.008H16.8v1.009zM4 18.998h7.467v-7.562H4v7.562zm1.067-5.437H7.2v-1.009H5.067v1.01zm3.2 0H10.4v-1.009H8.267v1.01zm-3.2 2.16H7.2v-1.008H5.067v1.009zm3.2 0H10.4v-1.008H8.267v1.009zm-3.2 2.161H7.2v-1.009H5.067v1.01zm3.2 0H10.4v-1.009H8.267v1.01z\"/><path clip-rule=\"evenodd\" d=\"M13.6 6.21h5.333V5H13.6v1.21zM5.066 11.61H10.4v-1.21H5.066v1.21zM10.506 19h2.987v-3.24h-2.987V19z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});