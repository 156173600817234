define("clarify-data-layer/adapters/-ember-m3", ["exports", "ember-data/adapters/rest", "clarify-data-layer/record-payload", "clarify-data-layer/errors/meta-error"], function (_exports, _rest, _recordPayload, _metaError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let attributes = {
    'urn:item': ['id', 'name', 'description', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'deletedAt', 'expiresAt', 'sourceType', 'deltaInterval', 'sampleInterval', 'engUnit', 'type', 'source', 'visible', 'displayOptions', 'enumValues', 'labels', 'permissions', 'organization'],
    'urn:integration': ['id', 'name', 'description', 'createdAt', 'updatedAt', 'updatedBy', 'createdBy', 'organization', 'dataUpdateInterval', 'permissions', 'labels', 'insertStats']
  };

  class M3Adapter extends _rest.default {
    buildURL(modelName, id, snapshot, requestType, query, options) {
      let adapterOptions = (options === null || options === void 0 ? void 0 : options.adapterOptions) ?? snapshot.adapterOptions;

      if (adapterOptions !== null && adapterOptions !== void 0 && adapterOptions.url) {
        let url = `${this.host}/${this.namespace}/${adapterOptions.url}`;

        if (query) {
          let fields = attributes[modelName] ? {
            fields: attributes[modelName].join(',')
          } : {};
          return this.queryParametersForURL(url, { ...query,
            ...fields
          });
        }

        return url;
      }

      return super.buildURL(modelName, id, snapshot, requestType, query);
    }

    urlForFindAll(_, snapshot) {
      return this.urlForResource(snapshot.modelName);
    }

    urlForFindRecord(modelId, _, snapshot) {
      return this.urlForRecord(modelId, snapshot);
    }

    urlForCreateRecord(_, snapshot) {
      switch (snapshot.modelName) {
        case 'urn:group':
          let organization = snapshot.attr('organization');
          return `${this.host}/${this.namespace}/organizations/${organization.id}/groups`;

        case 'urn:signal':
          let integration = snapshot.attr('integration');
          return `${this.host}/${this.namespace}/integrations/${integration.id}/signals`;

        default:
          return this.urlForResource(snapshot.modelName);
      }
    }

    urlForUpdateRecord(modelId, type, snapshot) {
      switch (snapshot.modelName) {
        case 'urn:signal':
          let integration = snapshot.attr('integration');
          return `${this.host}/${this.namespace}/integrations/${integration.id}/signals/${modelId}`;

        case 'urn:group':
          let organization = snapshot.attr('organization');
          return `${this.host}/${this.namespace}/organizations/${organization.id}/groups/${snapshot.id}`;

        case 'urn:member':
          let memberOrganization = snapshot.attr('organization');
          return `${this.host}/${this.namespace}/organizations/${memberOrganization.id}/members/${snapshot.id}`;

        default:
          return this.urlForRecord(modelId, snapshot);
      }
    }

    urlForQuery(query, type) {
      let queryParameters = new URLSearchParams();

      for (let [key, value] of Object.entries(query || {})) {
        queryParameters.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
      }

      let url = this.urlForResource(type);
      let queryString = queryParameters.toString();

      if (queryString) {
        let conjuction = url.indexOf('?') === -1 ? '?' : '&';
        return `${url}${conjuction}${queryString}`;
      }

      return url;
    }

    urlForRecord(modelId, snapshot) {
      switch (snapshot.modelName) {
        case 'urn:signal':
          let integration = snapshot.attr('integration');
          return `${this.host}/${this.namespace}/integrations/${integration.id}/signals/${modelId}`;

        case 'urn:item':
          return `${this.host}/${this.namespace}/items/${modelId}?fields=${attributes['urn:item'].join(',')}`;

        case 'urn:integration':
          return `${this.host}/${this.namespace}/integrations/${modelId}?fields=${attributes['urn:integration'].join(',')}`;

        case 'urn:organization':
          return `${this.host}/${this.namespace}/organizations/${modelId}`;
      }
    }

    urlForResource(modelName) {
      switch (modelName) {
        case 'urn:item':
          return `${this.host}/${this.namespace}/items?fields=${attributes['urn:item'].join(',')}`;

        case 'urn:integration':
          return `${this.host}/${this.namespace}/integrations?fields=${attributes['urn:integration'].join(',')}`;

        case 'urn:organization':
          return `${this.host}/${this.namespace}/organizations`;

        default:
          throw new Error(`Unsupported resource: ${modelName}`);
      }
    }

    normalizeRecord(payload, modelName) {
      let record = new _recordPayload.default(payload, modelName);
      return record.jsonApiPayload();
    }

    normalizeFindResponse(payload, snapshot, response) {
      let meta = {};
      let total = response.headers.get('x-total');
      let offset = response.headers.get('x-offset');

      if (total || offset) {
        meta.pagination = {
          total: total ? parseInt(total, 10) : Infinity,
          offset: offset ? parseInt(offset, 10) : 0
        };
      }

      let models = [];
      let included = [];

      for (let model of payload) {
        let data = this.normalizeRecord(model, snapshot.modelName);
        models.push(data.data);
        included.push(...data.included);
      }

      return {
        data: models,
        meta,
        included
      };
    }

    async findAll(store, type, id, snapshot) {
      let url = this.buildURL(snapshot.modelName, id, snapshot, 'findAll', null);
      let response = await this.fetch(url);
      let payload = await this.parse(response);
      return this.normalizeFindResponse(payload, snapshot, response);
    }

    async query(store, type, query, snapshot, options) {
      let url = this.buildURL(snapshot.modelName, null, snapshot, 'query', query, options);
      let response = await this.fetch(url);
      let payload = await this.parse(response);
      return this.normalizeFindResponse(payload, snapshot, response);
    }

    async queryURL(url, method, options) {
      let adapterOptions = options.adapterOptions ?? {};
      let path = this.queryParametersForURL(url, { ...options.params,
        ...options.queryParams
      });
      let response = await this.fetch(path);
      let payload = await this.parse(response);
      return this.normalizeFindResponse(payload, {
        modelName: adapterOptions.modelName
      }, response);
    }

    async findRecord(store, type, id, snapshot) {
      let url = this.buildURL(snapshot.modelName, id, snapshot, 'findRecord', null);
      let response = await this.fetch(url);
      let payload = await this.parse(response);
      return this.normalizeRecord(payload, snapshot.modelName);
    }

    async createRecord(store, model, snapshot) {
      let serializer = store.serializerFor('-ember-m3');
      let data = serializer.serialize(snapshot);
      let url = this.buildURL(snapshot.modelName, null, snapshot, 'createRecord', null);
      let response = await this.fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      let payload = await this.parse(response);
      return this.normalizeRecord(payload, snapshot.modelName);
    }

    async deleteRecord(store, model, snapshot) {
      let url = this.buildURL(snapshot.modelName, snapshot.id, snapshot, 'updateRecord', null);
      let response = await this.fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return await this.parse(response);
    }

    async updateRecord(store, model, snapshot) {
      let serializer = store.serializerFor('-ember-m3');
      let data = serializer.serialize(snapshot);
      let url = this.buildURL(snapshot.modelName, snapshot.id, snapshot, 'updateRecord', null);
      let response = await this.fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      let payload = await this.parse(response);
      return this.normalizeRecord(payload, snapshot.modelName);
    }

    queryParametersForURL(url, query) {
      let queryParameters = new URLSearchParams();

      for (let [key, value] of Object.entries(query || {})) {
        queryParameters.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
      }

      let queryString = queryParameters.toString();

      if (queryString) {
        let conjuction = url.indexOf('?') === -1 ? '?' : '&';
        return `${url}${conjuction}${queryString}`;
      }

      return url;
    }

    async fetch(input, init) {
      return fetch(input, init);
    }

    async parse(response) {
      if (response.status === 204) {
        return undefined;
      }

      let contentType = response.headers.get('Content-Type');

      if (contentType === 'application/json') {
        let payload = await response.json();

        if (response.ok) {
          return payload;
        }

        if (_metaError.default.isAPIError(payload)) {
          return Promise.reject(new _metaError.default(payload));
        }
      }

      return Promise.reject(new Error('Unknown error'));
    }

  }

  _exports.default = M3Adapter;
});