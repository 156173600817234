define("ember-m3/utils/schema-types-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MANAGED_ARRAY = _exports.REFERENCE = _exports.NESTED = _exports.schemaTypesInfo = void 0;
  const schemaTypesInfo = new WeakMap();
  _exports.schemaTypesInfo = schemaTypesInfo;
  const NESTED = 'nested';
  _exports.NESTED = NESTED;
  const REFERENCE = 'reference';
  _exports.REFERENCE = REFERENCE;
  const MANAGED_ARRAY = 'managed-array';
  _exports.MANAGED_ARRAY = MANAGED_ARRAY;
});