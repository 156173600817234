define("admin/components/metadata/fieldsets/textlabel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <HeaderContextual @title={{@label}} @tooltip={{@tooltip}} @icon={{@icon}} />
  */
  {
    "id": "J0eOigRT",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@tooltip\",\"@icon\"],[[30,1],[30,2],[30,3]]],null]],[\"@label\",\"@tooltip\",\"@icon\"],false,[\"header-contextual\"]]",
    "moduleName": "admin/components/metadata/fieldsets/textlabel.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});