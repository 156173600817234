define("admin/helpers/alert-signal-delete", ["exports", "@ember/object", "clarify/helpers/alert"], function (_exports, _object, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AlertSignalDelete = (_class = class AlertSignalDelete extends _alert.default {
    compute([signal], hash) {
      return () => {
        this.showAlert({
          title: this.intl.t('alert-signal-delete.initial.title', {
            name: (0, _object.get)(signal, 'name')
          }),
          message: this.intl.t('alert-signal-delete.initial.message', {
            htmlSafe: true
          }),
          primaryButton: {
            title: this.intl.t('alert-signal-delete.initial.primary-button'),
            action: () => {
              this.showExtraAlert(hash.task);
            },
            style: 'destructive'
          }
        });
      };
    }

    showExtraAlert(deleteSignalTask) {
      this.showAlert({
        title: this.intl.t('alert-signal-delete.confirmation.title'),
        message: this.intl.t('alert-signal-delete.confirmation.message', {
          htmlSafe: true
        }),
        primaryButton: {
          title: this.intl.t('alert-signal-delete.confirmation.primary-button'),
          task: deleteSignalTask,
          style: 'destructive'
        }
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "showExtraAlert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showExtraAlert"), _class.prototype)), _class);
  _exports.default = AlertSignalDelete;
});