define("admin/templates/admin/integrations/index/show/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Oam0tUjS",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"integrations-show.browser-title\"],[[\"pane\",\"name\"],[[30,0,[\"pane\"]],[30,0,[\"integration\",\"name\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@pane\",\"@categories\",\"@integration\"],[[30,0,[\"pane\"]],[30,0,[\"categories\"]],[30,0,[\"integration\"]]]],null]],[],false,[\"page-title\",\"t\",\"routes/integrations/index/show\"]]",
    "moduleName": "admin/templates/admin/integrations/index/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});