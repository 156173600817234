define("admin/components/dropdown-list/item/default", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-invalid-interactive }}
  <li
    class="labels-dropdown-list-item-default"
    data-highlighted={{@highlighted}}
    ...attributes
    {{on "click" (fn @select @item @index)}}
  >
    <div class="labels-dropdown-list-item-default-title">
      {{@item.title}}
    </div>
  </li>
  */
  {
    "id": "wcNwLHPW",
    "block": "[[[11,\"li\"],[24,0,\"labels-dropdown-list-item-default\"],[16,\"data-highlighted\",[30,1]],[17,2],[4,[38,0],[\"click\",[28,[37,1],[[30,3],[30,4],[30,5]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"labels-dropdown-list-item-default-title\"],[12],[1,\"\\n    \"],[1,[30,4,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@highlighted\",\"&attrs\",\"@select\",\"@item\",\"@index\"],false,[\"on\",\"fn\"]]",
    "moduleName": "admin/components/dropdown-list/item/default.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});