define("admin/components/metadata/fieldsets/labels-readonly/item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Fieldset>
    <:label>
      <Label @title={{@category.title}} @tooltip={{@category.key}} />
    </:label>
    <:input>
      <div class="labels-container">
        {{#each @labels as |label|}}
          <ATag
            role="listitem"
            class="labels-container--tag"
            @text={{t "timeline-card.categories.title" tag=label}}
            title={{t "timeline-card.categories.tooltip" tooltip=label}}
          />
        {{else}}
          <div class="labels-container--empty">
            {{t "labels.empty-state"}}
          </div>
        {{/each}}
      </div>
    </:input>
  </Fieldset>
  */
  {
    "id": "9aBDiM+I",
    "block": "[[[8,[39,0],null,null,[[\"label\",\"input\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@title\",\"@tooltip\"],[[30,1,[\"title\"]],[30,1,[\"key\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[10,0],[14,0,\"labels-container\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,2]],null]],null],null,[[[1,\"        \"],[8,[39,4],[[24,\"role\",\"listitem\"],[24,0,\"labels-container--tag\"],[16,\"title\",[28,[37,5],[\"timeline-card.categories.tooltip\"],[[\"tooltip\"],[[30,3]]]]]],[[\"@text\"],[[28,[37,5],[\"timeline-card.categories.title\"],[[\"tag\"],[[30,3]]]]]],null],[1,\"\\n\"]],[3]],[[[1,\"        \"],[10,0],[14,0,\"labels-container--empty\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[\"labels.empty-state\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@category\",\"@labels\",\"label\"],false,[\"fieldset\",\"label\",\"each\",\"-track-array\",\"a-tag\",\"t\"]]",
    "moduleName": "admin/components/metadata/fieldsets/labels-readonly/item.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});