define("admin/components/admin-state/loading", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-loading-content-screen">
    <div class="admin-loading-content-screen--center">
      {{svg-jar "clarify-content-loading" width=94 height=65}}
      <div class="admin-loading-content-screen-title">
        {{@title}}
      </div>
    </div>
  </div>
  */
  {
    "id": "CGkhgUew",
    "block": "[[[10,0],[14,0,\"admin-loading-content-screen\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-loading-content-screen--center\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"clarify-content-loading\"],[[\"width\",\"height\"],[94,65]]]],[1,\"\\n    \"],[10,0],[14,0,\"admin-loading-content-screen-title\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@title\"],false,[\"svg-jar\"]]",
    "moduleName": "admin/components/admin-state/loading.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});