define("admin/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function serializeIntegration(model) {
    return {
      integrationId: model.id
    };
  }

  var _default = (0, _routes.default)(function () {
    this.route('admin', {
      path: '/'
    }, function () {
      this.route('upgrade');
      this.route('item-viewer', {
        path: '/item-viewer/:itemId'
      });
      this.route('organization', function () {
        this.route('edit');
      });
      this.route('groups', function () {
        this.route('group-edit-members', {
          path: '/:groupId/members'
        });
        this.route('show', {
          path: '/:groupId'
        }, function () {});
        this.route('new');
      });
      this.route('integrations', function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.route('show', {
            path: '/:integrationId'
          }, function () {});
          this.route('new');
          this.route('created', {
            serialize: serializeIntegration,
            path: '/:integrationId/created'
          });
        });
        this.route('signals', {
          path: '/:integrationId/signals'
        }, function () {
          this.route('publish', {
            path: '/:signalId/publish'
          });
          this.route('edit-signal', {
            path: '/:signalId/edit'
          });
          this.route('show', {
            path: '/:signalId'
          }, function () {
            this.route('index', {
              path: '/'
            }, function () {
              this.route('connect', {
                path: '/connect'
              }, function () {});
            });
          });
          this.route('new', {
            path: '/new'
          });
          this.route('created', {
            path: '/:signalId/created'
          });
        });
      });
      this.route('invitations', function () {
        this.route('new');
      });
      this.route('items', function () {
        this.route('show', {
          path: '/:itemId'
        }, function () {});
        this.route('keywords', function () {
          this.route('new', {
            path: '/new'
          });
          this.route('edit', {
            path: '/:key'
          });
        });
      });
      this.route('users', function () {
        this.route('edit-group-memberships', {
          path: '/:memberId/groups'
        });
        this.route('show', {
          path: '/:memberId'
        }, function () {});
        this.route('invitations');
      });
    });
  });

  _exports.default = _default;
});