define("admin/components/data-grid/cell-checkbox/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="data-grid-cell" data-type="selected" role="gridcell" ...attributes>
    <Checkbox
      @size="normal"
      @checked={{@checked}}
      @style="light"
      @onChange={{fn @handleOnChange}}
      {{roving @pointer row=@row column=@column pointerDidChange=@pointerDidChange}}
    />
  </div>
  */
  {
    "id": "8Oi3qVkA",
    "block": "[[[11,0],[24,0,\"data-grid-cell\"],[24,\"data-type\",\"selected\"],[24,\"role\",\"gridcell\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],[[4,[38,2],[[30,4]],[[\"row\",\"column\",\"pointerDidChange\"],[[30,5],[30,6],[30,7]]]]],[[\"@size\",\"@checked\",\"@style\",\"@onChange\"],[\"normal\",[30,2],\"light\",[28,[37,1],[[30,3]],null]]],null],[1,\"\\n\"],[13]],[\"&attrs\",\"@checked\",\"@handleOnChange\",\"@pointer\",\"@row\",\"@column\",\"@pointerDidChange\"],false,[\"checkbox\",\"fn\",\"roving\"]]",
    "moduleName": "admin/components/data-grid/cell-checkbox/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});