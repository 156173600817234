define("ember-svg-jar/inlined/clarify-content-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<rect x=\"10\" y=\"10\" width=\"4\" height=\"45\" fill=\"#4564FD\">\n  <animateTransform attributeName=\"transform\" additive=\"sum\" accumulate=\"sum\" type=\"translate\" values=\"0 0 ; 0 0 ; -4 0 ; 0 0\" keyTimes=\"0; 0.6875; 0.8125; 1\" dur=\"1.33s\" begin=\"0\" repeatCount=\"indefinite\" fill=\"freeze\" calcMode=\"spline\" keySplines=\"0 0 0 0 ; 0.167 0.167 0.407 1 ; 0.593 0 0.833 0.833\">\n  </animateTransform>\n</rect>\n<rect x=\"80\" y=\"10\" width=\"4\" height=\"45\" fill=\"#4564FD\">\n  <animateTransform attributeName=\"transform\" additive=\"sum\" accumulate=\"sum\" type=\"translate\" values=\"0 0 ; 0 0 ; 4 0 ; 0 0 ; 0 0\" keyTimes=\"0; 0.1875; 0.3125; 0.5625; 1\" dur=\"1.33s\" begin=\"0\" repeatCount=\"indefinite\" fill=\"freeze\" calcMode=\"spline\" keySplines=\"0 0 0 0 ; 0.167 0.167 0.407 1 ; 0.593 0 0.833 0.833 ; 0 0 0 0\">\n  </animateTransform>\n</rect>\n<circle cx=\"47\" cy=\"33\" r=\"8\" fill=\"#4564FD\">\n  <animateTransform attributeName=\"transform\" additive=\"sum\" accumulate=\"sum\" type=\"translate\" values=\"0 0 ; 24 0 ; 0 0 ; -24 0 ; 0 0\" keyTimes=\"0; 0.2813; 0.5; 0.7813; 1\" dur=\"1.33s\" begin=\"0\" repeatCount=\"indefinite\" fill=\"freeze\" calcMode=\"spline\" keySplines=\"0.167 0.167 0.374 1 ; 0.626 0 0.833 0.833 ; 0.167 0.167 0.374 1 ; 0.626 0 0.833 0.833\">\n  </animateTransform>\n</circle>\n",
    "attrs": {
      "width": "94",
      "height": "65",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});