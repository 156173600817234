define("admin/helpers/alert-item-delete", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AlertItemDelete extends _alert.default {
    compute([item], hash) {
      return () => {
        this.showAlert({
          title: this.intl.t('alert-item-delete.title', {
            name: item.name
          }),
          message: this.intl.t('alert-item-delete.message', {
            htmlSafe: true
          }),
          primaryButton: {
            title: this.intl.t('alert-item-delete.primary-button'),
            task: hash.task,
            style: 'destructive'
          }
        });
      };
    }

  }

  _exports.default = AlertItemDelete;
});