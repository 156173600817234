define("admin/templates/admin/invitations", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "U4MwBeDz",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"users-index.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@invitations\",\"@members\",\"@organization\"],[[30,0,[\"invitations\"]],[30,0,[\"members\"]],[30,0,[\"organization\"]]]],null],[1,\"\\n\"],[8,[39,3],null,null,null]],[],false,[\"page-title\",\"t\",\"routes/invitations\",\"admin-outlet\"]]",
    "moduleName": "admin/templates/admin/invitations.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});