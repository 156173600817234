define("ember-svg-jar/inlined/icon-remove-trash-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M9.985 4v1.245H5.5v1.661h12.957V5.245h-4.486V4H9.985zM7.258 19.999h9.451V8.185H7.258v11.814zm6.993-1.654h1.182V9.781h-1.182v8.564zm-2.858 0h1.182V9.781h-1.182v8.564zm-2.86 0h1.182V9.781H8.533v8.564z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});