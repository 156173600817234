define("gesture-recognizer/services/gesture", ["exports", "@ember/service", "gesture-recognizer/gestures/-base", "@ember/object", "@ember-decorators/object", "@ember/object/internals"], function (_exports, _service, _base, _object, _object2, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Cache {
    constructor() {
      _defineProperty(this, "storage", new Map());
    }

    clear() {
      this.storage.clear();
    }

    set(a, b, result) {
      let keyA = (0, _internals.guidFor)(a);
      let keyB = (0, _internals.guidFor)(b);
      this.storage.set(`${keyA}-${keyB}`, result);
      this.storage.set(`${keyB}-${keyA}`, result);
    }

    get(a, b) {
      let keyA = (0, _internals.guidFor)(a);
      let keyB = (0, _internals.guidFor)(b);
      return this.storage.get(`${keyA}-${keyB}`);
    }

  }

  let Gesture = (_dec = (0, _object2.observes)('activeGestureRecognizers.@each.{state}'), (_class = class Gesture extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "gestureRecognizers", new WeakMap());

      _defineProperty(this, "all", new Set());

      _defineProperty(this, "activeGestureRecognizers", null);

      _defineProperty(this, "wipGestureRecognizers", null);

      _defineProperty(this, "internalChange", false);

      _defineProperty(this, "simultaneousCache", new Cache());

      _defineProperty(this, "dependenciesCache", new Map());

      _defineProperty(this, "pointerDown", event => {
        (0, _object.set)(this, 'internalChange', true);
        let activeGestureRecognizers = this.start(event);

        if (activeGestureRecognizers) {
          activeGestureRecognizers.forEach(recognizer => {
            recognizer.pointerDown(event);
          });
          this.runCallbacks(activeGestureRecognizers);
          this.removeIfNeeded();
          this.resetIfNeeded();
        }

        (0, _object.set)(this, 'internalChange', false);
      });

      _defineProperty(this, "pointerUp", event => {
        (0, _object.set)(this, 'internalChange', true);

        if (this.activeGestureRecognizers) {
          let activeGestureRecognizers = this.activeGestureRecognizers.slice();
          activeGestureRecognizers.forEach(recognizer => {
            recognizer.pointerUp(event);
          });
          this.runCallbacks(activeGestureRecognizers);
          this.removeIfNeeded();
          this.resetIfNeeded();
        }

        (0, _object.set)(this, 'internalChange', false);
      });

      _defineProperty(this, "pointerMove", event => {
        (0, _object.set)(this, 'internalChange', true);

        if (this.activeGestureRecognizers) {
          let activeGestureRecognizers = this.activeGestureRecognizers.slice();
          activeGestureRecognizers.forEach(recognizer => {
            recognizer.pointerMove(event);
          });
          this.runCallbacks(activeGestureRecognizers);
          this.removeIfNeeded();
          this.resetIfNeeded();
        }

        (0, _object.set)(this, 'internalChange', false);
      });
    }

    init() {
      super.init();
      document.body.addEventListener('touchstart', this.pointerDown, {
        passive: false,
        capture: true
      });
      document.body.addEventListener('touchmove', this.pointerMove, true);
      window.addEventListener('touchend', this.pointerUp, true);
      document.body.addEventListener('mousedown', this.pointerDown, true);
      document.body.addEventListener('mousemove', this.pointerMove, true);
      window.addEventListener('mouseup', this.pointerUp, true);
    }

    destroy() {
      document.body.removeEventListener('mousedown', this.pointerDown, true);
      document.body.removeEventListener('mousemove', this.pointerMove, true);
      window.removeEventListener('mouseup', this.pointerUp, true);
      return super.destroy();
    }

    stateObserver() {
      if (!this.internalChange && this.activeGestureRecognizers) {
        (0, _object.set)(this, 'internalChange', true);
        this.runCallbacks(this.activeGestureRecognizers);
        this.removeIfNeeded();
        this.resetIfNeeded();
        (0, _object.set)(this, 'internalChange', false);
      }
    }

    add(gestureRecognizers, node) {
      let existing = this.gestureRecognizers.get(node) || [];
      this.gestureRecognizers.set(node, [...existing, ...gestureRecognizers]);
      gestureRecognizers.forEach(gestureRecognizer => {
        (0, _object.set)(gestureRecognizer, 'element', node);
        this.all.add(gestureRecognizer);
      });
    }

    delete(gestureRecognizers, node) {
      let gestures = new Set(gestureRecognizers);
      let existingGestureRecognizers = this.gestureRecognizers.get(node);

      if (existingGestureRecognizers) {
        let remaining = existingGestureRecognizers.filter(gesture => {
          return !gestures.has(gesture);
        });
        gestureRecognizers.forEach(gesture => {
          this.all.delete(gesture);
        });

        if (remaining.length) {
          this.gestureRecognizers.set(node, remaining);
        } else {
          this.gestureRecognizers.delete(node);
        }
      }
    }

    install(node, gestureRecognizers) {
      this.gestureRecognizers.set(node, gestureRecognizers);
      gestureRecognizers.forEach(gestureRecognizer => {
        (0, _object.set)(gestureRecognizer, 'element', node);
        this.all.add(gestureRecognizer);
      });
    }

    uninstall(node) {
      let gestureRecognizers = this.gestureRecognizers.get(node);

      if (gestureRecognizers) {
        gestureRecognizers.forEach(gestureRecognizer => {
          this.all.delete(gestureRecognizer);
        });
      }

      this.gestureRecognizers.delete(node);
    }

    start(event) {
      if (this.activeGestureRecognizers) {
        return this.activeGestureRecognizers;
      }

      let validRecognizers = this.nodes(event.target);
      let recognizers = validRecognizers.filter(recognizer => {
        if (recognizer.delegate && recognizer.delegate.shouldBegin) {
          return recognizer.delegate.shouldBegin.call(recognizer.delegate, recognizer, event);
        }

        return true;
      });

      if (recognizers.length) {
        Array.from(this.all).forEach(recognizer => {
          (0, _object.set)(recognizer, 'state', _base.GestureRecognizerState.failed);
        });
        recognizers.forEach(recognizer => {
          (0, _object.set)(recognizer, 'state', _base.GestureRecognizerState.possible);
        });
        (0, _object.set)(this, 'activeGestureRecognizers', recognizers);
        (0, _object.set)(this, 'wipGestureRecognizers', recognizers);
        return recognizers;
      }

      return null;
    }

    removeIfNeeded() {
      if (this.activeGestureRecognizers) {
        let filtered = this.activeGestureRecognizers.filter(recognizer => {
          return recognizer.state === _base.GestureRecognizerState.possible || recognizer.state === _base.GestureRecognizerState.began || recognizer.state === _base.GestureRecognizerState.changed;
        });
        (0, _object.set)(this, 'activeGestureRecognizers', filtered);
      }
    }

    resetIfNeeded() {
      if (this.activeGestureRecognizers && this.activeGestureRecognizers.length === 0) {
        this.reset();
      }
    }

    reset() {
      if (this.wipGestureRecognizers) {
        this.wipGestureRecognizers.forEach(recognizer => recognizer.reset());
      }

      (0, _object.set)(this, 'activeGestureRecognizers', null);
      (0, _object.set)(this, 'wipGestureRecognizers', null);
      this.simultaneousCache.clear();
      this.dependenciesCache.clear();
    }

    nodes(node) {
      let recognizers = [];
      let target = node;

      while (target) {
        let elementRecognizers = this.gestureRecognizers.get(target);

        if (elementRecognizers) {
          recognizers.push(...elementRecognizers);
        }

        target = target.parentNode;
      }

      return recognizers;
    }

    runCallbacks(gestureRecognizers) {
      let active = gestureRecognizers.filter(gestureRecognizer => {
        return (gestureRecognizer.state === _base.GestureRecognizerState.began || gestureRecognizer.state === _base.GestureRecognizerState.changed || gestureRecognizer.state === _base.GestureRecognizerState.ended) && this.dependenciesMeet(gestureRecognizer);
      });
      active.forEach((gestureRecognizer, index) => {
        if (index === 0) {
          gestureRecognizer.didChange(gestureRecognizer);
          return;
        }

        let earlierRecognizers = active.slice(0, index);
        let run = earlierRecognizers.find(simGest => {
          let value = this.simultaneousCache.get(gestureRecognizer, simGest);

          if (value === undefined) {
            let value = false;

            if (gestureRecognizer.delegate && gestureRecognizer.delegate.shouldRecognizeSimultaneously) {
              value = gestureRecognizer.delegate.shouldRecognizeSimultaneously.call(gestureRecognizer.delegate, gestureRecognizer, simGest);
            }

            if (value === false && simGest.delegate && simGest.delegate.shouldRecognizeSimultaneously) {
              value = simGest.delegate.shouldRecognizeSimultaneously.call(simGest.delegate, simGest, gestureRecognizer);
            }

            this.simultaneousCache.set(gestureRecognizer, simGest, value);
            return value;
          }

          return value;
        });

        if (run) {
          gestureRecognizer.didChange(gestureRecognizer);
        }
      });
    }

    dependenciesMeet(gestureRecognizer) {
      let dependencies = this.failDependenciesFor(gestureRecognizer);
      return !dependencies.find(dependency => dependency.state !== _base.GestureRecognizerState.failed);
    }

    failDependenciesFor(gestureRecognizer) {
      let gestures = this.dependenciesCache.get(gestureRecognizer);

      if (gestures) {
        return gestures;
      }

      let dependencies = [];
      let activeGestureRecognizers = this.activeGestureRecognizers;

      if (activeGestureRecognizers) {
        for (let recognizer of activeGestureRecognizers) {
          if (recognizer === gestureRecognizer || recognizer.state === _base.GestureRecognizerState.failed) {
            continue;
          }

          if (gestureRecognizer.delegate && gestureRecognizer.delegate.shouldRequireFailureOf) {
            let result = gestureRecognizer.delegate.shouldRequireFailureOf.call(gestureRecognizer.delegate, gestureRecognizer, recognizer);

            if (result) {
              dependencies.push(recognizer);
              continue;
            }
          }

          if (recognizer.delegate && recognizer.delegate.shouldBeRequiredToFailBy) {
            let result = recognizer.delegate.shouldBeRequiredToFailBy.call(recognizer.delegate, recognizer, gestureRecognizer);

            if (result) {
              dependencies.push(recognizer);
              continue;
            }
          }
        }
      }

      let gestureRecognizers = gestureRecognizer.requireToFail.filter(recognizer => recognizer.state !== _base.GestureRecognizerState.failed);
      dependencies.push(...gestureRecognizers);
      this.dependenciesCache.set(gestureRecognizer, dependencies);
      return dependencies;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "stateObserver", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "stateObserver"), _class.prototype)), _class));
  _exports.default = Gesture;
});