define("admin/components/modals/signal-connect/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "admin/components/data-grid", "clarify/utilities/associated-models", "admin/components/data-view", "clarify/utilities/compact-map", "clarify/utilities/rpc/integration/connect-signal", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _dataGrid, _associatedModels, _dataView, _compactMap, _connectSignal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{meta-attribute name="theme-color" content="#D6D7DB"}}
  <DialogContainer>
    <Dialog class="signal-connect--dialog">
      <:header>
        <Dialog::Header
          @title={{t "signal-connect.header.title"}}
          @route={{route "admin.integrations.signals.show.index"}}
        />
        <Modals::SignalConnect::-info @description={{t "signal-connect.info.description"}} />
      </:header>
      <:content>
        <DataView
          class="signal-connect--data-view"
          @route="admin.items.show"
          @resource={{@resource}}
          @descriptors={{this.visibleDescriptors}}
          @selectedModelsDidChange={{this.selectedModelsDidChange}}
          @columnsSelectorData={{this.differ.viewModels}}
          @selectionStyle={{hash type="single"}}
        />
      </:content>
      <:footer>
        <div class="signal-connect--footer">
          <FlatButton
            class="signal-connect--connect-button"
            type="button"
            @loading={{this.connectToItem.isRunning}}
            @text={{t "signal-connect.connect-button"}}
            {{on "click" (perform this.connectToItem)}}
          />
        </div>
      </:footer>
    </Dialog>
  </DialogContainer>
  */
  {
    "id": "Ea8h8aqR",
    "block": "[[[1,[28,[35,0],null,[[\"name\",\"content\"],[\"theme-color\",\"#D6D7DB\"]]]],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"signal-connect--dialog\"]],null,[[\"header\",\"content\",\"footer\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@title\",\"@route\"],[[28,[37,4],[\"signal-connect.header.title\"],null],[28,[37,5],[\"admin.integrations.signals.show.index\"],null]]],null],[1,\"\\n      \"],[8,[39,6],null,[[\"@description\"],[[28,[37,4],[\"signal-connect.info.description\"],null]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[39,7],[[24,0,\"signal-connect--data-view\"]],[[\"@route\",\"@resource\",\"@descriptors\",\"@selectedModelsDidChange\",\"@columnsSelectorData\",\"@selectionStyle\"],[\"admin.items.show\",[30,1],[30,0,[\"visibleDescriptors\"]],[30,0,[\"selectedModelsDidChange\"]],[30,0,[\"differ\",\"viewModels\"]],[28,[37,8],null,[[\"type\"],[\"single\"]]]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[10,0],[14,0,\"signal-connect--footer\"],[12],[1,\"\\n        \"],[8,[39,9],[[24,0,\"signal-connect--connect-button\"],[24,4,\"button\"],[4,[38,10],[\"click\",[28,[37,11],[[30,0,[\"connectToItem\"]]],null]],null]],[[\"@loading\",\"@text\"],[[30,0,[\"connectToItem\",\"isRunning\"]],[28,[37,4],[\"signal-connect.connect-button\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@resource\"],false,[\"meta-attribute\",\"dialog-container\",\"dialog\",\"dialog/header\",\"t\",\"route\",\"modals/signal-connect/-info\",\"data-view\",\"hash\",\"flat-button\",\"on\",\"perform\"]]",
    "moduleName": "admin/components/modals/signal-connect/index.hbs",
    "isStrictMode": false
  });

  let ItemsRoute = (_class = class ItemsRoute extends _component2.default {
    //Services
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "alert", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "network", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "differ", _descriptor6, this);

      _initializerDefineProperty(this, "selectedModels", _descriptor7, this);

      this.differ = new _associatedModels.default(this.descriptors, _dataView.SelectableViewModel);
    }

    selectedModelsDidChange(selectedModels) {
      this.selectedModels = selectedModels;
    }

    get visibleDescriptors() {
      return this.differ.viewModels.filter(viewModel => viewModel.enabled).map(viewModel => viewModel.model);
    }

    *connectToItem() {
      if (this.selectedModels.length === 1) {
        let model = this.selectedModels[0];

        let request = _connectSignal.ConnectSignalRequestInfo.create({
          signal: this.args.signal.id,
          item: model.id
        });

        try {
          let result = yield this.network.perform('meta/rpc', {}, request);
          this.store.push({
            data: {
              id: this.args.signal.id,
              type: 'urn:signal',
              attributes: {
                item: result.item.id
              }
            }
          });
          yield this.router.transitionTo('admin.integrations.signals.show.index');
        } catch (error) {
          console.error('Error: ', error);
        }
      } else {
        this.alert.show({
          title: this.intl.t('export-modal.exporting.errors.title'),
          message: this.intl.t('export-modal.exporting.errors.empty'),
          actions: [{
            title: this.intl.t('thread.errors.dismiss'),
            action: () => {
              this.alert.dismissAlert();
            }
          }]
        });
      }
    }

    get descriptors() {
      let categories = this.args.keywords.categories.filter(category => category.type === 'labels' || category.type === 'location');
      let systemLabels = new Set(categories.map(category => category.id));
      let keywordsDescriptors = categories.map(category => {
        return {
          title: category.title,
          keyPath: category.id,
          type: 'label',
          sortable: false,
          visibility: _dataGrid.TableCellColumnVisibility.visible,
          width: _dataGrid.TableCellWidth.default,
          filter: {
            type: 'select-multiple',
            items: category.values.map(value => {
              return {
                id: JSON.stringify(value.path),
                parentId: value.path.length > 1 ? JSON.stringify(value.path.slice(0, -1)) : null,
                name: value.path[value.path.length - 1],
                path: value.path,
                filters: value.filters.items,
                count: value.counts.items
              };
            })
          }
        };
      });
      let labels = (0, _compactMap.default)(this.args.labels, label => {
        let keyPath = `labels.${label.key}`;

        if (systemLabels.has(keyPath)) {
          return undefined;
        }

        return {
          title: this.intl.t('items-index.data-grid-descriptors.label', {
            name: label.key
          }),
          keyPath: keyPath,
          type: 'label',
          visibility: _dataGrid.TableCellColumnVisibility.hidden,
          sortable: false,
          width: _dataGrid.TableCellWidth.default,
          filter: {
            type: 'select-multiple',
            items: label.existingValues.map(key => {
              return {
                id: key,
                parentId: null,
                name: key,
                path: [key],
                filters: {
                  [keyPath]: key
                },
                count: 0
              };
            })
          }
        };
      });
      return [{
        title: this.intl.t('items-index.data-grid-descriptors.name'),
        keyPath: 'name',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        type: 'string',
        sortable: true,
        style: _dataGrid.TableCellStyle.text,
        filter: {
          type: 'input'
        },
        width: _dataGrid.TableCellWidth.default
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.engUnit'),
        keyPath: 'engUnit',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        type: 'string',
        sortable: true,
        filter: {
          type: 'input'
        },
        width: _dataGrid.TableCellWidth.default
      }, ...keywordsDescriptors, {
        title: this.intl.t('items-index.data-grid-descriptors.id'),
        keyPath: 'id',
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        type: 'copy',
        sortable: false,
        filter: {
          type: 'input'
        },
        width: _dataGrid.TableCellWidth.default
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.type'),
        keyPath: 'type',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        type: 'string',
        sortable: true,
        width: _dataGrid.TableCellWidth.default,
        filter: {
          type: 'select',
          options: [{
            title: this.intl.t('items-index.data-grid-descriptors.type-options.numeric'),
            value: 'numeric'
          }, {
            title: this.intl.t('items-index.data-grid-descriptors.type-options.enum'),
            value: 'enum'
          }]
        }
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.createdAt'),
        keyPath: 'createdAt',
        width: _dataGrid.TableCellWidth.default,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        type: 'date',
        sortable: true,
        filter: undefined
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.updatedAt'),
        keyPath: 'updatedAt',
        width: _dataGrid.TableCellWidth.default,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        type: 'date',
        sortable: true,
        filter: undefined
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.sampleInterval'),
        keyPath: 'sampleInterval',
        width: _dataGrid.TableCellWidth.default,
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        type: 'duration',
        sortable: true,
        filter: undefined
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.deltaInterval'),
        keyPath: 'deltaInterval',
        width: _dataGrid.TableCellWidth.default,
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        type: 'duration',
        sortable: true,
        filter: undefined
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.labels'),
        keyPath: 'labels',
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        type: 'labels',
        width: _dataGrid.TableCellWidth.ultraWide,
        sortable: false,
        filter: undefined
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.description'),
        keyPath: 'description',
        width: _dataGrid.TableCellWidth.default,
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        type: 'string',
        sortable: false,
        filter: {
          type: 'input'
        }
      }, ...labels];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "alert", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "network", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "differ", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedModels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectedModelsDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectedModelsDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectToItem", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "connectToItem"), _class.prototype)), _class);
  _exports.default = ItemsRoute;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ItemsRoute);
});