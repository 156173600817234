define("admin/helpers/uuid", ["exports", "@ember/component/helper", "clarify/utilities/uuid"], function (_exports, _helper, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(() => {
    return (0, _uuid.default)();
  });

  _exports.default = _default;
});