define("ember-animated/-private/ember-internals", ["exports", "@ember/object", "@ember/object/internals", "ember"], function (_exports, _object, _internals, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentNodes = componentNodes;
  _exports.keyForArray = keyForArray;

  /*
    This module is intended to encapsulate all the known places where
    ember-animated depends on non-public Ember APIs.
  
    See also tests/helpers/ember-testing-internals.js, which does the
    same thing but for code that is only needed in the test environment.
  
   */
  const {
    getViewBounds
  } = _ember.default.ViewUtils;

  function componentNodes(view) {
    let bounds = getViewBounds(view);
    return {
      firstNode: bounds.firstNode,
      lastNode: bounds.lastNode
    };
  }

  function keyForArray(keyPath) {
    switch (keyPath) {
      case '@index':
        return index;

      case '@identity':
      case undefined:
      case null:
        return identity;

      default:
        return item => (0, _object.get)(item, keyPath);
    }
  }

  function index(_item, index) {
    return String(index);
  }

  function identity(item) {
    switch (typeof item) {
      case 'string':
      case 'number':
        return String(item);

      default:
        return (0, _internals.guidFor)(item);
    }
  }
});