define("admin/components/metadata/fieldsets/select", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Fieldset>
    <:label as |id|>
      <Label @for={{id}} @title={{@label}} @tooltip={{@tooltip}} />
    </:label>
    <:input as |id|>
      <InputSelect id={{id}} required {{setup-field @field}}>
        {{#if (has-block)}}
          {{yield}}
        {{else}}
          {{#each @options as |optionValue|}}
            <option value={{optionValue.value}}>
              {{optionValue.title}}
            </option>
          {{/each}}
        {{/if}}
      </InputSelect>
    </:input>
  </Fieldset>
  */
  {
    "id": "+lpmHXIQ",
    "block": "[[[8,[39,0],null,null,[[\"label\",\"input\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@for\",\"@title\",\"@tooltip\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n  \"]],[1]],[[[1,\"\\n    \"],[8,[39,2],[[16,1,[30,4]],[24,\"required\",\"\"],[4,[38,3],[[30,5]],null]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,8]],[[[1,\"        \"],[18,8,null],[1,\"\\n\"]],[]],[[[42,[28,[37,8],[[28,[37,8],[[30,6]],null]],null],null,[[[1,\"          \"],[10,\"option\"],[15,2,[30,7,[\"value\"]]],[12],[1,\"\\n            \"],[1,[30,7,[\"title\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[7]],null]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[4]]]]]],[\"id\",\"@label\",\"@tooltip\",\"id\",\"@field\",\"@options\",\"optionValue\",\"&default\"],false,[\"fieldset\",\"label\",\"input-select\",\"setup-field\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\"]]",
    "moduleName": "admin/components/metadata/fieldsets/select.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});