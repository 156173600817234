define("admin/helpers/alert-signal-disconnect", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AlertSignalDisconnect extends _alert.default {
    compute(_, hash) {
      return () => {
        this.showAlert({
          title: this.intl.t('alert-signal-disconnect.title'),
          message: this.intl.t('alert-signal-disconnect.message'),
          primaryButton: {
            title: this.intl.t('alert-signal-disconnect.primary-button'),
            task: hash.task,
            style: 'primary'
          }
        });
      };
    }

  }

  _exports.default = AlertSignalDisconnect;
});