define("admin/helpers/alert-integrations-delete", ["exports", "@ember/object", "clarify/helpers/alert"], function (_exports, _object, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AlertIntegrationDelete = (_class = class AlertIntegrationDelete extends _alert.default {
    compute([integrations], hash) {
      return event => {
        let names = integrations.map(integration => (0, _object.get)(integration, 'name')).join(', ');
        this.showAlert({
          title: this.intl.t('alert-integrations-delete.initial.title'),
          message: this.intl.t('alert-integrations-delete.initial.message', {
            name: names,
            count: integrations.length,
            htmlSafe: true
          }),
          primaryButton: {
            title: this.intl.t('alert-integrations-delete.initial.primary-button'),
            action: () => {
              this.showExtraLabel(integrations.length, hash.task);
            },
            style: 'destructive'
          }
        }, event === null || event === void 0 ? void 0 : event.currentTarget);
      };
    }

    showExtraLabel(integrationCount, deleteIntegrationTask) {
      this.showAlert({
        title: this.intl.t('alert-integrations-delete.confirmation.title'),
        message: this.intl.t('alert-integrations-delete.confirmation.message', {
          count: integrationCount,
          htmlSafe: true
        }),
        primaryButton: {
          title: this.intl.t('alert-integrations-delete.confirmation.primary-button', {
            count: integrationCount
          }),
          task: deleteIntegrationTask,
          style: 'destructive'
        }
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "showExtraLabel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showExtraLabel"), _class.prototype)), _class);
  _exports.default = AlertIntegrationDelete;
});