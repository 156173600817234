define("admin/templates/admin/integrations/signals/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2o/7yDbA",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"signal-create.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@step\",\"@handleUpdateStep\",\"@integration\",\"@itemKeywords\",\"@signalCategories\"],[[30,0,[\"step\"]],[28,[37,3],[[30,0,[\"handleUpdateStep\"]]],null],[30,0,[\"integration\"]],[30,0,[\"itemKeywords\"]],[30,0,[\"signalCategories\"]]]],null]],[],false,[\"page-title\",\"t\",\"modals/signal-new\",\"fn\"]]",
    "moduleName": "admin/templates/admin/integrations/signals/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});