define("ember-m3/model-data", ["exports", "ember-m3/record-data"], function (_exports, _recordData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _recordData.default;
    }
  });
});