define("admin/components/data-grid/fields/number", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (get @model @descriptor.keyPath) as |value|}}
    {{if value (format-number value) "–"}}
  {{/let}}
  */
  {
    "id": "MqdRBmjP",
    "block": "[[[44,[[28,[37,1],[[30,1],[30,2,[\"keyPath\"]]],null]],[[[1,\"  \"],[1,[52,[30,3],[28,[37,3],[[30,3]],null],\"–\"]],[1,\"\\n\"]],[3]]]],[\"@model\",\"@descriptor\",\"value\"],false,[\"let\",\"get\",\"if\",\"format-number\"]]",
    "moduleName": "admin/components/data-grid/fields/number.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});