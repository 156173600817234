define("clarify-data-layer/errors/meta-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MetaAPIError extends Error {
    static async isAPIError(response) {
      return response.code && response.message;
    }

    constructor(error) {
      super(`Route error: ${error.message}`);
      this.error = error;
      Object.setPrototypeOf(this, MetaAPIError.prototype);
      this.name = 'MetaAPIError';
    }

  }

  _exports.default = MetaAPIError;
});