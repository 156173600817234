define("admin/helpers/join", ["exports", "@ember/component/helper", "@ember/object", "clarify/utilities/compact-map"], function (_exports, _helper, _object, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class JoinHelper extends _helper.default {
    compute([array], hash) {
      let conjunction = hash.conjunction || ' / ';
      const keyPath = hash.keyPath;

      if (keyPath) {
        return (0, _compactMap.default)(array, entry => (0, _object.get)(entry, keyPath)).join(conjunction);
      }

      return array.toArray().join(conjunction);
    }

  }

  _exports.default = JoinHelper;
});