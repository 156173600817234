define("admin/components/data-grid/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DataGridItem = _exports.TableCellColumnVisibility = _exports.TableCellStyle = _exports.TableCellWidth = _exports.NullValue = void 0;

  var _class, _descriptor, _class3, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="data-grid"
    role="grid"
    aria-colcount={{@descriptors.length}}
    aria-rowcount={{add @items.length 2}}
    ...attributes
  >
    <div class="data-grid--container" role="presentation">
      <DataGrid::Header
        @pointer={{this.pointer}}
        @pointerDidChange={{fn this.pointerDidChange}}
        @didChangeSort={{fn @didChangeSort}}
        @sort={{@sort}}
        @descriptors={{@descriptors}}
        @updateQuery={{fn @updateQuery}}
        @query={{@query}}
      />
      {{#vertical-collection
        @items
        containerSelector=".data-view"
        staticHeight=true
        bufferSize=20
        estimateHeight=48 as |model index|
      }}
        <DataGrid::Row
          @pointer={{this.pointer}}
          @selectRow={{fn this.selectRow model}}
          @pointerDidChange={{fn this.pointerDidChange}}
          @model={{model.model}}
          @selectionStyle={{@selectionStyle}}
          @dataGridItem={{model}}
          @descriptors={{@descriptors}}
          @row={{add index 2}}
        />
      {{/vertical-collection}}
    </div>
  </div>
  */
  {
    "id": "0AIABblB",
    "block": "[[[11,0],[24,0,\"data-grid\"],[24,\"role\",\"grid\"],[16,\"aria-colcount\",[30,1,[\"length\"]]],[16,\"aria-rowcount\",[28,[37,0],[[30,2,[\"length\"]],2],null]],[17,3],[12],[1,\"\\n  \"],[10,0],[14,0,\"data-grid--container\"],[14,\"role\",\"presentation\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@pointer\",\"@pointerDidChange\",\"@didChangeSort\",\"@sort\",\"@descriptors\",\"@updateQuery\",\"@query\"],[[30,0,[\"pointer\"]],[28,[37,2],[[30,0,[\"pointerDidChange\"]]],null],[28,[37,2],[[30,4]],null],[30,5],[30,1],[28,[37,2],[[30,6]],null],[30,7]]],null],[1,\"\\n\"],[6,[39,3],[[30,2]],[[\"containerSelector\",\"staticHeight\",\"bufferSize\",\"estimateHeight\"],[\".data-view\",true,20,48]],[[\"default\"],[[[[1,\"      \"],[8,[39,4],null,[[\"@pointer\",\"@selectRow\",\"@pointerDidChange\",\"@model\",\"@selectionStyle\",\"@dataGridItem\",\"@descriptors\",\"@row\"],[[30,0,[\"pointer\"]],[28,[37,2],[[30,0,[\"selectRow\"]],[30,8]],null],[28,[37,2],[[30,0,[\"pointerDidChange\"]]],null],[30,8,[\"model\"]],[30,10],[30,8],[30,1],[28,[37,0],[[30,9],2],null]]],null],[1,\"\\n\"]],[8,9]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@descriptors\",\"@items\",\"&attrs\",\"@didChangeSort\",\"@sort\",\"@updateQuery\",\"@query\",\"model\",\"index\",\"@selectionStyle\"],false,[\"add\",\"data-grid/header\",\"fn\",\"vertical-collection\",\"data-grid/row\"]]",
    "moduleName": "admin/components/data-grid/index.hbs",
    "isStrictMode": false
  });

  const NullValue = Symbol('null');
  _exports.NullValue = NullValue;
  let TableCellWidth;
  _exports.TableCellWidth = TableCellWidth;

  (function (TableCellWidth) {
    TableCellWidth["default"] = "default";
    TableCellWidth["wide"] = "wide";
    TableCellWidth["ultraWide"] = "ultra-wide";
  })(TableCellWidth || (_exports.TableCellWidth = TableCellWidth = {}));

  let TableCellStyle;
  /**
   * Describing the visibility of a column
   */

  _exports.TableCellStyle = TableCellStyle;

  (function (TableCellStyle) {
    TableCellStyle["text"] = "text";
    TableCellStyle["bold"] = "bold";
    TableCellStyle["custom"] = "custom";
  })(TableCellStyle || (_exports.TableCellStyle = TableCellStyle = {}));

  let TableCellColumnVisibility;
  /**
   * The selection style of the grid
   */

  _exports.TableCellColumnVisibility = TableCellColumnVisibility;

  (function (TableCellColumnVisibility) {
    TableCellColumnVisibility["visible"] = "visible";
    TableCellColumnVisibility["hidden"] = "hidden";
    TableCellColumnVisibility["mandatory"] = "mandatory";
  })(TableCellColumnVisibility || (_exports.TableCellColumnVisibility = TableCellColumnVisibility = {}));

  let DataGridItem = (_class = class DataGridItem {
    constructor(model) {
      _initializerDefineProperty(this, "selected", _descriptor, this);

      this.model = model;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.DataGridItem = DataGridItem;
  let DataGrid = (_class3 = class DataGrid extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "pointer", _descriptor2, this);

      if (args.items.length) {
        // Sets the default pointer location, if the selection style is multiple, it's
        // likely its rendered with checkboxes, we we select the first column with data.
        this.pointer = args.selectionStyle.type === 'multiple' ? {
          row: 2,
          column: 1,
          editing: false
        } : {
          row: 2,
          column: 0,
          editing: false
        };
      }
    }
    /**
     * Set the selectiton state for a single row, deselects every other rows in the process.
     */


    selectRow(item, selected) {
      if (this.args.selectionStyle.type === 'single') {
        for (let item of this.args.items) {
          item.selected = false;
        }
      }

      item.selected = selected;
    }

    pointerDidChange(pointer) {
      if (pointer && this.pointer && this.pointer.row === pointer.row && this.pointer.column === pointer.column && this.pointer.editing === pointer.editing) {
        return;
      }

      this.pointer = pointer ? {
        row: Math.min(Math.max(pointer.row, 0), this.args.items.length + 1),
        column: Math.min(Math.max(pointer.column, 0), this.args.descriptors.length),
        editing: pointer.editing
      } : null;
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "pointer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "selectRow", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "selectRow"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "pointerDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "pointerDidChange"), _class3.prototype)), _class3);
  _exports.default = DataGrid;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataGrid);
});