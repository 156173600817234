define("admin/components/forms/transforms/duration", ["exports", "clarify/models/duration"], function (_exports, _duration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DurationTransform = void 0;

  class DurationTransform {
    static toForm(duration) {
      return {
        days: duration.days || undefined,
        hours: duration.hours || undefined,
        minutes: duration.minutes || undefined,
        seconds: duration.seconds || undefined
      };
    }

    static toOptionalForm(duration) {
      if (duration) {
        return this.toForm(duration);
      }

      return {
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
      };
    }

    static fromForm(form) {
      if (form.days === undefined && form.hours === undefined && form.minutes === undefined && form.seconds === undefined) {
        return null;
      }

      return new _duration.default(form.days ?? 0, form.hours ?? 0, form.minutes ?? 0, form.seconds ?? 0, 0);
    }

  }

  _exports.DurationTransform = DurationTransform;
});