define("admin/templates/admin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Tko/HoWg",
    "block": "[[[8,[39,0],null,[[\"@organization\",\"@user\"],[[30,1,[\"organization\"]],[30,1,[\"user\"]]]],null],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null]],[\"@model\"],false,[\"admin-navigation\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/admin.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});