define("ember-svg-jar/inlined/clarify-application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <rect x=\"20\" y=\"16\" width=\"8\" height=\"85\" fill=\"#00ff75\">\n    <animateTransform attributeName=\"transform\" additive=\"sum\" accumulate=\"sum\" type=\"translate\" values=\"0 0 ; 0 0 ; -8 0 ; 0 0\" keyTimes=\"0; 0.6667; 0.75; 1\" dur=\"1s\" begin=\"0\" repeatCount=\"indefinite\" fill=\"freeze\" calcMode=\"spline\" keySplines=\"0 0 0 0 ; 0.167 0.167 0.407 1 ; 0.593 0 0.833 0.833\">\n    </animateTransform>\n  </rect>\n<rect x=\"152\" y=\"16\" width=\"8\" height=\"85\" fill=\"#00ff75\">\n  <animateTransform attributeName=\"transform\" additive=\"sum\" accumulate=\"sum\" type=\"translate\" values=\"0 0 ; 0 0 ; 8 0 ; 0 0; 0 0\" keyTimes=\"0; 0.1667; 0.25; 0.5; 1\" dur=\"1s\" begin=\"0\" repeatCount=\"indefinite\" fill=\"freeze\" calcMode=\"spline\" keySplines=\"0 0 0 0 ; 0.167 0.167 0.407 1 ; 0.593 0 0.833 0.833 ; 0 0 0 0\">\n  </animateTransform>\n</rect>\n<circle cx=\"89.5\" cy=\"60.5\" r=\"14\" fill=\"#00ff75\">\n  <animateTransform attributeName=\"transform\" type=\"translate\" values=\"0 0 ; 52 0 ; 0 0 ; -52 0 ; 0 0\" keyTimes=\"0; 0.29; 0.5; 0.79; 1\" dur=\"1s\" begin=\"0\" repeatCount=\"indefinite\" calcMode=\"spline\" keySplines=\"0.167 0.167 0.374 1 ; 0.626 0 0.833 0.833 ; 0.167 0.167 0.374 1 ; 0.626 0 0.833 0.833\">\n  </animateTransform>\n</circle>\n",
    "attrs": {
      "width": "180",
      "height": "120",
      "viewBox": "0 0 180 120",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});