define("admin/templates/admin/groups", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9l1EMGmx",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"groups-index.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@resource\"],[[30,0,[\"resource\"]]]],null],[1,\"\\n\"],[8,[39,3],null,null,null]],[],false,[\"page-title\",\"t\",\"routes/groups\",\"admin-outlet\"]]",
    "moduleName": "admin/templates/admin/groups.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});