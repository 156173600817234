define("admin/routes/admin/users", ["exports", "@ember/routing/route", "@ember/object", "clarify/utilities/resource"], function (_exports, _route, _object, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UsersRoute extends _route.default {
    async model() {
      let {
        organization,
        groups
      } = this.modelFor('admin');

      let resource = _resource.default.create({
        store: this.store,
        type: 'urn:member',
        limit: 20,
        adapterOptions: {
          url: `organizations/${organization.id}/members`
        },
        query: {
          filter: {
            active: true
          }
        }
      });

      await resource.load();
      return {
        resource,
        groups
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'resource', model.resource);
      (0, _object.set)(controller, 'groups', model.groups);
    }

  }

  _exports.default = UsersRoute;
});