define("ember-m3/initializers/m3-store", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /*
   Configures a registry with injections on Ember applications
   for the m3 store. Accepts an optional namespace argument.
  
   @method initializeDebugAdapter
   @param {Ember.Registry} registry
   */
  function initializeDebugAdapter(registry) {
    // TODO make this configurable
    if (true
    /* DEBUG */
    ) {
      let InteropDebugAdapter = (0, _require.default)("ember-m3/adapters/interop-debug-adapter").default;
      registry.register('data-adapter:main', InteropDebugAdapter);
    }
  }

  function initialize(application) {
    // This should be unnecessary
    // it is done by the meta package
    // but it should be done by the store package
    // https://github.com/emberjs/data/issues/7158
    application.inject('route', 'store', 'service:store');
    application.inject('controller', 'store', 'service:store');
    initializeDebugAdapter(application);
  }

  var _default = {
    name: 'm3-store',
    initialize
  };
  _exports.default = _default;
});