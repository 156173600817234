define("ember-svg-jar/inlined/admin-signal-36", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 7.65C12.284 7.65 7.65 12.283 7.65 18c0 4.243 2.553 7.891 6.21 9.489a.65.65 0 01-.52 1.19C9.225 26.884 6.35 22.779 6.35 18c0-6.433 5.216-11.65 11.65-11.65S29.65 11.566 29.65 18c0 4.779-2.877 8.884-6.99 10.68a.65.65 0 11-.52-1.19 10.353 10.353 0 006.21-9.49c0-5.715-4.634-10.35-10.35-10.35zM12.05 18a5.95 5.95 0 119.917 4.434.65.65 0 10.867.969 7.25 7.25 0 10-9.667 0 .65.65 0 10.867-.969A5.934 5.934 0 0112.05 18zm6.749 2.892A3.001 3.001 0 0018 15a3 3 0 00-.8 5.892v8.04a.8.8 0 101.6 0v-8.04z\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});