define("admin/components/data-grid/filters/input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "escape-string-regexp"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _escapeStringRegexp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="data-grid-filter-column"
    role="columnheader"
    data-size={{@descriptor.width}}
    {{roving @pointer row=1 column=@column pointerDidChange=@pointerDidChange editable=true}}
    {{did-update (fn this.valueDidUpdate) this.value}}
    {{did-update (fn this.queryDidUpdate) @query}}
  >
    {{#let (and @pointer.editing (eq @pointer.row 1) (eq @pointer.column @column)) as |editing|}}
      <InputText
        maxlength="255"
        value={{this.value}}
        placeholder={{t "data-grid.filters.input.placeholder"}}
        tabindex={{if editing "0" "-1"}}
        @size="small"
        {{set-focus editing}}
        {{on "change" (fn this.handleInput)}}
      />
      {{#if this.value.length}}
        <DataGrid::Filters::ClearButton tabindex={{if editing "0" "-1"}} {{on "click" (fn this.clear)}} />
      {{/if}}
    {{/let}}
  </div>
  */
  {
    "id": "1iXmNHMi",
    "block": "[[[11,0],[24,0,\"data-grid-filter-column\"],[24,\"role\",\"columnheader\"],[16,\"data-size\",[30,1,[\"width\"]]],[4,[38,0],[[30,2]],[[\"row\",\"column\",\"pointerDidChange\",\"editable\"],[1,[30,3],[30,4],true]]],[4,[38,1],[[28,[37,2],[[30,0,[\"valueDidUpdate\"]]],null],[30,0,[\"value\"]]],null],[4,[38,1],[[28,[37,2],[[30,0,[\"queryDidUpdate\"]]],null],[30,5]],null],[12],[1,\"\\n\"],[44,[[28,[37,4],[[30,2,[\"editing\"]],[28,[37,5],[[30,2,[\"row\"]],1],null],[28,[37,5],[[30,2,[\"column\"]],[30,3]],null]],null]],[[[1,\"    \"],[8,[39,6],[[24,\"maxlength\",\"255\"],[16,2,[30,0,[\"value\"]]],[16,\"placeholder\",[28,[37,7],[\"data-grid.filters.input.placeholder\"],null]],[16,\"tabindex\",[52,[30,6],\"0\",\"-1\"]],[4,[38,9],[[30,6]],null],[4,[38,10],[\"change\",[28,[37,2],[[30,0,[\"handleInput\"]]],null]],null]],[[\"@size\"],[\"small\"]],null],[1,\"\\n\"],[41,[30,0,[\"value\",\"length\"]],[[[1,\"      \"],[8,[39,11],[[16,\"tabindex\",[52,[30,6],\"0\",\"-1\"]],[4,[38,10],[\"click\",[28,[37,2],[[30,0,[\"clear\"]]],null]],null]],null,null],[1,\"\\n\"]],[]],null]],[6]]],[13]],[\"@descriptor\",\"@pointer\",\"@column\",\"@pointerDidChange\",\"@query\",\"editing\"],false,[\"roving\",\"did-update\",\"fn\",\"let\",\"and\",\"eq\",\"input-text\",\"t\",\"if\",\"set-focus\",\"on\",\"data-grid/filters/clear-button\"]]",
    "moduleName": "admin/components/data-grid/filters/input.hbs",
    "isStrictMode": false
  });

  let InputComponent = (_class = class InputComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "value", _descriptor, this);
    }

    handleInput(event) {
      let target = event.currentTarget;
      this.value = target.value;
    }

    clear() {
      this.value = '';
    }

    queryDidUpdate() {
      if (this.value && this.args.query.has(this.args.descriptor.keyPath) === false) {
        this.clear();
      }
    }

    valueDidUpdate() {
      this.args.updateQuery(this.args.descriptor.keyPath, this.value ? {
        $regex: `(?i)${(0, _escapeStringRegexp.default)(this.value)}`
      } : undefined);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryDidUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "queryDidUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "valueDidUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "valueDidUpdate"), _class.prototype)), _class);
  _exports.default = InputComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputComponent);
});