define("ember-svg-jar/inlined/icon-delete-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 5h4v1h4v2H6V6h4V5zm7 5H7v9h10v-9z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});