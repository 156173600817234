define("admin/components/data-organization/subscription-information/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="data-subscription-info" data-intent={{or @intent "neutral"}} ...attributes>
    {{svg-jar @icon width=40 height=40}}
    <div class="data-subscription-info--description">
      {{@text}}
    </div>
  </div>
  */
  {
    "id": "vIxshcJP",
    "block": "[[[11,0],[24,0,\"data-subscription-info\"],[16,\"data-intent\",[28,[37,0],[[30,1],\"neutral\"],null]],[17,2],[12],[1,\"\\n  \"],[1,[28,[35,1],[[30,3]],[[\"width\",\"height\"],[40,40]]]],[1,\"\\n  \"],[10,0],[14,0,\"data-subscription-info--description\"],[12],[1,\"\\n    \"],[1,[30,4]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@intent\",\"&attrs\",\"@icon\",\"@text\"],false,[\"or\",\"svg-jar\"]]",
    "moduleName": "admin/components/data-organization/subscription-information/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});