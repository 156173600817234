define("admin/controllers/admin/users/show/index", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UsersShowIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "pane", 'about');

      _defineProperty(this, "queryParams", ['pane']);

      _defineProperty(this, "member", void 0);
    }

  }

  _exports.default = UsersShowIndexController;
});