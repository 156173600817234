define("admin/routes/admin/groups/show/index", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IntegrationShowIndexRoute extends _route.default {
    async model() {
      let params = this.modelFor('admin.groups.show');
      let {
        organization
      } = this.modelFor('admin');
      let group = await this.store.findRecord('urn:group', params.groupId, {
        adapterOptions: {
          url: `organizations/${organization.id}/groups/${params.groupId}`
        }
      });
      let members = await this.store.query('urn:member', {
        filter: {
          active: true,
          groups: {
            $in: [group.id]
          }
        },
        limit: 1000
      }, {
        adapterOptions: {
          url: `organizations/${organization.id}/members`
        }
      });
      return {
        group,
        members
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'group', model.group);
      (0, _object.set)(controller, 'members', model.members);
    }

  }

  _exports.default = IntegrationShowIndexRoute;
});