define("ember-animated/templates/components/animated-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "s5jF76DP",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[30,0,[\"tag\"]]],null]],null],0,null,[[\"tagName\"],[[30,0,[\"tag\"]]]]]],[[[8,[30,1],[[16,0,[29,[\"animated-container \",[30,2]]]],[17,3]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Tag\",\"@class\",\"&attrs\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"yield\"]]",
    "moduleName": "ember-animated/templates/components/animated-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});