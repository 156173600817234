define("admin/templates/admin/integrations/signals/show/index/connect/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "g3SWyrou",
    "block": "[[[8,[39,0],null,[[\"@signal\",\"@resource\",\"@keywords\",\"@labels\"],[[30,0,[\"model\",\"signal\"]],[30,0,[\"model\",\"resource\"]],[30,0,[\"model\",\"keywords\"]],[30,0,[\"model\",\"labels\"]]]],null]],[],false,[\"modals/signal-connect\"]]",
    "moduleName": "admin/templates/admin/integrations/signals/show/index/connect/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});