define("draggable/helpers/accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typesForDataTransfer = typesForDataTransfer;
  _exports.shouldAcceptEvent = shouldAcceptEvent;
  _exports.shouldAcceptTypes = shouldAcceptTypes;
  _exports.normalizeAllow = normalizeAllow;

  function typesForDataTransfer(dataTransfer) {
    return [...dataTransfer.types];
  }

  function shouldAcceptEvent(accepts, event) {
    if (event.dataTransfer) {
      const types = typesForDataTransfer(event.dataTransfer);
      return shouldAcceptTypes(accepts, types);
    }

    return false;
  }

  function shouldAcceptTypes(accepts, dataTransferTypes) {
    return !!dataTransferTypes.find(type => accepts.has(type));
  }

  function normalizeAllow(allow) {
    if (typeof allow == "string") {
      return new Set([allow]);
    }

    return new Set(allow);
  }
});