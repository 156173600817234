define("admin/components/data-grid/fields/labels", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.labels.length}}
    <div class="data-grid-labels--list">
      {{#each this.labels as |keyword|}}
        <ATag
          role="listitem"
          @text={{t "data-grid.content.labels" category=keyword.category label=keyword.label htmlSafe=true}}
        />
      {{/each}}
    </div>
  {{else}}
    –
  {{/if}}
  */
  {
    "id": "Yo4/GOsR",
    "block": "[[[41,[30,0,[\"labels\",\"length\"]],[[[1,\"  \"],[10,0],[14,0,\"data-grid-labels--list\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"labels\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],[[24,\"role\",\"listitem\"]],[[\"@text\"],[[28,[37,4],[\"data-grid.content.labels\"],[[\"category\",\"label\",\"htmlSafe\"],[[30,1,[\"category\"]],[30,1,[\"label\"]],true]]]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  –\\n\"]],[]]]],[\"keyword\"],false,[\"if\",\"each\",\"-track-array\",\"a-tag\",\"t\"]]",
    "moduleName": "admin/components/data-grid/fields/labels.hbs",
    "isStrictMode": false
  });

  class LabelsField extends _component2.default {
    get labels() {
      let labels = (0, _object.get)(this.args.model, this.args.descriptor.keyPath);
      let values = [];

      for (let [category, labelList] of Object.entries(labels)) {
        for (let label of labelList) {
          values.push({
            category,
            label
          });
        }
      }

      return values.slice(0, 10);
    }

  }

  _exports.default = LabelsField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LabelsField);
});