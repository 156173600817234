define("ember-engines/components/link-to-external", ["exports", "@ember/routing/link-component", "@ember/application", "@ember/object", "@embroider/macros/runtime"], function (_exports, _linkComponent, _application, _object, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LinkToExternal;

  if ((0, _runtime.macroCondition)(true)) {
    LinkToExternal = class LinkToExternal extends _linkComponent.default {
      _namespaceRoute(targetRouteName) {
        const owner = (0, _application.getOwner)(this);

        const externalRoute = owner._getExternalRoute(targetRouteName);

        return externalRoute;
      } // override LinkTo's assertLinkToOrigin method to noop. In LinkTo, this assertion
      // checks to make sure LinkTo is not being used inside a routeless engine
      // See this PR here for more details: https://github.com/emberjs/ember.js/pull/19477


      assertLinkToOrigin() {}

    };
  } else {
    LinkToExternal = _linkComponent.default.extend({
      didReceiveAttrs() {
        this._super(...arguments);

        const owner = (0, _application.getOwner)(this);

        if (owner.mountPoint) {
          // https://emberjs.github.io/rfcs/0459-angle-bracket-built-in-components.html
          const routeKey = 'targetRouteName' in this ? 'targetRouteName' : 'route';
          const routeName = (0, _object.get)(this, routeKey);

          const externalRoute = owner._getExternalRoute(routeName);

          (0, _object.set)(this, routeKey, externalRoute);
        }
      }

    });
  }

  var _default = LinkToExternal;
  _exports.default = _default;
});