define("gesture-recognizer/helpers/gesture", ["exports", "@ember/component/helper", "@ember/application", "@ember/object"], function (_exports, _helper, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GestureHelper extends _helper.default {
    compute(params, hash) {
      const owner = (0, _application.getOwner)(this);
      let recognizer = owner.lookup(`gesture:${params[0]}`);
      (0, _object.setProperties)(recognizer, hash);
      return recognizer;
    }

  }

  _exports.default = GestureHelper;
});