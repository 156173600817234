define("ember-svg-jar/inlined/p-settings-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M17.784 12c0 .272-.024.528-.056.784l1.688 1.32c.151.12.192.336.096.512l-1.6 2.768c-.096.176-.304.248-.488.176l-1.992-.8a6.146 6.146 0 01-1.353.784l-.304 2.12a.39.39 0 01-.392.336h-3.2a.39.39 0 01-.392-.336l-.304-2.12a5.845 5.845 0 01-1.352-.784l-1.992.8a.402.402 0 01-.488-.176l-1.6-2.768a.404.404 0 01.096-.512l1.688-1.32A6.344 6.344 0 015.783 12c0-.264.024-.528.056-.784l-1.688-1.32a.394.394 0 01-.096-.512l1.6-2.768c.096-.176.304-.248.488-.176l1.992.8a6.146 6.146 0 011.352-.784l.304-2.12A.39.39 0 0110.184 4h3.2c.2 0 .367.144.391.336l.304 2.12c.489.2.936.464 1.352.784l1.993-.8a.402.402 0 01.488.176l1.6 2.768a.404.404 0 01-.096.512l-1.689 1.32c.032.256.056.512.056.784zm-8.8 0c0 1.544 1.255 2.8 2.8 2.8 1.543 0 2.8-1.256 2.8-2.8 0-1.544-1.256-2.8-2.8-2.8a2.803 2.803 0 00-2.8 2.8z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});