define("admin/components/data-grid-filter/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "clarify/utilities/create-tree"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _createTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Filter @data={{this.treeItems}} @filterData={{fn this.filterData}} as |filter|>
    <PopoverLayout
      ...attributes
      {{trap-focus esc=(fn @closePopover)}}
      {{did-update (fn this.handleSelectionDidChange) this.selection}}
    >
      <:header>
        <InputText
          maxlength="255"
          class="data-grid-filter--input"
          placeholder={{t "data-grid-filter.placeholder"}}
          @size="small"
          {{set-focus true}}
          {{on "input" (fn filter.handleInput)}}
        />
      </:header>
      <:list>
        {{#each filter.data key="id" as |item|}}
          <DataGridFilter::Item @item={{item}} @isEnabled={{fn this.isEnabled}} @setEnabled={{fn this.setEnabled}} />
        {{else}}
          <EmptyState
            class="data-grid-filter--empty-state"
            @style="normal"
            @textAlign="center"
            @boxAlign="center"
            @text={{t "data-grid-filter.empty-state"}}
          />
        {{/each}}
      </:list>
      <:footer>
        <FlatButton @size="small" @text={{t "data-grid-filter.close-button"}} {{on "click" (fn @closePopover)}} />
      </:footer>
    </PopoverLayout>
  </Filter>
  */
  {
    "id": "2o7DUiSk",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@filterData\"],[[30,0,[\"treeItems\"]],[28,[37,1],[[30,0,[\"filterData\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[17,2],[4,[38,3],null,[[\"esc\"],[[28,[37,1],[[30,3]],null]]]],[4,[38,4],[[28,[37,1],[[30,0,[\"handleSelectionDidChange\"]]],null],[30,0,[\"selection\"]]],null]],null,[[\"header\",\"list\",\"footer\"],[[[[1,\"\\n      \"],[8,[39,5],[[24,\"maxlength\",\"255\"],[24,0,\"data-grid-filter--input\"],[16,\"placeholder\",[28,[37,6],[\"data-grid-filter.placeholder\"],null]],[4,[38,7],[true],null],[4,[38,8],[\"input\",[28,[37,1],[[30,1,[\"handleInput\"]]],null]],null]],[[\"@size\"],[\"small\"]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[42,[28,[37,10],[[28,[37,10],[[30,1,[\"data\"]]],null]],null],\"id\",[[[1,\"        \"],[8,[39,11],null,[[\"@item\",\"@isEnabled\",\"@setEnabled\"],[[30,4],[28,[37,1],[[30,0,[\"isEnabled\"]]],null],[28,[37,1],[[30,0,[\"setEnabled\"]]],null]]],null],[1,\"\\n\"]],[4]],[[[1,\"        \"],[8,[39,12],[[24,0,\"data-grid-filter--empty-state\"]],[[\"@style\",\"@textAlign\",\"@boxAlign\",\"@text\"],[\"normal\",\"center\",\"center\",[28,[37,6],[\"data-grid-filter.empty-state\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]],[[[1,\"\\n      \"],[8,[39,13],[[4,[38,8],[\"click\",[28,[37,1],[[30,3]],null]],null]],[[\"@size\",\"@text\"],[\"small\",[28,[37,6],[\"data-grid-filter.close-button\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"filter\",\"&attrs\",\"@closePopover\",\"item\"],false,[\"filter\",\"fn\",\"popover-layout\",\"trap-focus\",\"did-update\",\"input-text\",\"t\",\"set-focus\",\"on\",\"each\",\"-track-array\",\"data-grid-filter/item\",\"empty-state\",\"flat-button\"]]",
    "moduleName": "admin/components/data-grid-filter/index.hbs",
    "isStrictMode": false
  });

  let Index = (_class = class Index extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "selection", _descriptor, this);

      this.restoreCategories(args.selection);
    }

    get treeItems() {
      return (0, _createTree.createTree)(this.args.items, item => item.id, item => item.parentId);
    }

    get tokenFieldTokens() {
      return this.leafNodes();
    }

    filterData(query, data) {
      let lowerCaseQuery = query.toLowerCase();
      return data.filter(item => {
        return item.item.name.toLowerCase().indexOf(lowerCaseQuery) !== -1;
      });
    }

    clearAll() {
      if (this.args.selection.length !== 0) {
        this.selection = new Set();
      }
    }

    isEnabled(item) {
      return this.selection.has(item.id);
    }

    setEnabled(item, enabled) {
      this.setItemEnabled(item, enabled);
    }

    handleSelectionDidChange() {
      let selection = this.leafNodes().map(node => {
        return {
          displayName: node.name,
          id: node.id,
          filters: node.filters
        };
      });
      this.args.didUpdateSelection(selection);
    }

    setItemEnabled(item, enabled, _autoEnable = true) {
      this.updateSelection(item.id, enabled);

      if (enabled) {
        this.forEachParent(item, parent => {
          this.updateSelection(parent.id, enabled);
        });
      } else {
        this.forEachChild(item, child => {
          this.updateSelection(child.id, enabled);
        });
      }
    }

    updateSelection(itemId, enabled) {
      if (enabled) {
        this.selection.add(itemId);
      } else {
        this.selection.delete(itemId);
      }

      this.selection = this.selection;
    }

    forEachParent(item, iterator) {
      if (!item.parentId) {
        return;
      }

      let tree = _createTree.Tree.fromItems(this.args.items, item => item.id, item => item.parentId);

      let treeItem = tree.get(item.parentId);

      while (treeItem) {
        iterator(treeItem);
        treeItem = treeItem.parentId ? tree.get(treeItem.parentId) : undefined;
      }
    }

    forEachChild(item, iterator) {
      let tree = _createTree.Tree.fromItems(this.args.items, item => item.id, item => item.parentId);

      let treeItem = tree.get(item.id);

      let updateTreeItem = item => {
        iterator(item);

        for (let child of item.children) {
          updateTreeItem(child);
        }
      };

      if (treeItem) {
        updateTreeItem(treeItem);
      }
    }

    leafNodes() {
      let tree = _createTree.Tree.fromItems(this.args.items, item => item.id, item => item.parentId);

      let leafNodes = [];

      for (let child of tree.children) {
        this.appendLeafNodes(child, leafNodes, this.selection);
      }

      return leafNodes;
    }

    appendLeafNodes(treeItem, leafNodes, selectItems) {
      if (selectItems.has(treeItem.id)) {
        let children = this.checkedChildren(treeItem);

        if (children.length === 0) {
          leafNodes.push(treeItem.item);
        } else {
          for (let child of treeItem.children) {
            this.appendLeafNodes(child, leafNodes, selectItems);
          }
        }
      }
    }

    restoreCategories(tokens) {
      let tree = _createTree.Tree.fromItems(this.args.items, item => item.id, item => item.parentId);

      for (const token of tokens) {
        let leaf = tree.get(token.id);

        while (leaf) {
          this.updateSelection(leaf.id, true);
          leaf = leaf.parentId ? tree.get(leaf.parentId) : undefined;
        }
      }
    }

    checkedChildren(treeItem) {
      return treeItem.children.filter(child => this.selection.has(child.id));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Set();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "filterData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "filterData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEnabled", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEnabled", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSelectionDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSelectionDidChange"), _class.prototype)), _class);
  _exports.default = Index;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Index);
});