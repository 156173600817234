define("admin/routes/admin/item-viewer", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ItemViewer extends _route.default {
    redirect() {
      let {
        itemId
      } = this.paramsFor('admin.item-viewer'); // @ts-expect-error

      this.transitionToExternal('item-viewer', itemId);
    }

  }

  _exports.default = ItemViewer;
});