define("admin/components/modals/edit-signal/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-concurrency-ts", "ember-concurrency", "clarify-data-layer/models/signal", "admin/components/forms/signal", "admin/components/forms/transforms/duration", "admin/components/forms/transforms/enum", "admin/components/forms/transforms/labels"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberConcurrencyTs, _emberConcurrency, _signal, _signal2, _duration, _enum, _labels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{meta-attribute name="theme-color" content="#D6D7DB"}}
  <DialogContainer>
    <form autocomplete="off" {{on "submit" (prevent-default (fn this.submit))}}>
      <Dialog class="admin-edit-signal-form--container">
        <:header>
          <Dialog::Header
            @title={{t "integrations-show-edit.dialog.header"}}
            @route={{route "admin.integrations.signals"}}
          />
        </:header>
        <:content>
          <div>
            <Forms::Signal
              class="admin-edit-signal--form-container"
              @form={{this.signalForm}}
              @signal={{@signal}}
              @itemKeywords={{@itemKeywords}}
              @signalCategories={{@signalCategories}}
            />
          </div>
        </:content>
        <:footer>
          <div class="admin-edit-signal--footer">
            <div>
              <FlatButton::Link
                @style="neutral"
                @text={{t "integrations-show-edit.dialog.footer.action-dismiss"}}
                @route={{route "admin.integrations.signals"}}
              />
            </div>
            <div>
              <FlatButton
                @type="submit"
                @text={{t "integrations-show-edit.dialog.footer.action-save"}}
                @loading={{this.editSignal.isRunning}}
              />
            </div>
          </div>
        </:footer>
      </Dialog>
    </form>
  </DialogContainer>
  */
  {
    "id": "2bodlqei",
    "block": "[[[1,[28,[35,0],null,[[\"name\",\"content\"],[\"theme-color\",\"#D6D7DB\"]]]],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[11,\"form\"],[24,\"autocomplete\",\"off\"],[4,[38,2],[\"submit\",[28,[37,3],[[28,[37,4],[[30,0,[\"submit\"]]],null]],null]],null],[12],[1,\"\\n    \"],[8,[39,5],[[24,0,\"admin-edit-signal-form--container\"]],null,[[\"header\",\"content\",\"footer\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@title\",\"@route\"],[[28,[37,7],[\"integrations-show-edit.dialog.header\"],null],[28,[37,8],[\"admin.integrations.signals\"],null]]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n          \"],[8,[39,9],[[24,0,\"admin-edit-signal--form-container\"]],[[\"@form\",\"@signal\",\"@itemKeywords\",\"@signalCategories\"],[[30,0,[\"signalForm\"]],[30,1],[30,2],[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,0],[14,0,\"admin-edit-signal--footer\"],[12],[1,\"\\n          \"],[10,0],[12],[1,\"\\n            \"],[8,[39,10],null,[[\"@style\",\"@text\",\"@route\"],[\"neutral\",[28,[37,7],[\"integrations-show-edit.dialog.footer.action-dismiss\"],null],[28,[37,8],[\"admin.integrations.signals\"],null]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[12],[1,\"\\n            \"],[8,[39,11],null,[[\"@type\",\"@text\",\"@loading\"],[\"submit\",[28,[37,7],[\"integrations-show-edit.dialog.footer.action-save\"],null],[30,0,[\"editSignal\",\"isRunning\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@signal\",\"@itemKeywords\",\"@signalCategories\"],false,[\"meta-attribute\",\"dialog-container\",\"on\",\"prevent-default\",\"fn\",\"dialog\",\"dialog/header\",\"t\",\"route\",\"forms/signal\",\"flat-button/link\",\"flat-button\"]]",
    "moduleName": "admin/components/modals/edit-signal/index.hbs",
    "isStrictMode": false
  });

  let SignalEdit = (_class = class SignalEdit extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "alert", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _defineProperty(this, "signalForm", void 0);

      this.signalForm = (0, _signal2.signalFormFromSignal)(args.signal, args.signalCategories, args.itemKeywords);
    }

    submit() {
      let result = this.signalForm.serialize();

      if (result.type === 'success') {
        (0, _emberConcurrencyTs.taskFor)(this.editSignal).perform(result.value);
      }
    }

    *editSignal(entry) {
      try {
        this.args.signal.name = entry.name;
        this.args.signal.description = entry.description ?? '';
        this.args.signal.sourceType = entry.sourceType;
        this.args.signal.engUnit = entry.engUnit ?? '';
        this.args.signal.type = entry.type;
        this.args.signal.sampleInterval = _duration.DurationTransform.fromForm(entry.sampleInterval);
        this.args.signal.deltaInterval = _duration.DurationTransform.fromForm(entry.deltaInterval);
        this.args.signal.enumValues = entry.type === _signal.SignalType.enum ? _enum.EnumValuesTransform.fromForm(entry.enumValues) : {};
        this.args.signal.labels = _labels.LabelsTransform.fromForm([...entry.extraLabels]);
        yield this.args.signal.save();
        this.router.transitionTo('admin.integrations.signals');
      } catch (error) {
        console.error(`EditSignal#editSignal task failed with error: ${error}`);
        this.alert.showDismissibleError(error);
      }

      return undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "alert", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editSignal", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "editSignal"), _class.prototype)), _class);
  _exports.default = SignalEdit;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SignalEdit);
});