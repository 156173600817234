define("admin/components/data-grid/cell/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq @selectionStyle.type "multiple")}}
    <LinkTo
      @route={{@selectionStyle.route}}
      @model={{@dataGridItem.model.id}}
      class="data-grid-cell"
      role="gridcell"
      data-style={{@descriptor.style}}
      data-size={{@descriptor.width}}
      {{roving @pointer row=@row column=@column pointerDidChange=@pointerDidChange}}
    >
      <DataGrid::Cell::-content @descriptor={{@descriptor}} @model={{@dataGridItem.model}} />
    </LinkTo>
  {{else}}
    <div
      class="data-grid-cell"
      role="gridcell"
      data-style={{@descriptor.style}}
      data-size={{@descriptor.width}}
      {{on "click" (fn @selectRow (not @dataGridItem.selected))}}
      {{roving @pointer row=@row column=@column pointerDidChange=@pointerDidChange}}
    >
      <DataGrid::Cell::-content @descriptor={{@descriptor}} @model={{@dataGridItem.model}} />
    </div>
  {{/if}}
  */
  {
    "id": "xEcN00iY",
    "block": "[[[41,[28,[37,1],[[30,1,[\"type\"]],\"multiple\"],null],[[[1,\"  \"],[8,[39,2],[[24,0,\"data-grid-cell\"],[24,\"role\",\"gridcell\"],[16,\"data-style\",[30,2,[\"style\"]]],[16,\"data-size\",[30,2,[\"width\"]]],[4,[38,3],[[30,4]],[[\"row\",\"column\",\"pointerDidChange\"],[[30,5],[30,6],[30,7]]]]],[[\"@route\",\"@model\"],[[30,1,[\"route\"]],[30,3,[\"model\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@descriptor\",\"@model\"],[[30,2],[30,3,[\"model\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,0,\"data-grid-cell\"],[24,\"role\",\"gridcell\"],[16,\"data-style\",[30,2,[\"style\"]]],[16,\"data-size\",[30,2,[\"width\"]]],[4,[38,5],[\"click\",[28,[37,6],[[30,8],[28,[37,7],[[30,3,[\"selected\"]]],null]],null]],null],[4,[38,3],[[30,4]],[[\"row\",\"column\",\"pointerDidChange\"],[[30,5],[30,6],[30,7]]]],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@descriptor\",\"@model\"],[[30,2],[30,3,[\"model\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@selectionStyle\",\"@descriptor\",\"@dataGridItem\",\"@pointer\",\"@row\",\"@column\",\"@pointerDidChange\",\"@selectRow\"],false,[\"if\",\"eq\",\"link-to\",\"roving\",\"data-grid/cell/-content\",\"on\",\"fn\",\"not\"]]",
    "moduleName": "admin/components/data-grid/cell/index.hbs",
    "isStrictMode": false
  });

  let DataGridCell = (_class = class DataGridCell extends _component2.default {
    handleFocus() {
      this.args.pointerDidChange({
        row: this.args.row,
        column: this.args.column,
        editing: false
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleFocus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocus"), _class.prototype)), _class);
  _exports.default = DataGridCell;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataGridCell);
});