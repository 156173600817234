define("admin/components/forms/transforms/enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EnumValuesTransform = void 0;

  class EnumValuesTransform {
    static fromForm(enumValues) {
      return enumValues.reduce((hash, enumValue) => {
        hash[enumValue.key] = enumValue.label;
        return hash;
      }, {});
    }

    static toForm(enumValues) {
      return Object.entries(enumValues).map(([key, label]) => {
        return {
          key,
          label
        };
      });
    }

  }

  _exports.EnumValuesTransform = EnumValuesTransform;
});