define("ember-svg-jar/inlined/loading-animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <circle cx=\"1.67\" cy=\"1.67\" r=\"1.67\">\n    <animate attributeName=\"fill-opacity\" from=\"1\" to=\"1\" begin=\"0s\" dur=\"1.76s\" values=\"1; 0; 1; 1;\" keyTimes=\"0; 0.2; 0.34; 1\" keySplines=\"0.667 1 0.333 0; 0.667 1 0.333 0; 0 0 1 1\" calcMode=\"spline\" repeatCount=\"indefinite\"/>\n  </circle>\n  <circle cx=\"7.67\" cy=\"1.67\" r=\"1.67\">\n    <animate attributeName=\"fill-opacity\" from=\"1\" to=\"1\" begin=\"0s\" dur=\"1.76s\" values=\"1; 1; 0; 1; 1;\" keyTimes=\"0; 0.14; 0.32; 0.5; 1\" keySplines=\"0 0 1 1 0.667 1 0.333 0; 0.667 1 0.333 0; 0 0 1 1\" calcMode=\"spline\" repeatCount=\"indefinite\"/>\n  </circle>\n  <circle cx=\"13.67\" cy=\"1.67\" r=\"1.67\">\n    <animate attributeName=\"fill-opacity\" from=\"1\" to=\"1\" begin=\"0s\" dur=\"1.76s\" values=\"1; 1; 0; 1; 1;\" keyTimes=\"0; 0.2; 0.39; 0.52; 1\" keySplines=\"0 0 1 1; 0.667 1 0.333 0; 0.667 1 0.333 0; 0 0 1 1\" calcMode=\"spline\" repeatCount=\"indefinite\"/>\n  </circle>\n",
    "attrs": {
      "width": "16",
      "height": "4",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});