define("admin/controllers/admin/integrations/signals/show/index", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.IntegrationsSignalsShowPanes = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let IntegrationsSignalsShowPanes;
  _exports.IntegrationsSignalsShowPanes = IntegrationsSignalsShowPanes;

  (function (IntegrationsSignalsShowPanes) {
    IntegrationsSignalsShowPanes["metadata"] = "metadata";
    IntegrationsSignalsShowPanes["item"] = "item";
  })(IntegrationsSignalsShowPanes || (_exports.IntegrationsSignalsShowPanes = IntegrationsSignalsShowPanes = {}));

  class IntegrationsSignalsShowIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "pane", IntegrationsSignalsShowPanes.metadata);

      _defineProperty(this, "queryParams", ['pane']);

      _defineProperty(this, "signal", void 0);

      _defineProperty(this, "item", void 0);

      _defineProperty(this, "itemKeywords", void 0);
    }

  }

  _exports.default = IntegrationsSignalsShowIndexController;
});