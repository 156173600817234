define("ember-animated/templates/components/animated-value", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1HHHiiZU",
    "block": "[[[6,[39,0],[[30,0,[\"items\"]]],[[\"key\",\"rules\",\"use\",\"duration\",\"group\",\"watch\",\"initialInsertion\",\"finalRemoval\"],[[30,0,[\"key\"]],[30,0,[\"rules\"]],[30,0,[\"use\"]],[30,0,[\"duration\"]],[30,0,[\"group\"]],[30,0,[\"watch\"]],[30,0,[\"initialInsertion\"]],[30,0,[\"finalRemoval\"]]]],[[\"default\"],[[[[1,\"  \"],[18,2,[[30,1]]],[1,\"\\n\"]],[1]]]]]],[\"item\",\"&default\"],false,[\"animated-each\",\"yield\"]]",
    "moduleName": "ember-animated/templates/components/animated-value.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});