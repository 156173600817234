define("admin/routes/admin/groups/group-edit-members", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let GroupEditMembers = (_class = class GroupEditMembers extends _route.default {
    async model(params) {
      let {
        organization
      } = this.modelFor('admin');
      let group = await this.store.findRecord('urn:group', params.groupId, {
        adapterOptions: {
          url: `organizations/${organization.id}/groups/${params.groupId}`
        }
      });
      let members = await this.store.query('urn:member', {
        filter: {
          active: true
        },
        limit: 1000
      }, {
        adapterOptions: {
          url: `organizations/${organization.id}/members`
        }
      });
      return {
        group,
        members: members
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'group', model.group);
      (0, _object.set)(controller, 'members', model.members);
    }

    loading() {
      return false;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class);
  _exports.default = GroupEditMembers;
});