define("admin/instance-initializers/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'resources',
    initialize: async function (application) {
      let resources = application.lookup('service:resources');
      resources.registerBelongsToResource('urn:group', group => {
        return [`organizations.${group.organization.id}.groups`];
      });
      resources.registerBelongsToResource('urn:member', member => {
        if (member.user) {
          return [`organizations.${member.organization.id}.members`];
        }

        return [`organizations.${member.organization.id}.invitations`];
      });
      resources.registerBelongsToResource('urn:signal', member => {
        return [`integrations.${member.integration.id}.signals`];
      });
      resources.registerBelongsToResource('urn:integration', integration => {
        return [`organizations.${integration.organization.id}.integrations`];
      });
      resources.registerBelongsToResource('urn:item', member => {
        return [`organizations.${member.organization.id}.items`];
      });
    }
  };
  _exports.default = _default;
});