define("admin/templates/admin/organization/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zmPBoFiu",
    "block": "[[[8,[39,0],null,[[\"@organization\"],[[30,0,[\"organization\"]]]],null]],[],false,[\"modals/organization-edit\"]]",
    "moduleName": "admin/templates/admin/organization/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});