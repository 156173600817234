define("admin/helpers/alert-members-delete", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AlertMembersDelete extends _alert.default {
    compute([members], hash) {
      return event => {
        let names = members.map(member => member.name).join(', ');
        this.showAlert({
          title: this.intl.t('alert-members-delete.title', {
            count: members.length,
            name: names
          }),
          message: this.intl.t('alert-members-delete.message', {
            count: members.length,
            htmlSafe: true
          }),
          primaryButton: {
            title: this.intl.t('alert-members-delete.primary-button'),
            task: hash.task,
            style: 'destructive'
          }
        }, event === null || event === void 0 ? void 0 : event.currentTarget);
      };
    }

  }

  _exports.default = AlertMembersDelete;
});