define("admin/components/data-view/state/loading", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="data-view-loading" aria-busy="true" aria-live="polite" aria-label={{t "data-grid.loading.label"}}>
    {{svg-jar "design-system-spinner" aria-hidden="true" width=44 height=44}}
  </div>
  */
  {
    "id": "A4SLYUpL",
    "block": "[[[10,0],[14,0,\"data-view-loading\"],[14,\"aria-busy\",\"true\"],[14,\"aria-live\",\"polite\"],[15,\"aria-label\",[28,[37,0],[\"data-grid.loading.label\"],null]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"design-system-spinner\"],[[\"aria-hidden\",\"width\",\"height\"],[\"true\",44,44]]]],[1,\"\\n\"],[13]],[],false,[\"t\",\"svg-jar\"]]",
    "moduleName": "admin/components/data-view/state/loading.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});