define("ember-svg-jar/inlined/icon-cross-filled-40", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 36.667c9.205 0 16.667-7.462 16.667-16.667 0-9.205-7.462-16.667-16.667-16.667-9.205 0-16.667 7.462-16.667 16.667 0 9.205 7.462 16.667 16.667 16.667zm5.893-24.916l2.357 2.357L22.357 20l5.893 5.892-2.358 2.358L20 22.357l-5.893 5.893-2.357-2.357L17.643 20l-5.893-5.893 2.357-2.357L20 17.643l5.893-5.892z\" fill=\"#CE3E50\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});