define("admin/templates/admin/users/show/index-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qVcCHdMX",
    "block": "[[[8,[39,0],null,null,[[\"menu\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@route\"],[[28,[37,2],[\"admin.items\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@title\"],[[28,[37,4],[[30,0,[\"model\",\"message\"]],[28,[37,5],[\"users-show.states.error\"],null]],null]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"side-pane\",\"side-pane/menu\",\"route\",\"non-ideal-state\",\"or\",\"t\"]]",
    "moduleName": "admin/templates/admin/users/show/index-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});