define("gesture-recognizer/gestures/pan", ["exports", "gesture-recognizer/gestures/-base", "@ember/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pointForEvent = pointForEvent;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MAX_TIME_FOR_TRACKING_DATA_POINTS = 100;
  const zero = {
    x: 0,
    y: 0
  };

  function pointForEvent(event, target) {
    let bounds = target.getBoundingClientRect();

    if (event instanceof MouseEvent) {
      return {
        x: event.clientX - bounds.left,
        y: event.clientY - bounds.top
      };
    }

    let touch = event.changedTouches[0];
    return {
      x: touch.clientX - bounds.left,
      y: touch.clientY - bounds.left
    };
  }

  class PanGestureRecognizer extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "delta", zero);

      _defineProperty(this, "point", zero);

      _defineProperty(this, "dataPoints", []);

      _defineProperty(this, "event", null);
    }

    reset() {
      this.dataPoints = [];
      this.delta = zero;
      this.point = zero;
      this.event = null;
    }

    pointerDown(event) {
      let point;

      if (event instanceof MouseEvent) {
        if (event.button === 0) {
          point = {
            x: event.offsetX,
            y: event.offsetY
          };
        }
      } else {
        if (event.touches.length === 1) {
          event.preventDefault();
          const initialTouch = event.touches[0];
          point = {
            x: initialTouch.clientX,
            y: initialTouch.clientY
          };
        }
      }

      if (point) {
        this.dataPoints = [];
        this.delta = zero;
        this.point = point;
        (0, _object.set)(this, 'state', _base.GestureRecognizerState.began);
        this.addDataPoint(point);
      } else {
        (0, _object.set)(this, 'state', _base.GestureRecognizerState.failed);
      }
    }

    pointerUp(_event) {
      (0, _object.set)(this, 'state', _base.GestureRecognizerState.ended);
    }

    pointerMove(event) {
      event.preventDefault();
      let eventPosition = pointForEvent(event, this.element);
      const computedDeltaX = this.event ? eventPosition.x - this.event.x : 0;
      const computedDeltaY = this.event ? eventPosition.y - this.event.y : 0;
      let delta = {
        x: computedDeltaX,
        y: computedDeltaY
      };
      let point = {
        x: this.point.x + computedDeltaX,
        y: this.point.y + computedDeltaY
      };
      this.event = eventPosition;
      this.point = point;
      this.delta = delta;
      this.addDataPoint({ ...this.point
      });
      (0, _object.set)(this, 'state', _base.GestureRecognizerState.changed);
    }

    velocity() {
      let time = Date.now();
      this.purgeDataPointsWithTime(time);

      if (this.dataPoints.length <= 1) {
        return {
          x: 0,
          y: 0
        };
      }

      let first = this.dataPoints[0];
      let last = this.dataPoints[this.dataPoints.length - 1];
      let delta = (last.time - first.time) / 1000;
      return {
        x: (last.point.x - first.point.x) / delta,
        y: (last.point.y - first.point.y) / delta
      };
    } // Private


    addDataPoint(point) {
      let time = Date.now();
      this.purgeDataPointsWithTime(time);
      this.dataPoints.push({
        time,
        point
      });
    }

    purgeDataPointsWithTime(time) {
      while (this.dataPoints.length > 0) {
        if (time - this.dataPoints[0].time <= MAX_TIME_FOR_TRACKING_DATA_POINTS) {
          break;
        }

        this.dataPoints.shift();
      }
    }

  }

  _exports.default = PanGestureRecognizer;
});