define("admin/routes/admin", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "rsvp"], function (_exports, _route, _service, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Index = (_class = class Index extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "currentOrganization", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    async model() {
      let {
        id
      } = this.currentOrganization.organization;
      let organization = await this.store.findRecord('urn:organization', id);
      let groups = this.store.queryURL(`organizations/${id}/groups`, {
        cacheKey: `organizations.${id}.groups`,
        adapterOptions: {
          modelName: 'urn:group'
        }
      });
      let users = this.store.queryURL(`organizations/${id}/users`, {
        cacheKey: `organizations.${id}.users`,
        adapterOptions: {
          modelName: 'urn:user'
        }
      });
      return (0, _rsvp.hash)({
        user: this.store.findRecord('urn:user', this.currentUser.user.id, {
          adapterOptions: {
            url: `organizations/${id}/users/${this.currentUser.user.id}`
          }
        }),
        organization,
        users,
        groups
      });
    }

    afterModel(model, transition) {
      super.afterModel(model, transition); // Ideally this would live in setup controller, but due to the call order, it isn't invoked until
      // every route context is resovled. We want to have access to these models in nested routes, so we
      // need to invoke it earlier.

      let controller = this.controllerFor('admin');
      (0, _object.set)(controller, 'user', model.user);
      (0, _object.set)(controller, 'organization', model.organization);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentOrganization", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = Index;
});