define("ember-svg-jar/inlined/icon-remove-trash-16", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M6.333 2v1H3v1h10V3H9.667V2H6.333zM4 14h8V5H4v9zm5.8-1h1.4V6H9.8v7zm-2.55 0h1.5V6h-1.5v7zM4.8 13h1.4V6H4.8v7z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});