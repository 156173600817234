define("admin/components/metadata/fieldsets/duration/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Fieldset class="metadata-duration--grid">
    <:label as |id|>
      <Label @for={{id}} @title={{@label}} @tooltip={{@tooltip}} />
    </:label>
    <:input as |id|>
      <InputText
        maxlength="255"
        id={{id}}
        placeholder={{t "metadata.form.duration.placeholder"}}
        {{setup-field @form.fields.days}}
      />
      <p class="metadata-duration--text">
        {{t "metadata.form.duration.days"}}
      </p>
      <InputText
        maxlength="255"
        placeholder={{t "metadata.form.duration.placeholder"}}
        {{setup-field @form.fields.hours}}
      />
      <p class="metadata-duration--text">
        {{t "metadata.form.duration.hours"}}
      </p>
      <InputText
        maxlength="255"
        placeholder={{t "metadata.form.duration.placeholder"}}
        {{setup-field @form.fields.minutes}}
      />
      <p class="metadata-duration--text">
        {{t "metadata.form.duration.minutes"}}
      </p>
      <InputText
        maxlength="255"
        placeholder={{t "metadata.form.duration.placeholder"}}
        {{setup-field @form.fields.seconds}}
      />
      <p class="metadata-duration--text">
        {{t "metadata.form.duration.seconds"}}
      </p>
    </:input>
  </Fieldset>
  */
  {
    "id": "bkuhcPN9",
    "block": "[[[8,[39,0],[[24,0,\"metadata-duration--grid\"]],null,[[\"label\",\"input\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@for\",\"@title\",\"@tooltip\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n  \"]],[1]],[[[1,\"\\n    \"],[8,[39,2],[[24,\"maxlength\",\"255\"],[16,1,[30,4]],[16,\"placeholder\",[28,[37,3],[\"metadata.form.duration.placeholder\"],null]],[4,[38,4],[[30,5,[\"fields\",\"days\"]]],null]],null,null],[1,\"\\n    \"],[10,2],[14,0,\"metadata-duration--text\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"metadata.form.duration.days\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],[[24,\"maxlength\",\"255\"],[16,\"placeholder\",[28,[37,3],[\"metadata.form.duration.placeholder\"],null]],[4,[38,4],[[30,5,[\"fields\",\"hours\"]]],null]],null,null],[1,\"\\n    \"],[10,2],[14,0,\"metadata-duration--text\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"metadata.form.duration.hours\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],[[24,\"maxlength\",\"255\"],[16,\"placeholder\",[28,[37,3],[\"metadata.form.duration.placeholder\"],null]],[4,[38,4],[[30,5,[\"fields\",\"minutes\"]]],null]],null,null],[1,\"\\n    \"],[10,2],[14,0,\"metadata-duration--text\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"metadata.form.duration.minutes\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],[[24,\"maxlength\",\"255\"],[16,\"placeholder\",[28,[37,3],[\"metadata.form.duration.placeholder\"],null]],[4,[38,4],[[30,5,[\"fields\",\"seconds\"]]],null]],null,null],[1,\"\\n    \"],[10,2],[14,0,\"metadata-duration--text\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"metadata.form.duration.seconds\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[4]]]]]],[\"id\",\"@label\",\"@tooltip\",\"id\",\"@form\"],false,[\"fieldset\",\"label\",\"input-text\",\"t\",\"setup-field\"]]",
    "moduleName": "admin/components/metadata/fieldsets/duration/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});