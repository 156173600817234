define("clarify-data-layer/utility/belongs-to", ["exports", "ember-m3/-private"], function (_exports, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.belongsTo = belongsTo;

  // @ts-expect-error
  function belongsTo(model, property) {
    let record = (0, _private.recordDataFor)(model);
    return record.getAttr(property);
  }
});