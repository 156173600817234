define("admin/components/routes/organization", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PageLayout::Simple>
    <:header>
      <PageLayout::Header @icon="#p-settings-48" @headline={{t "organization-index.header.title"}} />
    </:header>
    <:content>
      <DataOrganization
        @organization={{@organization}}
        @members={{@members}}
        @integrations={{@integrations}}
        @items={{@items}}
        @subscription={{@subscription}}
      />
    </:content>
  </PageLayout::Simple>
  */
  {
    "id": "0mAhmGzN",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@headline\"],[\"#p-settings-48\",[28,[37,2],[\"organization-index.header.title\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@organization\",\"@members\",\"@integrations\",\"@items\",\"@subscription\"],[[30,1],[30,2],[30,3],[30,4],[30,5]]],null],[1,\"\\n  \"]],[]]]]]],[\"@organization\",\"@members\",\"@integrations\",\"@items\",\"@subscription\"],false,[\"page-layout/simple\",\"page-layout/header\",\"t\",\"data-organization\"]]",
    "moduleName": "admin/components/routes/organization.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});