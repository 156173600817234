define("ember-svg-jar/inlined/p-fig-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M40.22 35.967c5.02-7.045 4.37-16.89-1.95-23.21-7.045-7.046-18.468-7.046-25.513 0-7.046 7.045-7.046 18.468 0 25.513 6.32 6.32 16.165 6.97 23.21 1.95l12.934 12.933 4.252-4.252L40.22 35.967zm-5.486-19.675c5.093 5.093 5.093 13.35 0 18.442-5.092 5.093-13.35 5.093-18.442 0-5.093-5.092-5.093-13.35 0-18.442 5.093-5.093 13.35-5.093 18.442 0z\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});