define("admin/helpers/clipboard-write-text", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ([text]) {
    return function (event) {
      event.stopPropagation();
      event.preventDefault();
      navigator.clipboard.writeText(text);
    };
  });

  _exports.default = _default;
});