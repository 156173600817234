define("ember-svg-jar/inlined/admin-group-36", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.75 12.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zm-2.066 8.014A7.5 7.5 0 0130 25.5V27H6v-.75a6.75 6.75 0 0110.684-5.486zM9 14.25a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});