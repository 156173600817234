define("admin/components/metadata/fieldsets/text", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Fieldset>
    <:label>
      <Label @title={{@label}} @tooltip={{@tooltip}} />
    </:label>
    <:input>
      <AText @text={{or @text "-"}} />
    </:input>
  </Fieldset>
  */
  {
    "id": "RahXYGFx",
    "block": "[[[8,[39,0],null,null,[[\"label\",\"input\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@title\",\"@tooltip\"],[[30,1],[30,2]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],null,[[\"@text\"],[[28,[37,3],[[30,3],\"-\"],null]]],null],[1,\"\\n  \"]],[]]]]]],[\"@label\",\"@tooltip\",\"@text\"],false,[\"fieldset\",\"label\",\"a-text\",\"or\"]]",
    "moduleName": "admin/components/metadata/fieldsets/text.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});