define("canvas/components/layer", ["exports", "@ember/application", "@ember/component", "canvas/component-managers/layer"], function (_exports, _application, _component, _layer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PREVIOUS_ARGS = _exports.INTERNALS = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const INTERNALS = Symbol('INTERNALS');
  _exports.INTERNALS = INTERNALS;
  const PREVIOUS_ARGS = Symbol('PREVIOUS_ARGS');
  _exports.PREVIOUS_ARGS = PREVIOUS_ARGS;

  class Layer {
    constructor(owner, canvas, args) {
      _defineProperty(this, "args", void 0);

      _defineProperty(this, "context", void 0);

      _defineProperty(this, INTERNALS, void 0);

      _defineProperty(this, PREVIOUS_ARGS, void 0);

      this.args = args;
      this[INTERNALS] = canvas;
      this.context = canvas.context;
      (0, _application.setOwner)(this, owner);
    }

    render() {}

  }

  (0, _component.setComponentManager)(owner => {
    return new _layer.LayerComponentManager(owner);
  }, Layer);
  var _default = Layer;
  _exports.default = _default;
});