define("ember-svg-jar/inlined/admin-integration-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.442 8.321l-1.724 1.725 1.414 1.414 1.725-1.725 1.586 1.586-1.726 1.725 1.415 1.414 1.725-1.725L17.02 13.9l.707-.707a5 5 0 00.327-6.711l1.773-1.774L19.121 4l-1.775 1.776a5 5 0 00-6.69.345l-.706.707 1.492 1.493zm-5.32 2.336a5 5 0 00-.346 6.689L4 19.12l.707.707 1.774-1.773a5 5 0 006.711-.327l.707-.707L6.83 9.95l-.708.707z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});