define("admin/components/routes/users", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object", "ember-concurrency", "@ember/object/computed", "admin/components/data-grid", "admin/tasks/members-delete", "ember-concurrency-ts"], function (_exports, _component, _templateFactory, _component2, _service, _tracking, _object, _emberConcurrency, _computed, _dataGrid, _membersDelete, _emberConcurrencyTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PageLayout::Complex>
    <:header>
      <PageLayout::Header @icon="#admin-member-48" @headline={{t "users-index.header.title"}}>
        <:actions>
          <FlatButton::Link
            data-intercom-target="Invite member"
            @route={{route "admin.users.invitations"}}
            @text={{t "users-index.header.invite-button"}}
            @icon="#design-system-p-plus-thick-24"
            @size="medium"
          />
        </:actions>
      </PageLayout::Header>
    </:header>
    <:content>
      <DataView
        class="page-layout-complex--data-view"
        @route="admin.users.show"
        @resource={{@resource}}
        @descriptors={{this.descriptors}}
        @selectedModelsDidChange={{this.selectedModelsDidChange}}
        @selectionStyle={{hash type="multiple" route="admin.users.show"}}
      >
        <:toolbar-actions>
          {{#if this.selectedModels.length}}
            <AButtonTertiary::Group>
              <AButtonTertiary
                title={{
                  t
                  "users-index.toolbar.delete.tooltip"
                  disabled=this.isDeletableUser
                  count=this.selectedModels.length
                  selectedModelsCount=this.selectedModels.length
                }}
                @size="small"
                @theme="light"
                @icon="#icon-remove-trash-16"
                disabled={{this.isDeletableUser}}
                {{on "click" (alert-members-delete this.filteredMembersExceptCurrentUser task=(task this.deleteUsers))}}
              />
            </AButtonTertiary::Group>
          {{/if}}
        </:toolbar-actions>
      </DataView>
    </:content>
  </PageLayout::Complex>
  */
  {
    "id": "hsPLId9w",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@headline\"],[\"#admin-member-48\",[28,[37,2],[\"users-index.header.title\"],null]]],[[\"actions\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,\"data-intercom-target\",\"Invite member\"]],[[\"@route\",\"@text\",\"@icon\",\"@size\"],[[28,[37,4],[\"admin.users.invitations\"],null],[28,[37,2],[\"users-index.header.invite-button\"],null],\"#design-system-p-plus-thick-24\",\"medium\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"page-layout-complex--data-view\"]],[[\"@route\",\"@resource\",\"@descriptors\",\"@selectedModelsDidChange\",\"@selectionStyle\"],[\"admin.users.show\",[30,1],[30,0,[\"descriptors\"]],[30,0,[\"selectedModelsDidChange\"]],[28,[37,6],null,[[\"type\",\"route\"],[\"multiple\",\"admin.users.show\"]]]]],[[\"toolbar-actions\"],[[[[1,\"\\n\"],[41,[30,0,[\"selectedModels\",\"length\"]],[[[1,\"          \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,9],[[16,\"title\",[28,[37,2],[\"users-index.toolbar.delete.tooltip\"],[[\"disabled\",\"count\",\"selectedModelsCount\"],[[30,0,[\"isDeletableUser\"]],[30,0,[\"selectedModels\",\"length\"]],[30,0,[\"selectedModels\",\"length\"]]]]]],[16,\"disabled\",[30,0,[\"isDeletableUser\"]]],[4,[38,10],[\"click\",[28,[37,11],[[30,0,[\"filteredMembersExceptCurrentUser\"]]],[[\"task\"],[[28,[37,12],[[30,0,[\"deleteUsers\"]]],null]]]]],null]],[[\"@size\",\"@theme\",\"@icon\"],[\"small\",\"light\",\"#icon-remove-trash-16\"]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@resource\"],false,[\"page-layout/complex\",\"page-layout/header\",\"t\",\"flat-button/link\",\"route\",\"data-view\",\"hash\",\"if\",\"a-button-tertiary/group\",\"a-button-tertiary\",\"on\",\"alert-members-delete\",\"task\"]]",
    "moduleName": "admin/components/routes/users.hbs",
    "isStrictMode": false
  });

  let UserRouteComponent = (_dec = (0, _computed.alias)('currentUser.user'), (_class = class UserRouteComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "alert", _descriptor2, this);

      _initializerDefineProperty(this, "authentication", _descriptor3, this);

      _initializerDefineProperty(this, "selectedModels", _descriptor4, this);

      _initializerDefineProperty(this, "user", _descriptor5, this);

      _initializerDefineProperty(this, "deleteMembers", _descriptor6, this);
    }

    *deleteUsers() {
      try {
        let filteredMembers = Array.from(this.filteredMembersExceptCurrentUser);
        yield (0, _emberConcurrencyTs.taskFor)(this.deleteMembers).perform(filteredMembers);
      } catch (error) {
        this.alert.showDismissibleError(error);
      }
    }

    get isDeletableUser() {
      return this.filteredMembersExceptCurrentUser.length == 0;
    }

    get filteredMembersExceptCurrentUser() {
      return this.selectedModels.filter(member => {
        var _member$user;

        return this.authentication.userId !== ((_member$user = member.user) === null || _member$user === void 0 ? void 0 : _member$user.id);
      });
    }

    selectedModelsDidChange(selectedModels) {
      this.selectedModels = selectedModels;
    }

    get descriptors() {
      return [{
        type: 'checkbox',
        visibility: _dataGrid.TableCellColumnVisibility.mandatory
      }, {
        title: this.intl.t('users-index.data-grid-descriptors.name'),
        keyPath: 'name',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'input'
        }
      }, {
        title: this.intl.t('users-index.data-grid-descriptors.email'),
        keyPath: 'email',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'input'
        }
      }, {
        title: this.intl.t('users-index.data-grid-descriptors.role.title'),
        keyPath: 'role',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'select',
          options: [{
            title: this.intl.t('users-index.data-grid-descriptors.role.options.member'),
            value: 'member'
          }, {
            title: this.intl.t('users-index.data-grid-descriptors.role.options.admin'),
            value: 'admin'
          }]
        }
      }, {
        title: this.intl.t('users-index.data-grid-descriptors.groups'),
        keyPath: 'groups',
        width: _dataGrid.TableCellWidth.default,
        type: 'string-array',
        entryKeyPath: 'name',
        sortable: false,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'select-multiple',
          items: this.args.groups.map(group => {
            return {
              id: group.id,
              parentId: null,
              name: group.name,
              path: [group.id],
              filters: {
                groups: {
                  $in: [group.id]
                }
              },
              count: 0
            };
          })
        }
      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "alert", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "authentication", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedModels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deleteMembers", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _membersDelete.default;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteUsers", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedModelsDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectedModelsDidChange"), _class.prototype)), _class));
  _exports.default = UserRouteComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UserRouteComponent);
});