define("admin/templates/admin/integrations/signals/show/index/connect/index-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HUVzIBIv",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,0,\"signal-connect--dialog\"]],null,[[\"header\",\"content\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@title\",\"@route\"],[[28,[37,3],[\"signal-connect.header.title\"],null],[28,[37,4],[\"admin.integrations.signals.show.index\"],null]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[39,5],null,[[\"@title\"],[[28,[37,3],[\"signal-connect.states.loading\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"dialog-container\",\"dialog\",\"dialog/header\",\"t\",\"route\",\"admin-state/loading\"]]",
    "moduleName": "admin/templates/admin/integrations/signals/show/index/connect/index-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});