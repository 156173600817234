define("admin/components/data-view/column-selector/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "admin/components/data-grid"], function (_exports, _component, _templateFactory, _component2, _object, _dataGrid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Filter @data={{this.filterableColumns}} @filterData={{filter-by "model.title"}} as |filter|>
    <PopoverLayout ...attributes>
      <:header>
        <h2 class="column-selector--header">
          {{t "data-view.column-selector.header"}}
        </h2>
        <InputSearch
          placeholder={{t "data-view.column-selector.input-placeholder"}}
          value={{filter.input}}
          {{set-focus true}}
          {{on "input" (fn filter.handleInput)}}
        />
      </:header>
      <:list>
        {{#each filter.data as |result|}}
          <PopoverLayout::Item
            @title={{result.model.title}}
            @checked={{result.enabled}}
            @handleCheckedDidChange={{fn this.setEnabled result}}
          />
        {{else}}
          <NonIdealState @title={{t "data-view.column-selector.empty-state"}} />
        {{/each}}
      </:list>
      <:footer>
        <FlatButton @size="small" @text={{t "data-view.column-selector.close-button"}} {{on "click" (fn @hide)}} />
      </:footer>
    </PopoverLayout>
  </Filter>
  */
  {
    "id": "QufjXnXu",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@filterData\"],[[30,0,[\"filterableColumns\"]],[28,[37,1],[\"model.title\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[17,2]],null,[[\"header\",\"list\",\"footer\"],[[[[1,\"\\n      \"],[10,\"h2\"],[14,0,\"column-selector--header\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"data-view.column-selector.header\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,4],[[16,\"placeholder\",[28,[37,3],[\"data-view.column-selector.input-placeholder\"],null]],[16,2,[30,1,[\"input\"]]],[4,[38,5],[true],null],[4,[38,6],[\"input\",[28,[37,7],[[30,1,[\"handleInput\"]]],null]],null]],null,null],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,1,[\"data\"]]],null]],null],null,[[[1,\"        \"],[8,[39,10],null,[[\"@title\",\"@checked\",\"@handleCheckedDidChange\"],[[30,3,[\"model\",\"title\"]],[30,3,[\"enabled\"]],[28,[37,7],[[30,0,[\"setEnabled\"]],[30,3]],null]]],null],[1,\"\\n\"]],[3]],[[[1,\"        \"],[8,[39,11],null,[[\"@title\"],[[28,[37,3],[\"data-view.column-selector.empty-state\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]],[[[1,\"\\n      \"],[8,[39,12],[[4,[38,6],[\"click\",[28,[37,7],[[30,4]],null]],null]],[[\"@size\",\"@text\"],[\"small\",[28,[37,3],[\"data-view.column-selector.close-button\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"filter\",\"&attrs\",\"result\",\"@hide\"],false,[\"filter\",\"filter-by\",\"popover-layout\",\"t\",\"input-search\",\"set-focus\",\"on\",\"fn\",\"each\",\"-track-array\",\"popover-layout/item\",\"non-ideal-state\",\"flat-button\"]]",
    "moduleName": "admin/components/data-view/column-selector/index.hbs",
    "isStrictMode": false
  });

  let ColumnSelector = (_class = class ColumnSelector extends _component2.default {
    get filterableColumns() {
      return this.args.data.filter(entry => entry.model.visibility !== _dataGrid.TableCellColumnVisibility.mandatory);
    }

    setEnabled(result, enabled) {
      result.enabled = enabled;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setEnabled", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setEnabled"), _class.prototype)), _class);
  _exports.default = ColumnSelector;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ColumnSelector);
});