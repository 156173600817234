define("admin/components/data-organization/portal-loader/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "clarify/utilities/rpc/payment-create-session", "clarify/utilities/uuid", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _service, _paymentCreateSession, _uuid, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash open=(perform this.openPortal) loading=this.openPortal.isRunning)}}
  */
  {
    "id": "wypDBlgw",
    "block": "[[[18,1,[[28,[37,1],null,[[\"open\",\"loading\"],[[28,[37,2],[[30,0,[\"openPortal\"]]],null],[30,0,[\"openPortal\",\"isRunning\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"perform\"]]",
    "moduleName": "admin/components/data-organization/portal-loader/index.hbs",
    "isStrictMode": false
  });

  let PortalLoader = (_class = class PortalLoader extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "network", _descriptor, this);
    }

    *openPortal() {
      let request = _paymentCreateSession.PaymentCreateSessionRequestInfo.create((0, _uuid.default)(), {
        subscription: this.args.subscription.id
      });

      try {
        let [result] = yield this.network.rpc(`meta/rpc`, {}, request);

        if (result.type === 'result') {
          window.location.href = result.result.url;
          return;
        }

        throw result.error;
      } catch (error) {
        console.error('PortalLoader#openPortal failed with error: ', error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openPortal", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "openPortal"), _class.prototype)), _class);
  _exports.default = PortalLoader;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PortalLoader);
});