define("admin/routes/admin/integrations/index/created", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IntegrationIndexNewRoute extends _route.default {
    async model(params) {
      let integration = this.store.findRecord('urn:integration', params.integrationId);
      return integration;
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'integration', model);
    }

  }

  _exports.default = IntegrationIndexNewRoute;
});