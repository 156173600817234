define("clarify-data-layer/models/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ItemType = void 0;
  let ItemType;
  _exports.ItemType = ItemType;

  (function (ItemType) {
    ItemType["numeric"] = "numeric";
    ItemType["enum"] = "enum";
    ItemType["geoPosition"] = "geo-position";
    ItemType["vector"] = "vector";
  })(ItemType || (_exports.ItemType = ItemType = {}));
});