define("admin/controllers/admin/items/show/index", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ItemsShowPanes = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let ItemsShowPanes;
  _exports.ItemsShowPanes = ItemsShowPanes;

  (function (ItemsShowPanes) {
    ItemsShowPanes["metadata"] = "metadata";
    ItemsShowPanes["source"] = "source";
  })(ItemsShowPanes || (_exports.ItemsShowPanes = ItemsShowPanes = {}));

  class ItemShowController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "pane", ItemsShowPanes.metadata);

      _defineProperty(this, "queryParams", ['pane']);

      _defineProperty(this, "item", void 0);

      _defineProperty(this, "categories", void 0);

      _defineProperty(this, "signals", void 0);
    }

  }

  _exports.default = ItemShowController;
});