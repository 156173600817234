define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "access-control": {
      "profile-group": {
        "members": "{count} members"
      },
      "tooltip": {
        "title": "View group members"
      }
    },
    "activity-feed": {
      "activity-group-comment": {
        "title": "{name} commented"
      },
      "activity-group-comment-collapsed": {
        "title": "{names} commented"
      },
      "activity-group-reply": {
        "title": "{name} replied"
      },
      "activity-group-reply-collapsed": {
        "title": "{names} replied"
      },
      "comments": {
        "attachment-file": "{file, plural, one {Uploaded a file} other {Uploaded # files}}",
        "attachment-image": "{image, plural, one {Uploaded an image} other {Uploaded # images}}",
        "authors": "{name} and {others, plural, =0 {0 others} =1 {1} other {# others}}",
        "commented": "{date} ago",
        "multiple-attachments": "Uploaded {images, plural, =0 {0 images} =1 {1 image} other {# images}} and {files, plural, =0 {0 files} =1 {1} other {# files}}",
        "replies": "{comments, plural, one {1 comments} other {# new comments}}"
      },
      "createdAt": "{date} ago",
      "page-title": "Activities",
      "reply": {
        "attachments": "There is an attachment in this reply",
        "replied": "{date} ago",
        "replies": "{replies, plural,  =0 {No replies} =1 {1 reply} other {# replies}} in thread"
      },
      "state": {
        "empty": {
          "description": "Activities from your organization will show up here",
          "title": "No activity yet"
        },
        "loading-epilogue": "Loading activities",
        "loading-error": "Couldn’t load more activities right now."
      }
    },
    "admin-navigation": {
      "settings": {
        "header": "System settings",
        "link-groups": "Groups",
        "link-integrations": "Integrations",
        "link-invitations": "Invitations",
        "link-items": "Items",
        "link-members": "Members",
        "link-organization": "Billing & Organization",
        "link-profile": "My profile"
      }
    },
    "alert": {
      "cancel": "Cancel"
    },
    "alert-group-delete": {
      "message": "Members that have access to timelines, or data, by being a member of this group will no longer be able to access those entities.'<br><br>' Are you sure you want to delete this group?",
      "primary-button": "Delete",
      "title": "Delete group “{name}”"
    },
    "alert-groups-delete": {
      "message": "Members that have access to timelines, or data, by being a member of {count, plural, =1 {this group will no longer be able to access those entities.'<br><br>' Are you sure you want to delete this group?} other {these groups will no longer be able to access those entities.'<br><br>' Are you sure you want to delete these groups?}}",
      "primary-button": "Delete",
      "title": "{count, plural, one {Delete group {name}} other {Delete groups: {name}}}"
    },
    "alert-integration-delete": {
      "confirmation": {
        "message": "There is no way to get the data back once it is deleted.'<br><br>'Are you sure you want to delete all the data stored by the integration?",
        "primary-button": "Delete integration and data",
        "title": "Warning!"
      },
      "initial": {
        "message": "{name} will be deleted. All stored data in signals and items originating from this integration will be permanently deleted.'<br><br>'Are you sure you want to delete this integration?",
        "primary-button": "Delete",
        "title": "Delete integration"
      }
    },
    "alert-integrations-delete": {
      "confirmation": {
        "message": "There is no way to get the data back once it is deleted.'<br><br>'Are you sure you want to delete all the data stored by {count, plural, =1 {this integration?} other {these integrations?}}",
        "primary-button": "Delete {count, plural, =1 {integration} other {integrations}} and data",
        "title": "Warning!"
      },
      "initial": {
        "message": "{name} will be deleted. All stored data in signals and items originating from this integration will be permanently deleted.'<br><br>'Are you sure you want to delete {count, plural, =1{this integration?} other {these integrations?}}",
        "primary-button": "Delete",
        "title": "Delete integration"
      }
    },
    "alert-item-delete": {
      "message": "The item will be removed, but the original signal and all stored data will remain. Timelines that use this item will no longer be able to display the data.'<br><br>'Are you sure you want to remove this item?",
      "primary-button": "Delete",
      "title": "Delete item “{name}”"
    },
    "alert-items-delete": {
      "confirmation": {
        "message": "There is no way to get the data back once it is deleted.'<br><br>'Are you sure you want to delete all the data stored by {count, plural, =1 {this item?} other {these items?}}",
        "primary-button": "Delete {count, plural, =1 {item} other {items}} and data",
        "title": "Warning!"
      },
      "message": "The {count, plural, =1 {item will be removed, but the original signal and all stored data will remain. Timelines that use this item will no longer be able to display the data.'<br><br>'Are you sure you want to remove this item?} other {items will be removed, but the original signals and all stored data will remain. Timelines that use these items will no longer be able to display the data.'<br><br>'Are you sure you want to remove these items?}}",
      "primary-button": "Delete",
      "title": "{count, plural, one {Delete item “{name}”} other {Delete these items: “{name}”}}"
    },
    "alert-label-category-remove": {
      "message": "Are you sure you want to remove this label category?",
      "primary-button": "Remove",
      "title": "Remove label category"
    },
    "alert-label-remove": {
      "message": "Are you sure you want to remove this label?",
      "primary-button": "Remove",
      "title": "Remove label"
    },
    "alert-member-delete": {
      "message": "This user will no longer be able to log in to Clarify. All activity made by the user will still be visible for other users.'<br><br>'Are you sure you want to remove this user?",
      "primary-button": "Delete",
      "title": "Delete user “{name}”"
    },
    "alert-members-delete": {
      "message": "{count, plural, one {This user will no longer be able to log in to Clarify.'<br><br>'Are you sure you want to remove this user?} other {These users will no longer be able to log in to Clarify.'<br><br>'Are you sure you want to remove these users?}}",
      "primary-button": "Delete",
      "title": "{count, plural, one {Delete user “{name}”} other {Delete multiple users: {name}}}"
    },
    "alert-signal-delete": {
      "confirmation": {
        "message": "There is no way to get the data back once it is deleted.'<br><br>'Are you sure you want to delete all the data stored by this signal?",
        "primary-button": "Delete signal and data",
        "title": "Warning!"
      },
      "initial": {
        "message": "All stored data from this signal will be permanently deleted.'<br><br>'Are you sure you want to delete this signal?",
        "primary-button": "Delete",
        "title": "Delete signal “{name}”"
      }
    },
    "alert-signal-disconnect": {
      "message": "The item will no longer receive data from this signal.",
      "primary-button": "Disconnect",
      "title": "Disconnect from Item?"
    },
    "alert-signals-delete": {
      "confirmation": {
        "message": "There is no way to get the data back once it is deleted.'<br><br>'Are you sure you want to delete all the data stored by {count, plural, =1 {this signal?} other {these signals?}}",
        "primary-button": "Delete {count, plural, =1 {signal} other {signals}} and data",
        "title": "Warning!"
      },
      "initial": {
        "message": "All stored data from {count, plural, =1 {this signal will be permanently deleted.'<br><br>'Are you sure you want to delete this signal?} other {these signals will be permanently deleted.'<br><br>'Are you sure you want to delete these signals?}}",
        "primary-button": "Delete",
        "title": "{count, plural, one {Delete signal {name}} other {Delete signals: {name}}}"
      }
    },
    "app-header": {
      "admin": {
        "groups": "Groups",
        "integrations": "Integrations",
        "invitations": "Invitations",
        "items": "Items",
        "members": "Users",
        "organization": "Billing & Organization",
        "switch": "Switch organization:"
      },
      "logout": "Logout",
      "support": "Learn & support",
      "trial": {
        "button": "Upgrade",
        "description": "{daysLeftOfTrial, plural, =1 {1 day} other {# days}} left of trial"
      }
    },
    "application-404": {
      "description": "The page you’re looking for can’t be found",
      "title": "Page not found"
    },
    "application-browser-error": {
      "description": "Oops! It seems you are running Internet Explorer. Clarify supports most modern browsers like Firefox, Safari, Edge, Chrome, Brave or Opera. Please upgrade your browser to continue.",
      "title": "Your browser does not support Clarify."
    },
    "application-error": {
      "description": "There seems to be a problem with Clarify, please try again",
      "title": "Unknown error"
    },
    "application-loading": {
      "loading": "Starting Clarify"
    },
    "callback": {
      "description": "{error, select, \n  password_strength_error {The chosen password doesn’t match our password requirements} \n  invalid_password {The password used doesn’t comply with the password policy for the connection} \n  password_no_user_info_error {The chosen password is based on user information} \n  password_dictionary_error {The chosen password is too common} \n  invalid_signup {The user your are attempting to sign up is invalid} \n  user_exists {The user you are attempting to sign up has already signed up} \n  access_denied {Access denied} \n  invalid_user_password {The username and/or password used for authentication are invalid} \n  password_leaked {Your password isn’t safe you’ll need to reset it} \n  PasswordHistoryError {The password provided for sign up/update has already been used} \n  too_many_attempts {The account is blocked due to too many attempts to sign in} \n  unauthorized {The user you are attempting to sign in with is blocked} \n  other {Unknown authentication error}\n}",
      "passwordless": "{error, select, unauthorized {Looks like the verification link has expired or has already been used. '<a' href={retryURL}'>'Click here to resend the verification'</a>'} other {Unknown authentication error} }",
      "title": "Authentication error"
    },
    "color-popover": {
      "header": "Select color"
    },
    "comment-attachment": {
      "download": "Download"
    },
    "context-menus": {
      "visualization-item-settings": {
        "delete": "Remove item from visualization"
      },
      "visualization-settings": {
        "delete": "Delete visualization",
        "open-settings": "Open settings"
      }
    },
    "data-grid": {
      "content": {
        "item-visible-state": {
          "hidden": "Not visible",
          "status": "{status, select, true {Visible} false {Not visible} other {unknown status}}",
          "visible": "Visible"
        },
        "labels": "'<b>'{category}:'</b>' {label}",
        "signal-published-state": {
          "not-published": "Unpublished",
          "published": "Published"
        }
      },
      "failed": {
        "description": "We can write a smart message here if we have something that may help the user.",
        "retry": "Retry",
        "title": "Loading failed…"
      },
      "filters": {
        "clear": "Clear filter",
        "input": {
          "placeholder": "Filter…"
        },
        "select": {
          "placeholder": "Select…"
        },
        "select-multiple": {
          "placeholder": "Select…"
        }
      },
      "loading": {
        "label": "Loading"
      }
    },
    "data-grid-filter": {
      "close-button": "Apply",
      "empty-state": "No labels found",
      "placeholder": "Filter…"
    },
    "data-view": {
      "column-selector": {
        "close-button": "OK",
        "empty-state": "No results match your search",
        "header": "Select which fields to display",
        "input-placeholder": "Filter..."
      },
      "empty-state-filter": {
        "description": "'<p>'No matches for your search'</p>'",
        "title": "No entries"
      },
      "empty-state-initial": {
        "description": "'<p>'There are currently no resources here'</p>'",
        "title": "No entries"
      }
    },
    "delete-comment-alert": {
      "delete-button": {
        "name": "Remove"
      },
      "message": "Are you sure you want to remove the comment?",
      "title": "Remove comment?"
    },
    "delete-thread-alert": {
      "delete-button": {
        "name": "Delete"
      },
      "message": "The original post and {number} replies will be permanently removed. This action cannot be undone.",
      "title": "Delete this thread?"
    },
    "delete-timeline-alert": {
      "delete-button": {
        "named": "Delete “{name}”",
        "untitled": "Delete timeline"
      },
      "message": "",
      "title": "Are you sure?"
    },
    "delete-visualization-alert": {
      "delete-button": {
        "named": "Delete “{name}”",
        "untitled": "Delete visualization"
      },
      "message": "",
      "title": "Are you sure?"
    },
    "delete-visualization-item-alert": {
      "delete-button": "Remove “{name}”",
      "message": "",
      "title": "Are you sure?"
    },
    "edit-signal-helper": {
      "actions": {
        "edit": "Edit"
      },
      "message": "If your integration supports updating of metadata the changes you make to metadata may be reset by your integration. Are you sure you want to edit signal metadata?",
      "title": "Warning"
    },
    "error": {
      "dismiss": "Dismiss",
      "faq": "Please contact support if the problem persists.",
      "message": "Sorry, something went wrong. Try again or contact support if the problem persists.",
      "support": "If the problem persists, contact '<a href='mailto:support@clarify.us'>'Clarify support'</a>.",
      "title": "Uh oh!"
    },
    "export-modal": {
      "all-items": {
        "label": "All items ({items})"
      },
      "calendar": {
        "end": "To",
        "label": "Please select a start and end date.",
        "placeholder": "dd/mm/yyyy",
        "start": "From",
        "time": "hh:mm"
      },
      "exporting": {
        "back": "Back to export",
        "cancel": "Cancel",
        "errors": {
          "dismiss": "Close",
          "empty": "",
          "title": "Can’t export this data",
          "unsupported": "Export not supported by enum items"
        },
        "export": "Export",
        "paragraph": "A download link will be sent by email to the following address:",
        "state-accepted": "Your export will be ready soon!",
        "state-exporting": "Preparing export...",
        "title": "Confirm export?"
      },
      "interval-picker": {
        "hours": "hours",
        "validate": "Please select a valid time increment."
      },
      "items": {
        "average": "Avg",
        "empty-signal": "No items",
        "empty-title": "Visualization without name",
        "maximum": "Max",
        "minimum": "Min",
        "sum": "Sum"
      },
      "items-selected": "{items, plural, one {1 item selected} other {# items selected}}",
      "selected-period": {
        "export": "Export data",
        "label": "Selected period:"
      },
      "time-increment": {
        "days": "days",
        "hours": "hours",
        "label": "Time increment",
        "minutes": "minutes"
      }
    },
    "export-modal-exit-alert": {
      "exit-button": "Exit",
      "message": "Your export settings will not be saved, and the data will not be exported.",
      "title": "Exit export?"
    },
    "filter-auto-complete": {
      "filter-bar": "Find in timelines",
      "query": "Title: {query}"
    },
    "format-array": {
      "many": "{names}, and {last}",
      "many-others": "{names}, and {others} others",
      "others": "{name} and {others} others",
      "two": "{name} and {other}"
    },
    "format-duration": {
      "days": "{days, plural, one {1 day} other {# days}}",
      "hours": "{hours, plural, one {1 hour} other {# hrs}}",
      "minutes": "{minutes, plural, one {1 min} other {# min}}",
      "seconds": "{seconds, plural, one {1 sec} other {# sec}}"
    },
    "forms": {
      "label": {
        "copy-text": "Copy text"
      }
    },
    "forms-items": {
      "fieldset": {
        "delta-interval": {
          "title": "Delta interval",
          "tooltip": "Determine the maximum distance between points which are connected in timelines."
        },
        "description": {
          "placeholder": "Add some info to remember the item by...",
          "title": "Description"
        },
        "engineering-unit": {
          "placeholder": "Enter value…",
          "title": "Engineering unit",
          "tooltip": "The unit of measurement for the item makes it easier to read data in timelines."
        },
        "enum-values": {
          "title": "Enum values",
          "tooltip": "Enum values translates the time series data into a human readable text in timelines"
        },
        "id": {
          "copy-description": "Copy item Id",
          "title": "Item ID",
          "tooltip": "A unique identifier for the item."
        },
        "sample-interval": {
          "title": "Sample interval",
          "tooltip": "The sample rate of the data stored."
        },
        "source-type": {
          "options": {
            "aggregation": "Aggregation",
            "measurement": "Measurement",
            "prediction": "Prediction"
          },
          "title": "Engineering type",
          "tooltip": "Choose between numeric for data that is best plotted as a graph, or enums for data that you want plotted as different statuses. Enums allow you to map a textual representation for each value in your data!"
        },
        "title": {
          "placeholder": "Item name…",
          "title": "Item name"
        },
        "type": {
          "options": {
            "enum": "Enum",
            "numeric": "Numeric"
          },
          "title": "Type",
          "tooltip": "Choose between numeric for data that is best plotted as a graph, or enums for data that you want plotted as different statuses. Enums allow you to map a textual representation for each value in your data!"
        }
      }
    },
    "forms-signals": {
      "description": {
        "placeholder": "This signal is about…",
        "title": "Description",
        "tooltip": "Here you will find a short signal description"
      },
      "id": {
        "copy-description": "Copy signal Id",
        "title": "Signal ID",
        "tooltip": "Internal ID of the signal."
      },
      "input-id": {
        "copy-description": "Copy input Id",
        "description": "This will be the identifier used to send data to Clarify",
        "placeholder": "Enter an input ID…",
        "title": "Input ID",
        "title-required": "Input ID *"
      },
      "name": {
        "description": "This name will be used by default when you publish the signal.",
        "placeholder": "Enter a name…",
        "title": "Signal name",
        "title-required": "Signal name *",
        "tooltip": "The name of the signal."
      }
    },
    "group-edit-members": {
      "add": {
        "description": "Add to group",
        "title": "+ Add"
      },
      "browser-title": "Edit members — {name}",
      "empty-state": "No results match your search",
      "header": "Edit members",
      "input-placeholder": "Search by name, email...",
      "remove": {
        "description": "Remove from group",
        "title": "Remove"
      }
    },
    "groups-index": {
      "browser-title": "Groups",
      "data-grid-descriptors": {
        "description": "Description",
        "members": "Users",
        "name": "Name"
      },
      "header": {
        "create-button": "Create a new group",
        "title": "Groups"
      },
      "states": {
        "error": "Failed loading…",
        "loading": "Loading groups content"
      },
      "toolbar": {
        "delete": "{disabled, select, true {{selectedModelsCount, plural, =1 {This group can’t be deleted} other {These groups can’t be deleted}}} false {{count, plural, =1 {Delete group} other {Delete groups}}} other{} }"
      }
    },
    "groups-new": {
      "actions": {
        "cancel": "Cancel",
        "submit": "Create"
      },
      "alerts": {
        "errors": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong with your request.",
          "title": "Uh oh!"
        }
      },
      "create": {
        "browser-title": "New group",
        "group-name": "* Group name",
        "group-placeholder": "Enter a name...",
        "group-tooltip": "The name of the group (required)",
        "title": "New group"
      },
      "description": {
        "group-name": "description",
        "group-placeholder": "Add some info about the purpose of this group..."
      }
    },
    "groups-show": {
      "alerts": {
        "destroy": {
          "message": "Failed deleting group, try again or contact support if the problem persists",
          "primary-button": "Dismiss",
          "title": "Uh oh!"
        }
      },
      "fieldsets": {
        "createdAt": {
          "label": "Created at"
        },
        "description": {
          "label": "Description",
          "placeholder": "Add a description to remember this group by..."
        },
        "header": {
          "title": "Name & Description",
          "tooltip": "Name & Description"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name"
        }
      },
      "header": {
        "actions": {
          "delete": "Delete group"
        },
        "members": "{members, plural, one {1 member} other {# members}}"
      },
      "members": {
        "add-to-group-button": "+ Add members",
        "members-list": {
          "header": "Group members",
          "remove-button": "Remove from group"
        },
        "remove-user-from-group-alert": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong with your request.",
          "title": "Uh oh!"
        }
      }
    },
    "inputfield": {
      "edit-label": "Edit display name",
      "title": "Enter a label"
    },
    "integration-create": {
      "back": "Back",
      "browser-title": "Create integration",
      "cancel": "Cancel",
      "create": "Create",
      "header": "Create integration",
      "integration-info": {
        "description": {
          "placeholder": "Add some info to remember this integration by…",
          "title": "Description"
        },
        "name": {
          "placeholder": "Enter a name…",
          "title": "* Integration name",
          "tooltip": "The name of the integration is only used in the admin panel."
        },
        "title": "Integration info"
      },
      "intro": {
        "how-to-setup": "Guide: How to set up your integration >",
        "intro": "'<p aria-hidden=\"true\">'👋 👋 👋'</p>'\n'<p>'This wizard will help you connect data with Clarify.'</p>'\n'<p>'If you want more information about setting up integrations you can read more about it in one of the guides below.'</p>'\n'<p>'\n'<a href=\"https://docs.clarify.us/docs/setting-up-an-integration\">'Guide: Setting up an integration'</a>'\n'</p>'",
        "title": "Intro"
      },
      "next": "Next"
    },
    "integration-created": {
      "browser-title": "Create integration",
      "content": "'<p aria-hidden=\"true\">'🎉 🎉 🎉'</p>'\n'<p>'Success! The integration '<b>'{integrationName}'</b>' has been created, and is ready to receive data.'</p>'\n'<p>'To connect it, copy the credentials from below and paste them into the system you are sending the data from.'</p>'\n'<p>'\n'<a href=\"https://docs.clarify.us/docs/obtaining-an-access-token\">'Guide: Obtaining an access token'</a>'\n'<br>'\n'<a href=\"https://docs.clarify.us/docs/writing-meta-data-to-clarify\">'Guide: Writing meta-data for signals'</a>'\n'<br>'\n'<a href=\"https://docs.clarify.us/docs/writing-data-to-clarify\">'Guide: Writing data to Clarify'</a>'\n'</p>'",
      "credentials": {
        "title": "Credentials"
      },
      "header": "Create integration"
    },
    "integrations-index": {
      "browser-title": "Integrations",
      "data-grid-descriptors": {
        "createdAt": "Created at",
        "dataUpdateInterval": "Data update interval",
        "description": "Description",
        "firstInsertAt": "First insert at",
        "id": "Integration id",
        "insertedValues": "Data points",
        "lastInsertAt": "Last inserted at",
        "name": "Name",
        "recentInsertInterval": "Recent insert interval",
        "trackedInserts": "Insert operations",
        "trackedSince": "Tracked since",
        "updatedAt": "Last updated"
      },
      "header": {
        "create-button": "Add integration",
        "title": "Integrations"
      },
      "states": {
        "error": "Failed loading…",
        "loading": "Loading integrations content"
      },
      "toolbar": {
        "delete": {
          "tooltip": "{count, plural, one {Delete # integration} other {Delete # integrations}}"
        }
      }
    },
    "integrations-show": {
      "alerts": {
        "destroy": {
          "message": "Failed deleting integration, try again or contact support if the problem persists",
          "primary-button": "Dismiss",
          "title": "Uh oh!"
        }
      },
      "browser-title": "{pane, select, about {Metadata} labels {Labels} credentials {Credentials} other {Unknown}} – {name}",
      "credentials-pane": {
        "credentials-download-button": "clarify-credentials.json",
        "credentials-label": "Credentials",
        "header": "Client credentials"
      },
      "fieldsets": {
        "description": {
          "label": "Description",
          "placeholder": "Add a description to remember this integration by…"
        },
        "header": {
          "title": "Name & Description",
          "tooltip": "Name & Description"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name"
        }
      },
      "header": {
        "actions": {
          "delete": "Delete integration",
          "dismiss": "Done"
        },
        "integration-id": {
          "copy-description": "Copy integration Id"
        },
        "signals-button": "Show all signals >",
        "tabs": {
          "about": "General",
          "credentials": "Credentials",
          "labels": "Labels"
        }
      }
    },
    "integrations-show-edit": {
      "browser-title": "Edit signal — {name}",
      "dialog": {
        "footer": {
          "action-dismiss": "Cancel",
          "action-save": "Save"
        },
        "header": "Edit signal"
      }
    },
    "integrations-signals-index": {
      "browser-title": "Signals – {name}",
      "data-grid-descriptors": {
        "createdAt": "Created at",
        "deltaInterval": "Delta interval",
        "description": "Description",
        "engUnit": "Engineering unit",
        "id": "Signal ID",
        "input-id": "Input ID",
        "item": "Published",
        "item-options": {
          "not-published": "Not published",
          "published": "Published"
        },
        "label": "Label: {name}",
        "name": "Name",
        "sampleInterval": "Sample interval",
        "type": "Type",
        "updatedAt": "Last updated"
      },
      "header": {
        "create-button": "Create new signal",
        "title": "Signals"
      },
      "states": {
        "error": "Failed loading…",
        "loading": "Loading signals content"
      },
      "toolbar": {
        "delete": {
          "tooltip": "{count, plural, one {Delete # signal} other {Delete # signals}}"
        }
      }
    },
    "integrations-signals-show": {
      "alerts": {
        "destroy": {
          "message": "Failed deleting signal, try again or contact support if the problem persists",
          "primary-button": "Dismiss",
          "title": "Uh oh!"
        }
      },
      "browser-title": "{pane, select, item {Published as} metadata {Metadata} other {Unknown}} – {name}",
      "header": {
        "about": "General",
        "about-tooltip": "General information about the signal.",
        "actions": {
          "delete": "Delete signal",
          "edit": "Edit signal metadata"
        },
        "tabs": {
          "item": "Published as",
          "metadata": "Metadata"
        },
        "tagline": "Signal"
      },
      "item-pane": {
        "connect-to-item": {
          "button": "Connect to item",
          "description": "Connect this signal to an existing item"
        },
        "disconnect": "Disconnect",
        "empty-state": {
          "button": "+ Create new item",
          "description": "Publish this signal to view the data in Clarify."
        },
        "id": "Signal ID",
        "item-card-label": "Published as",
        "item-id-label": "Item id",
        "settings-button": "Go to item settings >"
      },
      "signal-published-state": {
        "not-published": "Not published",
        "published": "Published"
      },
      "states": {
        "error": "Failed loading…",
        "loading": "Loading signals content"
      }
    },
    "invitations-index": {
      "header": {
        "invite-button": "Invite a new user",
        "title": "Invitations"
      },
      "members": {
        "count": "{count, plural, one {User} other {Users}}",
        "organization": "Users in {organization}"
      },
      "pending": {
        "action": {
          "delete": "Delete invitation",
          "resend": "Resend",
          "resend-title": "Resend Invitation"
        },
        "date": "Invite sent {date} ago",
        "title": "Pending invitations"
      },
      "remove-invitation-alert": {
        "dismiss": "Close",
        "message": "Sorry, something went wrong with your request.",
        "title": "Uh oh!"
      },
      "state": {
        "empty": "There are currently no invitations pending. '<br><br>' To invite new users click the Invite A New User button.",
        "error": "Failed loading…",
        "loading": "Loading invitations content"
      }
    },
    "invitations-new": {
      "alerts": {
        "errors": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong.",
          "title": "Uh oh!"
        }
      },
      "browser-title": "Invite",
      "footer": {
        "submit-button": "Send invitations",
        "subtitle": "Administrators can use all basic features and in addition manage users, integrations, and data.",
        "title": "Members can use all basic features of Clarify."
      },
      "form": {
        "email-placeholder": "email address...",
        "remove-button": "Remove invitation",
        "roles": {
          "administrator": "Administrator",
          "member": "Member"
        }
      },
      "header": {
        "description": "Enter an e-mail address and select a role to invite a new user.",
        "subtitle": "Invite new users to Clarify",
        "title": "Invite"
      }
    },
    "invitations-show": {
      "description": "You will join {name} in their organization {organization}",
      "join-button": "Join organization",
      "title": "Join {name} in Clarify"
    },
    "invitations-show-error": {
      "description": "{name, select, RPCError { {type, select, operationNotPermitted {The invitation might have been retracted or expired} other {An unknown invitation error has accrued, please try again. If this error persist please contact support} } } other {Unknown invitation error}}",
      "title": "{name, select, RPCError { {type, select, operationNotPermitted {Invitation not found} other {Unknown invitation error} } } other {Unknown error} }"
    },
    "item-card": {
      "add": "+ Add",
      "add-tooltip": "Add items in this timeline",
      "added": "In this timeline",
      "empty-state": {
        "data-source": {
          "title": "Unknown data source"
        },
        "location": {
          "title": "Unknown location"
        }
      },
      "item-pane": {
        "tooltip-eng-unit": "Engineering unit: {unit}"
      },
      "open": "Open info"
    },
    "item-dialog": {
      "close": "Close",
      "create-timeline": "Open in new timeline",
      "create-timeline-tooltip": "Create a new timeline of this item",
      "fields": {
        "createdAt": "Created At",
        "dataType": "Type",
        "engUnit": "Unit",
        "id": "Item ID",
        "sampleInterval": "Sample rate",
        "sourceType": "Item type"
      },
      "header": {
        "title": "ITEM INFO"
      },
      "metadata": {
        "data-source": "Data source",
        "empty-state": {
          "value": "Unknown value"
        },
        "location": "Location"
      },
      "settings": "Open Item settings",
      "statistics": {
        "average": "AVG",
        "enum": "Statistics not supported by enum items",
        "failed": "Failed to load",
        "info": "Select a period in the timeline to view statistics.",
        "item": "Signal",
        "maximum": "Max",
        "minimum": "Min",
        "points": "Points",
        "points-unit": "{points, plural, one {point} other {points}}",
        "refresh": "Refresh",
        "sum": "Sum"
      }
    },
    "items-index": {
      "browser-title": "Items",
      "data-grid-descriptors": {
        "createdAt": "Created at",
        "deltaInterval": "Delta interval",
        "description": "Description",
        "engUnit": "Engineering unit",
        "id": "Item ID",
        "label": "Label: {name}",
        "labels": "All labels",
        "name": "Name",
        "sampleInterval": "Sample interval",
        "status": "Status",
        "type": "Type",
        "type-options": {
          "enum": "Enum",
          "numeric": "Numeric"
        },
        "updatedAt": "Last updated"
      },
      "header": {
        "title": "Items"
      },
      "states": {
        "error": "Failed loading…",
        "loading": "Loading items"
      },
      "toolbar": {
        "delete": "{count, plural, one {Delete # item} other {Delete # items}}",
        "hide": {
          "title": "Hide",
          "tooltip": "{disabled, select, true {{count, plural, =1 {This item is hidden} other {These items are hidden}}} false {{count, plural, =1 {Hide item} other {Hide items}}} other{}}"
        },
        "unhidden": {
          "title": "Unhide",
          "tooltip": "{disabled, select, true {{count, plural, =1 {This item is unhidden} other {These items are unhidden}}} false {{count, plural, =1 {Unhide item} other {Unhide items}}} other{} }"
        }
      }
    },
    "items-quest": {
      "items-state": {
        "failed": "Couldn’t load items.",
        "loading": "Loading items…",
        "no-items": {
          "description": "Your items will show up here.",
          "title": "No items yet"
        }
      }
    },
    "items-show": {
      "alerts": {
        "destroy": {
          "message": "Failed deleting item, try again or contact support if the problem persists",
          "primary-button": "Dismiss",
          "title": "Uh oh!"
        },
        "save": {
          "message": "Failed saving item, try again or contact support if the problem persists",
          "primary-button": "Dismiss",
          "title": "Uh oh!"
        }
      },
      "browser-title": "{pane, select, metadata {Metadata} source {Source} other {Unknown}} – {name}",
      "header": {
        "actions": {
          "delete": "Delete item"
        },
        "open-in-clarify": "Open in Clarify",
        "title": "Items",
        "visibility-item": "Toggle to select if the item should be visible for users in your organization.",
        "visibility-label": "Visibility toggle",
        "visibility-off": "No",
        "visibility-on": "Yes"
      },
      "metadata-pane": {
        "title": "Metadata"
      },
      "source-pane": {
        "dataset": {
          "description": "This item is created from a dataset"
        },
        "header": "Published from",
        "integration-label": "Integration",
        "integration-value": "{integration} >",
        "not-found": "Signal not found",
        "signal-input-id-label": "Input ID",
        "signal-label": "Signal name",
        "signal-value": "{signal} >",
        "title": "Source"
      },
      "states": {
        "error": "Failed loading…",
        "loading": "Loading items content"
      }
    },
    "keyword-auto-complete-list": {
      "no-matches": "No filters match your search",
      "select": "Select a filter, or search by name"
    },
    "keyword-auto-complete-list-item": {
      "add-filter": "Add filter"
    },
    "keyword-filter": {
      "empty": "{type, select, labels {no labels} permissions {no timelines} other {no labels}}",
      "item": {
        "count": "({count})"
      }
    },
    "keyword-label": {
      "item": {
        "tag": "+ New tag"
      }
    },
    "keywords-delete": {
      "alert": {
        "error": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong.",
          "title": "Uh oh!"
        }
      },
      "dialog": {
        "action": {
          "dismiss": "Cancel",
          "remove": "Remove"
        },
        "header": {
          "description": "Labels within this group will no longer be visible in Clarify.",
          "title": "Remove {title}?"
        }
      }
    },
    "keywords-new": {
      "alert": {
        "error": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong.",
          "title": "Uh oh!"
        },
        "form": {
          "error": {
            "message": "This field is required and cannot be left blank."
          }
        }
      },
      "dialog": {
        "content": {
          "action-add": "+ Add",
          "action-title": "Add new label"
        },
        "footer": {
          "action-dismiss": "Cancel",
          "action-save": "Create"
        },
        "form": {
          "add-button": "+ Add",
          "delete-key": "Remove selected key",
          "edit-key": "Key",
          "edit-key-tooltip": "Here you will find the key that you want to add",
          "edit-title": "Title",
          "edit-title-placeholder": "untitled",
          "edit-title-tooltip": "Here you will find the keywords title that you want to edit"
        },
        "header": "Add label group"
      }
    },
    "keywords-show-edit": {
      "alert": {
        "error": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong.",
          "title": "Uh oh!"
        },
        "form": {
          "error": {
            "message": "This field cannot be left blank."
          }
        }
      },
      "dialog": {
        "footer": {
          "action-dismiss": "Cancel",
          "action-save": "Done"
        },
        "form": {
          "edit-key": "Key",
          "edit-key-tooltip": "Key cannot be edited once is created",
          "edit-title": "Title",
          "edit-title-placeholder": "Label title",
          "edit-title-tooltip": "Here you will find the keywords title that you want to edit"
        },
        "header": "Edit label group"
      }
    },
    "keywords-show-modify": {
      "dialog": {
        "content": {
          "key": "Key",
          "key-tooltip": "Key: {key}",
          "title": "Title",
          "title-tooltip": "Title: {title}"
        },
        "footer": {
          "action-add": "+ Add label group",
          "action-title": "Open actions"
        },
        "header": "Edit labels visible in Clarify",
        "menu": {
          "delete": "Delete",
          "edit": "Edit"
        }
      },
      "empty-state": {
        "key": "No key found",
        "labels": "No labels added",
        "title": "No name found"
      }
    },
    "labels": {
      "add-button": {
        "button": {
          "title": "{disabled, select, true {You can only label an entity with 10 entries} other {Insert new label}}"
        },
        "empty-state": "No matches",
        "placeholder": "Start typing to search…"
      },
      "add-category": {
        "add-button": "+ Add label category",
        "dropdown": {
          "placeholder": "+ New label category"
        },
        "empty-state": "No matches",
        "placeholder": "Search for label category...",
        "validation-error": "Warning: Can not save new label category. Please remove any spaces or special characters."
      },
      "empty-state": "—",
      "item": {
        "remove": "Remove"
      },
      "label": {
        "query": {
          "placeholder": "+ New label"
        }
      }
    },
    "line-chart-range-fieldset": {
      "auto": "Scale automatically",
      "description": "Change the scale of the vertical axis",
      "invalid": "Invalid",
      "manual": "Set custom range",
      "max-label": "Max.",
      "min-label": "Min.",
      "placeholder": "Auto"
    },
    "login": {
      "description": "Clarify is a tool that lets you and your team easily share knowledge and explore industrial data - together. Helping you turn data into actual value, every day.",
      "learn-more": "Learn more about Clarify",
      "logIn": "Continue to login"
    },
    "logout": {
      "logOutInProgress": "Logging out...",
      "loggedOut": "Logged out"
    },
    "members-delete-error": {
      "message": "Failed to delete {members, plural, =1 {member} other {members}}, try again or contact support if the problem persists"
    },
    "metadata": {
      "about-header": {
        "title": "General",
        "tooltip": "General information"
      },
      "date-values": {
        "createdAt": "Created At",
        "createdAt-tooltip": "Created: {date}",
        "createdBy": "Created By",
        "updatedAt": "Updated At",
        "updatedAt-tooltip": "Updated: {date}"
      },
      "default-placeholder": "Enter value…",
      "delta-interval": {
        "title": "Delta interval",
        "tooltip": "Determine the maximum distance between points which are connected in timelines."
      },
      "engineering-unit": {
        "title": "Engineering unit",
        "tooltip": "The unit of measurement makes it easier to read data in timelines."
      },
      "enum-values": {
        "title": "Enum values",
        "tooltip": "Enum values translates the time series data into a human readable text in timelines"
      },
      "form": {
        "duration": {
          "days": "Days",
          "hours": "Hours",
          "minutes": "Minutes",
          "placeholder": "Enter value…",
          "seconds": "Seconds"
        },
        "enum-values": {
          "button-add": "+ Add Enum Value",
          "button-remove": "Remove",
          "key": {
            "placeholder": "Key"
          },
          "label": {
            "placeholder": "Label"
          }
        }
      },
      "log-header": {
        "title": "Updates",
        "tooltip": "Important times related to the resource."
      },
      "metrics-label-header": {
        "title": "Details",
        "tooltip": "Details about the resource"
      },
      "other-labels-header": {
        "title": "Other labels",
        "tooltip": "Labels that administrators can use to organize resources. These labels will not show up outside of the admin panel."
      },
      "sample-interval": {
        "title": "Sample interval",
        "tooltip": "The sample rate of the data stored."
      },
      "source-type": {
        "options": {
          "aggregation": "Aggregation",
          "measurement": "Measurement",
          "prediction": "Prediction"
        },
        "title": "Engineering type",
        "tooltip": "Choose between numeric for data that is best plotted as a graph, or enums for data that you want plotted as different statuses. Enums allow you to map a textual representation for each value in your data!",
        "value": "{value, select, aggregation {Aggregation} measurement {Measurement} prediction {Prediction} other {Unknown engineering type}}"
      },
      "system-header": {
        "title": "System info",
        "tooltip": "Values only used internally in Clarify."
      },
      "system-labels-header": {
        "title": "Labels",
        "tooltip": "Labels allow you to categorize your resources and makes them searchable"
      },
      "system-labels-visibility": {
        "action": "Edit",
        "title": "Visible in Clarify",
        "tooltip": "Labels allow you to categorize your resources and makes them searchable"
      },
      "type": {
        "options": {
          "enum": "Enum",
          "numeric": "Numeric"
        },
        "title": "Type",
        "tooltip": "Choose between numeric for data that is best plotted as a graph, or enums for data that you want plotted as different statuses. Enums allow you to map a textual representation for each value in your data!",
        "value": "{value, select, numeric {Numeric} enum {Enum} other {Unknown type}}"
      }
    },
    "mobile-upsell": {
      "app-store-button": "Download Clarify iOS app",
      "content": "You are about to open Clarify for desktop on a mobile device.",
      "open-app": "Open app",
      "open-web-app": "Open web app (not recommended)",
      "or": "or",
      "play-store-button": "Download Clarify Android app"
    },
    "non-admin": {
      "description": "You’re not an admin for this organization"
    },
    "organization-index": {
      "about": {
        "actions": {
          "edit": "Change organization name"
        },
        "content": {
          "integrations": "integrations",
          "items": "Items",
          "members": "Users"
        }
      },
      "alerts": {
        "errors": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong with your request.",
          "title": "Uh oh!"
        }
      },
      "browser-title": "Billing & Organization",
      "create": {
        "actions": {
          "cancel": "Cancel",
          "submit": "Save"
        },
        "organization-name": "Organization name",
        "organization-placeholder": "Enter a name...",
        "organization-tooltip": "Organization name",
        "title": "Edit organization name"
      },
      "header": {
        "title": "Billing & Organization"
      },
      "shortcuts": {
        "links": {
          "documentation": "Developer documentation",
          "guide": "Guide: Integration setup",
          "intro": "User documentation"
        },
        "title": "Get started"
      },
      "states": {
        "error": "Failed to load…",
        "loading": "Loading organization content"
      },
      "subscription": {
        "actions": {
          "choose-plan": "Choose plan",
          "manage-subscription": "Manage plan"
        },
        "cancelled": "Your plan will end on {endsAt, date, long}",
        "inactive": "Your plan ended on {endsAt, date, long}",
        "tagline": "Your plan",
        "title": "{status, select, active {{product}} inactive {{product} (Inactive)} trialing {{product} (Trial)} other {Unknown plan}}",
        "trial": "'<b>'{daysLeftOfTrial, plural, =1 {1 day} other {# days}}'</b>' left of trial (ends {endsAt, date, long})"
      },
      "subscription-paid": {
        "date": "Next billing date:",
        "description": "{currentPeriodEnd, date, long}",
        "invoice-to": "Send invoice to:"
      }
    },
    "organizations-new": {
      "demo-data": {
        "description": "We have collected real, ready-to-go data within a few industries.'<br />'Select topics to explore.",
        "form": {
          "demo-data": {
            "description": "{industryType, select, maritime {Ships, ports and weather} manufacturing {Machine stats, product performance and quality assurance metrics} aquaculture {Weather, fish health and prices} other {A collection of various data sources}}",
            "title": "{industryType, select, maritime {Maritime} manufacturing {Manufacturing} aquaculture {Aqua culture} other {Mystery box}}"
          },
          "submit": "I’m ready"
        },
        "header": {
          "progress": "organization setup 2/2",
          "title": "Hit the ground running with demo data"
        }
      },
      "errors": {
        "submit": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong when creating your organization.",
          "title": "Uh oh!"
        }
      },
      "organization": {
        "description": "The name of your company, or a project title.",
        "form": {
          "name": {
            "label": "Organization name",
            "placeholder": "enter organization name"
          },
          "submit": "Next"
        },
        "header": {
          "progress": "organization setup 1/2",
          "title": "Give your organization a name"
        }
      }
    },
    "organizations-show-activities": {
      "input": {
        "placeholder": "Find in comments"
      },
      "search": {
        "empty-state": {
          "description": "Check the spelling, or try searching for something else.",
          "title": "No results"
        },
        "error": {
          "description": "Something unexpected happen",
          "retry": "Retry",
          "title": "Loading failed search result"
        }
      }
    },
    "organizations-show-creating": {
      "description": "This will take a few seconds",
      "title": "Preparing your organization"
    },
    "organizations-show-upgrade": {
      "card-favorite-plan-title": "'<span>'⭐'</span>Most popular plan",
      "card-price-date": "/month*",
      "card-price-label": "Selected price",
      "card-price-symbol": "€",
      "condition": "* Limited time offer valid for the first 6 months of the paid plan. Offer is valid for all signups before July 1st 2021",
      "description": "Compare all features",
      "errors": {
        "dismiss": "Close",
        "message": "Sorry, something went wrong with your request.",
        "title": "Uh oh!"
      },
      "header": {
        "title-link": "Go back to Clarify timelines"
      },
      "link": "Contact us",
      "message": "Looking for an educational or non-profit license?",
      "plan": {
        "business": {
          "actions": {
            "text-link": "Continue with '<small>'Business'</small>"
          },
          "card-description": "More signals & members, API access, priority support",
          "card-price": "349",
          "card-title": "Business",
          "items": " '<li>' 500 signals '<span>' + $25/month per 50 signals added '</span></li>' '<li>' 10 members '<span>' $10/month per member added '</span></li>' '<li>' API access '</li>' '<li>' Grafana plugin'</li>' '<li>' Priority online support'</li>' "
        },
        "enterprise": {
          "actions": {
            "text-link": "Let’s talk"
          },
          "card-description": "For large businesses with lots of data",
          "card-title": "Enterprise",
          "items": " '<li>' Unlimited signals '</li>' '<li>' Ready-made integrations'</li>' '<li>' Dedicated technical account manager'</li>' '<li>' Extended APIs'</li>' '<li>' SLA'</li>' '<li>' Enterprise support'</li>' '<li>' Early access to new features'</li>' "
        },
        "individual": {
          "actions": {
            "text-link": "Continue with '<small>'Individual'</small>"
          },
          "card-description": "Our entry level version for hobbyists, tinkerers and small projects",
          "card-price": "9",
          "card-title": "Individual",
          "items": " '<li>' 20 signals  '<span>' non-upgradable '</span></li>' '<li>' 3 team members  '<span>' non-upgradable '</span></li>' "
        },
        "team": {
          "actions": {
            "text-link": "Continue with '<small>'Team'</small>"
          },
          "card-description": "All you need to get started with data exploration",
          "card-price": "199",
          "card-title": "Team",
          "items": " '<li>' 200 signals '<span>' + €25/month per 50 signals added '</span></li>' '<li>' 5 members '<span>' €10/month per member added '</span></li>' "
        }
      },
      "subtitle": "Riding solo?",
      "title": "Choose the plan that’s right for you."
    },
    "permission-type-dropdown": {
      "options": {
        "timelines:collaborator": {
          "description": "Can add/remove data & comment",
          "title": "Edit"
        },
        "timelines:guest": {
          "description": "Can view data & comment",
          "title": "View"
        },
        "timelines:owner": {
          "description": "Can edit members & delete timeline",
          "title": "Admin"
        }
      },
      "remove": "Remove permission",
      "title": {
        "timelines:collaborator": "can edit",
        "timelines:guest": "can view",
        "timelines:owner": "admin"
      }
    },
    "present-ruler-popover": {
      "now": "now",
      "today": "today"
    },
    "privacy": {
      "mixed": "Visible to {users, plural, =0 {0 people} =1 {1 person} other {# people}}",
      "private": "Only visible to you",
      "public": "Visible to everyone in {organizationName}"
    },
    "query-builder": {
      "remove": "Remove"
    },
    "routes": {
      "login": "Login",
      "organizations/show/timelines/index": "Timelines",
      "organizations/show/timelines/show": {
        "untitled": "Timeline without name"
      }
    },
    "settings-loading": {
      "title": "Loading settings"
    },
    "settings-visualization": {
      "accessDenied": {
        "description": "You need admin/edit access to edit timeline",
        "title": "No access"
      },
      "error": {
        "title": "Could not load visualization"
      },
      "visualizationNotFound": {
        "description": "The visualization might have been deleted",
        "title": "Visualization not found"
      }
    },
    "setup": {
      "description": "Fill in the details below to get started.\nThe name you choose will be visible to other users in your organization.",
      "error": {
        "message": "Try again or contact support if the problem persists.",
        "title": "Sorry, something went wrong with your request."
      },
      "form": {
        "name": {
          "label": "Name",
          "placeholder": "Enter your full name"
        },
        "password": {
          "description": "Password requires at least 8 characters",
          "label": "Password",
          "placeholder": "Choose a password for your account"
        },
        "submit": "Continue to Clarify"
      },
      "title": "Welcome!"
    },
    "sharing-info": {
      "timeline": {
        "collaborator": "Can edit",
        "guest": "Can view"
      },
      "user": {
        "private": "Private",
        "shared": "Shared"
      }
    },
    "signal-connect": {
      "browser-title": "Connect “{name}”",
      "connect-button": "+ Connect to Item",
      "header": {
        "title": "Connect to Item"
      },
      "info": {
        "description": "Insert an explanation of what you are doing here."
      },
      "states": {
        "error": "Loading failed, try again later",
        "loading": "Loading items"
      }
    },
    "signal-create": {
      "back": "Back",
      "browser-title": "Create signal",
      "cancel": "Cancel",
      "create": "Create",
      "errors": {
        "input-id": {
          "dismiss": "Close",
          "message": "Something’s wrong with the input ID, make sure it's unique for this integration and only contains valid characters",
          "title": "Uh oh!"
        }
      },
      "fieldset": {
        "labels": {
          "description": {
            "placeholder": "Add some info to remember this signal by...",
            "title": "Description"
          },
          "name": {
            "description": "This name will be used by default when you publish the signal to all your users in Clarify.",
            "placeholder": "Enter a name…",
            "title": "* Signal name",
            "tooltip": "* Signal name"
          }
        },
        "metrics": {
          "description": {
            "placeholder": "A description of the signal...",
            "title": "Description"
          },
          "name": {
            "description": "This name will be used by default when you publish the signal to all your users in Clarify.",
            "placeholder": "Enter a name…",
            "title": "* Signal name",
            "tooltip": "Name of the signal (required)"
          }
        }
      },
      "header": "Create signal",
      "next": "Next",
      "step-create": {
        "create": "'<p>'Ready to create.'</p>' '<p>'When you click the “Create” button, a signal with the selected input ID will be created. You can use the ID as an address to send data from your integration.'</p>'",
        "title": "Create"
      },
      "step-input-id": {
        "header": "Create Input ID.",
        "input-id": "The Input ID can be used when connecting external systems, so they can send data to this Signal.",
        "requirement": "The Input ID will be used to uniquely identify this signal. Only lower-case letters, numbers and the symbols _ and - are allowed.",
        "title": "Input ID",
        "type": {
          "auto": "Auto-generate an ID for me. (Default)",
          "manual": "Enter ID manually."
        },
        "validation-error": "Warning: Invalid input ID, please remove any spaces or special characters."
      },
      "step-intro": {
        "intro": "'<p aria-hidden=\"true\">'👋 👋 👋'</p>'\n'<p>'This wizard will help you create a signal that can be used to receive time series data.'</p>'\n'<p>'You want to create a signal through the UI only when your integration doesn't support automatically generating signals.'</p>'\n'<p>'Learn how to create signals automatically here:'</p>'\n'<p>'\n'<a href=\"https://docs.clarify.us/docs/writing-meta-data-to-clarify\">'Guide: Saving meta-data for signals'</a>'\n'</p>'",
        "title": "Intro"
      },
      "step-labels": {
        "labels": "'<p>'✏️ Enter the information you need to uniquely identify this signal later.'</p>'",
        "title": "Labels"
      },
      "step-metrics": {
        "metrics": "'<p>'✏️ Enter metadata for this signal.'</p>'",
        "title": "Details"
      }
    },
    "signal-created": {
      "cancel": "Publish later",
      "create": "Go to publish now",
      "header": "Signal created",
      "step-connect": {
        "connect": "'<p>'This ID can be used to write data to the signal. Read more about connecting your signal '<a target=\"_blank\" class=\"signal-created--link\" href='https://docs.clarify.us/docs/writing-data-to-clarify'>'here.'</a>' '</p>'",
        "description": "'<p>'You can find the ID again later by opening the signal details.'</p>'",
        "fieldset": {
          "input-id-label": "Input ID",
          "integration-id-copy-description": "Copy integration Id",
          "integration-id-description": "Integration ID",
          "integration-id-label": "Integration ID"
        },
        "title": "Connect"
      },
      "step-publish": {
        "publish": "'<p>'When you want to make the signal available for use in Clarify it has to be published.'</p>' '<p>'Do you want to publish the new signal “{name}” right now?'</p>' '<p>'You can choose to do this later.'</p>",
        "title": "Publish"
      },
      "step-success": {
        "success": "'<p aria-hidden=\"true\">'🎉 🎉 🎉'</p>' '<p>'Success! The Signal '<b>'{signalName}'</b>' has been created, and is ready to start receiving data.'</p>'",
        "title": "Success"
      }
    },
    "signal-publish": {
      "browser-title": "Publish “{name}”",
      "content": {
        "add": {
          "title": "Publish"
        },
        "item-title": "Item",
        "signal-name": "signal name",
        "signal-name-tooltip": "The name of the signal.",
        "signal-title": "signal"
      },
      "form": {
        "fieldset": {
          "signal-id": "Signal ID"
        }
      },
      "header": {
        "subtitle": "Edit metadata and click publish to make your data available in Clarify.",
        "title": "Publish"
      }
    },
    "statistics-panel": {
      "add-comment": "Add comment",
      "menu": "Visualization",
      "statistics-close": "Close",
      "statistics-export": "Export",
      "statistics-info": "Select a period in the timeline to view statistics.",
      "status": {
        "failed": "Something went wrong, could not display statistics.",
        "loading": "Loading…",
        "refresh": "Refresh"
      },
      "table": {
        "average": "AVG",
        "enum": "Statistics not supported by enum items",
        "item": "Signal",
        "maximum": "Max",
        "minimum": "Min",
        "points": "Points",
        "points-unit": "{points, plural, one {point} other {points}}",
        "sum": "Sum",
        "tooltip": {
          "item": "{title}, {location}, {dataSource}"
        }
      },
      "title": "Statistics",
      "view-statistics": "statistics"
    },
    "status": {
      "unknown-case": "Unknown status"
    },
    "subject-auto-complete": {
      "empty-state": "No groups or people match your search",
      "in-timeline": "in timeline",
      "placeholder": "Type to search…",
      "popover": {
        "header": "Suggestions"
      }
    },
    "table-toolbar": {
      "actions": {
        "add-columns": "Add fields",
        "edit": "Edit labels",
        "remove": "Remove all filters ({items})"
      },
      "checkbox-selected": {
        "label-selected": "{count, plural, one {1 Selected} other {# Selected}}",
        "label-total": "Showing all {count}",
        "label-total-filtered": "Showing {count} of {total}"
      }
    },
    "text-editor": {
      "auto-complete-empty-state": {
        "item": "No items found",
        "user": "No users found"
      },
      "compose": "Write something...",
      "compose-comment": "Write a comment…",
      "error": "Could not post comment",
      "remove-attachment": "Remove",
      "send": "Send",
      "toolbar": {
        "item": "Tag signal",
        "upload": "Upload",
        "user": "Mention"
      },
      "uploading": "Uploading... {uploadProgress}%"
    },
    "thread": {
      "emptyState": "Write a comment",
      "errors": {
        "deleted": "Thread deleted",
        "dismiss": "Dismiss",
        "empty": "A comment or attachment is required",
        "failed": "Failed saving comment with error {error}",
        "title": "Can’t save comment"
      },
      "label": {
        "item": {
          "tag": "+ keyword"
        }
      },
      "loading": "Loading…",
      "remove": "Remove",
      "replies": "{replies, plural,  =0 {No replies} =1 {1 reply} other {# replies}}"
    },
    "thread-feed": {
      "activity": "Activity",
      "delete-thread": "Delete thread",
      "empty": "There’re no comments on this timeline",
      "header": {
        "title": "Comment"
      },
      "loading": "Loading more comments…",
      "no-more-content": "No more comments",
      "sort": {
        "activity": "Recent activity",
        "chronological": "Timeline chronological"
      },
      "thread-state": {
        "error": "Error",
        "error-description": "Sorry, something went wrong with your request."
      },
      "tooltip": {
        "close-thread": "Close",
        "more-settings": "More options"
      }
    },
    "thread-label": {
      "add-label": {
        "add-button": "Add label"
      },
      "categories": {
        "placeholder": "Search all labels",
        "title": "{tag}"
      },
      "empty-state": {
        "no-tags": "No tags available"
      }
    },
    "thresholds": {
      "checkbox::enabled": "Enable",
      "checkbox::value": "These values will apply for all items in this visualization.",
      "description": "Thresholds can be used to discover when values from a signal is outside of allowed limits.",
      "form": {
        "edit-color-label": "Enter a name",
        "edit-label": "Enter a value",
        "invalid-label": "Value is invalid, only numeric values are allowed.",
        "option-above": "Above",
        "option-below": "Below",
        "options": "Select an option",
        "tooltip": {
          "hide": "Hide threshold",
          "remove": "Remove threshold"
        }
      },
      "values": {
        "date": "When",
        "label": "Name",
        "value": "Limit"
      }
    },
    "time-axis": {
      "tooltip": "{state, select,  resizable {Drag to adjust} movable {Drag to move selection} other {Click & drag to select} }"
    },
    "time-range": {
      "end": "End:",
      "length": "Period:",
      "start": "Start:"
    },
    "timeline-canvas": {
      "add-signal": {
        "default": "Add item",
        "disabled": "Only admins and collaborators can add items"
      },
      "add-thread": "Add new comment",
      "edit-timeline": {
        "default": "Timeline settings",
        "disabled": "Only admins and collaborators can edit timeline"
      },
      "timeline-legend": {
        "open-visualization": "Open visualization settings"
      },
      "timeline-share": "Share",
      "tooltip": {
        "activity": "Threads",
        "timeline-edit": {
          "default": "Edit timeline",
          "disabled": "Only admins and collaborators can edit items"
        },
        "timeline-export": "Export data"
      },
      "untitled": "Timeline without name"
    },
    "timeline-card": {
      "archive": "Delete",
      "button": {
        "delete": "Delete timeline",
        "title": "Add tag"
      },
      "categories": {
        "category": "Category: {title}",
        "search": "Results for: {title}",
        "title": "{tag}",
        "tooltip": "{tooltip}"
      },
      "commented": "{date} ago",
      "duplicate": "Duplicate",
      "labels": {
        "placeholder": "Find label…"
      },
      "last-updated": "Last updated {date, date, short}",
      "replied": "{date} ago",
      "share": "Share",
      "signals": "{signals, plural, one {signal} other {# signals}}",
      "timeline-state": {
        "failed": "Couldn’t load timelines.",
        "loading": "Loading timelines…",
        "loading-error": "Couldn’t load more timelines right now.",
        "no-matches": "No timelines match the current search. <br/> Try clearing some filters.",
        "no-timelines": {
          "action": "Create a timeline",
          "description": "Timelines by you, or shared with you, will show up here.",
          "title": "No timelines yet"
        },
        "retry": "Retry",
        "searching": "Searching for timelines…"
      },
      "untitled": "Timeline without name",
      "view": "View timeline",
      "visualizations": "{visualizations, plural, one {1 visualization} other {# visualizations}}"
    },
    "timeline-filter": {
      "all-items": "Showing all items",
      "epilogue": {
        "title": "No items match the current search. <br /> Try clearing some filters."
      },
      "filter-bar": "Find in items",
      "hide": "Hide items filter",
      "results": "Showing {count} results",
      "states": {
        "failed": "Could not load items",
        "loading": "Loading items…",
        "loading-epilogue": "Loading items"
      },
      "title": "Add item"
    },
    "timeline-loading": {
      "title": "Loading timelines…"
    },
    "timeline-permission-popover": {
      "invite-form": {
        "add-button": "Add"
      }
    },
    "timeline-settings": {
      "actions": {
        "more": "More",
        "share": "Share options"
      },
      "created-at": "Created at: {date, date, medium}",
      "header": "Edit Timeline",
      "item-actions": {
        "delete": "Delete",
        "hide": "Hide",
        "info": "Information"
      },
      "last-updated": "Last updated: {date, date, medium}",
      "more-context-menu": {
        "delete": "Delete timeline"
      },
      "settings": "Settings",
      "tooltip": {
        "close-edit": "Close",
        "more-settings": "More options"
      },
      "visualization-list": {
        "empty-state": "This visualization is empty"
      }
    },
    "timeline-sharing": {
      "disabled": "You cannot manage membership for this timeline",
      "group-members": {
        "title": "View members"
      },
      "header": "Add people or groups"
    },
    "timeline-visualization-item-settings": {
      "header": "Item",
      "inline-item": {
        "placeholder": "Enter name"
      },
      "tooltips": {
        "actions": "More actions",
        "visabillity": {
          "hide": "Hide item from visualization",
          "show": "Show signal in visualization"
        }
      }
    },
    "timeline-visualization-settings": {
      "header": "Visualization",
      "menu": {
        "display-type": {
          "header": "Select display type"
        }
      },
      "tooltips": {
        "actions": "More actions"
      }
    },
    "timelines": {
      "all-items": "Showing all timelines",
      "clear": "Clear {filter, plural,  =0 {(No match)} =1 {(1) filter} other {(#) filters}}",
      "results": "Showing {count} results"
    },
    "timelines-index": {
      "activity": "Activity",
      "invite": "Invite",
      "invite-tooltip": "Invite a new user from here",
      "items": "Items",
      "new-timeline": "+ Create timeline",
      "timeline": "Timelines"
    },
    "timelines-permissions": {
      "errors": {
        "add-subject": {
          "dismiss": "Dismiss",
          "message": "We couldn’t add this member right now. {error}",
          "title": "ERROR"
        },
        "delete-access-member-alert": {
          "cancel": "Cancel",
          "dismiss": "Dismiss",
          "failed": {
            "message": "We couldn’t delete the member right now. {error}",
            "title": "Error"
          },
          "message": "Are you sure you want to remove this member?",
          "prevent": {
            "message": "Unfortunately there must be at least one admin to perform this action",
            "title": "You can’t perform this action"
          },
          "remove": "Remove",
          "title": "Remove member"
        },
        "modify-access-member-alert": {
          "dismiss": "Dismiss",
          "failed": {
            "message": "Could not save updates to permission. {error}",
            "title": "Error"
          },
          "prevent": {
            "message": "Unfortunately you don’t have rights to perform this action.",
            "title": "You can’t perform this action"
          }
        }
      }
    },
    "timelines-show-error": {
      "button": "Refresh",
      "title": "We couldn’t connect to Clarify right now."
    },
    "timelines-show-loading": {
      "title": "Loading timeline"
    },
    "user-edit-group-memberships": {
      "add": {
        "description": "Add to group",
        "title": "+ Add"
      },
      "browser-title": "Edit memberships — {name}",
      "empty-state": "No results match your search",
      "header": "Edit group memberships",
      "input-placeholder": "Search by name…",
      "remove": {
        "description": "Remove from group",
        "title": "Remove"
      }
    },
    "users-index": {
      "browser-title": "Users",
      "data-grid-descriptors": {
        "email": "Email",
        "groups": "Groups",
        "name": "Name",
        "role": {
          "options": {
            "admin": "Admin",
            "member": "Member"
          },
          "title": "Role"
        }
      },
      "header": {
        "invite-button": "Invite a new user",
        "title": "Users"
      },
      "states": {
        "error": "Failed loading…",
        "loading": "Loading users content"
      },
      "toolbar": {
        "delete": {
          "tooltip": "{disabled, select, true {{selectedModelsCount, plural, =1 {You can’t delete yourself} other {These users can’t be deleted}}} false {{count, plural, =1 {Delete user} other {Delete users}}} other{}}"
        }
      }
    },
    "users-show": {
      "alerts": {
        "destroy": {
          "message": "Failed removing user from organization, try again or contact support if the problem persists",
          "primary-button": "Dismiss",
          "title": "Uh oh!"
        }
      },
      "browser-title": "{pane, select, about {Details} groups {Groups} other {Unknown}} — {name}",
      "fieldsets": {
        "createdAt": {
          "label": "Added"
        },
        "email": {
          "label": "Email",
          "placeholder": "Email"
        },
        "header": {
          "title": "Name & Description",
          "tooltip": "Name & Description"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name"
        },
        "role": {
          "label": "Role",
          "options": {
            "admin": "Administrator",
            "member": "Member"
          },
          "placeholder": "Role"
        }
      },
      "groups-tab": {
        "add-to-group-button": "+ Add to groups",
        "groups-list": {
          "header": "Group membership",
          "remove-button": "Remove from group"
        },
        "remove-user-from-group-alert": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong with your request.",
          "title": "Uh oh!"
        }
      },
      "header": {
        "actions": {
          "deletable": "{disabled, select, true {Delete user} false {You can’t delete yourself} other{}}",
          "remove": "Remove user from organization"
        },
        "tabs": {
          "about": "Details",
          "groups": "Groups"
        }
      },
      "states": {
        "loading": "Loading users content"
      }
    },
    "visualization-comments": {
      "uploaded-file": "Uploaded a file"
    },
    "visualization-header": {
      "default-name": "Graph",
      "visualization-settings": {
        "tooltip": "Show visualization settings",
        "tooltip-cannot-access": "You cannot open settings for this timeline"
      }
    },
    "visualization-legend-item": {
      "inaccesible": {
        "title": "Hidden item"
      }
    },
    "visualization-legend-item-aggregation": {
      "aggregations-full": {
        "average": "Average",
        "maximum": "Maximum",
        "minimum": "Minimum",
        "sum": "Sum"
      },
      "aggregations-short": {
        "average": "Avg.",
        "maximum": "Max.",
        "minimum": "Min.",
        "sum": "Sum"
      }
    },
    "visualization-settings": {
      "fieldsets": {
        "item-info": "Info",
        "line-chart-axis-scaling": "Y-axis",
        "line-chart-display-type": "Display",
        "line-chart-thresholds": "Thresholds"
      },
      "header": "Visualization",
      "inaccessible": {
        "title": "Hidden Signal"
      },
      "tooltip": {
        "collapse": "Collapse",
        "delete": "Remove item from visualization",
        "drag-item": "Drag and drop signal to rearrange",
        "expand": "Expand",
        "hide": "Hide item from visualization",
        "link-to-visualization": "Go to item",
        "open-settings": "Open settings",
        "select-color": "Change color",
        "show": "Show item"
      },
      "types": {
        "line-chart": "Line Chart",
        "status": "Status"
      },
      "untitled": "No name"
    }
  }], ["nb-no", {
    "access-control": {
      "profile-group": {
        "members": "{count} medlemmer"
      },
      "tooltip": {
        "title": "Vis gruppemedlemmer"
      }
    },
    "activity-feed": {
      "activity-group-comment": {
        "title": "{name} kommenterte"
      },
      "activity-group-comment-collapsed": {
        "title": "{names} kommenterte"
      },
      "activity-group-reply": {
        "title": "{name} svarte"
      },
      "activity-group-reply-collapsed": {
        "title": "{names} svarte"
      },
      "comments": {
        "attachment-file": "{file, plural, one {Lastet opp en fil} other {Lastet opp  # filer}}",
        "attachment-image": "{image, plural, one {Lastet opp et bilde} other {Lastet opp # bilder}}",
        "authors": "{name} og {others, plural, =0 {0 flere} =1 {1} other {# flere}}",
        "commented": "{date} siden",
        "multiple-attachments": "Lastet opp {images, plural, =0 {0 bilder} =1 {1 bilde} other {# bilder}} og {files, plural, =0 {0 filer} =1 {1} other {# filer}}",
        "replies": "{comments, plural, one {1 kommentarer} other {# nye kommentarer}}"
      },
      "createdAt": "{date} siden",
      "page-title": "Activities",
      "reply": {
        "attachments": "Svaret inneholder et vedlegg",
        "replied": "{date} siden",
        "replies": "{replies, plural,  =0 {Ingen svar} =1 {1 svar} other {# svar}} i tråd"
      },
      "state": {
        "empty": {
          "description": "Aktiviteter fra din organisasjon vil dukke opp her",
          "title": "Ingen aktiviteter"
        },
        "loading-epilogue": "Laster aktiviteter",
        "loading-error": "Kunne ikke laste flere aktiviteter for øyeblikket"
      }
    },
    "admin-navigation": {
      "settings": {
        "header": "Innstillinger",
        "link-groups": "Grupper",
        "link-integrations": "Integrasjoner",
        "link-invitations": "Invitasjoner",
        "link-items": "Item",
        "link-members": "Medlemmer",
        "link-organization": "Betaling og organisasjon",
        "link-profile": "Min profil"
      }
    },
    "alert": {
      "cancel": "Avbryt"
    },
    "alert-group-delete": {
      "message": "Medlemmer som har tilgang til tidslinjer, eller data, gjennom sitt medlemskap av denne gruppen vil miste sin tilgang. '<br><br>' Er du sikker på at du ønsker å slette denne gruppen?",
      "primary-button": "Slett",
      "title": "Slett “{name}”"
    },
    "alert-groups-delete": {
      "message": "Medlemmer som har tilgang til tidslinjer, eller data, gjennom sitt medlemskap i {count, plural, =1 {denne gruppen vil ikke lengre ha tilgang til disse. '<br><br>' Er du sikker på at du ønsker å slette denne gruppen?} other {disse gruppene vil ikke lengre ha tilgang til disse.'<br><br>' Er du sikker på at du ønsker å slette gruppene?}}",
      "primary-button": "Slett",
      "title": "{count, plural, one {Slett gruppe {name}} other {Slett grupper: {name}}}"
    },
    "alert-integration-delete": {
      "confirmation": {
        "message": "Det er ikke mulig å gjenopprette data når den er slettet.'<br><br>'Er du sikker på at du ønsker å slette all data for integrasjonen?",
        "primary-button": "Slett integrasjon og data",
        "title": "Advarsel!"
      },
      "initial": {
        "message": "{name} vil bli slettet. All data som er lagret i signal og item som er opprettet fra denne integrasjonen vil også bli slettet. '<br><br>'Er du sikker på at du ønsker å slette denne integrasjonen?",
        "primary-button": "Slett",
        "title": "Slett integrasjon"
      }
    },
    "alert-integrations-delete": {
      "confirmation": {
        "message": "Det er ikke mulig å gjenopprette data når den er slettet.'<br><br>'Er du sikker på at du ønsker å slette all data for {count, plural, =1 {denne integrasjonen?} other {disse integrasjonene?}}",
        "primary-button": "Slett {count, plural, =1 {integrasjon} other {integrasjoner}} og data",
        "title": "Advarsel!"
      },
      "initial": {
        "message": "{name} vil bli slettet. All data som er lagret i signal og item som er opprettet fra denne integrasjonen vil også bli slettet. '<br><br>'Er du sikker på at du ønsker å slette {count, plural, =1{denne integrasjonen?} other {disse integrasjonene?}}",
        "primary-button": "Slett",
        "title": "Slett integrasjon"
      }
    },
    "alert-item-delete": {
      "message": "Itemet vil bli fjernet, men det originale signalet og all data vil bli beholdt. Tidslinjer som bruker itemet vil ikke kunne vise denne dataen lengre.'<br><br>'Er du sikker på at du ønsker å slette itemet?",
      "primary-button": "Slett",
      "title": "Slett “{name}”"
    },
    "alert-items-delete": {
      "confirmation": {
        "message": "Det er ikke mulig å gjenopprette dataen når den er slettet.'<br><br>'Er du sikker på at du ønsker å slette all data lagret på {count, plural, =1 {dette itemet?} other {disse itemene?}}",
        "primary-button": "Slett {count, plural, =1 {item} other {item}} og data",
        "title": "Advarsel!"
      },
      "message": "{count, plural, =1 {Itemet vil bli slettet, men det originale signalet og all lagret data beholdes. Tidslinjer som bruker dette itemet vil ikke lengre kunne vise data fra det.'<br><br>'Er du sikker på at du ønsker å slette itemet?} other {Itemene vil bli fjernet, men de originale signalene og all lagret data beholdes. Tidslinjer som bruker disse itemene vi likke lengre kunne vise data fra de.'<br><br>'Er du sikker påt at du ønsker å slette itemene?}}",
      "primary-button": "Slett",
      "title": "{count, plural, one {Slett item {name}} other {Slett item {name}}}"
    },
    "alert-label-category-remove": {
      "message": "Er du sikker på at du vil fjerne denne kategorien?",
      "primary-button": "Fjern",
      "title": "Fjern kategori"
    },
    "alert-label-remove": {
      "message": "Er du sikker på at du vil fjerne denne merkelappen?",
      "primary-button": "Fjern",
      "title": "Fjern merkelapp"
    },
    "alert-member-delete": {
      "message": "Denne brukeren vil ikke lengre kunne logge inn i Clarify. Brukerens kommentarer vil fortsatt være synlige for andre brukere.'<br><br>'Er du sikker på at du ønsker å fjerne brukeren?",
      "primary-button": "Slett",
      "title": "Fjern bruker \"{name}\""
    },
    "alert-members-delete": {
      "message": "{count, plural, one {Brukeren vil ikke lengre ha tilgang til denne organisasjonen.'<br><br>'Er du sikker på at du ønsker å fjerne denne brukeren?} other {Brukerene vil ikke lengre ha tilgang til denne organisasjonen.'<br><br>'Er du sikker på at du ønsker å fjerne disse brukerene?}}",
      "primary-button": "Slett",
      "title": "{count, plural, one {Slett brukeren “{name}”} other {Slett brukerene: {name}}}"
    },
    "alert-signal-delete": {
      "confirmation": {
        "message": "Det er ikke mulig å gjenopprette dataen når den er slettet.'<br><br>'Er du sikker på at du ønsker å slette all data lagret for dette signalet?",
        "primary-button": "Slett signal og data",
        "title": "Advarsel!"
      },
      "initial": {
        "message": "All lagret data for dette signalet vil bli slettet permanent.'<br><br>'Er du sikker på at du ønsker å slette signalet?",
        "primary-button": "Slett",
        "title": "Slett signal “{name}”"
      }
    },
    "alert-signal-disconnect": {
      "message": "The item will no longer receive data from this signal.",
      "primary-button": "Disconnect",
      "title": "Disconnect from Item?"
    },
    "alert-signals-delete": {
      "confirmation": {
        "message": "Det er ikke mulig å gjenopprette dataen når den er slettet.'<br><br>'Er du sikker på at du ønsker å slette all data lagret for {count, plural, =1 {dette signalet?} other {disse signalene?}}",
        "primary-button": "Slett {count, plural, =1 {signal} other {signaler}} og data",
        "title": "Advarsel!"
      },
      "initial": {
        "message": "All lagret data for {count, plural, =1 {dette signalet vil bli permanent slettet.'<br><br>'Er du sikker på at du ønsker å slette signalet?} other {disse signalene vil bli permanent slettet.'<br><br>'Er du sikker på at du ønsker å slette signalene?}}",
        "primary-button": "Slett",
        "title": "{count, plural, one {Slett signal {name}} other {Slett signaler: {name}}}"
      }
    },
    "app-header": {
      "admin": {
        "groups": "Grupper",
        "integrations": "Integrasjoner",
        "invitations": "Invitasjoner",
        "items": "Item",
        "members": "Brukere",
        "organization": "Betaling og organisasjon",
        "switch": "Bytt organisasjon:"
      },
      "logout": "Logg ut",
      "support": "Brukerstøtte",
      "trial": {
        "button": "Oppgrader nå",
        "description": "{daysLeftOfTrial, plural, =1 {1 dag} other {# dager}} igjen av prøveperioden"
      }
    },
    "application-404": {
      "description": "Siden ble ikke funnet",
      "title": "Page not found"
    },
    "application-browser-error": {
      "description": "Oi! Det ser ut som du bruker Internet Explorer. Clarify støtter de fleste moderne nettlesere, som Firefox, Safari, Edge, Chrome, Brave eller Opera. Vennligst oppdater din nettleser for å fortsette.",
      "title": "Din nettleser støtter ikke Clarify."
    },
    "application-error": {
      "description": "Det ser ut til å være et problem med Clarify, vennligst prøv igjen.",
      "title": "Ukjent feil"
    },
    "application-loading": {
      "loading": "Starter Clarify"
    },
    "callback": {
      "description": "{error, select, \n  password_strength_error {Passordet er for svakt} \n  invalid_password {Passordet tilfredsstiller ikke kravene for innloggingsmetoden} \n  password_no_user_info_error {Passordet kan ikke inneholde elementer fra din epost} \n  password_dictionary_error {Passordet du har valgt befinner seg i en liste over kjente passord} \n  invalid_signup {Din konto kunne ikke registreres} \n  user_exists {Din konto er allerede registrer} \n  access_denied {Ingen tilgang} \n  invalid_user_password {Brukernavnet eller passordet er ugyldig} \n  password_leaked {Passordet ditt er utrygt. Vennligst endre passord.} \n  PasswordHistoryError {Passordet har vært brukt tidligere for denne kontoen} \n  too_many_attempts {Kontoen er blokkert på grunn av for mange innloggingsforsøk} \n  unauthorized {Kontoen er blokkert} \n  other {Ukjent feil ved innlogging}\n}",
      "passwordless": "{error, select, unauthorized {Looks like the verification link has expired or has already been used. '<a' href={retryURL}'>'Click here to resend the verification'</a>'} other {Unknown authentication error} }",
      "title": "Feil ved innlogging"
    },
    "color-popover": {
      "header": "Velg farge"
    },
    "comment-attachment": {
      "download": "Last ned"
    },
    "context-menus": {
      "visualization-item-settings": {
        "delete": "Fjern item fra visualisering"
      },
      "visualization-settings": {
        "delete": "Slett visualisering",
        "open-settings": "Åpne innstillinger"
      }
    },
    "data-grid": {
      "content": {
        "item-visible-state": {
          "hidden": "Ikke synlig",
          "status": "{status, select, true {Synlig} false {Ikke synlig} other {ukjent status}}",
          "visible": "Synlig"
        },
        "labels": "'<b>'{category}:'</b>' {label}",
        "signal-published-state": {
          "not-published": "Upublisert",
          "published": "Publisert"
        }
      },
      "failed": {
        "description": "We can write a smart message here if we have something that may help the user.",
        "retry": "Prøv igjen",
        "title": "Lasting feilet..."
      },
      "filters": {
        "clear": "Fjern filter",
        "input": {
          "placeholder": "Filtrer..."
        },
        "select": {
          "placeholder": "Velg..."
        },
        "select-multiple": {
          "placeholder": "Velg..."
        }
      },
      "loading": {
        "label": "Laster"
      }
    },
    "data-grid-filter": {
      "close-button": "Bruk",
      "empty-state": "Ingen merkelapper funnet",
      "placeholder": "Filtrer..."
    },
    "data-view": {
      "column-selector": {
        "close-button": "OK",
        "empty-state": "Ingen resultater for ditt søk",
        "header": "Velg hvilke felt som skal vises",
        "input-placeholder": "Filtrer..."
      },
      "empty-state-filter": {
        "description": "'<p>'Ingen treff i ditt søk'</p>'",
        "title": "Ingen treff"
      },
      "empty-state-initial": {
        "description": "'<p>'Det er ikke opprettet noen ressurser enda'</p>'",
        "title": "Ingen treff"
      }
    },
    "delete-comment-alert": {
      "delete-button": {
        "name": "Fjern"
      },
      "message": "Er du sikker på at du vil fjerne kommentaren?",
      "title": "Fjern kommentar?"
    },
    "delete-thread-alert": {
      "delete-button": {
        "name": "Slett"
      },
      "message": "Innlegget og alle {number} kommentarene i denne tråden vil bli permanent fjernet. Denne handlingen kan ikke angres.",
      "title": "Slett denne tråden?"
    },
    "delete-timeline-alert": {
      "delete-button": {
        "named": "Slett “{name}”",
        "untitled": "Slett tidslinje"
      },
      "message": "",
      "title": "Er du sikker?"
    },
    "delete-visualization-alert": {
      "delete-button": {
        "named": "Slett “{name}”",
        "untitled": "Slett visualisering"
      },
      "message": "",
      "title": "Er du sikker?"
    },
    "delete-visualization-item-alert": {
      "delete-button": "Fjern “{name}”",
      "message": "",
      "title": "Er du sikker?"
    },
    "edit-signal-helper": {
      "actions": {
        "edit": "Rediger"
      },
      "message": "Dersom integrasjonen din har støtte for å oppdatere metadata for signalet vil den overskrive dine endringer. Er du sikker på at du ønsker å redigere metadata for signalet manuelt?",
      "title": "Advarsel!"
    },
    "error": {
      "dismiss": "Dismiss",
      "faq": "Ta kontakt med support om problemet oppstår igjen.",
      "message": "Beklager, noe gikk galt. Forsøk igjen eller ta kontakt med support om problemet oppstår igjen.",
      "support": "Om problemet vedvarer, kontakt '<a href='mailto:support@clarify.us'>'Clarify support'</a>.",
      "title": "Oh no!"
    },
    "export-modal": {
      "all-items": {
        "label": "Alle item ({items})"
      },
      "calendar": {
        "end": "Til dato",
        "label": "Vennligst velgt datoer for start og slutt.",
        "placeholder": "dd/mm/yyyy",
        "start": "Fra dato",
        "time": "hh:mm"
      },
      "exporting": {
        "back": "Tilbake til eksport",
        "cancel": "Avbryt",
        "errors": {
          "dismiss": "Lukk",
          "empty": "",
          "title": "Kan ikke eksportere valgt data",
          "unsupported": "Export not supported by enum items"
        },
        "export": "Eksporter",
        "paragraph": "En nedlastingslenke blir sendt via epost til følgende addresse:",
        "state-accepted": "Eksporten snart ferdig!",
        "state-exporting": "Klargjør eksport....",
        "title": "Bekreft eksport?"
      },
      "interval-picker": {
        "hours": "timer",
        "validate": "Vennligst velg et gyldig tidsintervall."
      },
      "items": {
        "average": "Gj.snitt",
        "empty-signal": "Ingen items",
        "empty-title": "Visualisering uten navn",
        "maximum": "Maks.",
        "minimum": "Min",
        "sum": "Sum"
      },
      "items-selected": "{items, plural, one {1 item valgt} other {# item valgt}}",
      "selected-period": {
        "export": "Eksporter data",
        "label": "Valgt periode:"
      },
      "time-increment": {
        "days": "dager",
        "hours": "timer",
        "label": "Tidsintervall",
        "minutes": "minutter"
      }
    },
    "export-modal-exit-alert": {
      "exit-button": "Lukk",
      "message": "Dine innstillinger vil ikke bli lagret, og data vil ikke bli eksportert.",
      "title": "Avbryt eksport?"
    },
    "filter-auto-complete": {
      "filter-bar": "Søk…",
      "query": "Tittel: {query}"
    },
    "format-array": {
      "many": "{names}, og {last}",
      "many-others": "{names}, og {others} andre",
      "others": "{name} og {others} andre",
      "two": "{name} og {other}"
    },
    "format-duration": {
      "days": "{days, plural, one {1 dag} other {# dager}}",
      "hours": "{hours, plural, one {1 time} other {# timer}}",
      "minutes": "{minutes, plural, one {1 min} other {# min}}",
      "seconds": "{seconds, plural, one {1 sek} other {# sek}}"
    },
    "forms": {
      "label": {
        "copy-text": "Kopier tekst"
      }
    },
    "forms-items": {
      "fieldset": {
        "delta-interval": {
          "title": "Deltaintervall",
          "tooltip": "Bestemmer den maksimale avstanden mellom punkter som tegnes kontinuerlig i tidslinjer."
        },
        "description": {
          "placeholder": "Add some info to remember the item by...",
          "title": "Beskrivelse"
        },
        "engineering-unit": {
          "placeholder": "Skriv inn...",
          "title": "Enhet",
          "tooltip": "Måleenheten for itemet gjør det enklere å lese av data i tidslinjer."
        },
        "enum-values": {
          "title": "Enumverdier",
          "tooltip": "Enum values oversetter tidsseriedataen til forståelig tekst i tidsliner"
        },
        "id": {
          "copy-description": "Kopier item ID",
          "title": "Item ID",
          "tooltip": "En unik ID for itemet."
        },
        "sample-interval": {
          "title": "Samplingsintervall",
          "tooltip": "Samplingsraten for dataen."
        },
        "source-type": {
          "options": {
            "aggregation": "Aggregering",
            "measurement": "Måling",
            "prediction": "Prediksjon"
          },
          "title": "Type",
          "tooltip": "Velg mellom numerisk for data som skal plottes i en graf, eller enums for data som skal representeres som en status. Enums lar deg også velge en tekst som skal vises for hver ulik verdi i dataen!"
        },
        "title": {
          "placeholder": "Item-navn...",
          "title": "Item-navn"
        },
        "type": {
          "options": {
            "enum": "Enum",
            "numeric": "Numerisk"
          },
          "title": "Type",
          "tooltip": "Velg mellom numerisk for data som skal plottes i en graf, eller enums for data som skal representeres som en status. Enums lar deg også velge en tekst som skal vises for hver ulik verdi i dataen!"
        }
      }
    },
    "forms-signals": {
      "description": {
        "placeholder": "This signal is about…",
        "title": "Beskrivelse",
        "tooltip": "Her finner du en kort beskrivelse av signalet"
      },
      "id": {
        "copy-description": "Kopier signal ID",
        "title": "Signal ID",
        "tooltip": "Intern ID for signalet."
      },
      "input-id": {
        "copy-description": "Kopier input ID",
        "description": "Brukes for å sende data til Clarify",
        "placeholder": "Skriv inn input ID...",
        "title": "input ID",
        "title-required": "Input ID *"
      },
      "name": {
        "description": "Dette navnet vil brukes som standard når du publiserer signalet",
        "placeholder": "Skriv inn...",
        "title": "Signalnavn",
        "title-required": "Signalnavn *",
        "tooltip": "Navnet på signalet."
      }
    },
    "group-edit-members": {
      "add": {
        "description": "Legg til i gruppe",
        "title": "+ Legg til"
      },
      "browser-title": "Rediger medlemmer – {name}",
      "empty-state": "Ingen resultater for ditt søk",
      "header": "Rediger medlemmer",
      "input-placeholder": "Søk etter navn, epost...",
      "remove": {
        "description": "Fjern fra gruppe",
        "title": "Fjern"
      }
    },
    "groups-index": {
      "browser-title": "Grupper",
      "data-grid-descriptors": {
        "description": "Beskrivelse",
        "members": "Brukere",
        "name": "Navn"
      },
      "header": {
        "create-button": "Opprett ny gruppe",
        "title": "Grupper"
      },
      "states": {
        "error": "Lasting feilet...",
        "loading": "Laster grupper"
      },
      "toolbar": {
        "delete": "{disabled, select, true {{selectedModelsCount, plural, =1 {Denne gruppen kan ikke slettes} other {Disse gruppene kan ikke slettes}}} false {{count, plural, =1 {Slett gruppe} other {Slett grupper}}} other{} }"
      }
    },
    "groups-new": {
      "actions": {
        "cancel": "Avbryt",
        "submit": "Opprett"
      },
      "alerts": {
        "errors": {
          "dismiss": "Lukk",
          "message": "Beklager, noe gikk galt.",
          "title": "Å nei!"
        }
      },
      "create": {
        "browser-title": "Opprett gruppe",
        "group-name": "* Gruppenavn",
        "group-placeholder": "Skriv inn...",
        "group-tooltip": "Navnet på gruppen (påkrevd)",
        "title": "Opprett gruppe"
      },
      "description": {
        "group-name": "beskrivelse",
        "group-placeholder": "Add some info about the purpose of this group..."
      }
    },
    "groups-show": {
      "alerts": {
        "destroy": {
          "message": "Kunne ikke slette gruppen, prøv igjen eller ta kontakt med support om problemer oppstår igjen.",
          "primary-button": "Lukk",
          "title": "Å nei!"
        }
      },
      "fieldsets": {
        "createdAt": {
          "label": "Opprettet"
        },
        "description": {
          "label": "Beskrivelse",
          "placeholder": "Add a description to remember this group by..."
        },
        "header": {
          "title": "Navn og beskrivelse",
          "tooltip": "Navn og beskrivelse"
        },
        "name": {
          "label": "Navn",
          "placeholder": "Navn"
        }
      },
      "header": {
        "actions": {
          "delete": "Slett gruppe"
        },
        "members": "{members, plural, one {1 medlem} other {# medlemmer}}"
      },
      "members": {
        "add-to-group-button": "+ Legg til medlemmer",
        "members-list": {
          "header": "Gruppemedlemmer",
          "remove-button": "Fjern fra gruppe"
        },
        "remove-user-from-group-alert": {
          "dismiss": "Lukk",
          "message": "Beklager, noe gikk galt.",
          "title": "Å nei!"
        }
      }
    },
    "inputfield": {
      "edit-label": "Angi visningsnavn",
      "title": "Skriv en merkelapp"
    },
    "integration-create": {
      "back": "Tilbake",
      "browser-title": "Opprett integrasjon",
      "cancel": "Avbryt",
      "create": "Opprett",
      "header": "Opprett integrasjon",
      "integration-info": {
        "description": {
          "placeholder": "Add some info to remember this integration by…",
          "title": "Beskrivelse"
        },
        "name": {
          "placeholder": "Skriv inn...",
          "title": "* Integrasjonsnavn",
          "tooltip": "Navnet på integrasjonen benyttes kun i admin panelet."
        },
        "title": "Integrasjonsinfo"
      },
      "intro": {
        "how-to-setup": "Guide: How to set up your integration >",
        "intro": "'<p aria-hidden=\"true\">'👋 👋 👋'</p>'\n'<p>'Denne veilederen hjelper deg å koble data til Clarify.'</p>'\n'<p>'Om du vil lese mer om hvordan du setter opp en integrasjon finner du det i guiden under (kun på engelsk).'</p>'\n'<p>'\n'<a href=\"https://docs.clarify.us/docs/setting-up-an-integration\">'Guide: Setting up an integration'</a>'\n'</p>'",
        "title": "Intro"
      },
      "next": "Neste"
    },
    "integration-created": {
      "browser-title": "Opprett integrasjon",
      "content": "'<p aria-hidden=\"true\">'🎉 🎉 🎉'</p>'\n'<p>'Suksess! Integrasjonen '<b>'{integrationName}'</b>' ble opprettet og er klar til å motta data.'</p>'\n'<p>'For å koble til, last ned credentials under og bruk dem i systemet du skal sende data fra.'</p>'\n'<p>'\n'<a href=\"https://docs.clarify.us/docs/obtaining-an-access-token\">'Guide: Obtaining an access token'</a>'\n'<br>'\n'<a href=\"https://docs.clarify.us/docs/writing-meta-data-to-clarify\">'Guide: Writing meta-data for signals'</a>'\n'<br>'\n'<a href=\"https://docs.clarify.us/docs/writing-data-to-clarify\">'Guide: Writing data to Clarify'</a>'\n'</p>'",
      "credentials": {
        "title": "Credentials"
      },
      "header": "Opprett integrasjon"
    },
    "integrations-index": {
      "browser-title": "Integrasjoner",
      "data-grid-descriptors": {
        "createdAt": "Opprettet",
        "dataUpdateInterval": "Data update interval",
        "description": "Beskrivelse",
        "firstInsertAt": "First insert at",
        "id": "Integrasjons ID",
        "insertedValues": "Data points",
        "lastInsertAt": "Last inserted at",
        "name": "Navn",
        "recentInsertInterval": "Recent insert interval",
        "trackedInserts": "Insert operations",
        "trackedSince": "Tracked since",
        "updatedAt": "Sist oppdatert"
      },
      "header": {
        "create-button": "Ny integrasjon",
        "title": "Integrasjoner"
      },
      "states": {
        "error": "Lasting feilet...",
        "loading": "Laster integrasjoner"
      },
      "toolbar": {
        "delete": {
          "tooltip": "{count, plural, one {Slett # integrasjon} other {Slett # integrasjoner}}"
        }
      }
    },
    "integrations-show": {
      "alerts": {
        "destroy": {
          "message": "Kunne ikke slette integrasjonen, prøv igjen eller ta kontakt med support om problemer oppstår igjen.",
          "primary-button": "Lukk",
          "title": "Å nei!"
        }
      },
      "browser-title": "{pane, select, about {Metadata} labels {Merkelapper} credentials {Nøkler} other {Ukjent}} – {name}",
      "credentials-pane": {
        "credentials-download-button": "clarify-credentials.json",
        "credentials-label": "Nøkler",
        "header": "Client credentials"
      },
      "fieldsets": {
        "description": {
          "label": "Beskrivelse",
          "placeholder": "Add a description to remember this integration by…"
        },
        "header": {
          "title": "Navn & Beskrivelse",
          "tooltip": "Navn & Beskrivelse"
        },
        "name": {
          "label": "Navn",
          "placeholder": "Navn"
        }
      },
      "header": {
        "actions": {
          "delete": "Slett integrasjon",
          "dismiss": "Ferdig"
        },
        "integration-id": {
          "copy-description": "Kopier integrasjons ID"
        },
        "signals-button": "Vis alle signaler >",
        "tabs": {
          "about": "Generelt",
          "credentials": "Credentials",
          "labels": "Merkelapper"
        }
      }
    },
    "integrations-show-edit": {
      "browser-title": "Rediger signal — {name}",
      "dialog": {
        "footer": {
          "action-dismiss": "Avbryt",
          "action-save": "Lagre"
        },
        "header": "Rediger signal"
      }
    },
    "integrations-signals-index": {
      "browser-title": "Signaler - {name}",
      "data-grid-descriptors": {
        "createdAt": "Opprettet",
        "deltaInterval": "Deltaintervall",
        "description": "Beskrivelse",
        "engUnit": "Engineering unit",
        "id": "Signal ID",
        "input-id": "Input ID",
        "item": "Publisert",
        "item-options": {
          "not-published": "Ikke publisert",
          "published": "Publisert"
        },
        "label": "Merkelapp: {name}",
        "name": "Navn",
        "sampleInterval": "Samplingsintervall",
        "type": "Type",
        "updatedAt": "Sist oppdatert"
      },
      "header": {
        "create-button": "Opprett signal",
        "title": "Signaler"
      },
      "states": {
        "error": "Lasting feilet...",
        "loading": "Laster signaler"
      },
      "toolbar": {
        "delete": {
          "tooltip": "{count, plural, one {Slett # signal} other {Slett # signaler}}"
        }
      }
    },
    "integrations-signals-show": {
      "alerts": {
        "destroy": {
          "message": "Kunne ikke slette signalet, prøv igjen eller ta kontakt med support om problemet oppstår igjen.",
          "primary-button": "Lukk",
          "title": "Å nei!"
        }
      },
      "browser-title": "{pane, select, item {Publisert som} metadata {Metadata} other {Ukjent}} – {name}",
      "header": {
        "about": "Generelt",
        "about-tooltip": "Generell informasjon om signalet.",
        "actions": {
          "delete": "Slett signal",
          "edit": "Rediger metadata for signal"
        },
        "tabs": {
          "item": "Publisert som",
          "metadata": "Metadata"
        },
        "tagline": "Signal"
      },
      "item-pane": {
        "connect-to-item": {
          "button": "Connect to item",
          "description": "Connect this signal to an existing item"
        },
        "disconnect": "Disconnect",
        "empty-state": {
          "button": "Publiser",
          "description": "Publiser signalet for å se dataen i Clarify."
        },
        "id": "Signal ID",
        "item-card-label": "Publisert som",
        "item-id-label": "Item ID",
        "settings-button": "Gå til innstillinger for item >"
      },
      "signal-published-state": {
        "not-published": "Ikke publisert",
        "published": "Publisert"
      },
      "states": {
        "error": "Lasting feilet...",
        "loading": "Laster signaler"
      }
    },
    "invitations-index": {
      "header": {
        "invite-button": "Inviter en ny bruker",
        "title": "Invitasjoner"
      },
      "members": {
        "count": "{count, plural, one {Bruker} other {Brukere}}",
        "organization": "Bruker i {organization}"
      },
      "pending": {
        "action": {
          "delete": "Slett invitasjon",
          "resend": "Send på nytt",
          "resend-title": "Send på nytt"
        },
        "date": "Sendt for {date} siden",
        "title": "Ventende invitasjoner"
      },
      "remove-invitation-alert": {
        "dismiss": "Lukk",
        "message": "Beklager, noe gikk galt.",
        "title": "Å nei!"
      },
      "state": {
        "empty": "Ingen ventende invitasjoner. '<br><br>'For å invitere nye brukere, trykk på \"Inviter en ny bruker\" knappen.",
        "error": "Lasting feilet...",
        "loading": "Laster invitasjoner"
      }
    },
    "invitations-new": {
      "alerts": {
        "errors": {
          "dismiss": "Lukk",
          "message": "Beklager, noe gikk galt!",
          "title": "Å nei!"
        }
      },
      "browser-title": "Inviter",
      "footer": {
        "submit-button": "Send invitasjoner",
        "subtitle": "Adminstratorer kan bruke alle grunnfunksjoner i Clarify i tillegg til å kunne redigere brukere, integrasjoner og data.",
        "title": "Medlemmer kan bruke alle grunnfunksjoner i Clarify."
      },
      "form": {
        "email-placeholder": "Epost-adresse...",
        "remove-button": "Slett invitasjon",
        "roles": {
          "administrator": "Administrator",
          "member": "Medlem"
        }
      },
      "header": {
        "description": "Skriv inn en epost-adresse og velg en rolle for å invitere en ny bruker.",
        "subtitle": "Inviter nye brukere til Clarify",
        "title": "Inviter"
      }
    },
    "invitations-show": {
      "description": "Du vil bli medlem i {name}s organisasjon {organization}",
      "join-button": "Bli med i organisasjonen",
      "title": "Bli med {name} i Clarify"
    },
    "invitations-show-error": {
      "description": "{name, select, RPCError { {type, select, operationNotPermitted {Invitasjonen er ikke lengre gyldig} other {En ukjent feil har oppstått, vennligst prøv igjen. Ta kontakt med support om feilen oppstår igjen} } } other {En ukjent feil har oppstått} }",
      "title": "{name, select, RPCError { {type, select, operationNotPermitted {Invitasjonen ble ikke funnet} other {En feil oppstod} } } other {En feil oppstod} }"
    },
    "item-card": {
      "add": "+ Legg til",
      "add-tooltip": "Legg til items i denne tidslinjen",
      "added": "I denne tidslinjen",
      "empty-state": {
        "data-source": {
          "title": "Ukjent datakilde"
        },
        "location": {
          "title": "Ukjent sted"
        }
      },
      "item-pane": {
        "tooltip-eng-unit": "Engineering unit: {unit}"
      },
      "open": "Åpne info"
    },
    "item-dialog": {
      "close": "Lukk",
      "create-timeline": "Åpne i ny tidslinje",
      "create-timeline-tooltip": "Create a new timeline of this item",
      "fields": {
        "createdAt": "Opprettet",
        "dataType": "Type",
        "engUnit": "Enhet",
        "id": "Item ID",
        "sampleInterval": "Samplingsrate",
        "sourceType": "Item-type"
      },
      "header": {
        "title": "ITEM INFO"
      },
      "metadata": {
        "data-source": "Datakilde",
        "empty-state": {
          "value": "Ukjent verdi"
        },
        "location": "Lokasjon"
      },
      "settings": "Open Item settings",
      "statistics": {
        "average": "AVG",
        "enum": "Statistics not supported by enum items",
        "failed": "Failed to load",
        "info": "Select a period in the timeline to view statistics.",
        "item": "Signal",
        "maximum": "Max",
        "minimum": "Min",
        "points": "Points",
        "points-unit": "{points, plural, one {point} other {points}}",
        "refresh": "Refresh",
        "sum": "Sum"
      }
    },
    "items-index": {
      "browser-title": "Item",
      "data-grid-descriptors": {
        "createdAt": "Opprettet",
        "deltaInterval": "Deltaintervall",
        "description": "Beskrivelse",
        "engUnit": "Engineering unit",
        "id": "Item ID",
        "label": "Merkelapp: {name}",
        "labels": "Alle merkelapper",
        "name": "Navn",
        "sampleInterval": "Samplingsintervall",
        "status": "Status",
        "type": "Type",
        "type-options": {
          "enum": "Enum",
          "numeric": "Numerisk"
        },
        "updatedAt": "Sist oppdatert"
      },
      "header": {
        "title": "Item"
      },
      "states": {
        "error": "Lasting feilet...",
        "loading": "Laster item"
      },
      "toolbar": {
        "delete": "{count, plural, one {Slett # item} other {Slett # item}}",
        "hide": {
          "title": "Skjul",
          "tooltip": "{disabled, select, true {{count, plural, =1 {Itemet er skjult} other {Itemene er skjult}}} false {{count, plural, =1 {Skjul item} other {Skjul item}}} other{}}"
        },
        "unhidden": {
          "title": "Vis",
          "tooltip": "{disabled, select, true {{count, plural, =1 {Dette itemet er synlig} other {Disse itemene er synlig}}} false {{count, plural, =1 {Vis item} other {Vis item}}} other{} }"
        }
      }
    },
    "items-quest": {
      "items-state": {
        "failed": "Kunne ikke laste item.",
        "loading": "Laster item…",
        "no-items": {
          "description": "Dine items vil vises her.",
          "title": "Ingen item"
        }
      }
    },
    "items-show": {
      "alerts": {
        "destroy": {
          "message": "Kunne ikke slette itemet, prøv igjen eller ta kontakt med support om problemet oppstår igjen.",
          "primary-button": "Lukk",
          "title": "Å nei!"
        },
        "save": {
          "message": "Kunne ikke lagre itemet, prøv igjen eller ta kontakt med support om problemet oppstår igjen.",
          "primary-button": "Lukk",
          "title": "Å nei!"
        }
      },
      "browser-title": "{pane, select, metadata {Metadata} source {Kilde} other {Ukjent}} – {name}",
      "header": {
        "actions": {
          "delete": "Slett item"
        },
        "open-in-clarify": "Open in Clarify",
        "title": "Item",
        "visibility-item": "Velg om itemet skal være synlig for brukere i din organisasjon.",
        "visibility-label": "Visibility toggle",
        "visibility-off": "Nei",
        "visibility-on": "Ja"
      },
      "metadata-pane": {
        "title": "Metadata"
      },
      "source-pane": {
        "dataset": {
          "description": "Dette itemet er opprettet fra et datasett"
        },
        "header": "Publisert fra",
        "integration-label": "Integrasjon",
        "integration-value": "{integration} >",
        "not-found": "Fant ikke signalet",
        "signal-input-id-label": "Input ID",
        "signal-label": "Signalnavn",
        "signal-value": "{signal} >",
        "title": "Kilde"
      },
      "states": {
        "error": "Lasting feilet...",
        "loading": "Laster item"
      }
    },
    "keyword-auto-complete-list": {
      "no-matches": "Ingen filter stemmer med søket",
      "select": "Velg et filter, eller søk etter navn"
    },
    "keyword-auto-complete-list-item": {
      "add-filter": "Legg til filter"
    },
    "keyword-filter": {
      "empty": "{type, select, labels {ingen merkelapper} permissions {ingen tidslinjer} other {ingen merkelapper}}",
      "item": {
        "count": "({count})"
      }
    },
    "keyword-label": {
      "item": {
        "tag": "+ Ny merkelapp"
      }
    },
    "keywords-delete": {
      "alert": {
        "error": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong.",
          "title": "Uh oh!"
        }
      },
      "dialog": {
        "action": {
          "dismiss": "Cancel",
          "remove": "Remove"
        },
        "header": {
          "description": "Labels within this group will no longer be visible in Clarify.",
          "title": "Remove {title}?"
        }
      }
    },
    "keywords-new": {
      "alert": {
        "error": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong.",
          "title": "Uh oh!"
        },
        "form": {
          "error": {
            "message": "This field is required and cannot be left blank."
          }
        }
      },
      "dialog": {
        "content": {
          "action-add": "+ Add",
          "action-title": "Add new label"
        },
        "footer": {
          "action-dismiss": "Cancel",
          "action-save": "Create"
        },
        "form": {
          "add-button": "+ Add",
          "delete-key": "Remove selected key",
          "edit-key": "Key",
          "edit-key-tooltip": "Here you will find the key that you want to add",
          "edit-title": "Title",
          "edit-title-placeholder": "untitled",
          "edit-title-tooltip": "Here you will find the keywords title that you want to edit"
        },
        "header": "Add label group"
      }
    },
    "keywords-show-edit": {
      "alert": {
        "error": {
          "dismiss": "Close",
          "message": "Sorry, something went wrong.",
          "title": "Uh oh!"
        },
        "form": {
          "error": {
            "message": "This field cannot be left blank."
          }
        }
      },
      "dialog": {
        "footer": {
          "action-dismiss": "Cancel",
          "action-save": "Done"
        },
        "form": {
          "edit-key": "Key",
          "edit-key-tooltip": "Key cannot be edited once is created",
          "edit-title": "Title",
          "edit-title-placeholder": "Label title",
          "edit-title-tooltip": "Here you will find the keywords title that you want to edit"
        },
        "header": "Edit label group"
      }
    },
    "keywords-show-modify": {
      "dialog": {
        "content": {
          "key": "Key",
          "key-tooltip": "Key: {key}",
          "title": "Title",
          "title-tooltip": "Title: {title}"
        },
        "footer": {
          "action-add": "+ Add label group",
          "action-title": "Open actions"
        },
        "header": "Edit labels visible in Clarify",
        "menu": {
          "delete": "Delete",
          "edit": "Edit"
        }
      },
      "empty-state": {
        "key": "No key found",
        "labels": "No labels added",
        "title": "No name found"
      }
    },
    "labels": {
      "add-button": {
        "button": {
          "title": "{disabled, select, true {Du kan ikke bruke mer enn 10 labels} other {Legg til ny merkelapp}}"
        },
        "empty-state": "Ingen treff",
        "placeholder": "Skriv for å søke…"
      },
      "add-category": {
        "add-button": "+ Legg til kategori",
        "dropdown": {
          "placeholder": "+ Ny kategori"
        },
        "empty-state": "Ingen treff",
        "placeholder": "Søk etter kategori...",
        "validation-error": "Advarsel: Kan ikke lagre ny kategori. Vennligst fjern alle mellomrom eller spesialtegn."
      },
      "empty-state": "—",
      "item": {
        "remove": "Fjern"
      },
      "label": {
        "query": {
          "placeholder": "+ Ny merkelapp"
        }
      }
    },
    "line-chart-range-fieldset": {
      "auto": "Automatisk skalering",
      "description": "Endre skalering av vertikal akse",
      "invalid": "Ugyldig",
      "manual": "Egendefinert skalering",
      "max-label": "Maks.",
      "min-label": "Min.",
      "placeholder": "Auto"
    },
    "login": {
      "description": "Clarify er et verktøy som lar deg og ditt team enkelt dele kunnskap og utforske industridata - sammen. Sånn at du kan gjøre om data til faktisk verdi, hver dag.",
      "learn-more": "Lær mer om Clarify.",
      "logIn": "Logg inn"
    },
    "logout": {
      "logOutInProgress": "Logger ut...",
      "loggedOut": "Logget ut"
    },
    "members-delete-error": {
      "message": "Kunne ikke slette {members, plural, =1 {medlemmet} other {medlemmene}}, prøv igjen eller ta kontakt med support om problemet oppstår igjen"
    },
    "metadata": {
      "about-header": {
        "title": "Generelt",
        "tooltip": "Generell informasjon"
      },
      "date-values": {
        "createdAt": "Opprettet",
        "createdAt-tooltip": "Opprettet: {date}",
        "createdBy": "Opprettet av",
        "updatedAt": "Sist oppdatert",
        "updatedAt-tooltip": "Sist oppdatert: {date}"
      },
      "default-placeholder": "Skriv inn...",
      "delta-interval": {
        "title": "Deltaintervall",
        "tooltip": "Bestemmer den maksimale avstanden mellom punkter som tegnes kontinuerlig i tidslinjer."
      },
      "engineering-unit": {
        "title": "Engineering unit",
        "tooltip": "Måleenheten gjør det enklere å lese av data i tidslinjer."
      },
      "enum-values": {
        "title": "Enumverdier",
        "tooltip": "Enum values oversetter tidsseriedataen til forståelig tekst i tidsliner"
      },
      "form": {
        "duration": {
          "days": "Dager",
          "hours": "Timer",
          "minutes": "Minutter",
          "placeholder": "Skriv inn...",
          "seconds": "Sekunder"
        },
        "enum-values": {
          "button-add": "+ Legg til verdi",
          "button-remove": "Fjern",
          "key": {
            "placeholder": "Key"
          },
          "label": {
            "placeholder": "Merkelapp"
          }
        }
      },
      "log-header": {
        "title": "Oppdateringer",
        "tooltip": "Viktige tidspunkter for ressursen."
      },
      "metrics-label-header": {
        "title": "Detaljer",
        "tooltip": "Detaljer om ressursen"
      },
      "other-labels-header": {
        "title": "Brukerderfinerte merkelapper",
        "tooltip": "Merkelapper som administratorer bruker for å organisere ressurser. Disse vil ikke vises utenfor adminpanelet"
      },
      "sample-interval": {
        "title": "Samplingsintervall",
        "tooltip": "Samplingsraten for dataen."
      },
      "source-type": {
        "options": {
          "aggregation": "Aggregering",
          "measurement": "Måling",
          "prediction": "Prediksjon"
        },
        "title": "Engineering type",
        "tooltip": "Velg mellom numerisk for data som skal plottes i en graf, eller enums for data som skal representeres som en status. Enums lar deg også velge en tekst som skal vises for hver ulik verdi i dataen!",
        "value": "{value, select, aggregation {Aggregering} measurement {Måling} prediction {Prediksjon} other {Ukjent type}}"
      },
      "system-header": {
        "title": "Systeminfo",
        "tooltip": "Verdier som kun brukes internt i Clarify."
      },
      "system-labels-header": {
        "title": "Merkelapper",
        "tooltip": "Merkelapper lar deg kategorisere dine ressurser og gjør det enklere å søke etter de"
      },
      "system-labels-visibility": {
        "action": "Redigere",
        "title": "Visible in Clarify",
        "tooltip": "Merkelapper lar deg kategorisere dine ressurser og gjør det enklere å søke etter de"
      },
      "type": {
        "options": {
          "enum": "Enum",
          "numeric": "Numerisk"
        },
        "title": "Type",
        "tooltip": "Velg mellom numerisk for data som skal plottes i en graf, eller enums for data som skal representeres som en status. Enums lar deg også velge en tekst som skal vises for hver ulik verdi i dataen!",
        "value": "{value, select, numeric {Numerisk} enum {Enum} other {Ukjent type}}"
      }
    },
    "mobile-upsell": {
      "app-store-button": "Last ned Clarify for iOS",
      "content": "You are about to open the Clarify desktop app on a mobile device.",
      "open-app": "Åpne appen",
      "open-web-app": "Open nettsiden (ikke anbefalt)",
      "or": "eller",
      "play-store-button": "Last ned Clarify for Android"
    },
    "non-admin": {
      "description": "Du er ikke administrator i denne organisasjonen"
    },
    "organization-index": {
      "about": {
        "actions": {
          "edit": "Endre organisasjonsnavn"
        },
        "content": {
          "integrations": "Integrasjoner",
          "items": "Item",
          "members": "Brukere"
        }
      },
      "alerts": {
        "errors": {
          "dismiss": "Lukk",
          "message": "Beklager, noe gikk galt.",
          "title": "Å nei!"
        }
      },
      "browser-title": "Betaling og organisasjon",
      "create": {
        "actions": {
          "cancel": "Avbryt",
          "submit": "Lagre"
        },
        "organization-name": "Organisasjonsnavn",
        "organization-placeholder": "Skriv inn...",
        "organization-tooltip": "Organisasjonsnavn",
        "title": "Rediger organisasjonsnavn"
      },
      "header": {
        "title": "Betaling og organisasjon"
      },
      "shortcuts": {
        "links": {
          "documentation": "Utviklerdokumentasjon",
          "guide": "Guide: Integrasjonsoppsett",
          "intro": "Brukerdokumentasjon"
        },
        "title": "Kom igang"
      },
      "states": {
        "error": "Lasting feilet...",
        "loading": "Laster organisasjonen"
      },
      "subscription": {
        "actions": {
          "choose-plan": "Velg abbonement",
          "manage-subscription": "Administrer ditt abonnement"
        },
        "cancelled": "Ditt abonnement vil bli avsluttet {endsAt, date, long}",
        "inactive": "Ditt abonnement ble avsluttet {endsAt, date, long}",
        "tagline": "Ditt abonnement",
        "title": "{status, select, active {{product}} inactive {{product} (Inaktivt)} trialing {{product} (Prøveperiode)} other {Ukjent}}",
        "trial": "'<b>'{daysLeftOfTrial, plural, =1 {1 dag} other {# dager}}'</b>' igjen av prøveperioden (avsluttes {endsAt, date, long})"
      },
      "subscription-paid": {
        "date": "Neste faktureringsdato:",
        "description": "{currentPeriodEnd, date, long}",
        "invoice-to": "Send faktura til:"
      }
    },
    "organizations-new": {
      "demo-data": {
        "description": "Vi har gjort klar data for et par ulike industrier.'<br />'Velg et tema for å gå videre.",
        "form": {
          "demo-data": {
            "description": "{industryType, select, maritime {Skip, havner og værdata} manufacturing {Maskindata, produktytelse og kvalitetsparametre} aquaculture {Værdata, fiskehelse og priser} other {En samling med data}}",
            "title": "{industryType, select, maritime {Maritim} manufacturing {Produksjon} aquaculture {Akvakultur} other {Forundringspakke}}"
          },
          "submit": "Jeg er klar"
        },
        "header": {
          "progress": "Organisasjonsoppsett 2/2",
          "title": "Kom igang med demo-data"
        }
      },
      "errors": {
        "submit": {
          "dismiss": "Lukk",
          "message": "Beklager, noe gikk galt under opprettelsen av din organisasjon.",
          "title": "Å nei!"
        }
      },
      "organization": {
        "description": "Navnet på selskapet ditt, eller et prosjektnavn.",
        "form": {
          "name": {
            "label": "Organisasjonsnavn",
            "placeholder": "Skriv inn..."
          },
          "submit": "Neste"
        },
        "header": {
          "progress": "Organisasjonsoppsett 1/2",
          "title": "Velg et navn på din organisasjon"
        }
      }
    },
    "organizations-show-activities": {
      "input": {
        "placeholder": "Søk etter kommentarer"
      },
      "search": {
        "empty-state": {
          "description": "Kontroller stavemåten, eller forsøk å søke etter noe annet.",
          "title": "Ingen resultater"
        },
        "error": {
          "description": "Noe uventet skjedde",
          "retry": "Prøv igjen",
          "title": "Lasting feilet"
        }
      }
    },
    "organizations-show-creating": {
      "description": "Dette kan ta noen sekunder",
      "title": "Klargjør din organisasjon"
    },
    "organizations-show-upgrade": {
      "card-favorite-plan-title": "'<span>'⭐'</span>Mest populært",
      "card-price-date": "/måned*",
      "card-price-label": "Valgt pris",
      "card-price-symbol": "€",
      "condition": "* Tilbudet gjelder for de første 6 månedene av et betalt abbonement. Tilbudet gjelder for alle nye kunder før 1.juli 2021.",
      "description": "Sammenlign alle funksjoner",
      "errors": {
        "dismiss": "Close",
        "message": "Sorry, something went wrong with your request.",
        "title": "Uh oh!"
      },
      "header": {
        "title-link": "Gå tilbake til Clarify"
      },
      "link": "Ta kontakt",
      "message": "Letter du etter en lisens for utdanning eller veldedige organisasjoner?",
      "plan": {
        "business": {
          "actions": {
            "text-link": "Fortsett med '<small>'Business'</small>"
          },
          "card-description": "Flere signaler og brukere, API tilgang, prioritert support",
          "card-price": "349",
          "card-title": "Business",
          "items": " '<li>' 500 signaler '<span>' + €25/måned for å legge til 50 signaler '</span></li>' '<li>' 10 brukere '<span>' €10/måned per ekstra bruker '</span></li>' '<li>' API tilgang '</li>' '<li>' Grafana plugin'</li>' '<li>' Prioritert online support'</li>' "
        },
        "enterprise": {
          "actions": {
            "text-link": "Ta kontakt"
          },
          "card-description": "For bedrifter med store mengder data",
          "card-title": "Enterprise",
          "items": " '<li>' Ubegrensede signaler '</li>' '<li>' Ferdige integrasjoner'</li>' '<li>' Dedikert teknisk kontaktperson'</li>' '<li>' Utvidede APIs'</li>' '<li>' SLA'</li>' '<li>' Enterprise support'</li>' '<li>' Tidlig tilgang til nye funksjoner'</li>' "
        },
        "individual": {
          "actions": {
            "text-link": "Fortsett med '<small>'Individual'</small>"
          },
          "card-description": "Vår plan for hobbybrukere, utviklere eller mindre prosjekter.",
          "card-price": "9",
          "card-title": "Individual",
          "items": " '<li>' 20 signaler  '<span>' non-upgradable '</span></li>' '<li>' 3 brukere  '<span>' non-upgradable '</span></li>' "
        },
        "team": {
          "actions": {
            "text-link": "Fortsett med '<small>'Team'</small>"
          },
          "card-description": "Alt du trenger for å komme i gang med datautforskning",
          "card-price": "199",
          "card-title": "Team",
          "items": " '<li>' 200 signaler '<span>' + €25/måned for å legge til 50 signaler '</span></li>' '<li>' 5 brukere '<span>' €10/måned per ekstra bruker '</span></li>' "
        }
      },
      "subtitle": "Riding solo?",
      "title": "Velg abbonementet som passer for deg."
    },
    "permission-type-dropdown": {
      "options": {
        "timelines:collaborator": {
          "description": "Kan legge til/fjerne data og kommentere",
          "title": "Rediger"
        },
        "timelines:guest": {
          "description": "Kan lese data og kommentere",
          "title": "Lese"
        },
        "timelines:owner": {
          "description": "Kan redigere medlemmer og tidslinje",
          "title": "Admin"
        }
      },
      "remove": "Fjern tilgang",
      "title": {
        "timelines:collaborator": "kan redigere",
        "timelines:guest": "kan se",
        "timelines:owner": "admin"
      }
    },
    "present-ruler-popover": {
      "now": "nå",
      "today": "i dag"
    },
    "privacy": {
      "mixed": "Synlig for  {users, plural, =0 {0 person} =1 {1 person} other {# personer}}",
      "private": "Kun synlig for deg",
      "public": "Synlig for alle i {organizationName}"
    },
    "query-builder": {
      "remove": "Fjern"
    },
    "routes": {
      "login": "Logg inn",
      "organizations/show/timelines/index": "Tidslinjer",
      "organizations/show/timelines/show": {
        "untitled": "Tidslinje uten navn"
      }
    },
    "settings-loading": {
      "title": "Laster innstillinger"
    },
    "settings-visualization": {
      "accessDenied": {
        "description": "Kun personer med admin/rediger tilgang kan redigere tidslinjen",
        "title": "Ingen tilgang"
      },
      "error": {
        "title": "Kunne ikke laste visualisering"
      },
      "visualizationNotFound": {
        "description": "Visualiseringen kan ha blitt slettet",
        "title": "Fant ikke visualisering"
      }
    },
    "setup": {
      "description": "Fyll inn feltene under for å komme i gang. \nNavnet ditt vil bli synlig for andre brukere i din organisasjon.",
      "error": {
        "message": "Prøv igjen, eller ta kontakt med support om problemet oppstår igjen.",
        "title": "Beklager, noe gikk galt."
      },
      "form": {
        "name": {
          "label": "Navn",
          "placeholder": "Skriv inn ditt fulle navn"
        },
        "password": {
          "description": "Passordet må ha minst 8 bokstaver",
          "label": "Passord",
          "placeholder": "Velg et passord for din konto"
        },
        "submit": "Fortsett til Clarify"
      },
      "title": "Velkommen!"
    },
    "sharing-info": {
      "timeline": {
        "collaborator": "Kan redigere",
        "guest": "Kan se"
      },
      "user": {
        "private": "Privat",
        "shared": "Delt"
      }
    },
    "signal-connect": {
      "browser-title": "Connect “{name}”",
      "connect-button": "+ Connect to Item",
      "header": {
        "title": "Connect to Item"
      },
      "info": {
        "description": "Insert an explanation of what you are doing here."
      },
      "states": {
        "error": "Loading failed, try again later",
        "loading": "Loading items"
      }
    },
    "signal-create": {
      "back": "Tilbake",
      "browser-title": "Opprett signal",
      "cancel": "Avbryt",
      "create": "Opprett",
      "errors": {
        "input-id": {
          "dismiss": "Lukk",
          "message": "Noe er galt med signalets input ID, kontroller at IDen er unik for denne integrasjonen og at den ikke inneholder ugyldige tegn",
          "title": "Å nei!"
        }
      },
      "fieldset": {
        "labels": {
          "description": {
            "placeholder": "Add some info to remember this signal by...",
            "title": "Beskrivelse"
          },
          "name": {
            "description": "Dette navnet vil brukes som standard når du publiserer signalet",
            "placeholder": "Skriv inn...",
            "title": "* Signalnavn",
            "tooltip": "* Signalnavn"
          }
        },
        "metrics": {
          "description": {
            "placeholder": "En beskrivelse av signalet...",
            "title": "Beskrivelse"
          },
          "name": {
            "description": "This name will be used by default when you publish the signal to all your users in Clarify.",
            "placeholder": "Skriv inn...",
            "title": "* Signalnavn",
            "tooltip": "Navnet på signalet (påkrevd)"
          }
        }
      },
      "header": "Opprett signal",
      "next": "Neste",
      "step-create": {
        "create": "'<p>'Klar til å opprette.'</p>' '<p>'Når du trykker på \"Opprett\" blir et signal med den valgte input IDen opprettet. Du kan bruke denne som en adresse for å sende data fra din integrasjon.'</p>'",
        "title": "Opprett"
      },
      "step-input-id": {
        "header": "Opprett input ID.",
        "input-id": "Input IDen brukes når data skal skrives til signalet.",
        "requirement": "Input IDen brukes for å unikt identifisere signalet for denne integrasjonen. Kun små bokstaver, tall og symbolene _ og - er tillatt.",
        "title": "Input ID",
        "type": {
          "auto": "Autogenerer en ID for meg. (Standard)",
          "manual": "Velg ID manuelt."
        },
        "validation-error": "Advarsel: Ugyldig input ID, vennligst fjern mellomrom eller ugyldige tegn"
      },
      "step-intro": {
        "intro": "'<p aria-hidden=\"true\">'👋 👋 👋'</p>'\n'<p>'Denne veilederen hjelper deg å opprette et signal som kan ta imot tidsseriedata.'</p>'\n'<p>'Signaler bør kun opprettes i UIet dersom integrasjonen din ikke støtter automatisk opprettelse av signaler.'</p>'\n'<p>'Lær mer om hvordan du automatisk oppretter signaler her:'</p>' \n'<p>'\n'<a href=\"https://docs.clarify.us/docs/writing-meta-data-to-clarify\">'Guide: Saving meta-data for signals'</a>'\n'</p>'",
        "title": "Intro"
      },
      "step-labels": {
        "labels": "'<p>'✏️ Skriv inn informasjonen du trenger for å identifisere signalet senere.'</p>'",
        "title": "Merkelapper"
      },
      "step-metrics": {
        "metrics": "'<p>'✏️ Fyll inn metadata for signalet.'</p>'",
        "title": "Detaljer"
      }
    },
    "signal-created": {
      "cancel": "Publiser senere",
      "create": "Gå til publisering nå",
      "header": "Signal opprettet",
      "step-connect": {
        "connect": "'<p>'Denne IDen benyttes for å skrive data til signalet. Les mer om å koble til signalet '<a target=\"_blank\" class=\"signal-created--link\" href='https://docs.clarify.us/docs/writing-data-to-clarify'>'her'</a>''</p>'",
        "description": "'<p>'Du kan finne IDen igjen ved å åpne signalvisningen'</p>'",
        "fieldset": {
          "input-id-label": "Input ID",
          "integration-id-copy-description": "Kopier integrasjons ID",
          "integration-id-description": "Integrasjons ID",
          "integration-id-label": "Integrasjons ID"
        },
        "title": "Koble til"
      },
      "step-publish": {
        "publish": "'<p>'Når du vil gjøre signalet tilgjengelig for bruk i Clarify må det publiseres.'</p>' '<p>'Ønsker du å publisere det nye signalet “{name}” med en gang?'</p>' '<p>'Du kan også gjøre dette senere.'</p>",
        "title": "Publiser"
      },
      "step-success": {
        "success": "'<p aria-hidden=\"true\">'🎉 🎉 🎉'</p>' '<p>'Suksess! Signalet '<b>'{signalName}'</b>' har blitt opprettet og er klar til å motta data.'</p>'",
        "title": "Suksess"
      }
    },
    "signal-publish": {
      "browser-title": "Publiser “{name}”",
      "content": {
        "add": {
          "title": "Publiser"
        },
        "item-title": "Item",
        "signal-name": "signalnavn",
        "signal-name-tooltip": "Navnet på signalet.",
        "signal-title": "signal"
      },
      "form": {
        "fieldset": {
          "signal-id": "Signal ID"
        }
      },
      "header": {
        "subtitle": "Rediger metadata og trykk publiser for å gjøre dataen tilgjengelig i Clarify.",
        "title": "Publiser"
      }
    },
    "statistics-panel": {
      "add-comment": "Add comment",
      "menu": "Visualisering",
      "statistics-close": "Lukk",
      "statistics-export": "Eksporter",
      "statistics-info": "Velg en periode i tidslinjen for å vise statistikk.",
      "status": {
        "failed": "Noe gikk galt, kunne ikke vise statistikk.",
        "loading": "Lasting…",
        "refresh": "Oppdater"
      },
      "table": {
        "average": "Gj.snitt",
        "enum": "Statistikk er ikke støttet for enum-item",
        "item": "Signal",
        "maximum": "Maks",
        "minimum": "Min",
        "points": "Punkter",
        "points-unit": "{points, plural, one {punkt} other {punkter}}",
        "sum": "Sum",
        "tooltip": {
          "item": "{title}, {location}, {dataSource}"
        }
      },
      "title": "Statistikk",
      "view-statistics": "statistikk"
    },
    "status": {
      "unknown-case": "Ukjent status"
    },
    "subject-auto-complete": {
      "empty-state": "Fant ingen grupper eller personer i søket",
      "in-timeline": "i tidslinje",
      "placeholder": "Skriv for å søke…",
      "popover": {
        "header": "Forslag"
      }
    },
    "table-toolbar": {
      "actions": {
        "add-columns": "Legg til felt",
        "edit": "Edit labels",
        "remove": "Fjern alle filtre ({items})"
      },
      "checkbox-selected": {
        "label-selected": "{count, plural, one {1 Valgt} other {# Valgt}}",
        "label-total": "Viser alle {count}",
        "label-total-filtered": "Viser {count} av {total}"
      }
    },
    "text-editor": {
      "auto-complete-empty-state": {
        "item": "Ingen item funnet",
        "user": "Ingen brukere funnet"
      },
      "compose": "Skriv noe...",
      "compose-comment": "Skriv en kommentar…",
      "error": "Kunne ikke publisere kommentar",
      "remove-attachment": "Fjern",
      "send": "Send",
      "toolbar": {
        "item": "Merk signal",
        "upload": "Last opp",
        "user": "Nevn"
      },
      "uploading": "Laster opp... {uploadProgress}%"
    },
    "thread": {
      "emptyState": "Skriv en kommentar",
      "errors": {
        "deleted": "Tråd slettet",
        "dismiss": "Lukk",
        "empty": "Mangler tekst eller vedlegg",
        "failed": "Feil ved lagring av kommentar {error}",
        "title": "Kunne ikke lagre kommentar"
      },
      "label": {
        "item": {
          "tag": "+ keyword"
        }
      },
      "loading": "Laster…",
      "remove": "Fjern",
      "replies": "{replies, plural,  =0 {Ingen svar} =1 {1 svar} other {# svar}}"
    },
    "thread-feed": {
      "activity": "Aktivitet",
      "delete-thread": "Slett tråden",
      "empty": "Ingen kommentarer i tidslinjen",
      "header": {
        "title": "Kommentar"
      },
      "loading": "Laster flere kommentarer…",
      "no-more-content": "Ingen flere kommentarer å vise",
      "sort": {
        "activity": "Nylig aktivitet",
        "chronological": "Kronologisk i tidslinje"
      },
      "thread-state": {
        "error": "Error",
        "error-description": "Sorry, something went wrong with your request."
      },
      "tooltip": {
        "close-thread": "Lukk",
        "more-settings": "Flere valg"
      }
    },
    "thread-label": {
      "add-label": {
        "add-button": "Add label"
      },
      "categories": {
        "placeholder": "Search all labels",
        "title": "{tag}"
      },
      "empty-state": {
        "no-tags": "No tags available"
      }
    },
    "thresholds": {
      "checkbox::enabled": "Aktiver",
      "checkbox::value": "Disse verdiene vil gjelde for alle item i denne visualiseringen.",
      "description": "Terskler kan brukes til å oppdage når verdier er utenfor tillatte grenseverdier.",
      "form": {
        "edit-color-label": "Angi navn",
        "edit-label": "Angi verdi",
        "invalid-label": "Ugyldig verdi, kun numeriske verdier er tillatt.",
        "option-above": "Over",
        "option-below": "Under",
        "options": "Velg et alternativ",
        "tooltip": {
          "hide": "Gjem terskel",
          "remove": "Fjern terskel"
        }
      },
      "values": {
        "date": "Når",
        "label": "Navn",
        "value": "Grense"
      }
    },
    "time-axis": {
      "tooltip": "{state, select,  resizable {Drag to adjust} movable {Drag to move selection} other {Click & drag to select} }"
    },
    "time-range": {
      "end": "Til:",
      "length": "Periode:",
      "start": "Fra:"
    },
    "timeline-canvas": {
      "add-signal": {
        "default": "Legg til item",
        "disabled": "Kun personer med admin/rediger tilgang kan legge til item"
      },
      "add-thread": "Legg til ny kommentar",
      "edit-timeline": {
        "default": "Innstillinger for tidslinje",
        "disabled": "Kun personer med admin/rediger tilgang kan redigere tidslinje"
      },
      "timeline-legend": {
        "open-visualization": "Åpne visualiseringsinnstillinger"
      },
      "timeline-share": "Del",
      "tooltip": {
        "activity": "Tråder",
        "timeline-edit": {
          "default": "Rediger tidslinje",
          "disabled": "Kun personer med admin/rediger tilgang kan redigere item"
        },
        "timeline-export": "Eksporter data"
      },
      "untitled": "Tidslinje uten navn"
    },
    "timeline-card": {
      "archive": "Slett",
      "button": {
        "delete": "Fjern tidslinje",
        "title": "Legg til merkelapp"
      },
      "categories": {
        "category": "Kategori: {title}",
        "search": "Resultat for: {title}",
        "title": "{tag}",
        "tooltip": "{tooltip}"
      },
      "commented": "kommenterte for {date} siden",
      "duplicate": "Dupliser",
      "labels": {
        "placeholder": "Find label…"
      },
      "last-updated": "Sist oppdatert {date, date, short}",
      "replied": "svarte for {date} siden",
      "share": "Del",
      "signals": "{signals, plural, one {item} other {# items}}",
      "timeline-state": {
        "failed": "Kunne ikke laste tidslinjer.",
        "loading": "Laster tidslinjer…",
        "loading-error": "Kunne ikke laste tidslinjer akkurat nå.",
        "no-matches": "Fant ingen tidslinjer som stemmer med søket. <br/> Prøv å fjerne noen søkefilter.",
        "no-timelines": {
          "action": "Create a timeline",
          "description": "Tidslinjer opprettet av deg eller delt med deg vil dukke opp her.",
          "title": "Ingen tidslinjer"
        },
        "retry": "Prøv igjen",
        "searching": "Søker etter tidslinjer…"
      },
      "untitled": "Tidslinje uten navn",
      "view": "Se tidslinje",
      "visualizations": "{visualizations, plural, one {1 visualisering} other {# visualisering}}"
    },
    "timeline-filter": {
      "all-items": "Viser alle item",
      "epilogue": {
        "title": "Ingen item stemmer med søket.  <br /> Prøv å fjerne noen av søkefiltrene."
      },
      "filter-bar": "Find in items",
      "hide": "Skjul filter",
      "results": "Viser {count} resultater",
      "states": {
        "failed": "Kunne ikke laste item",
        "loading": "Laster item…",
        "loading-epilogue": "Laster item"
      },
      "title": "Legg til item"
    },
    "timeline-loading": {
      "title": "Laster tidslinjer…"
    },
    "timeline-permission-popover": {
      "invite-form": {
        "add-button": "Legg til"
      }
    },
    "timeline-settings": {
      "actions": {
        "more": "Mer",
        "share": "Delt valg"
      },
      "created-at": "Opprettet: {date, date, medium}",
      "header": "Rediger Tidslinje",
      "item-actions": {
        "delete": "Slett",
        "hide": "Skjul",
        "info": "Informasjon"
      },
      "last-updated": "Sist oppdatert: {date, date, medium}",
      "more-context-menu": {
        "delete": "Slett tidslinje"
      },
      "settings": "Innstillinger",
      "tooltip": {
        "close-edit": "Lukk",
        "more-settings": "Flere valg"
      },
      "visualization-list": {
        "empty-state": "Ingen innhold ennå"
      }
    },
    "timeline-sharing": {
      "disabled": "Du kan ikke administrere medlemskap for denne tidslinjen",
      "group-members": {
        "title": "Vis medlemmer"
      },
      "header": "Legg til personer eller grupper"
    },
    "timeline-visualization-item-settings": {
      "header": "Item",
      "inline-item": {
        "placeholder": "Angi navn"
      },
      "tooltips": {
        "actions": "Flere handlinger",
        "visabillity": {
          "hide": "Skjul item fra visualisering",
          "show": "Vis signal i visualisering"
        }
      }
    },
    "timeline-visualization-settings": {
      "header": "Visualisering",
      "menu": {
        "display-type": {
          "header": "Velg visningstype"
        }
      },
      "tooltips": {
        "actions": "Flere handlinger"
      }
    },
    "timelines": {
      "all-items": "Viser alle tidslinjer",
      "clear": "Fjern {filter, plural,  =0 {(Ingen treff)} =1 {(1) filter} other {(#) filtre}}",
      "results": "Viser {count} resultater"
    },
    "timelines-index": {
      "activity": "Aktivitet",
      "invite": "Invite",
      "invite-tooltip": "Invite a new user from here",
      "items": "Item",
      "new-timeline": "+ Ny tidslinje",
      "timeline": "Tidslinjer"
    },
    "timelines-permissions": {
      "errors": {
        "add-subject": {
          "dismiss": "Lukk",
          "message": "Kunne ikke legge til dette medlemmet. {error}",
          "title": "FEIL"
        },
        "delete-access-member-alert": {
          "cancel": "Avbryt",
          "dismiss": "Lukk",
          "failed": {
            "message": "Kunne ikke fjerne medlemmet. {error}",
            "title": "Feil"
          },
          "message": "Vil du virkelig fjerne dette medlemmet?",
          "prevent": {
            "message": "Krever minst én admin for å utføre denne handlingen",
            "title": "Du kan ikke utføre denne handlingen"
          },
          "remove": "Fjern",
          "title": "Fjern medlem"
        },
        "modify-access-member-alert": {
          "dismiss": "Lukk",
          "failed": {
            "message": "Kunne ikke lagre endringer i tilgang. {error}",
            "title": "Feil"
          },
          "prevent": {
            "message": "Du har ikke tilgang til å utføre denne handlingen.",
            "title": "Du kan ikke utføre denne handlingen"
          }
        }
      }
    },
    "timelines-show-error": {
      "button": "Oppdater",
      "title": "Vi får ikke kontakt med Clarify for øyeblikket."
    },
    "timelines-show-loading": {
      "title": "Laster tidslinje"
    },
    "user-edit-group-memberships": {
      "add": {
        "description": "Legg til i gruppe",
        "title": "+ Legg til"
      },
      "browser-title": "Rediger medlemskap – {name}",
      "empty-state": "Ingen resultater for ditt søk",
      "header": "Rediger gruppemedlemskap",
      "input-placeholder": "Søk etter navn...",
      "remove": {
        "description": "Fjern fra gruppe",
        "title": "Fjern"
      }
    },
    "users-index": {
      "browser-title": "Brukere",
      "data-grid-descriptors": {
        "email": "E-post",
        "groups": "Grupper",
        "name": "Navn",
        "role": {
          "options": {
            "admin": "Administrator",
            "member": "Medlem"
          },
          "title": "Rolle"
        }
      },
      "header": {
        "invite-button": "Inviter en ny bruker",
        "title": "Brukere"
      },
      "states": {
        "error": "Lasting feilet...",
        "loading": "Laster brukere"
      },
      "toolbar": {
        "delete": {
          "tooltip": "{disabled, select, true {{selectedModelsCount, plural, =1 {Du kan ikke slette deg selv} other {Disse brukerene kan ikke slettes}}} false {{count, plural, =1 {Slett bruker} other {Slett brukere}}} other{}}"
        }
      }
    },
    "users-show": {
      "alerts": {
        "destroy": {
          "message": "Kunne ikke fjerne bruker fra organisasjon. Prøv igjen eller kontakt support om problemet oppstår igjen",
          "primary-button": "Lukk",
          "title": "Å nei!"
        }
      },
      "browser-title": "{pane, select, about {Detaljer} groups {Grupper} other {Ukjent}} — {name}",
      "fieldsets": {
        "createdAt": {
          "label": "Lagt til"
        },
        "email": {
          "label": "E-post",
          "placeholder": "E-post"
        },
        "header": {
          "title": "Navn & Beskrivelse",
          "tooltip": "Navn & Beskrivelse"
        },
        "name": {
          "label": "Navn",
          "placeholder": "Navn"
        },
        "role": {
          "label": "Rolle",
          "options": {
            "admin": "Administrator",
            "member": "Medlem"
          },
          "placeholder": "Rolle"
        }
      },
      "groups-tab": {
        "add-to-group-button": "+ Legg til i grupper",
        "groups-list": {
          "header": "Gruppemedlemskap",
          "remove-button": "Fjern fra gruppe"
        },
        "remove-user-from-group-alert": {
          "dismiss": "Lukk",
          "message": "Beklager, noe gikk galt.",
          "title": "Å nei!"
        }
      },
      "header": {
        "actions": {
          "deletable": "{disabled, select, true {Slett bruker} false {Du kan ikke slette deg selv} other{}}",
          "remove": "Fjern bruker fra organisasjon"
        },
        "tabs": {
          "about": "Detaljer",
          "groups": "Grupper"
        }
      },
      "states": {
        "loading": "Laster brukere"
      }
    },
    "visualization-comments": {
      "uploaded-file": "Lastet opp en fil"
    },
    "visualization-header": {
      "default-name": "Graf",
      "visualization-settings": {
        "tooltip": "Åpne innstillinger for visualisering",
        "tooltip-cannot-access": "Du kan ikke redigere innstillingene for denne tidslinjen"
      }
    },
    "visualization-legend-item": {
      "inaccesible": {
        "title": "Skjult Signal"
      }
    },
    "visualization-legend-item-aggregation": {
      "aggregations-full": {
        "average": "Gjennomsnitt",
        "maximum": "Maksimum",
        "minimum": "Minimum",
        "sum": "Sum"
      },
      "aggregations-short": {
        "average": "Avg.",
        "maximum": "Max.",
        "minimum": "Min.",
        "sum": "Sum"
      }
    },
    "visualization-settings": {
      "fieldsets": {
        "item-info": "Info",
        "line-chart-axis-scaling": "Skalering",
        "line-chart-display-type": "Visning",
        "line-chart-thresholds": "Terskler"
      },
      "header": "Visualisering",
      "inaccessible": {
        "title": "Skjult signal"
      },
      "tooltip": {
        "collapse": "Vis mindre",
        "delete": "Fjern item fra visualisering",
        "drag-item": "Dra og slipp signal for å ordne liste",
        "expand": "Vis mer",
        "hide": "Skjul item fra visualisering",
        "link-to-visualization": "Go to item",
        "open-settings": "Åpne innstillinger",
        "select-color": "Endre farge",
        "show": "Vis signal"
      },
      "types": {
        "line-chart": "Linjediagram",
        "status": "Status"
      },
      "untitled": "Uten navn"
    }
  }]];
  _exports.default = _default;
});