define("admin/helpers/alert-signals-delete", ["exports", "@ember/object", "clarify/helpers/alert"], function (_exports, _object, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AlertSignalDelete = (_class = class AlertSignalDelete extends _alert.default {
    compute([signals], hash) {
      return event => {
        let names = signals.map(signal => (0, _object.get)(signal, 'name')).join(', ');
        this.showAlert({
          title: this.intl.t('alert-signals-delete.initial.title', {
            count: signals.length,
            name: names
          }),
          message: this.intl.t('alert-signals-delete.initial.message', {
            count: signals.length,
            htmlSafe: true
          }),
          primaryButton: {
            title: this.intl.t('alert-signal-delete.initial.primary-button'),
            action: () => {
              this.showExtraAlert(signals.length, hash.task);
            },
            style: 'destructive'
          }
        }, event === null || event === void 0 ? void 0 : event.currentTarget);
      };
    }

    showExtraAlert(integrationCount, deleteSignalTask) {
      this.showAlert({
        title: this.intl.t('alert-signals-delete.confirmation.title'),
        message: this.intl.t('alert-signals-delete.confirmation.message', {
          count: integrationCount,
          htmlSafe: true
        }),
        primaryButton: {
          title: this.intl.t('alert-signals-delete.confirmation.primary-button', {
            count: integrationCount
          }),
          task: deleteSignalTask,
          style: 'destructive'
        }
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "showExtraAlert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showExtraAlert"), _class.prototype)), _class);
  _exports.default = AlertSignalDelete;
});