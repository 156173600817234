define("draggable/modifiers/drag-source", ["exports", "ember-modifier", "@ember/service", "@ember/object", "draggable/helpers/canvas"], function (_exports, _emberModifier, _service, _object, _canvas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DragSourceOnModifier = (_dec = (0, _service.inject)('drag-coordinator'), (_class = class DragSourceOnModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dragCoordinator", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);
    }

    didInstall() {
      this.element.setAttribute('draggable', 'true');
      this.element.addEventListener('dragstart', this.dragStart, true);
      this.element.addEventListener('dragend', this.dragEnd, true);
    }

    dragStart(event) {
      if (event.dataTransfer) {
        let provider = this.dragCoordinator.contextProviderFor(this.args.named.contextProvider);
        let context = provider.serialize([this.args.named.model]);
        event.dataTransfer.setData('clarify/provider', this.args.named.contextProvider);
        event.dataTransfer.setData(`clarify/${this.args.named.contextProvider}`, JSON.stringify(context.data));
        event.dataTransfer.setData('Text', context.title);

        if (context.URL) {
          event.dataTransfer.setData('URL', context.URL);
        }

        let dragImageType = this.args.named.dragImage;

        if (dragImageType) {
          let provider = this.dragCoordinator.dragImageProviderFor(dragImageType);
          let dragImage = provider.draw(_canvas.canvasConstructor, [this.args.named.model], event);

          if (dragImage) {
            let canvas = dragImage.canvas;
            document.body.appendChild(canvas);
            event.dataTransfer.setDragImage(canvas, dragImage.x, dragImage.y);
            setTimeout(() => {
              document.body.removeChild(canvas);
            });
          }
        }

        setTimeout(() => {
          this.element.setAttribute('data-dragging', 'true');
        });
      }
    }

    dragEnd(_event) {
      this.element.removeAttribute('data-dragging');
    }

    removeEventListener() {
      this.element.removeEventListener('dragstart', this.dragStart, true);
      this.element.removeEventListener('dragend', this.dragEnd, true);
    }

    willRemove() {
      this.removeEventListener();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dragCoordinator", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dragStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnd"), _class.prototype)), _class));
  _exports.default = DragSourceOnModifier;
});