define("admin/templates/admin/integrations/signals/publish", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yOh9Pbfu",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"signal-publish.browser-title\"],[[\"name\"],[[30,0,[\"signal\",\"name\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@signal\",\"@itemCategories\",\"@signalCategories\",\"@organization\",\"@itemKeywords\"],[[30,0,[\"signal\"]],[30,0,[\"itemCategories\"]],[30,0,[\"signalCategories\"]],[30,0,[\"organization\"]],[30,0,[\"itemKeywords\"]]]],null]],[],false,[\"page-title\",\"t\",\"modals/signal-publish\"]]",
    "moduleName": "admin/templates/admin/integrations/signals/publish.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});