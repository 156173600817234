define("account/templates/account/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "z9jR/w9F",
    "block": "[[[1,\"from the account engine!:\"],[1,[30,0,[\"model\",\"user\",\"name\"]]],[1,[30,0,[\"model\",\"organization\",\"name\"]]]],[],false,[]]",
    "moduleName": "account/templates/account/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});