define("admin/components/metadata/fieldsets/labels/-add-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "clarify/utilities/compact-map"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _object, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.autoCompleteVisible}}
    <div class="labels-container--tag">
      <AutoComplete
        type="text"
        class="m-card-timeline-action-tag-search"
        placeholder={{t "labels.add-button.placeholder"}}
        @items={{this.sections}}
        @filter={{fn this.filter}}
        @selectItem={{fn @insert}}
        @visibilityDidChange={{fn this.visibilityDidChange}}
        {{set-focus this.autoCompleteVisible}} as |auto|
      >
        {{#if (eq auto.items.length 0)}}
          <div class="tag-list-container">
            <EmptyState
              @style="normal"
              @textAlign="center"
              @boxAlign="center"
              @text={{t "labels.add-button.empty-state"}}
            />
          </div>
        {{else}}
          <KeywordLabelDropdown
            @query={{auto.query}}
            @sections={{auto.items}}
            @isSelected={{fn auto.isSelected}}
            @insertLabel={{fn auto.selectItem}}
          />
        {{/if}}
      </AutoComplete>
    </div>
  {{else}}
    <ATag::Button
      id={{@addButtonId}}
      role="listitem"
      class="labels-container--tag"
      disabled={{@disabled}}
      title={{t "labels.add-button.button.title" disabled=@disabled}}
      @icon={{svg-jar "#design-system-union" width=18 height=18}}
      {{on "click" (fn this.focusInput)}}
    />
  {{/if}}
  */
  {
    "id": "Nwi6CRFH",
    "block": "[[[41,[30,0,[\"autoCompleteVisible\"]],[[[1,\"  \"],[10,0],[14,0,\"labels-container--tag\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"m-card-timeline-action-tag-search\"],[16,\"placeholder\",[28,[37,2],[\"labels.add-button.placeholder\"],null]],[24,4,\"text\"],[4,[38,4],[[30,0,[\"autoCompleteVisible\"]]],null]],[[\"@items\",\"@filter\",\"@selectItem\",\"@visibilityDidChange\"],[[30,0,[\"sections\"]],[28,[37,3],[[30,0,[\"filter\"]]],null],[28,[37,3],[[30,1]],null],[28,[37,3],[[30,0,[\"visibilityDidChange\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,5],[[30,2,[\"items\",\"length\"]],0],null],[[[1,\"        \"],[10,0],[14,0,\"tag-list-container\"],[12],[1,\"\\n          \"],[8,[39,6],null,[[\"@style\",\"@textAlign\",\"@boxAlign\",\"@text\"],[\"normal\",\"center\",\"center\",[28,[37,2],[\"labels.add-button.empty-state\"],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,7],null,[[\"@query\",\"@sections\",\"@isSelected\",\"@insertLabel\"],[[30,2,[\"query\"]],[30,2,[\"items\"]],[28,[37,3],[[30,2,[\"isSelected\"]]],null],[28,[37,3],[[30,2,[\"selectItem\"]]],null]]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,8],[[16,1,[30,3]],[24,\"role\",\"listitem\"],[24,0,\"labels-container--tag\"],[16,\"disabled\",[30,4]],[16,\"title\",[28,[37,2],[\"labels.add-button.button.title\"],[[\"disabled\"],[[30,4]]]]],[4,[38,10],[\"click\",[28,[37,3],[[30,0,[\"focusInput\"]]],null]],null]],[[\"@icon\"],[[28,[37,9],[\"#design-system-union\"],[[\"width\",\"height\"],[18,18]]]]],null],[1,\"\\n\"]],[]]]],[\"@insert\",\"auto\",\"@addButtonId\",\"@disabled\"],false,[\"if\",\"auto-complete\",\"t\",\"fn\",\"set-focus\",\"eq\",\"empty-state\",\"keyword-label-dropdown\",\"a-tag/button\",\"svg-jar\",\"on\"]]",
    "moduleName": "admin/components/metadata/fieldsets/labels/-add-button.hbs",
    "isStrictMode": false
  });

  let AddButton = (_class = class AddButton extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "autoCompleteVisible", _descriptor2, this);
    }

    visibilityDidChange(visibility) {
      this.autoCompleteVisible = visibility;
    }

    focusInput() {
      this.autoCompleteVisible = true;
    }

    get sections() {
      let labels = new Set(this.args.labels);
      let items = this.args.availableLabels.map(label => {
        return {
          checked: labels.has(label),
          model: label,
          title: label,
          type: 'keyword'
        };
      });
      return [{
        title: null,
        id: this.args.category.key,
        items: [...items, {
          type: 'input',
          model: this.args.category,
          placeholder: this.intl.t('labels.label.query.placeholder')
        }]
      }];
    }

    filter(items, query) {
      if (!query) {
        return {
          type: 'visible',
          sections: items
        };
      }

      let lowerCaseQuery = query.toLowerCase();
      let sections = (0, _compactMap.default)(items, section => {
        let items = section.items.filter(item => {
          if (item.type === 'keyword') {
            let name = item.title.toLowerCase();
            return name.indexOf(lowerCaseQuery) !== -1;
          }

          return null;
        });

        if (items.length) {
          return { ...section,
            items
          };
        }

        return null;
      });
      return {
        type: 'visible',
        sections: sections
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "autoCompleteVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "visibilityDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "visibilityDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "filter"), _class.prototype)), _class);
  _exports.default = AddButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddButton);
});