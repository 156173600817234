define("ember-svg-jar/inlined/icon-clock-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.082 15.26l-3.467-3.512V8.926h-1.23v3.326l3.822 3.873.875-.865zM12 5.23A6.776 6.776 0 005.23 12 6.777 6.777 0 0012 18.77 6.778 6.778 0 0018.77 12 6.777 6.777 0 0012 5.23zM12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});