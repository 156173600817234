define("admin/routes/admin/items/keywords/edit", ["exports", "@ember/routing/route", "clarify/errors/route"], function (_exports, _route, _route2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class KeywordEdit extends _route.default {
    async model(params) {
      let {
        organization
      } = this.modelFor('admin');
      let keyword = organization.keywords.find(keyword => {
        if (keyword.type === 'labels') {
          return keyword.key === params.key;
        }

        return false;
      });

      if (!keyword) {
        return Promise.reject(new _route2.default(_route2.RouteErrorType.notFound));
      }

      return {
        organization,
        keyword: keyword
      };
    }

  }

  _exports.default = KeywordEdit;
});