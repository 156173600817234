define("admin/components/integration-credential-downloader/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-concurrency", "clarify/utilities/rpc/integration/get-credentials"], function (_exports, _component, _templateFactory, _component2, _service, _emberConcurrency, _getCredentials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash download=(perform this.downloadCredentials) loading=this.downloadCredentials.isRunning)}}
  */
  {
    "id": "a+txZHdK",
    "block": "[[[18,1,[[28,[37,1],null,[[\"download\",\"loading\"],[[28,[37,2],[[30,0,[\"downloadCredentials\"]]],null],[30,0,[\"downloadCredentials\",\"isRunning\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"perform\"]]",
    "moduleName": "admin/components/integration-credential-downloader/index.hbs",
    "isStrictMode": false
  });

  let IntegrationCredentialDownloader = (_class = class IntegrationCredentialDownloader extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "network", _descriptor, this);
    }

    *downloadCredentials() {
      let request = _getCredentials.GetCredentialsRequestInfo.create({
        integration: this.args.integration.id
      });

      try {
        let result = yield this.network.perform(`meta/rpc`, {}, request);
        let value = JSON.stringify(result, null, 2);
        let blob = new Blob([value], {
          type: 'application/json'
        });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = 'clarify-credentials.json';
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(url);
        }, 1000);
      } catch (error) {
        console.error('IntegrationCredentialDownloader#downloadCredentials failed with error: ', error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "downloadCredentials", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "downloadCredentials"), _class.prototype)), _class);
  _exports.default = IntegrationCredentialDownloader;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IntegrationCredentialDownloader);
});