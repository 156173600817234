define("admin/components/admin-card/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-card">
    <div class="admin-card--header">
      {{yield to="header"}}
    </div>
    <div>
      {{yield to="content"}}
    </div>
  </div>
  */
  {
    "id": "uRcqcPhV",
    "block": "[[[10,0],[14,0,\"admin-card\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-card--header\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&header\",\"&content\"],false,[\"yield\"]]",
    "moduleName": "admin/components/admin-card/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});