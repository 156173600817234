define("admin/routes/admin/integrations/signals/show/index", ["exports", "@ember/routing/route", "@ember/object", "clarify-data-layer/utility/belongs-to"], function (_exports, _route, _object, _belongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IntegrationShowSignalShowRoute extends _route.default {
    async model() {
      let params = this.paramsFor('admin.integrations.signals.show');
      let {
        organization
      } = this.modelFor('admin');
      let {
        integration
      } = this.modelFor('admin.integrations.signals');
      let signal = await this.store.findRecord('urn:signal', params.signalId, {
        adapterOptions: {
          url: `integrations/${integration.id}/signals/${params.signalId}`
        }
      });
      let itemId = (0, _belongsTo.belongsTo)(signal, 'item');
      let item;

      if (itemId) {
        item = await this.store.findRecord('urn:item', itemId);
      }

      return {
        signal,
        item,
        itemKeywords: organization.keywords
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'signal', model.signal);
      (0, _object.set)(controller, 'item', model.item);
      (0, _object.set)(controller, 'itemKeywords', model.itemKeywords);
    }

  }

  _exports.default = IntegrationShowSignalShowRoute;
});