define("admin/modifiers/roving", ["exports", "ember-modifier", "@ember/object", "ui/helpers/normalize-key-codes"], function (_exports, _emberModifier, _object, _normalizeKeyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let RovingModifier = (_class = class RovingModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tabIndex", void 0);
    }

    handleFocus(event) {
      var _this$args$named$poin, _this$args$named;

      (_this$args$named$poin = (_this$args$named = this.args.named).pointerDidChange) === null || _this$args$named$poin === void 0 ? void 0 : _this$args$named$poin.call(_this$args$named, {
        row: this.args.named.row,
        column: this.args.named.column,
        editing: event.currentTarget !== event.target
      });
    }

    handleKeypress(event) {
      let pointerDidChange = this.args.named.pointerDidChange;

      if (!pointerDidChange) {
        return;
      }

      let [pointer] = this.args.positional;
      let key = (0, _normalizeKeyCodes.default)(event);

      if (event.currentTarget !== event.target) {
        switch (key) {
          case _normalizeKeyCodes.Key.escape:
          case _normalizeKeyCodes.Key.f2:
            if (this.args.named.editable) {
              pointerDidChange({ ...pointer,
                editing: false
              });
              event.preventDefault();
            } else {
              return;
            }

            break;
        }

        return;
      }

      switch (key) {
        case _normalizeKeyCodes.Key.enter:
        case _normalizeKeyCodes.Key.f2:
          if (this.args.named.editable) {
            pointerDidChange({ ...pointer,
              editing: true
            });
          } else {
            return;
          }

          break;

        case _normalizeKeyCodes.Key.up:
          pointerDidChange({
            row: pointer.row - 1,
            column: pointer.column,
            editing: false
          });
          break;

        case _normalizeKeyCodes.Key.down:
          pointerDidChange({
            row: pointer.row + 1,
            column: pointer.column,
            editing: false
          });
          break;

        case _normalizeKeyCodes.Key.left:
          pointerDidChange({
            row: pointer.row,
            column: pointer.column - 1,
            editing: false
          });
          break;

        case _normalizeKeyCodes.Key.right:
          pointerDidChange({
            row: pointer.row,
            column: pointer.column + 1,
            editing: false
          });
          break;

        default:
          return;
      }

      event.preventDefault();
    }

    didInstall() {
      if (this.args.named.pointerDidChange) {
        this.element.addEventListener('focusin', this.handleFocus);
        this.element.addEventListener('keydown', this.handleKeypress);
      }
    }

    willDestroy() {
      this.element.removeEventListener('focusin', this.handleFocus);
      this.element.removeEventListener('keydown', this.handleKeypress);
    }

    didReceiveArguments() {
      let tabIndex = '-1';
      let pointer = this.args.positional[0];

      if (pointer) {
        if (pointer.row === this.args.named.row && pointer.column === this.args.named.column) {
          tabIndex = pointer.editing ? '-1' : '0';
        }
      }

      if (this.tabIndex !== tabIndex) {
        this.tabIndex = tabIndex;
        this.element.setAttribute('tabindex', tabIndex);
      }
    }

    didUpdateArguments() {
      let pointer = this.args.positional[0];

      if (pointer && pointer.row === this.args.named.row && pointer.column === this.args.named.column) {
        let activeElement = document.activeElement;

        if (activeElement && this.element.contains(activeElement) && pointer.editing) {
          return;
        }

        this.element.focus();
        return;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleFocus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeypress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeypress"), _class.prototype)), _class);
  _exports.default = RovingModifier;
});