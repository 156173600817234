define("admin/controllers/admin/integrations/index/show/index", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.IntegrationShowPanes = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let IntegrationShowPanes;
  _exports.IntegrationShowPanes = IntegrationShowPanes;

  (function (IntegrationShowPanes) {
    IntegrationShowPanes["about"] = "about";
    IntegrationShowPanes["labels"] = "labels";
    IntegrationShowPanes["credentials"] = "credentials";
  })(IntegrationShowPanes || (_exports.IntegrationShowPanes = IntegrationShowPanes = {}));

  class IntegrationShowController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "pane", IntegrationShowPanes.about);

      _defineProperty(this, "queryParams", ['pane']);

      _defineProperty(this, "integration", void 0);

      _defineProperty(this, "categories", void 0);
    }

  }

  _exports.default = IntegrationShowController;
});