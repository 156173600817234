define("admin/templates/admin/items", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8Je1qE+l",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"items-index.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],[[4,[38,3],[[28,[37,4],[[30,0,[\"updateKeywords\"]]],null],[30,0,[\"admin\",\"organization\",\"keywords\"]]],null]],[[\"@labels\",\"@keywords\",\"@resource\"],[[30,0,[\"labels\"]],[30,0,[\"keywords\"]],[30,0,[\"resource\"]]]],null],[1,\"\\n\"],[8,[39,5],null,null,null]],[],false,[\"page-title\",\"t\",\"routes/items\",\"did-update\",\"perform\",\"admin-outlet\"]]",
    "moduleName": "admin/templates/admin/items.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});