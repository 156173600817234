define("admin/components/dropdown-list/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ol class="labels-dropdown-list" ...attributes>
    {{#each @items as |item index|}}
      {{#let (component (concat "dropdown-list/item/" item.type)) as |Entry|}}
        <Entry @index={{index}} @select={{fn @select}} @highlighted={{eq index @highlightedIndex}} @item={{item}} />
      {{/let}}
    {{else if (has-block "empty-state")}}
      {{yield to="empty-state"}}
    {{/each}}
  </ol>
  */
  {
    "id": "l2ECiVU3",
    "block": "[[[11,\"ol\"],[24,0,\"labels-dropdown-list\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[44,[[50,[28,[37,4],[\"dropdown-list/item/\",[30,3,[\"type\"]]],null],0,null,null]],[[[1,\"      \"],[8,[30,5],null,[[\"@index\",\"@select\",\"@highlighted\",\"@item\"],[[30,4],[28,[37,5],[[30,6]],null],[28,[37,6],[[30,4],[30,7]],null],[30,3]]],null],[1,\"\\n\"]],[5]]]],[3,4]],[[[41,[48,[30,8]],[[[1,\"    \"],[18,8,null],[1,\"\\n  \"]],[]],null]],[]]],[13]],[\"&attrs\",\"@items\",\"item\",\"index\",\"Entry\",\"@select\",\"@highlightedIndex\",\"&empty-state\"],false,[\"each\",\"-track-array\",\"let\",\"component\",\"concat\",\"fn\",\"eq\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "admin/components/dropdown-list/index.hbs",
    "isStrictMode": false
  });

  class DropdownList extends _component2.default {}

  _exports.default = DropdownList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownList);
});