define("draggable/helpers/dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findParentOfElement = findParentOfElement;

  function findParentOfElement(element, child) {
    if (child.parentElement === element) {
      return child;
    }

    if (element.contains(child) === false) {
      return null;
    }

    let el = child;

    while (el !== null && el.nodeType === 1) {
      let parent = el.parentElement;

      if (parent === element) {
        return el;
      }

      el = parent;
    }

    return null;
  }
});