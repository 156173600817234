define("ember-svg-jar/inlined/p-settings-48", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M35.567 24c0 .544-.048 1.056-.112 1.568l3.376 2.64c.304.24.384.672.192 1.024l-3.2 5.536c-.192.352-.608.496-.976.352l-3.984-1.6c-.832.624-1.728 1.168-2.704 1.568l-.608 4.24a.78.78 0 01-.784.672h-6.4a.78.78 0 01-.784-.672l-.608-4.24a11.69 11.69 0 01-2.704-1.568l-3.984 1.6c-.352.128-.784 0-.976-.352l-3.2-5.536a.807.807 0 01.192-1.024l3.376-2.64A12.689 12.689 0 0111.567 24c0-.528.048-1.056.112-1.568l-3.376-2.64a.789.789 0 01-.192-1.024l3.2-5.536c.192-.352.608-.496.976-.352l3.984 1.6c.832-.624 1.728-1.168 2.704-1.568l.608-4.24A.78.78 0 0120.367 8h6.4c.4 0 .736.288.784.672l.608 4.24c.976.4 1.872.928 2.704 1.568l3.984-1.6c.352-.128.784 0 .976.352l3.2 5.536a.807.807 0 01-.192 1.024l-3.376 2.64c.064.512.112 1.024.112 1.568zm-17.6 0c0 3.088 2.512 5.6 5.6 5.6 3.088 0 5.6-2.512 5.6-5.6 0-3.088-2.512-5.6-5.6-5.6a5.606 5.606 0 00-5.6 5.6z\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});