define("admin/routes/admin/users/invitations", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UsersInvitationsRoute extends _route.default {
    async model() {
      let model = this.modelFor('admin');
      return {
        organization: model.organization
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'organization', model.organization);
    }

  }

  _exports.default = UsersInvitationsRoute;
});