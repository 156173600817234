define("admin/routes/admin/organization", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "clarify/utilities/resource"], function (_exports, _route, _service, _object, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrganizationRoute = (_class = class OrganizationRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "currentOrganization", _descriptor2, this);
    }

    async model() {
      let {
        organization
      } = this.modelFor('admin');

      let members = _resource.default.create({
        store: this.store,
        type: 'urn:member',
        limit: 0,
        query: {
          filter: {
            active: true
          }
        },
        adapterOptions: {
          url: `organizations/${organization.id}/members`
        }
      });

      await members.load();

      let integrations = _resource.default.create({
        store: this.store,
        type: 'urn:integration',
        limit: 0,
        query: {
          filter: {
            organization: organization.id
          },
          sort: '-createdAt'
        }
      });

      await integrations.load();

      let items = _resource.default.create({
        store: this.store,
        type: 'urn:item',
        limit: 0,
        query: {
          filter: {
            organization: organization.id
          },
          sort: '-createdAt'
        }
      });

      await items.load();
      let subscriptions = await this.store.query('urn:subscription', {
        limit: 1,
        sort: '-createdAt'
      }, {
        adapterOptions: {
          url: `organizations/${organization.id}/subscriptions`
        }
      });
      return {
        organization,
        members,
        integrations,
        items,
        subscription: subscriptions.toArray()[0]
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'organization', model.organization);
      (0, _object.set)(controller, 'members', model.members);
      (0, _object.set)(controller, 'integrations', model.integrations);
      (0, _object.set)(controller, 'items', model.items);
      (0, _object.set)(controller, 'subscription', model.subscription);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentOrganization", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = OrganizationRoute;
});