define("admin/components/data-grid/filters/clear-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button ...attributes title={{t "data-grid.filters.clear"}} class="data-grid-filter-column--clear-button" type="button">
    {{svg-jar "#icon-cross-16" width=16 height=16}}
  </button>
  */
  {
    "id": "kGjq5rub",
    "block": "[[[11,\"button\"],[17,1],[16,\"title\",[28,[37,0],[\"data-grid.filters.clear\"],null]],[24,0,\"data-grid-filter-column--clear-button\"],[24,4,\"button\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"#icon-cross-16\"],[[\"width\",\"height\"],[16,16]]]],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"t\",\"svg-jar\"]]",
    "moduleName": "admin/components/data-grid/filters/clear-button.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});