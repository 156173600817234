define("admin/components/metadata/fieldsets/enum-values-readonly", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Fieldset>
    <:label>
    <Label @title={{@label}} @tooltip={{@tooltip}} />
    </:label>
    <:input>
      <div class="enum-fields--editable">
        {{#each @enumValues as |enumValue|}}
          <p class="enum-fields--editable-text">
            {{enumValue.key}}: {{enumValue.value}}
          </p>
        {{/each}}
      </div>
    </:input>
  </Fieldset>
  */
  {
    "id": "zAcC2PPg",
    "block": "[[[8,[39,0],null,null,[[\"label\",\"input\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@title\",\"@tooltip\"],[[30,1],[30,2]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[10,0],[14,0,\"enum-fields--editable\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3]],null]],null],null,[[[1,\"        \"],[10,2],[14,0,\"enum-fields--editable-text\"],[12],[1,\"\\n          \"],[1,[30,4,[\"key\"]]],[1,\": \"],[1,[30,4,[\"value\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@label\",\"@tooltip\",\"@enumValues\",\"enumValue\"],false,[\"fieldset\",\"label\",\"each\",\"-track-array\"]]",
    "moduleName": "admin/components/metadata/fieldsets/enum-values-readonly.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});