define("admin/components/autocomplete-labels-category/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/internals", "clarify/utilities/compact-map"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _internals, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="labels-add-category">
    {{#if this.autoCompleteVisible}}
      <AutoComplete
        type="text"
        class="labels-add-category--input"
        placeholder={{t "labels.add-category.placeholder"}}
        @items={{this.sections}}
        @filter={{fn this.filter}}
        @selectItem={{fn @insert}}
        @visibilityDidChange={{fn this.visibilityDidChange}}
        {{set-focus this.autoCompleteVisible}}
        as |auto|
      >
        <DropdownList
          class="labels-add-category--dropdown-list"
          @select={{fn this.insert}}
          @highlightedIndex={{auto.highlightedIndex.row}}
          @items={{auto.items.0.items}}
        >
          <:empty-state>
            <EmptyState
              class="labels-add-category--empty-state"
              @style="normal"
              @text={{t "labels.add-category.empty-state"}}
            />
          </:empty-state>
        </DropdownList>
      </AutoComplete>
    {{else}}
      <TextButton @text={{t "labels.add-category.add-button"}} {{on "click" (fn this.focusInput)}} />
    {{/if}}
  </div>
  */
  {
    "id": "UND2N5MF",
    "block": "[[[10,0],[14,0,\"labels-add-category\"],[12],[1,\"\\n\"],[41,[30,0,[\"autoCompleteVisible\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"labels-add-category--input\"],[16,\"placeholder\",[28,[37,2],[\"labels.add-category.placeholder\"],null]],[24,4,\"text\"],[4,[38,4],[[30,0,[\"autoCompleteVisible\"]]],null]],[[\"@items\",\"@filter\",\"@selectItem\",\"@visibilityDidChange\"],[[30,0,[\"sections\"]],[28,[37,3],[[30,0,[\"filter\"]]],null],[28,[37,3],[[30,1]],null],[28,[37,3],[[30,0,[\"visibilityDidChange\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],[[24,0,\"labels-add-category--dropdown-list\"]],[[\"@select\",\"@highlightedIndex\",\"@items\"],[[28,[37,3],[[30,0,[\"insert\"]]],null],[30,2,[\"highlightedIndex\",\"row\"]],[30,2,[\"items\",\"0\",\"items\"]]]],[[\"empty-state\"],[[[[1,\"\\n          \"],[8,[39,6],[[24,0,\"labels-add-category--empty-state\"]],[[\"@style\",\"@text\"],[\"normal\",[28,[37,2],[\"labels.add-category.empty-state\"],null]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,7],[[4,[38,8],[\"click\",[28,[37,3],[[30,0,[\"focusInput\"]]],null]],null]],[[\"@text\"],[[28,[37,2],[\"labels.add-category.add-button\"],null]]],null],[1,\"\\n\"]],[]]],[13]],[\"@insert\",\"auto\"],false,[\"if\",\"auto-complete\",\"t\",\"fn\",\"set-focus\",\"dropdown-list\",\"empty-state\",\"text-button\",\"on\"]]",
    "moduleName": "admin/components/autocomplete-labels-category/index.hbs",
    "isStrictMode": false
  });

  let AddCategory = (_class = class AddCategory extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "autoCompleteVisible", _descriptor, this);
    }

    visibilityDidChange(visibility) {
      this.autoCompleteVisible = visibility;
    }

    focusInput() {
      this.autoCompleteVisible = true;
    }

    insert(dropdownItem, index, event) {
      this.autoCompleteVisible = false;
      this.args.insert(dropdownItem, index, event);
    }

    get sections() {
      let categories = new Set(this.args.categories);
      let items = (0, _compactMap.default)(this.args.availableCategories, label => {
        if (categories.has(label.key)) {
          return null;
        }

        return {
          subtitle: '',
          accessory: '',
          value: label.key,
          title: label.key,
          type: 'default'
        };
      });
      return [{
        title: null,
        id: (0, _internals.guidFor)(this),
        items: [{
          subtitle: '',
          accessory: '',
          value: null,
          title: '',
          type: 'query'
        }, ...items]
      }];
    }

    filter(items, query) {
      if (!query) {
        return {
          type: 'visible',
          sections: items
        };
      }

      let lowerCaseQuery = query.toLowerCase();
      let sections = (0, _compactMap.default)(items, section => {
        let items = section.items.filter(item => {
          if (item.type !== 'query') {
            let name = item.title.toLowerCase();
            return name.indexOf(lowerCaseQuery) !== -1;
          }

          return null;
        });

        if (items.length) {
          return { ...section,
            items
          };
        }

        return null;
      });
      return {
        type: 'visible',
        sections: sections
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "autoCompleteVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "visibilityDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "visibilityDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "filter"), _class.prototype)), _class);
  _exports.default = AddCategory;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddCategory);
});