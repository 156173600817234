define("admin/templates/admin/users/show/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3AXEK/9w",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"users-show.browser-title\"],[[\"pane\",\"name\"],[[30,0,[\"pane\"]],[30,0,[\"member\",\"name\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@member\",\"@pane\"],[[30,0,[\"member\"]],[30,0,[\"pane\"]]]],null]],[],false,[\"page-title\",\"t\",\"routes/users/show/index\"]]",
    "moduleName": "admin/templates/admin/users/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});