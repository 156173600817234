define("admin/routes/admin/items/keywords/new", ["exports", "@ember/routing/route", "clarify/utilities/rpc/label-item-labels", "clarify/utilities/uuid", "@ember/service"], function (_exports, _route, _labelItemLabels, _uuid, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let KeywordNew = (_class = class KeywordNew extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "network", _descriptor, this);
    }

    async model() {
      let {
        organization
      } = this.modelFor('admin');

      let request = _labelItemLabels.LabelItemLablesRequestInfo.create((0, _uuid.default)(), {
        organization: organization.id,
        noValues: false
      });

      let [itemCategories] = await this.network.rpc('meta/rpc', {}, request);

      if (itemCategories.type === 'error') {
        return Promise.reject(itemCategories.error);
      }

      return {
        itemCategories: itemCategories.result,
        organization: organization
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = KeywordNew;
});