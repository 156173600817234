define("canvas/components/canvas", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <canvas
    width={{this.width}}
    height={{this.height}}
    style={{this.style}}
    ...attributes
    {{did-update (fn this.render) this.tag @devicePixelRatio}}
    {{did-insert (fn this.setCanvas)}}
    {{will-destroy (fn this.setCanvas)}}
  ></canvas>
  {{#if this.context}}
    {{yield
      (hash
        context=this.context
        setNeedsRender=(fn this.setNeedsRender)
        registerLayer=(fn this.registerLayer)
        removeLayer=(fn this.removeLayer)
        width=@width
        height=@height
      )
    }}
  {{/if}}
  */
  {
    "id": "TMzRTuvf",
    "block": "[[[11,\"canvas\"],[16,\"width\",[30,0,[\"width\"]]],[16,\"height\",[30,0,[\"height\"]]],[16,5,[30,0,[\"style\"]]],[17,1],[4,[38,0],[[28,[37,1],[[30,0,[\"render\"]]],null],[30,0,[\"tag\"]],[30,2]],null],[4,[38,2],[[28,[37,1],[[30,0,[\"setCanvas\"]]],null]],null],[4,[38,3],[[28,[37,1],[[30,0,[\"setCanvas\"]]],null]],null],[12],[13],[1,\"\\n\"],[41,[30,0,[\"context\"]],[[[1,\"  \"],[18,5,[[28,[37,6],null,[[\"context\",\"setNeedsRender\",\"registerLayer\",\"removeLayer\",\"width\",\"height\"],[[30,0,[\"context\"]],[28,[37,1],[[30,0,[\"setNeedsRender\"]]],null],[28,[37,1],[[30,0,[\"registerLayer\"]]],null],[28,[37,1],[[30,0,[\"removeLayer\"]]],null],[30,3],[30,4]]]]]],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@devicePixelRatio\",\"@width\",\"@height\",\"&default\"],false,[\"did-update\",\"fn\",\"did-insert\",\"will-destroy\",\"if\",\"yield\",\"hash\"]]",
    "moduleName": "canvas/components/canvas.hbs",
    "isStrictMode": false
  });

  let Canvas = (_class = class Canvas extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "layers", []);

      _defineProperty(this, "canvas", null);

      _initializerDefineProperty(this, "tag", _descriptor, this);

      _initializerDefineProperty(this, "context", _descriptor2, this);
    }

    get width() {
      return this.args.width * this.args.devicePixelRatio;
    }

    get height() {
      return this.args.height * this.args.devicePixelRatio;
    }

    get style() {
      return (0, _template.htmlSafe)(`width: ${this.args.width}px; height: ${this.args.height}px`);
    }

    registerLayer(layer) {
      this.layers.unshift(layer);
    }

    removeLayer(layer) {
      this.layers = this.layers.filter(storedLayer => storedLayer !== layer);
    }

    setCanvas(target) {
      this.canvas = target;

      if (target) {
        this.context = target.getContext('2d', {
          alpha: false
        });
      }
    }

    setNeedsRender() {
      this.tag = this.tag + 1;
    }

    render() {
      let context = this.context;

      if (context) {
        context.setTransform(this.args.devicePixelRatio, 0, 0, this.args.devicePixelRatio, 0, 0);

        for (let layer of this.layers) {
          layer.render();
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tag", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "context", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "registerLayer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerLayer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeLayer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeLayer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCanvas", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setCanvas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setNeedsRender", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setNeedsRender"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "render", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "render"), _class.prototype)), _class);
  _exports.default = Canvas;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Canvas);
});