define("draggable/services/drag-coordinator", ["exports", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DragCoordinator extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "types", new Array());

      _defineProperty(this, "count", 0);
    }

    init() {
      super.init();
      document.addEventListener('dragenter', event => {
        if (this.count === 0) {
          if (event.dataTransfer) {
            (0, _object.set)(this, 'types', [...event.dataTransfer.types]);
          }
        }

        this.count += 1;
      }, true);
      document.addEventListener('dragleave', () => {
        this.count -= 1;

        if (this.count === 0) {
          (0, _object.set)(this, 'types', []);
        }
      }, true);
      document.addEventListener('dragend', () => {
        this.count = 0;
        (0, _object.set)(this, 'types', []);
      }, true);
    }

    contextProviderFor(type) {
      const owner = (0, _application.getOwner)(this);
      return owner.lookup(`context-provider:${type}`);
    }

    dragImageProviderFor(type) {
      const owner = (0, _application.getOwner)(this);
      return owner.lookup(`drag-image:${type}`);
    }

  }

  _exports.default = DragCoordinator;
});