define("clarify-data-layer/record-payload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RecordPayload {
    constructor(payload, model) {
      _defineProperty(this, "included", []);

      _defineProperty(this, "relationships", {});

      _defineProperty(this, "payload", void 0);

      this.model = model;
      this.payload = { ...payload
      };
    }

    extractRelationship(key, model, serializer, modelClass) {
      let attributes = this.payload[key];

      if (attributes && typeof attributes === 'object') {
        this.relationships[key] = {
          data: {
            id: attributes.id,
            type: model
          }
        };
        let {
          data
        } = serializer.normalize(modelClass, attributes);
        this.included.push(data);
        this.payload[key] = undefined;
      } else if (typeof attributes === 'string') {
        this.relationships[key] = {
          data: {
            id: attributes,
            type: model
          }
        };
        this.payload[key] = undefined;
      }
    }

    extractRelationshipsForEntries(key, model) {
      let payload = this.payload[key];
      let entry = {};

      for (let [key, attributes] of Object.entries(payload)) {
        this.included.push({
          type: model,
          id: attributes.id,
          attributes: { ...attributes,
            id: undefined
          }
        });
        entry[key] = attributes.id;
      }

      this.payload[key] = entry;
    }

    extractRelationshipsForArray(key, model) {
      let payload = this.payload[key];
      let entry = [];

      for (let attributes of payload) {
        this.included.push({
          type: model,
          id: attributes.id,
          attributes: { ...attributes,
            id: undefined
          }
        });
        entry.push(attributes.id);
      }

      this.payload[key] = entry;
    }

    jsonApiPayload() {
      return {
        data: {
          id: this.payload.id,
          type: this.model,
          relationships: { ...this.relationships
          },
          attributes: { ...this.payload,
            id: undefined
          }
        },
        included: [...this.included]
      };
    }

  }

  _exports.default = RecordPayload;
});