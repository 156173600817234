define("admin/components/dropdown-list/item/query", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "form/fields/-form", "form/fields/input"], function (_exports, _component, _templateFactory, _component2, _object, _form, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="labels-dropdown-list-item-query" data-highlighted={{@highlighted}} ...attributes>
    <form {{on "submit" (fn this.handleSubmit)}}>
      <input
        class="labels-dropdown-list-item-query--input"
        maxlength="50"
        placeholder={{t "labels.add-category.dropdown.placeholder"}}
        {{setup-field this.form.fields.query validateOn="input"}}
      />
    </form>
    {{#if (and this.form.fields.query.value.length this.form.fields.query.isInvalid)}}
      <p class="labels-dropdown-list-item-query--error">
        {{t "labels.add-category.validation-error"}}
      </p>
    {{/if}}
  </li>
  */
  {
    "id": "xrPbRBf2",
    "block": "[[[11,\"li\"],[24,0,\"labels-dropdown-list-item-query\"],[16,\"data-highlighted\",[30,1]],[17,2],[12],[1,\"\\n  \"],[11,\"form\"],[4,[38,0],[\"submit\",[28,[37,1],[[30,0,[\"handleSubmit\"]]],null]],null],[12],[1,\"\\n    \"],[11,\"input\"],[24,0,\"labels-dropdown-list-item-query--input\"],[24,\"maxlength\",\"50\"],[16,\"placeholder\",[28,[37,2],[\"labels.add-category.dropdown.placeholder\"],null]],[4,[38,3],[[30,0,[\"form\",\"fields\",\"query\"]]],[[\"validateOn\"],[\"input\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"form\",\"fields\",\"query\",\"value\",\"length\"]],[30,0,[\"form\",\"fields\",\"query\",\"isInvalid\"]]],null],[[[1,\"    \"],[10,2],[14,0,\"labels-dropdown-list-item-query--error\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"labels.add-category.validation-error\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@highlighted\",\"&attrs\"],false,[\"on\",\"fn\",\"t\",\"setup-field\",\"if\",\"and\"]]",
    "moduleName": "admin/components/dropdown-list/item/query.hbs",
    "isStrictMode": false
  });

  const emailRegex = /^[a-zA-Z0-9_/-]{1,40}$/;

  let QueryForm = _form.default.define({
    query: _input.InputField.required([value => {
      if (value) {
        return emailRegex.test(value) ? {
          type: 'valid'
        } : {
          type: 'invalid'
        };
      }

      return {
        type: 'valid'
      };
    }])
  });

  let Query = (_class = class Query extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "form", new QueryForm());

      this.form.populate({
        query: ''
      });
    }

    handleSubmit(event) {
      event.preventDefault();
      let result = this.form.serialize();

      if (result.type === 'success') {
        this.args.select({ ...this.args.item,
          value: result.value.query
        }, this.args.index, event);
        this.form.populate({
          query: ''
        });
        event.currentTarget.value = '';
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype)), _class);
  _exports.default = Query;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Query);
});