define("admin/helpers/alert-groups-delete", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AlertGroupDelete extends _alert.default {
    compute([groups], hash) {
      return event => {
        let names = groups.map(group => group.name).join(', ');
        this.showAlert({
          title: this.intl.t('alert-groups-delete.title', {
            count: groups.length,
            name: names
          }),
          message: this.intl.t('alert-groups-delete.message', {
            count: groups.length,
            htmlSafe: true
          }),
          primaryButton: {
            title: this.intl.t('alert-groups-delete.primary-button'),
            task: hash.task,
            style: 'destructive'
          }
        }, event === null || event === void 0 ? void 0 : event.currentTarget);
      };
    }

  }

  _exports.default = AlertGroupDelete;
});