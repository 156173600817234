define("gesture-recognizer/gestures/tap", ["exports", "gesture-recognizer/gestures/-base", "@ember/object", "gesture-recognizer/gestures/pan"], function (_exports, _base, _object, _pan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Tap extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "start", null);

      _defineProperty(this, "event", null);

      _defineProperty(this, "threshold", 10);
    }

    pointerDown(event) {
      this.start = (0, _pan.pointForEvent)(event, this.element);
    }

    pointerUp(event) {
      if (this.validateMouseEvent(event)) {
        (0, _object.set)(this, 'event', event);
        (0, _object.set)(this, 'state', _base.GestureRecognizerState.ended);
      } else {
        (0, _object.set)(this, 'state', _base.GestureRecognizerState.failed);
      }
    }

    reset() {
      this.start = null;
      this.event = null;
    }

    pointerMove(event) {
      if (this.validateMouseEvent(event) === false) {
        (0, _object.set)(this, 'state', _base.GestureRecognizerState.failed);
      }
    }

    validateMouseEvent(event) {
      if (this.start == null) {
        return false;
      }

      let change = (0, _pan.pointForEvent)(event, this.element);

      if (Math.abs(this.start.x - change.x) > this.threshold || Math.abs(this.start.y - change.y) > this.threshold) {
        return false;
      }

      return true;
    }

  }

  _exports.default = Tap;
});