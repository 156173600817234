define("admin/helpers/get-labels", ["exports", "@ember/component/helper", "clarify/utilities/compact-map", "clarify/utilities/flat-map"], function (_exports, _helper, _compactMap, _flatMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(([item], hash) => {
    let organization = item.organization;
    let keywords = organization.keywords;
    let itemKeywords = (0, _compactMap.default)(keywords, keyword => {
      if (keyword.type === 'labels' && keyword.items) {
        return keyword;
      }

      return null;
    });
    let labels = item.labels;
    return (0, _flatMap.flatMap)(itemKeywords, keyword => {
      return labels[keyword.key] ?? [];
    }).slice(0, hash.limit);
  });

  _exports.default = _default;
});