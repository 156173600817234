define("ember-m3/utils/notify-changes", ["exports", "ember", "@ember/object", "@ember/debug"], function (_exports, _ember, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deferPropertyChange = deferPropertyChange;
  _exports.deferArrayPropertyChange = deferArrayPropertyChange;
  _exports.flushChanges = flushChanges;
  _exports.assertNoChanges = assertNoChanges;
  _exports.notifyPropertyChange = void 0;
  const {
    changeProperties
  } = _ember.default;
  const {
    propertyDidChange
  } = _ember.default;
  let notifyPropertyChange;
  _exports.notifyPropertyChange = notifyPropertyChange;
  const HasNotifyPropertyChange = _object.notifyPropertyChange !== undefined;

  if (HasNotifyPropertyChange) {
    _exports.notifyPropertyChange = notifyPropertyChange = _object.notifyPropertyChange;
  } else {
    _exports.notifyPropertyChange = notifyPropertyChange = propertyDidChange;
  } // Separate array & prop changes for simplicity.  This prevents us from
  // re-issuing the property changes in order, but Ember already triggers array
  // changes eagerly, even within `changeProperties`
  // WeakMap<Store, [obj, startIdx: number, removeCount: number, addCount: number]>


  const StoreToArrayChanges = new WeakMap(); // WeakMap<Store, [obj, property: string]>

  const StoreToPropChanges = new WeakMap();

  function getPropertyChanges(store) {
    if (!StoreToPropChanges.has(store)) {
      StoreToPropChanges.set(store, []);
    }

    return StoreToPropChanges.get(store);
  }

  function getArrayChanges(store) {
    if (!StoreToArrayChanges.has(store)) {
      StoreToArrayChanges.set(store, []);
    }

    return StoreToArrayChanges.get(store);
  }

  function deferPropertyChange(store, obj, key) {
    getPropertyChanges(store).push(obj, key);
  }

  function deferArrayPropertyChange(store, array, start, deleteCount, addCount) {
    if (true
    /* DEBUG */
    ) {
      // don't assert Ember.isArray as that will return true for native arrays
      (true && !(typeof array.arrayContentDidChange === 'function') && (0, _debug.assert)(`deferArrayPropertyChange called on something other than an Ember array; wrap native arrays with Ember.A(array) or enable Array prototype extensions`, typeof array.arrayContentDidChange === 'function'));
    }

    getArrayChanges(store).push(array, start, deleteCount, addCount);
  }

  function flushArrayChanges(store) {
    let changes = StoreToArrayChanges.get(store) || [];
    changeProperties(() => {
      for (let i = 0; i < changes.length; i += 4) {
        let array = changes[i];
        let startIdx = changes[i + 1];
        let removeCount = changes[i + 2];
        let addCount = changes[i + 3];
        array.arrayContentDidChange(startIdx, removeCount, addCount);
      }
    });
    StoreToArrayChanges.set(store, []);
  }

  function flushPropChanges(store) {
    let changes = StoreToPropChanges.get(store) || [];
    changeProperties(() => {
      for (let i = 0; i < changes.length; i += 2) {
        let obj = changes[i];
        let change = changes[i + 1];
        notifyPropertyChange(obj, change);
      }
    });
    StoreToPropChanges.set(store, []);
  }

  function flushChanges(store) {
    changeProperties(() => {
      flushArrayChanges(store);
      flushPropChanges(store);
    });
  }

  function assertNoChanges(store) {
    if (true
    /* DEBUG */
    ) {
      let propChanges = StoreToPropChanges.get(store) || [];
      let changedProps = propChanges.filter((o, i) => i % 2 === 1);
      (true && !(changedProps.length === 0) && (0, _debug.assert)(`There should be no queued changes, but we have: ${changedProps.join(', ')} `, changedProps.length === 0));
      let arrayChanges = StoreToArrayChanges.get(store) || [];
      (true && !(arrayChanges.length === 0) && (0, _debug.assert)(`There should be no queued array changes, but we have: ${arrayChanges.length} `, arrayChanges.length === 0));
    }
  }
});