define("admin/routes/admin/users/edit-group-memberships", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let UserEditGroupMemberships = (_class = class UserEditGroupMemberships extends _route.default {
    async model(params) {
      let {
        groups,
        organization
      } = this.modelFor('admin');
      let member = await this.store.findRecord('urn:member', params.memberId, {
        adapterOptions: {
          url: `organizations/${organization.id}/members/${params.memberId}`
        }
      });
      return {
        member,
        groups: groups
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'groups', model.groups);
      (0, _object.set)(controller, 'member', model.member);
    }

    loading() {
      return false;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class);
  _exports.default = UserEditGroupMemberships;
});