define("admin/components/routes/groups", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "admin/components/data-grid", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _dataGrid, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PageLayout::Complex>
    <:header>
      <PageLayout::Header @icon="#admin-group-48" @headline={{t "groups-index.header.title"}}>
        <:actions>
          <FlatButton::Link
            @route={{route "admin.groups.new"}}
            @text={{t "groups-index.header.create-button"}}
            @size="medium"
            @icon="#design-system-p-plus-thick-24"
          />
        </:actions>
      </PageLayout::Header>
    </:header>
    <:content>
      <DataView
        class="page-layout-complex--data-view"
        @selectedModelsDidChange={{this.selectedModelsDidChange}}
        @resource={{@resource}}
        @descriptors={{this.descriptors}}
        @selectionStyle={{hash type="multiple" route="admin.groups.show"}}
      >
        <:toolbar-actions>
          {{#if this.selectedModels.length}}
            <AButtonTertiary::Group>
              {{#let (eq this.deletableGroups.length 0) as |disabled|}}
                <AButtonTertiary
                  disabled={{disabled}}
                  title={{
                    t
                    "groups-index.toolbar.delete"
                    disabled=disabled
                    count=this.deletableGroups.length
                    selectedModelsCount=this.selectedModels.length
                  }}
                  @size="small"
                  @theme="light"
                  @icon="#icon-remove-trash-16"
                  {{on
                    "click"
                    (alert-groups-delete this.deletableGroups task=(task this.deleteGroups this.deletableGroups))
                  }}
                />
              {{/let}}
            </AButtonTertiary::Group>
          {{/if}}
        </:toolbar-actions>
      </DataView>
    </:content>
  </PageLayout::Complex>
  */
  {
    "id": "jmhmNBfW",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@headline\"],[\"#admin-group-48\",[28,[37,2],[\"groups-index.header.title\"],null]]],[[\"actions\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@route\",\"@text\",\"@size\",\"@icon\"],[[28,[37,4],[\"admin.groups.new\"],null],[28,[37,2],[\"groups-index.header.create-button\"],null],\"medium\",\"#design-system-p-plus-thick-24\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"page-layout-complex--data-view\"]],[[\"@selectedModelsDidChange\",\"@resource\",\"@descriptors\",\"@selectionStyle\"],[[30,0,[\"selectedModelsDidChange\"]],[30,1],[30,0,[\"descriptors\"]],[28,[37,6],null,[[\"type\",\"route\"],[\"multiple\",\"admin.groups.show\"]]]]],[[\"toolbar-actions\"],[[[[1,\"\\n\"],[41,[30,0,[\"selectedModels\",\"length\"]],[[[1,\"          \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,10],[[30,0,[\"deletableGroups\",\"length\"]],0],null]],[[[1,\"              \"],[8,[39,11],[[16,\"disabled\",[30,2]],[16,\"title\",[28,[37,2],[\"groups-index.toolbar.delete\"],[[\"disabled\",\"count\",\"selectedModelsCount\"],[[30,2],[30,0,[\"deletableGroups\",\"length\"]],[30,0,[\"selectedModels\",\"length\"]]]]]],[4,[38,12],[\"click\",[28,[37,13],[[30,0,[\"deletableGroups\"]]],[[\"task\"],[[28,[37,14],[[30,0,[\"deleteGroups\"]],[30,0,[\"deletableGroups\"]]],null]]]]],null]],[[\"@size\",\"@theme\",\"@icon\"],[\"small\",\"light\",\"#icon-remove-trash-16\"]],null],[1,\"\\n\"]],[2]]],[1,\"          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@resource\",\"disabled\"],false,[\"page-layout/complex\",\"page-layout/header\",\"t\",\"flat-button/link\",\"route\",\"data-view\",\"hash\",\"if\",\"a-button-tertiary/group\",\"let\",\"eq\",\"a-button-tertiary\",\"on\",\"alert-groups-delete\",\"task\"]]",
    "moduleName": "admin/components/routes/groups.hbs",
    "isStrictMode": false
  });

  let GroupsRouteComponent = (_class = class GroupsRouteComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "selectedModels", _descriptor2, this);
    }

    *deleteGroups() {
      let deletableGroups = Array.from(this.deletableGroups);
      let promises = deletableGroups.map(group => group.destroyRecord());
      yield (0, _emberConcurrency.all)(promises);

      for (let group of deletableGroups) {
        group.unloadRecord();
      }
    }

    selectedModelsDidChange(selectedModels) {
      this.selectedModels = selectedModels;
    }

    get deletableGroups() {
      return this.selectedModels.filter(group => group.preventDelete === false);
    }

    get descriptors() {
      return [{
        type: 'checkbox',
        visibility: _dataGrid.TableCellColumnVisibility.mandatory
      }, {
        title: this.intl.t('groups-index.data-grid-descriptors.name'),
        keyPath: 'name',
        type: 'string',
        sortable: true,
        filter: {
          type: 'input'
        },
        width: _dataGrid.TableCellWidth.default,
        visibility: _dataGrid.TableCellColumnVisibility.visible
      }, {
        title: this.intl.t('groups-index.data-grid-descriptors.members'),
        keyPath: 'users.length',
        type: 'string',
        width: _dataGrid.TableCellWidth.default,
        sortable: false,
        filter: undefined,
        visibility: _dataGrid.TableCellColumnVisibility.visible
      }, {
        title: this.intl.t('groups-index.data-grid-descriptors.description'),
        keyPath: 'description',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        sortable: false,
        filter: {
          type: 'input'
        },
        visibility: _dataGrid.TableCellColumnVisibility.visible
      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedModels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteGroups", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedModelsDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectedModelsDidChange"), _class.prototype)), _class);
  _exports.default = GroupsRouteComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupsRouteComponent);
});