define("ember-svg-jar/inlined/design-system-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <circle cx=\"42\" cy=\"42\" fill=\"none\" stroke-linecap=\"round\" r=\"40\" stroke-width=\"2\" stroke-dasharray=\"62.83185307179586 62.83185307179586\">\n      <animateTransform attributeName=\"transform\" type=\"rotate\" calcMode=\"linear\" values=\"0 42 42;360 42 42\" keyTimes=\"0;1\" dur=\"1s\" begin=\"0s\" repeatCount=\"indefinite\"/>\n  </circle>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "84",
      "height": "84",
      "viewBox": "0 0 84 84",
      "preserveAspectRatio": "xMidYMid"
    }
  };
  _exports.default = _default;
});