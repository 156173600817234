define("admin/components/admin-side-pane-header/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-side-pane-header">
    <header class="admin-side-pane-header--header">
      {{svg-jar @icon class="admin-side-pane-header--icon" width=36 height=36}}
      <h1 class="admin-side-pane-header--heading">
        {{@text}}
      </h1>
    </header>
  </div>
  */
  {
    "id": "UhLApsx1",
    "block": "[[[10,0],[14,0,\"admin-side-pane-header\"],[12],[1,\"\\n  \"],[10,\"header\"],[14,0,\"admin-side-pane-header--header\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,1]],[[\"class\",\"width\",\"height\"],[\"admin-side-pane-header--icon\",36,36]]]],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"admin-side-pane-header--heading\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@icon\",\"@text\"],false,[\"svg-jar\"]]",
    "moduleName": "admin/components/admin-side-pane-header/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});