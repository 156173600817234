define("ember-m3/query-array", ["exports", "ember-m3/base-record-array"], function (_exports, _baseRecordArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class QueryArray extends _baseRecordArray.default {
    init(options = {}) {
      super.init(options, ...arguments);
      this.isLoaded = true;
      this.isUpdating = false;
      this._updatingPromise = null;
    }

    update() {
      if (this.isUpdating) {
        return this._updatingPromise;
      }

      this.setProperties({
        isLoaded: false,
        isUpdating: true
      });
      this._updatingPromise = this._update().finally(() => {
        this._updatingPromise = null;

        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }

        this.setProperties({
          isLoaded: true,
          isUpdating: false
        });
      });
      return this._updatingPromise;
    }

    _update() {
      if (!this.query) {
        throw new Error(`QueryArray requires a query property`);
      }

      let {
        url,
        params,
        method,
        cacheKey
      } = this.query;
      return this.queryCache.queryURL(url, {
        params,
        method,
        cacheKey
      }, this);
    }

  }

  _exports.default = QueryArray;
});