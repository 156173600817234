define("admin/components/data-grid/column/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "admin/components/data-view"], function (_exports, _component, _templateFactory, _component2, _object, _dataView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div role="presentation" data-size={{@descriptor.width}} class="data-grid-column" ...attributes>
    {{#if @descriptor.sortable}}
      <div role="columnheader" aria-sort={{this.sortOrder}} class="data-grid-column--title">
        <button
          class="data-grid-column--sort-button"
          type="button"
          {{on "click" (fn this.changeSort)}}
          {{roving @pointer row=0 column=@column pointerDidChange=@pointerDidChange}}
        >
          <div class="data-grid-column-sort-button--container">
            {{@descriptor.title}}
            {{svg-jar
              "#chevron-filled-down-16"
              class="data-grid-column--change-sort"
              aria-hidden="true"
              width=16
              height=16
            }}
          </div>
        </button>
      </div>
    {{else}}
      <div
        role="columnheader"
        class="data-grid-column--title"
        {{roving @pointer row=0 column=@column pointerDidChange=@pointerDidChange}}
      >
        {{@descriptor.title}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "vzaO39nF",
    "block": "[[[11,0],[24,\"role\",\"presentation\"],[16,\"data-size\",[30,1,[\"width\"]]],[24,0,\"data-grid-column\"],[17,2],[12],[1,\"\\n\"],[41,[30,1,[\"sortable\"]],[[[1,\"    \"],[10,0],[14,\"role\",\"columnheader\"],[15,\"aria-sort\",[30,0,[\"sortOrder\"]]],[14,0,\"data-grid-column--title\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"data-grid-column--sort-button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"changeSort\"]]],null]],null],[4,[38,3],[[30,3]],[[\"row\",\"column\",\"pointerDidChange\"],[0,[30,4],[30,5]]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"data-grid-column-sort-button--container\"],[12],[1,\"\\n          \"],[1,[30,1,[\"title\"]]],[1,\"\\n          \"],[1,[28,[35,4],[\"#chevron-filled-down-16\"],[[\"class\",\"aria-hidden\",\"width\",\"height\"],[\"data-grid-column--change-sort\",\"true\",16,16]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,0],[24,\"role\",\"columnheader\"],[24,0,\"data-grid-column--title\"],[4,[38,3],[[30,3]],[[\"row\",\"column\",\"pointerDidChange\"],[0,[30,4],[30,5]]]],[12],[1,\"\\n      \"],[1,[30,1,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@descriptor\",\"&attrs\",\"@pointer\",\"@column\",\"@pointerDidChange\"],false,[\"if\",\"on\",\"fn\",\"roving\",\"svg-jar\"]]",
    "moduleName": "admin/components/data-grid/column/index.hbs",
    "isStrictMode": false
  });

  let DataGridItemComponent = (_class = class DataGridItemComponent extends _component2.default {
    get isActive() {
      return this.args.sort && this.args.sort.keyPath === this.args.descriptor.keyPath;
    }

    get sortOrder() {
      if (this.isActive && this.args.sort) {
        switch (this.args.sort.order) {
          case _dataView.SortOrder.ascending:
            return 'ascending';

          case _dataView.SortOrder.descending:
            return 'descending';
        }
      }

      return this.args.descriptor.sortable ? 'none' : null;
    }

    changeSort() {
      if (this.args.sort && this.args.sort.keyPath === this.args.descriptor.keyPath) {
        this.args.didChangeSort({
          keyPath: this.args.descriptor.keyPath,
          order: this.args.sort.order === _dataView.SortOrder.descending ? _dataView.SortOrder.ascending : _dataView.SortOrder.descending
        });
      } else {
        this.args.didChangeSort({
          keyPath: this.args.descriptor.keyPath,
          order: _dataView.SortOrder.ascending
        });
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "changeSort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSort"), _class.prototype)), _class);
  _exports.default = DataGridItemComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataGridItemComponent);
});