define("admin/components/metadata/fieldsets/divider", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Divider />
  */
  {
    "id": "r160TdJR",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"divider\"]]",
    "moduleName": "admin/components/metadata/fieldsets/divider.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});