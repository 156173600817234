define("ember-m3/-infra/features", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CUSTOM_MODEL_CLASS = void 0;

  /**
    ⚠️ This file exists ONLY for DEV ergonomics to ensure
    JS import autocompletion works and flags can be toggled.
  
    In production, The actual flag states are ALWAYS determined
    at build-time and off-branches stripped from the output.
  
    This determination is done in `src/debug-macros.js`
  
    The file itself is stripped from production builds.
  */
  function dataFlagState(flagName) {
    let value;

    if ((0, _require.has)('@ember-data/canary-features')) {
      value = (0, _require.default)("@ember-data/canary-features")[flagName];
    }

    return value || false;
  }

  const CUSTOM_MODEL_CLASS = dataFlagState('CUSTOM_MODEL_CLASS');
  _exports.CUSTOM_MODEL_CLASS = CUSTOM_MODEL_CLASS;
});