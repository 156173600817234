define("admin/routes/admin/integrations/signals/created", ["exports", "@ember/routing/route", "@ember/object", "admin/controllers/admin/integrations/signals/created"], function (_exports, _route, _object, _created) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SignalsCreatedRoute extends _route.default {
    async model(params) {
      let {
        integration
      } = this.modelFor('admin.integrations.signals');
      let signal = await this.store.findRecord('urn:signal', params.signalId, {
        adapterOptions: {
          url: `integrations/${integration.id}/signals/${params.signalId}`
        }
      });
      return {
        integration,
        signal
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'integration', model.integration);
      (0, _object.set)(controller, 'signal', model.signal);
      (0, _object.set)(controller, 'step', _created.IntegrationsSignalsCreatedStep.success);
    }

  }

  _exports.default = SignalsCreatedRoute;
});