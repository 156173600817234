define("admin/components/data-grid/fields/signal-published-state", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "clarify-data-layer/utility/belongs-to"], function (_exports, _component, _templateFactory, _component2, _object, _belongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TextTag
    class="data-grid--signal-published-state"
    @style={{if this.isPublished "green" "gray"}}
    @icon={{if this.isPublished "#admin-signal-24" "#icon-cross-filled-24"}}
    @text={{
      if
      this.isPublished
      (t "data-grid.content.signal-published-state.published")
      (t "data-grid.content.signal-published-state.not-published")
    }}
  />
  */
  {
    "id": "COS+cxJQ",
    "block": "[[[8,[39,0],[[24,0,\"data-grid--signal-published-state\"]],[[\"@style\",\"@icon\",\"@text\"],[[52,[30,0,[\"isPublished\"]],\"green\",\"gray\"],[52,[30,0,[\"isPublished\"]],\"#admin-signal-24\",\"#icon-cross-filled-24\"],[52,[30,0,[\"isPublished\"]],[28,[37,2],[\"data-grid.content.signal-published-state.published\"],null],[28,[37,2],[\"data-grid.content.signal-published-state.not-published\"],null]]]],null]],[],false,[\"text-tag\",\"if\",\"t\"]]",
    "moduleName": "admin/components/data-grid/fields/signal-published-state.hbs",
    "isStrictMode": false
  });

  let SignalPublishedState = (_dec = (0, _object.computed)('args.model.item'), (_class = class SignalPublishedState extends _component2.default {
    get isPublished() {
      return (0, _belongsTo.belongsTo)(this.args.model, 'item');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "isPublished", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isPublished"), _class.prototype)), _class));
  _exports.default = SignalPublishedState;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SignalPublishedState);
});