define("admin/components/routes/users/show/-groups-pane", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FlatButton::Link
    @size="medium"
    @route={{route "admin.users.edit-group-memberships" @member.id}}
    @text={{t "users-show.groups-tab.add-to-group-button"}}
    @style="secondary"
  />
  {{#let (loaded-models @member.groups) as |groups|}}
    {{#if groups.length}}
      <div class="users-groups">
        <HeaderContextual @title={{t "users-show.groups-tab.groups-list.header"}} />
        <ul class="list-entries">
          {{#each groups as |group|}}
            <ListEntries::Group
              class="users-sidebar-group-list--group"
              @link={{route "admin.groups.show" group.id}}
              @group={{group}}
            >
              <button
                title={{t "users-show.groups-tab.groups-list.remove-button"}}
                class="users-sidebar-group-list-group--remove-button"
                type="button"
                {{on "click" (perform @removeFromGroup group)}}
              >
                {{svg-jar "#icon-delete-24" width=24 height=24}}
              </button>
            </ListEntries::Group>
          {{/each}}
        </ul>
      </div>
    {{/if}}
  {{/let}}
  */
  {
    "id": "eQKdbre/",
    "block": "[[[8,[39,0],null,[[\"@size\",\"@route\",\"@text\",\"@style\"],[\"medium\",[28,[37,1],[\"admin.users.edit-group-memberships\",[30,1,[\"id\"]]],null],[28,[37,2],[\"users-show.groups-tab.add-to-group-button\"],null],\"secondary\"]],null],[1,\"\\n\"],[44,[[28,[37,4],[[30,1,[\"groups\"]]],null]],[[[41,[30,2,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"users-groups\"],[12],[1,\"\\n      \"],[8,[39,6],null,[[\"@title\"],[[28,[37,2],[\"users-show.groups-tab.groups-list.header\"],null]]],null],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"list-entries\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,2]],null]],null],null,[[[1,\"          \"],[8,[39,9],[[24,0,\"users-sidebar-group-list--group\"]],[[\"@link\",\"@group\"],[[28,[37,1],[\"admin.groups.show\",[30,3,[\"id\"]]],null],[30,3]]],[[\"default\"],[[[[1,\"\\n            \"],[11,\"button\"],[16,\"title\",[28,[37,2],[\"users-show.groups-tab.groups-list.remove-button\"],null]],[24,0,\"users-sidebar-group-list-group--remove-button\"],[24,4,\"button\"],[4,[38,10],[\"click\",[28,[37,11],[[30,4],[30,3]],null]],null],[12],[1,\"\\n              \"],[1,[28,[35,12],[\"#icon-delete-24\"],[[\"width\",\"height\"],[24,24]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[2]]]],[\"@member\",\"groups\",\"group\",\"@removeFromGroup\"],false,[\"flat-button/link\",\"route\",\"t\",\"let\",\"loaded-models\",\"if\",\"header-contextual\",\"each\",\"-track-array\",\"list-entries/group\",\"on\",\"perform\",\"svg-jar\"]]",
    "moduleName": "admin/components/routes/users/show/-groups-pane.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});