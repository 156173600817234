define("admin/components/data-grid/row/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ui/helpers/normalize-key-codes"], function (_exports, _component, _templateFactory, _component2, _object, _normalizeKeyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-invalid-interactive }}
  <div
    class="data-grid-row"
    aria-selected={{this.checked}}
    aria-rowindex={{@row}}
    data-is-active={{is-active @route @dataGridItem.model.id}}
    role="row"
    ...attributes
    {{on "keydown" (fn this.handleRowKeypress)}}
  >
    {{#each @descriptors as |descriptor index|}}
      {{#if (eq descriptor.type "checkbox")}}
        <DataGrid::CellCheckbox
          @checked={{this.checked}}
          @handleOnChange={{fn this.handleOnChange}}
          @pointerDidChange={{@pointerDidChange}}
          @pointer={{@pointer}}
          @row={{@row}}
          @column={{index}}
        />
      {{else}}
        <DataGrid::Cell
          @pointer={{@pointer}}
          @pointerDidChange={{fn @pointerDidChange}}
          @dataGridItem={{@dataGridItem}}
          @selectionStyle={{@selectionStyle}}
          @descriptor={{descriptor}}
          @selectRow={{fn @selectRow}}
          @column={{index}}
          @row={{@row}}
        />
      {{/if}}
    {{/each}}
  </div>
  */
  {
    "id": "JQ6e6ErH",
    "block": "[[[11,0],[24,0,\"data-grid-row\"],[16,\"aria-selected\",[30,0,[\"checked\"]]],[16,\"aria-rowindex\",[30,1]],[16,\"data-is-active\",[28,[37,0],[[30,2],[30,3,[\"model\",\"id\"]]],null]],[24,\"role\",\"row\"],[17,4],[4,[38,1],[\"keydown\",[28,[37,2],[[30,0,[\"handleRowKeypress\"]]],null]],null],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,5]],null]],null],null,[[[41,[28,[37,6],[[30,6,[\"type\"]],\"checkbox\"],null],[[[1,\"      \"],[8,[39,7],null,[[\"@checked\",\"@handleOnChange\",\"@pointerDidChange\",\"@pointer\",\"@row\",\"@column\"],[[30,0,[\"checked\"]],[28,[37,2],[[30,0,[\"handleOnChange\"]]],null],[30,8],[30,9],[30,1],[30,7]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,8],null,[[\"@pointer\",\"@pointerDidChange\",\"@dataGridItem\",\"@selectionStyle\",\"@descriptor\",\"@selectRow\",\"@column\",\"@row\"],[[30,9],[28,[37,2],[[30,8]],null],[30,3],[30,10],[30,6],[28,[37,2],[[30,11]],null],[30,7],[30,1]]],null],[1,\"\\n\"]],[]]]],[6,7]],null],[13]],[\"@row\",\"@route\",\"@dataGridItem\",\"&attrs\",\"@descriptors\",\"descriptor\",\"index\",\"@pointerDidChange\",\"@pointer\",\"@selectionStyle\",\"@selectRow\"],false,[\"is-active\",\"on\",\"fn\",\"each\",\"-track-array\",\"if\",\"eq\",\"data-grid/cell-checkbox\",\"data-grid/cell\"]]",
    "moduleName": "admin/components/data-grid/row/index.hbs",
    "isStrictMode": false
  });

  let DataGridRow = (_class = class DataGridRow extends _component2.default {
    get checked() {
      return this.args.dataGridItem.selected;
    }

    handleRowKeypress(event) {
      let key = (0, _normalizeKeyCodes.default)(event);

      switch (key) {
        case _normalizeKeyCodes.Key.space:
          if (event.shiftKey) {
            this.handleOnChange();
            event.preventDefault();
            event.stopPropagation();
          }

          break;
      }
    }

    handleOnChange() {
      this.args.selectRow(!this.args.dataGridItem.selected);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleRowKeypress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRowKeypress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOnChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnChange"), _class.prototype)), _class);
  _exports.default = DataGridRow;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataGridRow);
});