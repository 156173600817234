define("admin/templates/admin/users-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GJmUjyvi",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@headline\"],[\"#admin-member-48\",[28,[37,2],[\"users-index.header.title\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@title\"],[[28,[37,2],[\"users-index.states.loading\"],null]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"page-layout/complex\",\"page-layout/header\",\"t\",\"admin-state/loading\"]]",
    "moduleName": "admin/templates/admin/users-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});