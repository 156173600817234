define("ember-animated/-private/concurrency-helpers", ["exports", "@ember/runloop", "@ember/debug", "rsvp"], function (_exports, _runloop, _debug, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerCancellation = registerCancellation;
  _exports.fireCancellation = fireCancellation;
  _exports.rAF = rAF;
  _exports.microwait = microwait;
  _exports.wait = wait;
  _exports.afterRender = afterRender;
  _exports.allSettled = allSettled;
  _exports.clock = _exports.currentFrameClock = void 0;

  if (!window.Promise) {
    (true && (0, _debug.warn)('Unable to achieve proper rAF timing on this browser, microtask-based Promise implementation needed.', false, {
      id: 'ember-animated-missing-microtask'
    }));
    window.Promise = _rsvp.default.Promise;
  }

  const cancellation = new WeakMap();

  function registerCancellation(promise, handler) {
    cancellation.set(promise, handler);
  }

  function fireCancellation(promise) {
    let fn = cancellation.get(promise);

    if (fn) {
      fn(promise);
    }
  } // This is a cancelable way to requestAnimationFrame that's designed
  // to resolve via the microtask queue (like real spec-compliant
  // Promises are supposed to). This lets us use rAF within
  // ember-concurrency correctly. RSVP promises within Ember do not
  // resolve on the microtask queue, because Ember schedules them inside
  // backburner.


  function rAF() {
    if (!nextFrame) {
      nextFrame = requestAnimationFrame(rAFDidFire);
    }

    let resolve;
    let promise = new Promise(r => {
      resolve = r;
    });
    nextFrameWaiters.push({
      resolve: resolve,
      promise
    });
    registerCancellation(promise, removeWaiter);
    return promise;
  }

  function rAFDidFire(clock) {
    nextFrame = null;
    _exports.currentFrameClock = currentFrameClock = clock;
    let waiters = nextFrameWaiters;
    nextFrameWaiters = [];

    for (let i = 0; i < waiters.length; i++) {
      waiters[i].resolve();
    }
  }

  function removeWaiter(promise) {
    let pair = nextFrameWaiters.find(pair => pair.promise === promise);

    if (pair) {
      let index = nextFrameWaiters.indexOf(pair);

      if (index > -1) {
        nextFrameWaiters.splice(index, 1);
      }
    }
  }

  let nextFrame = null;
  let nextFrameWaiters = []; // rAF guarantees that callbacks within the same frame will see the
  // same clock. We stash it here so that arbitrary code can easily ask
  // "did I already do that this frame?" without needing to thread the
  // clock values around.

  let currentFrameClock = -Infinity;
  _exports.currentFrameClock = currentFrameClock;

  function microwait() {
    return new Promise(resolve => resolve());
  }

  function wait(ms = 0) {
    if (clock.now === originalClock) {
      let ticket;
      let promise = new _rsvp.default.Promise(resolve => {
        ticket = window.setTimeout(resolve, ms);
      });
      registerCancellation(promise, () => {
        clearTimeout(ticket);
      });
      return promise;
    } else {
      let canceled = false;
      let started = clock.now();
      let promise = new _rsvp.default.Promise(resolve => {
        function checkIt() {
          if (!canceled) {
            if (clock.now() - started > ms) {
              resolve();
            }

            rAF().then(checkIt);
          }
        }

        checkIt();
      });
      registerCancellation(promise, () => {
        canceled = true;
      });
      return promise;
    }
  }

  function afterRender() {
    let ticket;
    let promise = new Promise(resolve => {
      ticket = (0, _runloop.schedule)('afterRender', resolve);
    });
    registerCancellation(promise, () => {
      (0, _runloop.cancel)(ticket);
    });
    return promise;
  } // This provides a unified place to hook into time control for testing.


  let clock = {
    now() {
      return new Date().getTime();
    }

  };
  _exports.clock = clock;
  const originalClock = clock.now;

  function allSettled(promises) {
    return Promise.all(promises.map(p => {
      if (p) {
        return p.catch(() => null);
      }

      return;
    }));
  }
});