define("canvas/component-managers/layer", ["exports", "@ember/application", "@ember/component", "@ember/destroyable", "canvas/components/layer"], function (_exports, _application, _component, _destroyable, _layer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LayerComponentManager = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LayerComponentManager {
    static create(attrs) {
      let owner = (0, _application.getOwner)(attrs);
      return new LayerComponentManager(owner);
    }

    constructor(owner) {
      _defineProperty(this, "capabilities", (0, _component.capabilities)('3.13', {
        destructor: true,
        asyncLifecycleCallbacks: true,
        updateHook: true
      }));

      (0, _application.setOwner)(this, owner);
    }

    didCreateComponent(component) {
      component[_layer.INTERNALS].registerLayer(component);

      component[_layer.INTERNALS].setNeedsRender();
    }

    didUpdateComponent(component) {
      component[_layer.INTERNALS].setNeedsRender();
    }

    updateComponent(component, args) {
      component[_layer.PREVIOUS_ARGS] = { ...args.named
      };
    }

    destroyComponent(component) {
      if ((0, _destroyable.isDestroying)(component)) {
        return;
      }

      component[_layer.INTERNALS].removeLayer(component);

      (0, _destroyable.destroy)(component);
    }

    createComponent(Klass, args) {
      let instance = new Klass((0, _application.getOwner)(this), args.named.canvas, args.named);
      instance[_layer.PREVIOUS_ARGS] = { ...args.named
      };
      return instance;
    }

    getContext(component) {
      return component;
    }

  }

  _exports.LayerComponentManager = LayerComponentManager;
});