define("ember-svg-jar/inlined/icon-input-search-icon-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.02 6.949a5 5 0 11-7.07 7.07 5 5 0 017.07-7.07zm1.356 7.012a6.002 6.002 0 00-9.133-7.72 6 6 0 007.72 9.135l4.3 4.3 1.415-1.413-4.302-4.302z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});