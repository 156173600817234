define("admin/components/admin-outlet/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "ember-animated/easings/cosine", "ember-animated/motions/move", "ember-animated/motions/opacity"], function (_exports, _component, _templateFactory, _object, _component2, _cosine, _move, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#in-element this.insertionPoint insertBefore=null}}
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      <AnimatedOutlet @use={{this.transition}} @duration={{150}}>
        {{outlet}}
      </AnimatedOutlet>
    {{/if}}
  {{/in-element}}
  */
  {
    "id": "xGe3iubX",
    "block": "[[[40,[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,[[\"@use\",\"@duration\"],[[30,0,[\"transition\"]],150]],[[\"default\"],[[[[1,\"\\n      \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[]],\"%cursor:0%\",[28,[37,1],[[30,0,[\"insertionPoint\"]]],null],null]],[\"&default\"],false,[\"in-element\",\"-in-el-null\",\"if\",\"has-block\",\"yield\",\"animated-outlet\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/components/admin-outlet/index.hbs",
    "isStrictMode": false
  });

  let AdminSidePaneOutlet = (_class = class AdminSidePaneOutlet extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "transitions", {
        'admin.groups.show': {
          insert: this.slideIn,
          remove: this.slideOut
        },
        'admin.items.show': {
          insert: this.slideIn,
          remove: this.slideOut
        },
        'admin.integrations.index.show': {
          insert: this.slideIn,
          remove: this.slideOut
        },
        'admin.integrations.signals.show': {
          insert: this.slideIn,
          remove: this.slideOut
        },
        'admin.users.show': {
          insert: this.slideIn,
          remove: this.slideOut
        }
      });
    }

    get insertionPoint() {
      return document.querySelector('.admin-container');
    }

    *slideIn(sprite) {
      (0, _opacity.fadeIn)(sprite);
      sprite.startTranslatedBy(40, 0);
      (0, _move.default)(sprite, {
        easing: _cosine.easeOut
      });
    }

    *slideOut(sprite) {
      (0, _opacity.fadeOut)(sprite);
      sprite.endTranslatedBy(40, 0);
      (0, _move.default)(sprite, {
        easing: _cosine.easeIn
      });
    }

    *transition(context) {
      for (let sprite of context.insertedSprites) {
        if (sprite.owner) {
          let config = this.transitions[sprite.owner.id];

          if (config) {
            yield* config.insert(sprite);
          }
        }
      }

      for (let sprite of context.keptSprites) {
        (0, _move.default)(sprite);
      }

      for (let sprite of context.removedSprites) {
        if (sprite.owner) {
          let config = this.transitions[sprite.owner.id];

          if (config) {
            yield* config.remove(sprite);
          }
        }
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "transition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transition"), _class.prototype)), _class);
  _exports.default = AdminSidePaneOutlet;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminSidePaneOutlet);
});