define("ember-svg-jar/inlined/admin-invitations-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M5 7v11h14V7H5zm.723 2.416l6 4 .277.185.277-.185 6-4-.554-.832L12 12.399 6.277 8.584l-.554.832z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});