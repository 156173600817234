define("admin/templates/admin/items/keywords/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JumsNG76",
    "block": "[[[8,[39,0],null,[[\"@organization\",\"@itemCategories\"],[[30,0,[\"model\",\"organization\"]],[30,0,[\"model\",\"itemCategories\"]]]],null]],[],false,[\"modals/keywords-new\"]]",
    "moduleName": "admin/templates/admin/items/keywords/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});