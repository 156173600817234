define("ember-svg-jar/inlined/admin-group-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M12.5 8.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zm-1.377 5.343A5 5 0 0120 17v1H4v-.5a4.5 4.5 0 017.123-3.657zM6 9.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});