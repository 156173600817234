define("ember-svg-jar/inlined/admin-item-48", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.019 34L8 31.845l6.719-7.176 4.283 4.573L33.279 14 40 21.176l-2.019 2.155-4.703-5.018-14.276 15.242-4.283-4.572-4.7 5.017z\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});