define("admin/templates/admin/groups/show/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hilidBqT",
    "block": "[[[1,[28,[35,0],[[30,0,[\"group\",\"name\"]]],null]],[1,\"\\n\"],[8,[39,1],null,[[\"@group\",\"@members\"],[[30,0,[\"group\"]],[30,0,[\"members\"]]]],null]],[],false,[\"page-title\",\"routes/groups/show\"]]",
    "moduleName": "admin/templates/admin/groups/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});