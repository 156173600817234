define("clarify-data-layer/models/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SubscriptionStatus = void 0;
  let SubscriptionStatus;
  _exports.SubscriptionStatus = SubscriptionStatus;

  (function (SubscriptionStatus) {
    SubscriptionStatus["trialing"] = "trialing";
    SubscriptionStatus["incomplete"] = "incomplete";
    SubscriptionStatus["incompleteExpired"] = "incomplete_expired";
    SubscriptionStatus["canceled"] = "canceled";
    SubscriptionStatus["active"] = "active";
    SubscriptionStatus["pastDue"] = "past_due";
    SubscriptionStatus["unpaid"] = "unpaid";
  })(SubscriptionStatus || (_exports.SubscriptionStatus = SubscriptionStatus = {}));
});