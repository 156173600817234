define("admin/components/metadata/fieldsets/labels-readonly/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @labels as |label|}}
    <Metadata::Fieldsets::LabelsReadonly::Item @category={{label.category}} @labels={{label.labels}} />
  {{/each}}
  */
  {
    "id": "cj+haQ0H",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@category\",\"@labels\"],[[30,2,[\"category\"]],[30,2,[\"labels\"]]]],null],[1,\"\\n\"]],[2]],null]],[\"@labels\",\"label\"],false,[\"each\",\"-track-array\",\"metadata/fieldsets/labels-readonly/item\"]]",
    "moduleName": "admin/components/metadata/fieldsets/labels-readonly/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});