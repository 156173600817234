define("admin/components/metadata/fieldsets/labels/visibility", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <HeaderContextual
    class="labels-visibility"
    @title={{@label}}
    @tooltip={{@tooltip}}
    @icon={{@icon}}
    @theme="light-medium"
  >
    <:actions>
      <AText::Link @route={{@route}} @text={{@text}} />
    </:actions>
  </HeaderContextual>
  */
  {
    "id": "6AcTvGBK",
    "block": "[[[8,[39,0],[[24,0,\"labels-visibility\"]],[[\"@title\",\"@tooltip\",\"@icon\",\"@theme\"],[[30,1],[30,2],[30,3],\"light-medium\"]],[[\"actions\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@route\",\"@text\"],[[30,4],[30,5]]],null],[1,\"\\n  \"]],[]]]]]],[\"@label\",\"@tooltip\",\"@icon\",\"@route\",\"@text\"],false,[\"header-contextual\",\"a-text/link\"]]",
    "moduleName": "admin/components/metadata/fieldsets/labels/visibility.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});