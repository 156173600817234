define("gesture-recognizer/gestures/hold", ["exports", "gesture-recognizer/gestures/-base", "@ember/object", "gesture-recognizer/gestures/pan"], function (_exports, _base, _object, _pan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class HoldGestureRecognizer extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "start", null);

      _defineProperty(this, "threshold", 10);

      _defineProperty(this, "timer", null);

      _defineProperty(this, "handleTimeout", () => {
        (0, _object.set)(this, 'state', _base.GestureRecognizerState.ended);
      });
    }

    pointerDown(event) {
      this.start = (0, _pan.pointForEvent)(event, this.element);
      this.timer = setTimeout(this.handleTimeout, 2500);
    }

    pointerUp(_event) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      (0, _object.set)(this, 'state', _base.GestureRecognizerState.failed);
    }

    reset() {
      this.start = null;
    }

    pointerMove(event) {
      if (this.validateMouseEvent(event) === false) {
        (0, _object.set)(this, 'state', _base.GestureRecognizerState.failed);

        if (this.timer) {
          clearTimeout(this.timer);
        }
      }
    }

    validateMouseEvent(event) {
      if (this.start == null) {
        return false;
      }

      let change = (0, _pan.pointForEvent)(event, this.element);

      if (Math.abs(this.start.x - change.x) > this.threshold || Math.abs(this.start.y - change.y) > this.threshold) {
        return false;
      }

      return true;
    }

  }

  _exports.default = HoldGestureRecognizer;
});