define("admin/components/data-view/state/failed", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="data-view-failed">
    <span class="data-view-failed--title">
      {{t "data-grid.failed.title"}}
    </span>
    <p class="data-view-failed--description">
      {{t "data-grid.failed.description"}}
    </p>
    <FlatButton @size="small" @text={{t "data-grid.failed.retry"}} {{on "click" (fn @retry)}} />
  </div>
  */
  {
    "id": "zZ7Wcuff",
    "block": "[[[10,0],[14,0,\"data-view-failed\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"data-view-failed--title\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"data-grid.failed.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"data-view-failed--description\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"data-grid.failed.description\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[4,[38,2],[\"click\",[28,[37,3],[[30,1]],null]],null]],[[\"@size\",\"@text\"],[\"small\",[28,[37,0],[\"data-grid.failed.retry\"],null]]],null],[1,\"\\n\"],[13]],[\"@retry\"],false,[\"t\",\"flat-button\",\"on\",\"fn\"]]",
    "moduleName": "admin/components/data-view/state/failed.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});