define("ember-m3/base-record-array", ["exports", "@ember/object", "@ember/string", "@ember/array/mutable", "@ember/array", "ember-m3/model", "ember-m3/utils/resolve", "ember-m3/utils/notify-changes", "ember-m3/utils/caches", "@ember-data/store"], function (_exports, _object, _string, _mutable, _array, _model, _resolve, _notifyChanges, _caches, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.associateRecordWithRecordArray = associateRecordWithRecordArray;
  _exports.default = void 0;

  /**
   * BaseRecordArray
   *
   * @class BaseRecordArray
   */
  let BaseRecordArray;
  let baseRecordArrayProxyHandler;

  if (true
  /* CUSTOM_MODEL_CLASS */
  ) {
    const convertToInt = prop => {
      if (typeof prop === 'symbol') return null;
      const num = Number(prop);
      if (isNaN(num)) return null;
      return num % 1 === 0 ? num : null;
    };

    const BaseRecordArrayProxyHandler = class {
      get(target, key, receiver) {
        let index = convertToInt(key);

        if (index !== null) {
          return receiver.objectAt(key);
        }

        return Reflect.get(target, key, receiver);
      }

      set(target, key, value, receiver) {
        let index = convertToInt(key);

        if (index !== null) {
          receiver.replace(index, 1, [value]);
        } else {
          Reflect.set(target, key, value, receiver);
        }

        return true;
      }

    };
    baseRecordArrayProxyHandler = new BaseRecordArrayProxyHandler();
  }

  if (true
  /* CUSTOM_MODEL_CLASS */
  ) {
    let _Symbol$iterator;

    /**
     * BaseRecordArray
     *
     * @class BaseRecordArray
     */
    BaseRecordArray = (_Symbol$iterator = Symbol.iterator, class BaseRecordArray extends _object.default.extend(_mutable.default) {
      constructor(...args) {
        super(...args);
        this[_Symbol$iterator] = Array.prototype.values;
      }

      // public RecordArray API
      static create(...args) {
        let instance = super.create(...args);
        return new Proxy(instance, baseRecordArrayProxyHandler);
      }

      init() {
        super.init(...arguments);
        this._references = [];

        if (!this._objects) {
          this._objects = (0, _array.A)();
        }

        this._resolved = false;
        this.store = this.store || null;
      }

      replace(idx, removeAmt, newRecords) {
        let addAmt = (0, _object.get)(newRecords, 'length');
        let newObjects = new Array(addAmt);

        if (addAmt > 0) {
          let _newRecords = (0, _array.A)(newRecords);

          for (let i = 0; i < newObjects.length; ++i) {
            newObjects[i] = _newRecords.objectAt(i);
          }
        }

        this._objects.replace(idx, removeAmt, newObjects);

        this.arrayContentDidChange(idx, removeAmt, newObjects.length);

        this._registerWithObjects(newObjects);

        this._resolved = true;
      }

      objectAt(idx) {
        this._resolve(); // TODO make this lazy again


        let record = this._objects[idx];
        return record;
      }

      _removeObject(object) {
        if (this._resolved) {
          this._objects.removeObject(object);

          (0, _notifyChanges.deferArrayPropertyChange)(this.store, this, 0, 1, 0);
          (0, _notifyChanges.deferPropertyChange)(this.store, this, '[]');
          (0, _notifyChanges.deferPropertyChange)(this.store, this, 'length'); // eager change events here; we're not processing payloads (that goes
          // through `_setInternalModels`); we're doing `unloadRecord`

          (0, _notifyChanges.flushChanges)(this.store);
        } else {
          for (let j = 0; j < this._references.length; ++j) {
            let {
              id,
              type
            } = this._references[j];
            let dtype = type && (0, _string.dasherize)(type); // TODO we might not need the second condition

            let identifier = (0, _store.recordIdentifierFor)(object);

            if ((dtype === null || dtype === identifier.type) && id === identifier.id) {
              this._references.splice(j, 1);

              break;
            }
          }
        }
      } // Private API


      _setObjects(objects, triggerChange = true) {
        let originalLength = this._objects.length;

        if (triggerChange) {
          this._objects.replace(0, this._objects.length, objects);

          (0, _notifyChanges.deferArrayPropertyChange)(this.store, this, 0, originalLength, this._objects.length);
          (0, _notifyChanges.deferPropertyChange)(this.store, this, '[]');
          (0, _notifyChanges.deferPropertyChange)(this.store, this, 'length');
        } else {
          this._objects.splice(0, this._objects.length, ...objects);
        }

        this.setProperties({
          isLoaded: true,
          isUpdating: false
        });

        this._registerWithObjects(objects);

        this._resolved = true;
      }

      _setReferences(references) {
        this._isAllReference = true;
        this._references = references;
        this._resolved = false;
        let originalLength = this._objects.length;
        this._objects = (0, _array.A)();
        (0, _notifyChanges.deferArrayPropertyChange)(this.store, this, 0, originalLength, this._objects.length);
        (0, _notifyChanges.deferPropertyChange)(this.store, this, '[]');
        (0, _notifyChanges.deferPropertyChange)(this.store, this, 'length');
      }

      _removeRecordData(recordData) {
        if (this._resolved) {
          let record = _caches.recordDataToRecordMap.get(recordData);

          if (!record) {
            return;
          }

          let index = this._objects.indexOf(record);

          if (index > -1) {
            this._objects.removeObject(record);

            this.arrayContentDidChange(index, 1, 0);
          }
        }
      }

      _registerWithObjects(objects) {
        objects.forEach(object => {
          if (!object || !(0, _resolve.isResolvedValue)(object)) {
            return;
          }

          associateRecordWithRecordArray(object, this);
        });
      }

      _resolve() {
        if (this._resolved) {
          return;
        }

        if (this._references !== null) {
          let objects = (0, _resolve.resolveReferencesWithRecords)(this.store, this._references);

          this._setObjects(objects, false);
        }

        this._resolved = true;
      }

      get length() {
        return this._resolved ? this._objects.length : this._references.length;
      }

    });
  } else {
    BaseRecordArray = class BaseRecordArray extends _object.default.extend(_mutable.default) {
      // public RecordArray API
      static create(...args) {
        let instance = super.create(...args);
        return instance;
      }

      init() {
        this._internalModels = (0, _array.A)();
        super.init(...arguments);
        this._references = [];
        this._resolved = false;
        this.store = this.store || null;
      }

      replace(idx, removeAmt, newRecords) {
        let addAmt = (0, _object.get)(newRecords, 'length');
        let newInternalModels = new Array(addAmt);

        if (addAmt > 0) {
          let _newRecords = (0, _array.A)(newRecords);

          for (let i = 0; i < newInternalModels.length; ++i) {
            let newRecord = _newRecords.objectAt(i);

            newInternalModels[i] = newRecord._internalModel || newRecord;
          }
        }

        this._internalModels.replace(idx, removeAmt, newInternalModels);

        this._registerWithInternalModels(newInternalModels);

        this._resolved = true;
        this.arrayContentDidChange(idx, removeAmt, newRecords.length);
      }

      objectAt(idx) {
        this._resolve();

        let internalModel = this._internalModels[idx];
        return internalModel !== null && internalModel !== undefined ? typeof internalModel === 'object' && 'getRecord' in internalModel ? internalModel.getRecord() : internalModel : undefined;
      } // RecordArrayManager private api


      _pushInternalModels(internalModels) {
        this._resolve();

        this._internalModels.pushObjects(internalModels);
      }

      _removeInternalModels(internalModels) {
        if (this._resolved) {
          this._internalModels.removeObjects(internalModels);

          (0, _notifyChanges.deferArrayPropertyChange)(this.store, this, 0, internalModels.length, 0);
          (0, _notifyChanges.deferPropertyChange)(this.store, this, '[]');
          (0, _notifyChanges.deferPropertyChange)(this.store, this, 'length'); // eager change events here; we're not processing payloads (that goes
          // through `_setInternalModels`); we're doing `unloadRecord`

          (0, _notifyChanges.flushChanges)(this.store);
        } else {
          for (let i = 0; i < internalModels.length; ++i) {
            let internalModel = internalModels[i];

            for (let j = 0; j < this._references.length; ++j) {
              let {
                id,
                type
              } = this._references[j];
              let dtype = type && (0, _string.dasherize)(type);

              if ((dtype === null || dtype === internalModel.modelName) && id === internalModel.id) {
                this._references.splice(j, 1);

                break;
              }
            }
          }
        }
      } // Private API


      _setInternalModels(internalModels, triggerChange = true) {
        let originalLength = this._internalModels.length;

        this._internalModels.replace(0, this._internalModels.length, internalModels);

        if (triggerChange) {
          (0, _notifyChanges.deferArrayPropertyChange)(this.store, this, 0, originalLength, this._internalModels.length);
          (0, _notifyChanges.deferPropertyChange)(this.store, this, '[]');
          (0, _notifyChanges.deferPropertyChange)(this.store, this, 'length');
        }

        this.setProperties({
          isLoaded: true,
          isUpdating: false
        });

        this._registerWithInternalModels(internalModels);

        this._resolved = true;
      }

      _setReferences(references) {
        this._isAllReference = true;
        this._references = references;
        this._resolved = false;
        let originalLength = this._internalModels.length;
        this._internalModels = (0, _array.A)();
        (0, _notifyChanges.deferArrayPropertyChange)(this.store, this, 0, originalLength, this._internalModels.length);
        (0, _notifyChanges.deferPropertyChange)(this.store, this, '[]');
        (0, _notifyChanges.deferPropertyChange)(this.store, this, 'length');
      }

      _registerWithInternalModels(internalModels) {
        for (let i = 0, l = internalModels.length; i < l; i++) {
          let internalModel = internalModels[i]; // allow refs to point to resources not in the store
          // TODO: instead add a schema missing ref hook; #254

          if (internalModel !== null && internalModel !== undefined && typeof internalModel === 'object' && '_recordArrays' in internalModel) {
            internalModel._recordArrays.add(this);
          }
        }
      }

      _resolve() {
        if (this._resolved) {
          return;
        }

        if (this._references !== null) {
          let internalModels = (0, _resolve.resolveReferencesWithInternalModels)(this.store, this._references);

          this._setInternalModels(internalModels, false);
        }

        this._resolved = true;
      }

      get length() {
        return this._resolved ? this._internalModels.length : this._references.length;
      }

    };
  }

  if (true
  /* CUSTOM_MODEL_CLASS */
  ) {
    // Add native array methods here
    Object.assign(BaseRecordArray.prototype, {
      values: Array.prototype.values,
      keys: Array.prototype.keys,
      entries: Array.prototype.entries,
      copyWithin: Array.prototype.copyWithin,
      fill: Array.prototype.fill,
      findIndex: Array.prototype.findIndex,
      at: Array.prototype.at,
      join: Array.prototype.join,

      push(...values) {
        return this.pushObjects(values);
      },

      pop(...values) {
        return this.popObjects(values);
      },

      shift(...values) {
        return this.shiftObjects(values);
      },

      unshift(...values) {
        return this.unshiftObjects(values);
      },

      splice(idx, amt, ...values) {
        return this.replace(idx, amt, values);
      },

      some(callback) {
        return this.any(callback);
      },

      concat(values) {
        return this.toArray().concat(...values);
      },

      reverse() {
        let reversed = this.toArray().reverse();
        this.replace(0, this.length, reversed);
      },

      reduceRight(callback, init) {
        return this.toArray().reduceRight(callback, init);
      },

      sort(callback) {
        let sorted = this.toArray().sort(callback);
        this.replace(0, this.length, sorted);
      }

    });
  }

  function associateRecordWithRecordArray(record, recordArray) {
    if (record instanceof _model.EmbeddedMegamorphicModel) {
      // embedded models can be added across tracked arrays (although this is
      // weird) but since they can't be unloaded there's no need to associate the
      // array with the model
      //
      // unloading the top model after adding one of its embedded models to some
      // other tracked array is undefined behaviour
      return;
    }

    if (true
    /* CUSTOM_MODEL_CLASS */
    ) {
      if (record instanceof _model.default) {
        record._recordData._recordArrays.add(recordArray);
      } else {
        let recordArrays = _caches.recordToRecordArrayMap.get(record);

        if (!recordArrays) {
          _caches.recordToRecordArrayMap.set(record, [recordArray]);
        } else {
          recordArrays.push(recordArray);
        }
      }
    } else {
      record._internalModel._recordArrays.add(recordArray);
    }
  }

  var _default = BaseRecordArray;
  _exports.default = _default;
});