define("admin/components/data-grid/filters/select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="data-grid-filter-column"
    role="columnheader"
    data-size={{@descriptor.width}}
    {{did-update (fn this.valueDidUpdate) this.value}}
    {{did-update (fn this.queryDidUpdate) @query}}
    {{roving @pointer row=1 column=@column pointerDidChange=@pointerDidChange editable=true}}
  >
    {{#let (and @pointer.editing (eq @pointer.row 1) (eq @pointer.column @column)) as |editing|}}
      <InputSelect
        value={{this.value}}
        tabindex={{if editing "0" "-1"}}
        required
        @size="small"
        {{set-focus editing}}
        {{on "change" (fn this.handleInput)}}
        {{did-insert (fn this.setInitialValue)}}
      >
        <option value="" disabled hidden>
          {{t "data-grid.filters.select.placeholder"}}
        </option>
        {{#each @filter.options as |optionValue|}}
          <option value={{optionValue.value}}>
            {{optionValue.title}}
          </option>
        {{/each}}
      </InputSelect>
      {{#if this.value}}
        <DataGrid::Filters::ClearButton tabindex={{if editing "0" "-1"}} {{on "click" (fn this.clear)}} />
      {{/if}}
    {{/let}}
  </div>
  */
  {
    "id": "C3McNDfR",
    "block": "[[[11,0],[24,0,\"data-grid-filter-column\"],[24,\"role\",\"columnheader\"],[16,\"data-size\",[30,1,[\"width\"]]],[4,[38,0],[[28,[37,1],[[30,0,[\"valueDidUpdate\"]]],null],[30,0,[\"value\"]]],null],[4,[38,0],[[28,[37,1],[[30,0,[\"queryDidUpdate\"]]],null],[30,2]],null],[4,[38,2],[[30,3]],[[\"row\",\"column\",\"pointerDidChange\",\"editable\"],[1,[30,4],[30,5],true]]],[12],[1,\"\\n\"],[44,[[28,[37,4],[[30,3,[\"editing\"]],[28,[37,5],[[30,3,[\"row\"]],1],null],[28,[37,5],[[30,3,[\"column\"]],[30,4]],null]],null]],[[[1,\"    \"],[8,[39,6],[[16,2,[30,0,[\"value\"]]],[16,\"tabindex\",[52,[30,6],\"0\",\"-1\"]],[24,\"required\",\"\"],[4,[38,8],[[30,6]],null],[4,[38,9],[\"change\",[28,[37,1],[[30,0,[\"handleInput\"]]],null]],null],[4,[38,10],[[28,[37,1],[[30,0,[\"setInitialValue\"]]],null]],null]],[[\"@size\"],[\"small\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"option\"],[14,2,\"\"],[14,\"disabled\",\"\"],[14,\"hidden\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,11],[\"data-grid.filters.select.placeholder\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"],[42,[28,[37,13],[[28,[37,13],[[30,7,[\"options\"]]],null]],null],null,[[[1,\"        \"],[10,\"option\"],[15,2,[30,8,[\"value\"]]],[12],[1,\"\\n          \"],[1,[30,8,[\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[8]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"value\"]],[[[1,\"      \"],[8,[39,14],[[16,\"tabindex\",[52,[30,6],\"0\",\"-1\"]],[4,[38,9],[\"click\",[28,[37,1],[[30,0,[\"clear\"]]],null]],null]],null,null],[1,\"\\n\"]],[]],null]],[6]]],[13]],[\"@descriptor\",\"@query\",\"@pointer\",\"@column\",\"@pointerDidChange\",\"editing\",\"@filter\",\"optionValue\"],false,[\"did-update\",\"fn\",\"roving\",\"let\",\"and\",\"eq\",\"input-select\",\"if\",\"set-focus\",\"on\",\"did-insert\",\"t\",\"each\",\"-track-array\",\"data-grid/filters/clear-button\"]]",
    "moduleName": "admin/components/data-grid/filters/select.hbs",
    "isStrictMode": false
  });

  let SelectComponent = (_class = class SelectComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "value", _descriptor, this);
    }

    handleInput(event) {
      let target = event.currentTarget;
      this.value = target.value;
    }

    clear() {
      this.value = '';
    }

    queryDidUpdate() {
      if (this.value && this.args.query.has(this.args.descriptor.keyPath) === false) {
        this.clear();
      }
    }

    setInitialValue(element) {
      element.value = this.value ?? '';
    }

    valueDidUpdate() {
      let value = undefined;

      if (this.value) {
        let filterValue = this.args.descriptor.filter;
        let filter = filterValue.options.find(option => option.value === this.value);
        value = (filter === null || filter === void 0 ? void 0 : filter.filter) !== undefined ? filter.filter : this.value;
      }

      this.args.updateQuery(this.args.descriptor.keyPath, value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryDidUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "queryDidUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInitialValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setInitialValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "valueDidUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "valueDidUpdate"), _class.prototype)), _class);
  _exports.default = SelectComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectComponent);
});