define("admin/templates/admin/integrations/index/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kSr33dc+",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"integration-create.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@organization\",\"@step\",\"@handleUpdateStep\"],[[30,0,[\"organization\"]],[30,0,[\"step\"]],[28,[37,3],[[30,0,[\"handleUpdateStep\"]]],null]]],null]],[],false,[\"page-title\",\"t\",\"modals/integration-create\",\"fn\"]]",
    "moduleName": "admin/templates/admin/integrations/index/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});