define("clarify-data-layer/models/source-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SourceType = void 0;
  let SourceType;
  _exports.SourceType = SourceType;

  (function (SourceType) {
    SourceType["aggregation"] = "aggregation";
    SourceType["measurement"] = "measurement";
    SourceType["prediction"] = "prediction";
  })(SourceType || (_exports.SourceType = SourceType = {}));
});