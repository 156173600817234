define("gesture-recognizer/gestures/-base", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GestureRecognizerState = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let GestureRecognizerState;
  _exports.GestureRecognizerState = GestureRecognizerState;

  (function (GestureRecognizerState) {
    GestureRecognizerState[GestureRecognizerState["possible"] = 0] = "possible";
    GestureRecognizerState[GestureRecognizerState["began"] = 1] = "began";
    GestureRecognizerState[GestureRecognizerState["changed"] = 2] = "changed";
    GestureRecognizerState[GestureRecognizerState["ended"] = 3] = "ended";
    GestureRecognizerState[GestureRecognizerState["cancelled"] = 4] = "cancelled";
    GestureRecognizerState[GestureRecognizerState["failed"] = 5] = "failed";
  })(GestureRecognizerState || (_exports.GestureRecognizerState = GestureRecognizerState = {}));

  class GestureRecognizer extends _object.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "element", void 0);

      _defineProperty(this, "name", null);

      _defineProperty(this, "didChange", void 0);

      _defineProperty(this, "delegate", null);

      _defineProperty(this, "requireToFail", []);

      _defineProperty(this, "state", GestureRecognizerState.possible);
    }

    reset() {}

    pointerDown(_event) {}

    pointerUp(_event) {}

    pointerMove(_event) {}

  }

  _exports.default = GestureRecognizer;
});