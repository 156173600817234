define("admin/templates/admin/groups/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "E+d214X6",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"groups-new.create.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@organization\"],[[30,0,[\"model\",\"organization\"]]]],null],[1,\"\\n\"]],[],false,[\"page-title\",\"t\",\"modals/group-new\"]]",
    "moduleName": "admin/templates/admin/groups/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});