define("admin/templates/admin/users/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "046bIamx",
    "block": "[[[10,0],[14,0,\"admin-right-sidebar\"],[12],[46,[28,[37,1],null,null],null,null,null],[13]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/admin/users/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});