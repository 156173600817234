define("admin/components/routes/integrations/index/-credentials-pane", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <HeaderContextual @title={{t "integrations-show.credentials-pane.header"}} />
  <Fieldset>
    <:label>
      <Label @title={{t "integrations-show.credentials-pane.credentials-label"}} />
    </:label>
    <:input>
      <IntegrationCredentialDownloader @integration={{@integration}} as |downloader|>
        <FlatButton
          @loading={{downloader.loading}}
          @style="secondary"
          @text={{t "integrations-show.credentials-pane.credentials-download-button"}}
          {{on "click" (fn downloader.download)}}
        />
      </IntegrationCredentialDownloader>
    </:input>
  </Fieldset>
  */
  {
    "id": "GT79HK2M",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"integrations-show.credentials-pane.header\"],null]]],null],[1,\"\\n\"],[8,[39,2],null,null,[[\"label\",\"input\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@title\"],[[28,[37,1],[\"integrations-show.credentials-pane.credentials-label\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],null,[[\"@integration\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],[[4,[38,6],[\"click\",[28,[37,7],[[30,2,[\"download\"]]],null]],null]],[[\"@loading\",\"@style\",\"@text\"],[[30,2,[\"loading\"]],\"secondary\",[28,[37,1],[\"integrations-show.credentials-pane.credentials-download-button\"],null]]],null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[]]]]]],[\"@integration\",\"downloader\"],false,[\"header-contextual\",\"t\",\"fieldset\",\"label\",\"integration-credential-downloader\",\"flat-button\",\"on\",\"fn\"]]",
    "moduleName": "admin/components/routes/integrations/index/-credentials-pane.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});