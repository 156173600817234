define("admin/helpers/to-local-iso-string", ["exports", "@ember/component/helper", "admin/utilities/to-local-iso-string"], function (_exports, _helper, _toLocalIsoString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ([date]) {
    return (0, _toLocalIsoString.default)(date);
  });

  _exports.default = _default;
});