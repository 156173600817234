define("admin/components/data-organization/shortcuts/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminCard>
    <:header>
      <h3 class="data-organization-shortcuts--content-title">
        {{t "organization-index.shortcuts.title"}}
      </h3>
    </:header>
    <:content>
      <div class="data-organization-shortcuts--content-links">
        <AText::Link
          target="_blank"
          href="https://support.clarify.us"
          @text={{t "organization-index.shortcuts.links.intro"}}
        />
        <AText::Link
          target="_blank"
          href="https://docs.clarify.us"
          @text={{t "organization-index.shortcuts.links.documentation" htmlSafe=true}}
        />
      </div>
    </:content>
  </AdminCard>
  */
  {
    "id": "HP0eT9Xl",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[10,\"h3\"],[14,0,\"data-organization-shortcuts--content-title\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"organization-index.shortcuts.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[10,0],[14,0,\"data-organization-shortcuts--content-links\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,\"target\",\"_blank\"],[24,6,\"https://support.clarify.us\"]],[[\"@text\"],[[28,[37,1],[\"organization-index.shortcuts.links.intro\"],null]]],null],[1,\"\\n      \"],[8,[39,2],[[24,\"target\",\"_blank\"],[24,6,\"https://docs.clarify.us\"]],[[\"@text\"],[[28,[37,1],[\"organization-index.shortcuts.links.documentation\"],[[\"htmlSafe\"],[true]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[],false,[\"admin-card\",\"t\",\"a-text/link\"]]",
    "moduleName": "admin/components/data-organization/shortcuts/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});