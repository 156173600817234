define("admin/components/routes/integrations/signals/-published-state", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TextTag
    class="integration-signal--published-state"
    @text={{
      if
      @item
      (t "integrations-signals-show.signal-published-state.published")
      (t "integrations-signals-show.signal-published-state.not-published")
    }}
    @style={{if @item "green" "gray"}}
    @icon={{if @item "#admin-signal-24" "#icon-cross-filled-24"}}
  />
  */
  {
    "id": "1Xi21oa/",
    "block": "[[[8,[39,0],[[24,0,\"integration-signal--published-state\"]],[[\"@text\",\"@style\",\"@icon\"],[[52,[30,1],[28,[37,2],[\"integrations-signals-show.signal-published-state.published\"],null],[28,[37,2],[\"integrations-signals-show.signal-published-state.not-published\"],null]],[52,[30,1],\"green\",\"gray\"],[52,[30,1],\"#admin-signal-24\",\"#icon-cross-filled-24\"]]],null]],[\"@item\"],false,[\"text-tag\",\"if\",\"t\"]]",
    "moduleName": "admin/components/routes/integrations/signals/-published-state.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});