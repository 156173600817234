define("ember-svg-jar/inlined/design-library-icon-info-circle-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M18.8 12a6.8 6.8 0 11-13.6 0 6.8 6.8 0 0113.6 0zm1.2 0a8 8 0 11-16 0 8 8 0 0116 0zm-7-5v2h-2V7h2zm-.045 3v7H11.06v-7h1.895z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});