define("admin/context-providers/keyword", ["exports", "@ember/object", "@ember/service", "clarify/utilities/compact-map"], function (_exports, _object, _service, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let KeywordProvider = (_class = class KeywordProvider extends _object.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentOrganization", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);
    }

    serialize(models) {
      const organization = this.currentOrganization.organization;

      if (!organization) {
        throw new Error('Organization isn’t defined');
      }

      return {
        title: models.length === 1 ? (0, _object.get)(models[0], 'title') : 'Multiple keywords',
        URL: null,
        data: models.map(model => JSON.stringify({
          type: model.type,
          key: model.key,
          organization: organization.id
        }))
      };
    }

    async deserialize(data, _dataTransfer) {
      let payloads = data;
      return (0, _compactMap.default)(payloads, blob => {
        let payload = JSON.parse(blob);
        let organization = this.store.peekRecord('urn:organization', payload.organization);

        if (!organization) {
          return null;
        }

        let keywords = (0, _object.get)(organization, 'keywords');
        return keywords.find(keyword => keyword.type === 'labels' && keyword.key === payload.key);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentOrganization", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = KeywordProvider;
});