define("admin/components/metadata/fieldsets/labels/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <fieldset class="labels-fieldset" {{did-insert (fn this.setElement)}} {{will-destroy (fn this.clearElement)}}>
    {{#each @form.forms as |label|}}
      <Metadata::Fieldsets::Labels::Item
        @remove={{fn this.removeCategory label}}
        @showsAddCategory={{@showsAddCategory}}
        @category={{label.fields.category.value}}
        @form={{label}}
        @triggerChange={{fn this.triggerChange}}
      />
    {{/each}}
    {{#if @showsAddCategory}}
      <AutocompleteLabelsCategory @insert={{fn this.insertCategory}} @availableCategories={{@categories}} />
    {{/if}}
  </fieldset>
  */
  {
    "id": "0N07Av6M",
    "block": "[[[11,\"fieldset\"],[24,0,\"labels-fieldset\"],[4,[38,0],[[28,[37,1],[[30,0,[\"setElement\"]]],null]],null],[4,[38,2],[[28,[37,1],[[30,0,[\"clearElement\"]]],null]],null],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"forms\"]]],null]],null],null,[[[1,\"    \"],[8,[39,5],null,[[\"@remove\",\"@showsAddCategory\",\"@category\",\"@form\",\"@triggerChange\"],[[28,[37,1],[[30,0,[\"removeCategory\"]],[30,2]],null],[30,3],[30,2,[\"fields\",\"category\",\"value\"]],[30,2],[28,[37,1],[[30,0,[\"triggerChange\"]]],null]]],null],[1,\"\\n\"]],[2]],null],[41,[30,3],[[[1,\"    \"],[8,[39,7],null,[[\"@insert\",\"@availableCategories\"],[[28,[37,1],[[30,0,[\"insertCategory\"]]],null],[30,4]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@form\",\"label\",\"@showsAddCategory\",\"@categories\"],false,[\"did-insert\",\"fn\",\"will-destroy\",\"each\",\"-track-array\",\"metadata/fieldsets/labels/item\",\"if\",\"autocomplete-labels-category\"]]",
    "moduleName": "admin/components/metadata/fieldsets/labels/index.hbs",
    "isStrictMode": false
  });

  let LabelsComponent = (_class = class LabelsComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "fieldsetElement", null);
    }

    setElement(element) {
      this.fieldsetElement = element;
    }

    clearElement() {
      this.fieldsetElement = null;
    }

    get categories() {
      return this.args.form.forms.map(form => {
        let field = form.fields.category;
        let category = field.value;
        return category.key;
      });
    }

    removeCategory(entry) {
      this.args.form.remove(entry);
      this.triggerChange();
    }

    insertCategory(dropdownItem) {
      let existing = this.args.form.forms.find(form => {
        let category = form.fields.category;
        return category.value.key === dropdownItem.value;
      });

      if (existing) {
        return;
      }

      let category = this.args.categories.find(category => category.key === dropdownItem.value);
      let value = dropdownItem.value;
      this.args.form.push({
        name: value,
        labels: [],
        category: category ? { ...category,
          title: value
        } : {
          title: value,
          key: value,
          existingValues: []
        }
      });
      this.triggerChange();
    }

    triggerChange() {
      var _this$fieldsetElement;

      (_this$fieldsetElement = this.fieldsetElement) === null || _this$fieldsetElement === void 0 ? void 0 : _this$fieldsetElement.dispatchEvent(new Event('change', {
        bubbles: true,
        cancelable: false
      }));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCategory", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertCategory", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insertCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "triggerChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerChange"), _class.prototype)), _class);
  _exports.default = LabelsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LabelsComponent);
});