define("admin/templates/admin/integrations/signals/edit-signal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "el3zvyV6",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"integrations-show-edit.browser-title\"],[[\"name\"],[[30,0,[\"signal\",\"name\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@signal\",\"@signalCategories\",\"@organization\",\"@itemKeywords\"],[[30,0,[\"signal\"]],[30,0,[\"signalCategories\"]],[30,0,[\"organization\"]],[30,0,[\"itemKeywords\"]]]],null]],[],false,[\"page-title\",\"t\",\"modals/edit-signal\"]]",
    "moduleName": "admin/templates/admin/integrations/signals/edit-signal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});