define("draggable/modifiers/scroll-interaction", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scroll = scroll;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const velocity = 50;
  const distance = 75;

  function scroll(element, speed) {
    if (speed.x) {
      let scrollLeft = element.scrollLeft;
      let scrollWidth = element.scrollWidth;
      let offsetWidth = element.offsetWidth;
      let newLeftValue = Math.min(scrollLeft + speed.x, scrollWidth - offsetWidth);

      if (newLeftValue != scrollLeft) {
        element.scrollLeft = newLeftValue;
      }
    }

    if (speed.y) {
      let scrollTop = element.scrollTop;
      let scrollHeight = element.scrollHeight;
      let offsetHeight = element.offsetHeight;
      let newTopValue = Math.min(scrollTop + speed.y, scrollHeight - offsetHeight);

      if (newTopValue != scrollTop) {
        element.scrollTop = newTopValue;
      }
    }
  }

  const throttles = (func, limit) => {
    let inThrottle = false;
    return function () {
      const args = arguments;
      const context = this;

      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    };
  };

  class ScrollInteractionModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "throttleScroll", throttles((element, speed) => {
        scroll(element, speed);
      }, 50));

      _defineProperty(this, "_dragOver", event => {
        let rect = this.element.getBoundingClientRect();
        let y = event.clientY;
        let x = event.clientX;

        if (rect) {
          let speed = {
            x: 0,
            y: 0
          };

          if (y < rect.top + distance) {
            let value = y - (rect.top + distance);
            speed.y = value / distance * velocity;
          } else if (y > rect.bottom - distance) {
            let value = y - (rect.bottom - distance);
            speed.y = value / distance * velocity;
          }

          if (x < rect.left + distance) {
            let value = x - (rect.left + distance);
            speed.x = value / distance * velocity;
          } else if (x > rect.right - distance) {
            let value = x - (rect.right - distance);
            speed.x = value / distance * velocity;
          }

          if (speed.x || speed.y) {
            this.throttleScroll(this.element, speed);
          }
        }
      });
    }

    didInstall() {
      super.didInstall();
      this.element.addEventListener('dragover', this._dragOver, true);
    }

    willDestroy() {
      super.willDestroy();
      this.element.removeEventListener('dragover', this._dragOver, true);
    }

  }

  _exports.default = ScrollInteractionModifier;
});