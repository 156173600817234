define("ember-svg-jar/inlined/admin-integration-36", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.41 13.5l-2.16 2.16 1.771 1.772 2.16-2.16 1.987 1.986-2.16 2.16 1.77 1.772 2.162-2.16 1.457 1.457.886-.886a6.265 6.265 0 00.64-8.11l2.256-2.255-1.415-1.414-2.26 2.26a6.265 6.265 0 00-8.078.662l-.886.886 1.87 1.87zm-6.665 2.925l.886-.886 8.857 8.858-.86.86-.026.026a6.265 6.265 0 01-8.11.64l-2.255 2.255-1.414-1.415 2.26-2.259a6.265 6.265 0 01.662-8.079z\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});