define("admin/templates/admin/items/keywords/index-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ETRp81Vd",
    "block": "[[[1,\"KEYWORDS ERROR\"]],[],false,[]]",
    "moduleName": "admin/templates/admin/items/keywords/index-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});