define("admin/components/data-grid/fields/copy", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="data-grid-fields-copy">
    {{#let (get @model @descriptor.keyPath) as |value|}}
      <span class="data-grid-fields-copy--text">
        {{or value "–"}}
      </span>
      {{#if (clipboard-supported)}}
        <button
          class="data-grid-fields-copy--button"
          type="button"
          tabindex="-1"
          title={{t "forms.label.copy-text"}}
          {{on "click" (fn (clipboard-write-text value))}}
        >
          {{svg-jar "#icon-copy-24" width=24 height=24 role="presentation"}}
        </button>
      {{/if}}
    {{/let}}
  </div>
  */
  {
    "id": "xNKs+Wv+",
    "block": "[[[10,0],[14,0,\"data-grid-fields-copy\"],[12],[1,\"\\n\"],[44,[[28,[37,1],[[30,1],[30,2,[\"keyPath\"]]],null]],[[[1,\"    \"],[10,1],[14,0,\"data-grid-fields-copy--text\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,3],\"–\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[28,[37,4],null,null],[[[1,\"      \"],[11,\"button\"],[24,0,\"data-grid-fields-copy--button\"],[24,\"tabindex\",\"-1\"],[16,\"title\",[28,[37,5],[\"forms.label.copy-text\"],null]],[24,4,\"button\"],[4,[38,6],[\"click\",[28,[37,7],[[28,[37,8],[[30,3]],null]],null]],null],[12],[1,\"\\n        \"],[1,[28,[35,9],[\"#icon-copy-24\"],[[\"width\",\"height\",\"role\"],[24,24,\"presentation\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[3]]],[13]],[\"@model\",\"@descriptor\",\"value\"],false,[\"let\",\"get\",\"or\",\"if\",\"clipboard-supported\",\"t\",\"on\",\"fn\",\"clipboard-write-text\",\"svg-jar\"]]",
    "moduleName": "admin/components/data-grid/fields/copy.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});