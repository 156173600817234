define("admin/helpers/alert-group-delete", ["exports", "clarify/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AlertGroupDelete extends _alert.default {
    compute([group], hash) {
      return () => {
        this.showAlert({
          title: this.intl.t('alert-group-delete.title', {
            name: group.name
          }),
          message: this.intl.t('alert-group-delete.message', {
            htmlSafe: true
          }),
          primaryButton: {
            title: this.intl.t('alert-group-delete.primary-button'),
            task: hash.task,
            style: 'destructive'
          }
        });
      };
    }

  }

  _exports.default = AlertGroupDelete;
});