define("ember-m3/utils/caches", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.recordToRecordArrayMap = _exports.recordDataToQueryCache = _exports.recordDataToRecordMap = void 0;
  const recordDataToRecordMap = new WeakMap();
  _exports.recordDataToRecordMap = recordDataToRecordMap;
  const recordDataToQueryCache = new WeakMap();
  _exports.recordDataToQueryCache = recordDataToQueryCache;
  const recordToRecordArrayMap = new WeakMap();
  _exports.recordToRecordArrayMap = recordToRecordArrayMap;
});