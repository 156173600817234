define("admin/components/data-organization/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="data-organization--container">
    {{#if @subscription}}
      <DataOrganization::SubscriptionPlan @subscription={{@subscription}} />
    {{/if}}
    <DataOrganization::About
      @organization={{@organization}}
      @members={{@members}}
      @integrations={{@integrations}}
      @items={{@items}}
    />
    <DataOrganization::Shortcuts />
  </div>
  */
  {
    "id": "P/jDbQ41",
    "block": "[[[10,0],[14,0,\"data-organization--container\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,1],null,[[\"@subscription\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,2],null,[[\"@organization\",\"@members\",\"@integrations\",\"@items\"],[[30,2],[30,3],[30,4],[30,5]]],null],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n\"],[13]],[\"@subscription\",\"@organization\",\"@members\",\"@integrations\",\"@items\"],false,[\"if\",\"data-organization/subscription-plan\",\"data-organization/about\",\"data-organization/shortcuts\"]]",
    "moduleName": "admin/components/data-organization/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});