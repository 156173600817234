define("admin/templates/admin/groups/group-edit-members", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mWb6K3El",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"group-edit-members.browser-title\"],[[\"name\"],[[30,0,[\"group\",\"name\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@group\",\"@members\"],[[30,0,[\"group\"]],[30,0,[\"members\"]]]],null]],[],false,[\"page-title\",\"t\",\"modals/group-edit-members\"]]",
    "moduleName": "admin/templates/admin/groups/group-edit-members.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});