define("ember-m3/utils/resolve", ["exports", "@ember/string", "ember-m3/utils/caches"], function (_exports, _string, _caches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeAttributeReference = computeAttributeReference;
  _exports.computeNestedModel = computeNestedModel;
  _exports.computeAttribute = computeAttribute;
  _exports.resolveReferencesWithInternalModels = resolveReferencesWithInternalModels;
  _exports.resolveReferencesWithRecords = resolveReferencesWithRecords;
  _exports.isResolvedValue = isResolvedValue;
  _exports.getOrCreateRecordFromRecordData = getOrCreateRecordFromRecordData;

  function computeAttributeReference(key, value, modelName, schemaInterface, schema) {
    schemaInterface._beginDependentKeyResolution(key);

    let reference = schema.computeAttributeReference(key, value, modelName, schemaInterface);

    schemaInterface._endDependentKeyResolution(key);

    return reference;
  }

  function computeNestedModel(key, value, modelName, schemaInterface, schema) {
    schemaInterface._beginDependentKeyResolution(key);

    let nestedModel = schema.computeNestedModel(key, value, modelName, schemaInterface);

    schemaInterface._endDependentKeyResolution(key);

    return nestedModel;
  }

  function computeAttribute(key, value, modelName, schemaInterface, schema) {
    schemaInterface._beginDependentKeyResolution(key);

    let model = schema.computeAttribute(key, value, modelName, schemaInterface);

    schemaInterface._endDependentKeyResolution(key);

    return model;
  }

  function resolveReferencesWithInternalModels(store, references) {
    if (!true
    /* CUSTOM_MODEL_CLASS */
    ) {
      return references.map(reference => reference.type ? store._internalModelForId((0, _string.dasherize)(reference.type), reference.id) : store._globalM3Cache[reference.id]);
    }
  }

  function resolveReferencesWithRecords(store, references) {
    if (true
    /* CUSTOM_MODEL_CLASS */
    ) {
      return references.map(reference => {
        if (reference.type) {
          let normalizedType = (0, _string.dasherize)(reference.type);
          let record = store.peekRecord(normalizedType, reference.id);

          if (record) {
            return record;
          } // If we have a cached recordData with the same id, but we have not seen a record with the same { type, id } pair
          // We could be a projection, in which case we want to push in a projected record with the new type


          let cachedRD = store._globalM3RecordDataCache[reference.id];

          if (cachedRD) {
            let baseTypeName = (0, _string.dasherize)(store._schemaManager.computeBaseModelName(normalizedType)); // We are a projection

            if (baseTypeName) {
              // Our projection matches the cached one
              if (baseTypeName === cachedRD.modelName || baseTypeName === store._schemaManager.computeBaseModelName(cachedRD.modelName)) return store.push({
                data: {
                  type: normalizedType,
                  id: reference.id,
                  attributes: {}
                }
              });
            }
          }
        } else {
          let rd = store._globalM3RecordDataCache[reference.id];

          if (rd) {
            return getOrCreateRecordFromRecordData(rd, store);
          }
        }
      });
    }
  }

  function isResolvedValue(value) {
    return value && value.constructor && value.constructor.isModel;
  }

  function getOrCreateRecordFromRecordData(rd, store) {
    if (true
    /* CUSTOM_MODEL_CLASS */
    ) {
      let record = _caches.recordDataToRecordMap.get(rd);

      if (_caches.recordDataToRecordMap.get(rd)) {
        return record;
      } else {
        return store.peekRecord(rd.modelName, rd.id);
      }
    }
  }
});