define("admin/routes/admin/integrations/signals/edit-signal", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "clarify/utilities/uuid", "clarify/utilities/rpc/label-signal-labels", "clarify/utilities/rpc/label-item-labels"], function (_exports, _route, _object, _service, _uuid, _labelSignalLabels, _labelItemLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IntegrationShowSignalsEditRoute = (_class = class IntegrationShowSignalsEditRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "network", _descriptor, this);
    }

    async model(params) {
      let {
        organization
      } = this.modelFor('admin');
      let {
        integration
      } = this.modelFor('admin.integrations.signals');
      let signal = await this.store.findRecord('urn:signal', params.signalId, {
        adapterOptions: {
          url: `integrations/${integration.id}/signals/${params.signalId}`
        }
      });

      let request = _labelItemLabels.LabelItemLablesRequestInfo.create((0, _uuid.default)(), {
        organization: organization.id,
        noValues: false
      });

      let signalLabelRequest = _labelSignalLabels.LabelSignalLabelsRequestInfo.create({
        integration: (0, _object.get)(signal, 'integration').id,
        noValues: false
      });

      let [signalCategories] = await this.network.rpc('meta/rpc', {}, request, signalLabelRequest);

      if (signalCategories.type === 'error') {
        return Promise.reject(signalCategories.error);
      }

      return {
        signal,
        signalCategories: signalCategories.result,
        organization: organization,
        itemKeywords: (0, _object.get)(organization, 'keywords')
      };
    }

    setupController(controller, model) {
      (0, _object.set)(controller, 'signal', model.signal);
      (0, _object.set)(controller, 'organization', model.organization);
      (0, _object.set)(controller, 'signalCategories', model.signalCategories);
      (0, _object.set)(controller, 'itemKeywords', model.itemKeywords);
    }

    loading() {
      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class);
  _exports.default = IntegrationShowSignalsEditRoute;
});