define("admin/templates/admin/integrations/index/created", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QrF+YFak",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"integration-create.browser-title\"],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@integration\"],[[30,0,[\"integration\"]]]],null]],[],false,[\"page-title\",\"t\",\"modals/integration-created\"]]",
    "moduleName": "admin/templates/admin/integrations/index/created.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});