define("admin/tasks/members-delete", ["exports", "@ember/service", "clarify/errors/localizable-error", "clarify/utilities/rpc/organization/suspend-member"], function (_exports, _service, _localizableError, _suspendMember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DeleteMembersRPCError = void 0;

  class DeleteMembersRPCError extends _localizableError.default {
    constructor(membersMap) {
      super(`DeleteMembers error`);
      this.membersMap = membersMap;
      Object.setPrototypeOf(this, DeleteMembersRPCError.prototype);
    }
    /**
     * Lists all members resources that failed saving
     */


    get members() {
      return Array.from(this.membersMap).map(([member]) => member);
    }
    /**
     * Localized description of the error
     */


    get description() {
      return this.intl.t('members-delete-error.message');
    }

  }

  _exports.DeleteMembersRPCError = DeleteMembersRPCError;
  let deleteMembers = {
    network: (0, _service.inject)('network'),

    *perform(members) {
      if (members.length > 0) {
        let requests = members.map(member => {
          return _suspendMember.SuspendMemberRequestInfo.create({
            organization: member.organization.id,
            member: member.id
          });
        });
        let data = yield this.network.rpc(`meta/rpc`, {}, ...requests);
        let failures = new Map();

        for (let [index, result] of data.entries()) {
          let member = members[index];

          if (result.type === 'error') {
            failures.set(member, result.error);
          } else {
            member.deleteRecord();
            member.unloadRecord();
          }
        }

        if (failures.size !== 0) {
          throw new DeleteMembersRPCError(failures);
        }
      }
    }

  };
  var _default = deleteMembers;
  _exports.default = _default;
});