define("admin/components/routes/items/keywords/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Modals::KeywordsModify @organization={{@organization}} />
  */
  {
    "id": "q6kT4Lv7",
    "block": "[[[8,[39,0],null,[[\"@organization\"],[[30,1]]],null]],[\"@organization\"],false,[\"modals/keywords-modify\"]]",
    "moduleName": "admin/components/routes/items/keywords/index.hbs",
    "isStrictMode": false
  });

  class KeywordsRoute extends _component2.default {}

  _exports.default = KeywordsRoute;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, KeywordsRoute);
});