define("clarify-data-layer/models/member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Role = void 0;
  let Role;
  _exports.Role = Role;

  (function (Role) {
    Role["member"] = "member";
    Role["admin"] = "admin";
  })(Role || (_exports.Role = Role = {}));
});