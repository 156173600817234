define("admin/components/metadata/fieldsets/labels/item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "form/fields/-form", "form/fields/input", "form/fields/form-list"], function (_exports, _component, _templateFactory, _component2, _object, _form, _input, _formList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LabelsForm = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Fieldset>
    <:label as |id|>
      {{#if @showsAddCategory}}
        <Label @for={{id}} @title={{@category.title}} @tooltip={{@category.key}}>
          <:actions as |menu|>
            <menu.item
              @text={{t "labels.item.remove"}}
              @icon="#icon-delete-24"
              @intent="danger"
              @select={{fn (alert-label-category-remove @remove)}}
            />
          </:actions>
        </Label>
      {{else}}
        <Label @title={{@category.title}} @tooltip={{@category.key}} />
      {{/if}}
    </:label>
    <:input as |id|>
      <div class="labels-container">
        {{#each @form.fields.labels.forms as |label|}}
          <ATag
            role="listitem"
            class="labels-container--tag"
            @text={{t "timeline-card.categories.title" tag=label.value}}
            title={{t "timeline-card.categories.tooltip" tooltip=label.value}}
            @removeTag={{alert-label-remove (fn this.removeLabel label)}}
          />
        {{/each}}
        <Metadata::Fieldsets::Labels::-AddButton
          @addButtonId={{id}}
          @labels={{this.labels}}
          @availableLabels={{@category.existingValues}}
          @category={{@category}}
          @disabled={{gte this.labels.length 10}}
          @insert={{fn this.insert}}
        />
      </div>
    </:input>
  </Fieldset>
  */
  {
    "id": "52CD8j47",
    "block": "[[[8,[39,0],null,null,[[\"label\",\"input\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[8,[39,2],null,[[\"@for\",\"@title\",\"@tooltip\"],[[30,1],[30,3,[\"title\"]],[30,3,[\"key\"]]]],[[\"actions\"],[[[[1,\"\\n          \"],[8,[30,4,[\"item\"]],null,[[\"@text\",\"@icon\",\"@intent\",\"@select\"],[[28,[37,3],[\"labels.item.remove\"],null],\"#icon-delete-24\",\"danger\",[28,[37,4],[[28,[37,5],[[30,5]],null]],null]]],null],[1,\"\\n        \"]],[4]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],null,[[\"@title\",\"@tooltip\"],[[30,3,[\"title\"]],[30,3,[\"key\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[1]],[[[1,\"\\n    \"],[10,0],[14,0,\"labels-container\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,7,[\"fields\",\"labels\",\"forms\"]]],null]],null],null,[[[1,\"        \"],[8,[39,8],[[24,\"role\",\"listitem\"],[24,0,\"labels-container--tag\"],[16,\"title\",[28,[37,3],[\"timeline-card.categories.tooltip\"],[[\"tooltip\"],[[30,8,[\"value\"]]]]]]],[[\"@text\",\"@removeTag\"],[[28,[37,3],[\"timeline-card.categories.title\"],[[\"tag\"],[[30,8,[\"value\"]]]]],[28,[37,9],[[28,[37,4],[[30,0,[\"removeLabel\"]],[30,8]],null]],null]]],null],[1,\"\\n\"]],[8]],null],[1,\"      \"],[8,[39,10],null,[[\"@addButtonId\",\"@labels\",\"@availableLabels\",\"@category\",\"@disabled\",\"@insert\"],[[30,6],[30,0,[\"labels\"]],[30,3,[\"existingValues\"]],[30,3],[28,[37,11],[[30,0,[\"labels\",\"length\"]],10],null],[28,[37,4],[[30,0,[\"insert\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[6]]]]]],[\"id\",\"@showsAddCategory\",\"@category\",\"menu\",\"@remove\",\"id\",\"@form\",\"label\"],false,[\"fieldset\",\"if\",\"label\",\"t\",\"fn\",\"alert-label-category-remove\",\"each\",\"-track-array\",\"a-tag\",\"alert-label-remove\",\"metadata/fieldsets/labels/-add-button\",\"gte\"]]",
    "moduleName": "admin/components/metadata/fieldsets/labels/item.hbs",
    "isStrictMode": false
  });

  let LabelsForm = _form.default.define({
    name: _input.InputField.required(),
    labels: _formList.default.define(_input.InputField.required())
  });

  _exports.LabelsForm = LabelsForm;
  let Labels = (_class = class Labels extends _component2.default {
    insert(label) {
      switch (label.type) {
        case 'input':
          if (label.query) {
            this.insertLabel(label.query);
          }

          break;

        case 'keyword':
          this.insertLabel(label.model);
          break;
      }
    }

    insertLabel(label) {
      if (this.labels.includes(label) === false) {
        this.args.form.fields.labels.push(label);
        this.args.triggerChange();
      }
    }

    removeLabel(label) {
      let labels = this.args.form.fields.labels;
      labels.remove(label);
      this.args.triggerChange();
    }

    get labels() {
      return this.args.form.fields.labels.forms.map(form => form.value);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "insert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertLabel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insertLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeLabel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeLabel"), _class.prototype)), _class);
  _exports.default = Labels;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Labels);
});