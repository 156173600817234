define("account/engine", ["exports", "ember-engines/engine", "ember-load-initializers", "account/resolver", "account/config/environment"], function (_exports, _engine, _emberLoadInitializers, _resolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Account extends _engine.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "Resolver", _resolver.default);

      _defineProperty(this, "dependencies", {
        services: ['authentication', 'current-user', 'current-organization', 'network', 'store', 'app-store', 'm3-schema-manager', 'locale', 'alert']
      });
    }

  }

  (0, _emberLoadInitializers.default)(Account, _environment.default.modulePrefix);
  var _default = Account;
  _exports.default = _default;
});