define("admin/components/routes/users/show/-header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="users-show-header">
    <img aria-hidden="true" src={{@member.picture}} class="users-show-header--avatar" width="100" height="100" />
    <h2 class="users-show-header--name">
      {{@member.name}}
    </h2>
    <span class="users-show-header--email">
      {{@member.email}}
    </span>
  </div>
  */
  {
    "id": "r3xzQV89",
    "block": "[[[10,0],[14,0,\"users-show-header\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"aria-hidden\",\"true\"],[15,\"src\",[30,1,[\"picture\"]]],[14,0,\"users-show-header--avatar\"],[14,\"width\",\"100\"],[14,\"height\",\"100\"],[12],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"users-show-header--name\"],[12],[1,\"\\n    \"],[1,[30,1,[\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"users-show-header--email\"],[12],[1,\"\\n    \"],[1,[30,1,[\"email\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@member\"],false,[]]",
    "moduleName": "admin/components/routes/users/show/-header.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});