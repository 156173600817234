define("admin/components/routes/integrations/signals", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "ember-concurrency", "clarify/utilities/associated-models", "admin/components/data-view", "admin/components/data-grid", "clarify/utilities/compact-map"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _object, _emberConcurrency, _associatedModels, _dataView, _dataGrid, _compactMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PageLayout::Complex>
    <:header>
      <PageLayout::Header
        @icon="#admin-integration-48"
        @parentRoute={{route "admin.integrations.index.show" @integration.id}}
        @parentTitle={{@integration.name}}
        @headline={{t "integrations-signals-index.header.title"}}
      >
        <:actions>
          <FlatButton::Link
            @route={{route "admin.integrations.signals.new" @signal.id}}
            @text={{t "integrations-signals-index.header.create-button"}}
            @size="medium"
            @icon="#design-system-p-plus-thick-24"
          />
        </:actions>
      </PageLayout::Header>
    </:header>
    <:content>
      <DataView
        class="page-layout-complex--data-view"
        @resource={{@resource}}
        @descriptors={{this.visibleDescriptors}}
        @selectedModelsDidChange={{this.selectedModelsDidChange}}
        @columnsSelectorData={{this.differ.viewModels}}
        @selectionStyle={{hash type="multiple" route="admin.integrations.signals.show"}}
      >
        <:toolbar-actions>
          {{#if this.selectedModels.length}}
            <AButtonTertiary::Group>
              <AButtonTertiary
                title={{t "integrations-signals-index.toolbar.delete.tooltip" count=this.selectedModels.length}}
                @size="small"
                @theme="light"
                @icon="#icon-remove-trash-16"
                {{on "click" (alert-signals-delete this.selectedModels task=(task this.deleteSignals))}}
              />
            </AButtonTertiary::Group>
          {{/if}}
        </:toolbar-actions>
      </DataView>
    </:content>
  </PageLayout::Complex>
  */
  {
    "id": "xooYIvR1",
    "block": "[[[8,[39,0],null,null,[[\"header\",\"content\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@parentRoute\",\"@parentTitle\",\"@headline\"],[\"#admin-integration-48\",[28,[37,2],[\"admin.integrations.index.show\",[30,1,[\"id\"]]],null],[30,1,[\"name\"]],[28,[37,3],[\"integrations-signals-index.header.title\"],null]]],[[\"actions\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@route\",\"@text\",\"@size\",\"@icon\"],[[28,[37,2],[\"admin.integrations.signals.new\",[30,2,[\"id\"]]],null],[28,[37,3],[\"integrations-signals-index.header.create-button\"],null],\"medium\",\"#design-system-p-plus-thick-24\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"page-layout-complex--data-view\"]],[[\"@resource\",\"@descriptors\",\"@selectedModelsDidChange\",\"@columnsSelectorData\",\"@selectionStyle\"],[[30,3],[30,0,[\"visibleDescriptors\"]],[30,0,[\"selectedModelsDidChange\"]],[30,0,[\"differ\",\"viewModels\"]],[28,[37,6],null,[[\"type\",\"route\"],[\"multiple\",\"admin.integrations.signals.show\"]]]]],[[\"toolbar-actions\"],[[[[1,\"\\n\"],[41,[30,0,[\"selectedModels\",\"length\"]],[[[1,\"          \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,9],[[16,\"title\",[28,[37,3],[\"integrations-signals-index.toolbar.delete.tooltip\"],[[\"count\"],[[30,0,[\"selectedModels\",\"length\"]]]]]],[4,[38,10],[\"click\",[28,[37,11],[[30,0,[\"selectedModels\"]]],[[\"task\"],[[28,[37,12],[[30,0,[\"deleteSignals\"]]],null]]]]],null]],[[\"@size\",\"@theme\",\"@icon\"],[\"small\",\"light\",\"#icon-remove-trash-16\"]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@integration\",\"@signal\",\"@resource\"],false,[\"page-layout/complex\",\"page-layout/header\",\"route\",\"t\",\"flat-button/link\",\"data-view\",\"hash\",\"if\",\"a-button-tertiary/group\",\"a-button-tertiary\",\"on\",\"alert-signals-delete\",\"task\"]]",
    "moduleName": "admin/components/routes/integrations/signals.hbs",
    "isStrictMode": false
  });

  let SignalsRoute = (_class = class SignalsRoute extends _component2.default {
    *deleteSignals() {
      let selectedSignals = Array.from(this.selectedModels);
      let promises = selectedSignals.map(signal => signal.destroyRecord());
      yield (0, _emberConcurrency.all)(promises);

      for (let signal of this.selectedModels) {
        signal.unloadRecord();
      }
    }

    selectedModelsDidChange(selectedModels) {
      this.selectedModels = selectedModels;
    }

    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "differ", _descriptor2, this);

      _initializerDefineProperty(this, "selectedModels", _descriptor3, this);

      this.differ = new _associatedModels.default(this.descriptors, _dataView.SelectableViewModel);
    }

    get visibleDescriptors() {
      return this.differ.viewModels.filter(viewModel => viewModel.enabled).map(viewModel => viewModel.model);
    }

    get descriptors() {
      let itemKeywords = (0, _compactMap.default)(this.args.itemKeywords, keyword => keyword.type === 'labels' && keyword.items ? keyword : null);
      let systemLabels = new Set(itemKeywords.map(category => category.key));
      let categories = new Map(this.args.categories.map(category => {
        return [category.key, category];
      }));
      let keywordsDescriptors = itemKeywords.map(category => {
        let signalCategory = categories.get(category.key);
        let existingValues = (signalCategory === null || signalCategory === void 0 ? void 0 : signalCategory.existingValues) ?? [];
        return {
          title: category.title,
          keyPath: `labels.${category.key}`,
          type: 'label',
          sortable: false,
          visibility: _dataGrid.TableCellColumnVisibility.visible,
          width: _dataGrid.TableCellWidth.default,
          filter: {
            type: 'select-multiple',
            items: existingValues.map(value => {
              return {
                id: value,
                parentId: null,
                name: value,
                path: [value],
                filters: null,
                count: 0
              };
            })
          }
        };
      });
      let labels = (0, _compactMap.default)(this.args.categories, category => {
        if (systemLabels.has(category.key)) {
          return;
        }

        return {
          title: this.intl.t('integrations-signals-index.data-grid-descriptors.label', {
            name: category.key
          }),
          keyPath: `labels.${category.key}`,
          type: 'label',
          sortable: false,
          visibility: _dataGrid.TableCellColumnVisibility.hidden,
          width: _dataGrid.TableCellWidth.default,
          filter: {
            type: 'select-multiple',
            items: category.existingValues.map(key => {
              return {
                id: key,
                parentId: null,
                name: key,
                path: [key],
                filters: null,
                count: 0
              };
            })
          }
        };
      });
      return [{
        type: 'checkbox',
        visibility: _dataGrid.TableCellColumnVisibility.mandatory
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.item'),
        keyPath: 'item',
        width: _dataGrid.TableCellWidth.default,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        type: 'signal-published-state',
        sortable: false,
        filter: {
          type: 'select',
          options: [{
            title: this.intl.t('integrations-signals-index.data-grid-descriptors.item-options.published'),
            value: 'published',
            filter: {
              $ne: null
            }
          }, {
            title: this.intl.t('integrations-signals-index.data-grid-descriptors.item-options.not-published'),
            value: 'not-published',
            filter: _dataGrid.NullValue
          }]
        }
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.name'),
        keyPath: 'name',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'input'
        }
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.input-id'),
        keyPath: 'inputID',
        width: _dataGrid.TableCellWidth.default,
        type: 'copy',
        sortable: false,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'input'
        }
      }, ...keywordsDescriptors, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.engUnit'),
        keyPath: 'engUnit',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        sortable: true,
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        filter: {
          type: 'input'
        }
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.type'),
        keyPath: 'type',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        sortable: true
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.createdAt'),
        keyPath: 'createdAt',
        width: _dataGrid.TableCellWidth.default,
        type: 'date',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        sortable: true
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.updatedAt'),
        keyPath: 'updatedAt',
        width: _dataGrid.TableCellWidth.default,
        type: 'date',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        sortable: true
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.sampleInterval'),
        keyPath: 'sampleInterval',
        width: _dataGrid.TableCellWidth.default,
        type: 'duration',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        sortable: true
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.deltaInterval'),
        keyPath: 'deltaInterval',
        width: _dataGrid.TableCellWidth.default,
        type: 'duration',
        visibility: _dataGrid.TableCellColumnVisibility.visible,
        sortable: true
      }, {
        title: this.intl.t('items-index.data-grid-descriptors.labels'),
        keyPath: 'labels',
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        type: 'labels',
        width: _dataGrid.TableCellWidth.ultraWide,
        sortable: false,
        filter: undefined
      }, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.description'),
        keyPath: 'description',
        width: _dataGrid.TableCellWidth.default,
        type: 'string',
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        sortable: false
      }, ...labels, {
        title: this.intl.t('integrations-signals-index.data-grid-descriptors.id'),
        keyPath: 'id',
        width: _dataGrid.TableCellWidth.default,
        type: 'copy',
        sortable: false,
        visibility: _dataGrid.TableCellColumnVisibility.hidden,
        filter: {
          type: 'input'
        }
      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "differ", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedModels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteSignals", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteSignals"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedModelsDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectedModelsDidChange"), _class.prototype)), _class);
  _exports.default = SignalsRoute;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SignalsRoute);
});