define("admin/components/data-grid-filter/item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-animated/transitions/fade"], function (_exports, _component, _templateFactory, _component2, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PopoverLayout::Item
    @title={{this.item.name}}
    @checked={{this.isChecked}}
    @handleCheckedDidChange={{fn @setEnabled this.item}}
  />
  {{#if @item.children.length}}
    <AnimatedContainer>
      {{#animated-if (and this.isChecked (gt @item.children.length 0)) duration=150 use=transition}}
        <ul class="data-grid-filter-item--children">
          {{#each @item.children key="id" as |item|}}
            <DataGridFilter::Item @item={{item}} @isEnabled={{fn @isEnabled}} @setEnabled={{fn @setEnabled}} />
          {{/each}}
        </ul>
      {{/animated-if}}
    </AnimatedContainer>
  {{/if}}
  */
  {
    "id": "OFiSmhjT",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@checked\",\"@handleCheckedDidChange\"],[[30,0,[\"item\",\"name\"]],[30,0,[\"isChecked\"]],[28,[37,1],[[30,1],[30,0,[\"item\"]]],null]]],null],[1,\"\\n\"],[41,[30,2,[\"children\",\"length\"]],[[[1,\"  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,4],[[28,[37,5],[[30,0,[\"isChecked\"]],[28,[37,6],[[30,2,[\"children\",\"length\"]],0],null]],null]],[[\"duration\",\"use\"],[150,[33,7]]],[[\"default\"],[[[[1,\"      \"],[10,\"ul\"],[14,0,\"data-grid-filter-item--children\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,2,[\"children\"]]],null]],null],\"id\",[[[1,\"          \"],[8,[39,10],null,[[\"@item\",\"@isEnabled\",\"@setEnabled\"],[[30,3],[28,[37,1],[[30,4]],null],[28,[37,1],[[30,1]],null]]],null],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]]]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@setEnabled\",\"@item\",\"item\",\"@isEnabled\"],false,[\"popover-layout/item\",\"fn\",\"if\",\"animated-container\",\"animated-if\",\"and\",\"gt\",\"transition\",\"each\",\"-track-array\",\"data-grid-filter/item\"]]",
    "moduleName": "admin/components/data-grid-filter/item.hbs",
    "isStrictMode": false
  });

  class ItemComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "transition", _fade.default);
    }

    get item() {
      return this.args.item.item;
    }

    get isChecked() {
      return this.args.isEnabled(this.item);
    }

  }

  _exports.default = ItemComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ItemComponent);
});