define("admin/components/admin-navigation", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Navigation>
    <Navigation::Header @title={{t "admin-navigation.settings.header"}} />
    {{#if (is-admin @organization)}}
      <Navigation::Hyperlink
        @route={{route "admin.organization"}}
        @iconName="#p-settings-24"
        @title={{t "admin-navigation.settings.link-organization"}}
      />
      <Navigation::Divider />
      <Navigation::Hyperlink
        @route={{route "admin.invitations"}}
        @iconName="#admin-invitations-24"
        @title={{t "admin-navigation.settings.link-invitations"}}
      />
      <Navigation::Hyperlink
        @route={{route "admin.users"}}
        @iconName="#admin-member-24"
        @title={{t "admin-navigation.settings.link-members"}}
      />
      <Navigation::Hyperlink
        @route={{route "admin.groups"}}
        @iconName="#admin-group-24"
        @title={{t "admin-navigation.settings.link-groups"}}
      />
      <Navigation::Divider />
      <Navigation::Hyperlink
        @route={{route "admin.integrations"}}
        @iconName="#admin-integration-24"
        @title={{t "admin-navigation.settings.link-integrations"}}
      />
      <Navigation::Hyperlink
        @route={{route "admin.items"}}
        @iconName="#admin-item-24"
        @title={{t "admin-navigation.settings.link-items"}}
      />
    {{/if}}
  </Navigation>
  */
  {
    "id": "Axc/dfXQ",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@title\"],[[28,[37,2],[\"admin-navigation.settings.header\"],null]]],null],[1,\"\\n\"],[41,[28,[37,4],[[30,1]],null],[[[1,\"    \"],[8,[39,5],null,[[\"@route\",\"@iconName\",\"@title\"],[[28,[37,6],[\"admin.organization\"],null],\"#p-settings-24\",[28,[37,2],[\"admin-navigation.settings.link-organization\"],null]]],null],[1,\"\\n    \"],[8,[39,7],null,null,null],[1,\"\\n    \"],[8,[39,5],null,[[\"@route\",\"@iconName\",\"@title\"],[[28,[37,6],[\"admin.invitations\"],null],\"#admin-invitations-24\",[28,[37,2],[\"admin-navigation.settings.link-invitations\"],null]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@route\",\"@iconName\",\"@title\"],[[28,[37,6],[\"admin.users\"],null],\"#admin-member-24\",[28,[37,2],[\"admin-navigation.settings.link-members\"],null]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@route\",\"@iconName\",\"@title\"],[[28,[37,6],[\"admin.groups\"],null],\"#admin-group-24\",[28,[37,2],[\"admin-navigation.settings.link-groups\"],null]]],null],[1,\"\\n    \"],[8,[39,7],null,null,null],[1,\"\\n    \"],[8,[39,5],null,[[\"@route\",\"@iconName\",\"@title\"],[[28,[37,6],[\"admin.integrations\"],null],\"#admin-integration-24\",[28,[37,2],[\"admin-navigation.settings.link-integrations\"],null]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@route\",\"@iconName\",\"@title\"],[[28,[37,6],[\"admin.items\"],null],\"#admin-item-24\",[28,[37,2],[\"admin-navigation.settings.link-items\"],null]]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@organization\"],false,[\"navigation\",\"navigation/header\",\"t\",\"if\",\"is-admin\",\"navigation/hyperlink\",\"route\",\"navigation/divider\"]]",
    "moduleName": "admin/components/admin-navigation.hbs",
    "isStrictMode": false
  });

  class AdminNavigation extends _component2.default {}

  _exports.default = AdminNavigation;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminNavigation);
});