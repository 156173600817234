define("account/routes/account/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountIndexRoute extends _route.default {
    model() {
      return this.modelFor('account');
    }

  }

  _exports.default = AccountIndexRoute;
});