define("ember-m3/adapters/interop-debug-adapter", ["exports", "ember-m3/adapters/m3-debug-adapter", "@ember/application", "@ember/object", "@ember/service", "ember-m3/model", "require", "@ember/runloop", "@ember/debug/data-adapter"], function (_exports, _m3DebugAdapter, _application, _object, _service, _model, _require, _runloop, _dataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let DebugAdapter;

  if (true
  /* HAS_DEBUG_PACKAGE */
  ) {
    DebugAdapter = (0, _require.default)("@ember-data/debug").default;
  } else if (true
  /* HAS_EMBER_DATA_PACKAGE */
  ) {
    DebugAdapter = (0, _require.default)("ember-data/-private").DebugAdapter;
  } else {
    DebugAdapter = _dataAdapter.default;
  }
  /*
    Extend Ember Data's `DebugAdapter` to handle both m3 and @ember-data/model model types
  
    @class InteropDebugAdapter
    @extends DebugAdapter
    @private
  */


  class InteropDebugAdapter extends DebugAdapter {
    init() {
      super.init(...arguments);
      const store = (0, _object.get)(this, 'store');
      const schema = (0, _object.get)(this, 'schema');
      const owner = (0, _application.getOwner)(this);
      const injections = {
        store,
        schema
      };
      (0, _application.setOwner)(injections, owner);
      this._m3DebugAdapter = _m3DebugAdapter.default.create(injections);
    }
    /**
      Function to call m3's debug adapter addedType method
      This is only needed for m3 records that have been dynamically added after
      watchModelTypes has been called
      @private
      @method addedType
      @param {String} type Model type
    */


    addedType(type) {
      this._m3DebugAdapter.addedType(type);
    }
    /**
      Calls the getRecordColumnValues function from either m3's debug adapter
      or Ember Data's debug adapter depending on the record type
      @public
      @method getRecordColumnValues
      @param {MegamorphicModel|Model} record to get values from
      @return {Object} Keys should match column names defined by the model type
    */


    getRecordColumnValues(record) {
      if (record instanceof _model.default) {
        return this._m3DebugAdapter.getRecordColumnValues(record);
      }

      return super.getRecordColumnValues(record);
    }
    /**
      Calls both the m3 and Ember Data watchModelTypes functions
      @public
      @method watchModelTypes
      @param {Function} typesAdded Callback to call to add types
      Takes an array of objects containing wrapped types
      @param {Function} typesUpdated Callback to call when a type has changed
      Takes an array of objects containing wrapped types
      @return {Function} Method to call to remove all observers from m3 and @ember-data/model model types
    */


    watchModelTypes(typesAdded, typesUpdated) {
      const schema = this.schema;

      let releaseM3 = this._m3DebugAdapter.watchModelTypes(typesAdded, typesUpdated);

      let releaseSuper = super.watchModelTypes(interceptDataTypes(schema, typesAdded), interceptDataTypes(schema, typesUpdated));
      return () => {
        releaseSuper();
        releaseM3();
      };
    }

    destroy() {
      (0, _runloop.run)(this._m3DebugAdapter, 'destroy');
      super.destroy(...arguments);
    }

  }

  _exports.default = InteropDebugAdapter;

  function interceptDataTypes(schema, method) {
    return types => {
      const dataTypes = types.filter(type => !schema.includesModel(type.name));

      if (dataTypes.length) {
        method(dataTypes);
      }
    };
  }

  (0, _object.defineProperty)(InteropDebugAdapter.prototype, 'schema', (0, _service.inject)('m3-schema'));
  (0, _object.defineProperty)(InteropDebugAdapter.prototype, 'store', (0, _service.inject)('store'));
});