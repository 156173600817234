define("ember-m3/factory", ["exports", "ember-m3/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    class: _model.default,

    create(props) {
      return _model.default.create(props);
    }

  };
  _exports.default = _default;
});