define("ember-svg-jar/inlined/icon-clock-40", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M26.799 25.434l-5.779-5.855v-4.703h-2.051v5.544l6.371 6.456 1.459-1.442zM19.997 8.718c-6.222 0-11.282 5.06-11.282 11.282 0 6.22 5.06 11.282 11.282 11.282 6.22 0 11.282-5.063 11.282-11.282 0-6.222-5.063-11.282-11.282-11.282zm0 24.615c-7.351 0-13.333-5.982-13.333-13.334 0-7.351 5.982-13.333 13.333-13.333 7.352 0 13.334 5.982 13.334 13.333 0 7.352-5.982 13.334-13.334 13.334z\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});