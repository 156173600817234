define("draggable/helpers/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canvasConstructor = void 0;

  const canvasConstructor = (width, height) => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    context.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);
    return canvas;
  };

  _exports.canvasConstructor = canvasConstructor;
});