define("admin/components/data-grid/header/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="data-grid--header" role="rowgroup">
    <div class="data-grid--columns" aria-rowindex={{1}} role="row">
      {{#each @descriptors as |descriptor index|}}
        {{#if (eq descriptor.type "checkbox")}}
          <div
            role="columnheader"
            aria-label="Selected column"
            class="data-grid-column--empty"
            {{roving @pointer row=0 column=index pointerDidChange=@pointerDidChange}}
          ></div>
        {{else}}
          <DataGrid::Column
            @pointer={{@pointer}}
            @pointerDidChange={{fn @pointerDidChange}}
            @column={{index}}
            @didChangeSort={{fn @didChangeSort}}
            @sort={{@sort}}
            @descriptor={{descriptor}}
          />
        {{/if}}
      {{/each}}
    </div>
    <div class="data-grid--columns" aria-rowindex={{2}} role="row">
      {{#each @descriptors as |descriptor index|}}
        {{#if (eq descriptor.type "checkbox")}}
          <div
            role="columnheader"
            aria-label="Filter row"
            class="data-grid-column--empty"
            {{roving @pointer row=1 column=index pointerDidChange=@pointerDidChange}}
          ></div>
        {{else}}
          <DataGrid::Filters
            @pointer={{@pointer}}
            @pointerDidChange={{fn @pointerDidChange}}
            @column={{index}}
            @didChangeSort={{fn @didChangeSort}}
            @sort={{@sort}}
            @descriptor={{descriptor}}
            @updateQuery={{fn @updateQuery}}
            @query={{@query}}
          />
        {{/if}}
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "vgu1J5ge",
    "block": "[[[10,0],[14,0,\"data-grid--header\"],[14,\"role\",\"rowgroup\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"data-grid--columns\"],[15,\"aria-rowindex\",1],[14,\"role\",\"row\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[41,[28,[37,3],[[30,2,[\"type\"]],\"checkbox\"],null],[[[1,\"        \"],[11,0],[24,\"role\",\"columnheader\"],[24,\"aria-label\",\"Selected column\"],[24,0,\"data-grid-column--empty\"],[4,[38,4],[[30,4]],[[\"row\",\"column\",\"pointerDidChange\"],[0,[30,3],[30,5]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,5],null,[[\"@pointer\",\"@pointerDidChange\",\"@column\",\"@didChangeSort\",\"@sort\",\"@descriptor\"],[[30,4],[28,[37,6],[[30,5]],null],[30,3],[28,[37,6],[[30,6]],null],[30,7],[30,2]]],null],[1,\"\\n\"]],[]]]],[2,3]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"data-grid--columns\"],[15,\"aria-rowindex\",2],[14,\"role\",\"row\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[41,[28,[37,3],[[30,8,[\"type\"]],\"checkbox\"],null],[[[1,\"        \"],[11,0],[24,\"role\",\"columnheader\"],[24,\"aria-label\",\"Filter row\"],[24,0,\"data-grid-column--empty\"],[4,[38,4],[[30,4]],[[\"row\",\"column\",\"pointerDidChange\"],[1,[30,9],[30,5]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,7],null,[[\"@pointer\",\"@pointerDidChange\",\"@column\",\"@didChangeSort\",\"@sort\",\"@descriptor\",\"@updateQuery\",\"@query\"],[[30,4],[28,[37,6],[[30,5]],null],[30,9],[28,[37,6],[[30,6]],null],[30,7],[30,8],[28,[37,6],[[30,10]],null],[30,11]]],null],[1,\"\\n\"]],[]]]],[8,9]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@descriptors\",\"descriptor\",\"index\",\"@pointer\",\"@pointerDidChange\",\"@didChangeSort\",\"@sort\",\"descriptor\",\"index\",\"@updateQuery\",\"@query\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"roving\",\"data-grid/column\",\"fn\",\"data-grid/filters\"]]",
    "moduleName": "admin/components/data-grid/header/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});