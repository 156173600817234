define("clarify-data-layer/serializers/-ember-m3", ["exports", "ember-data", "ember-m3/model", "ember-m3/base-record-array"], function (_exports, _emberData, _model, _baseRecordArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isM3Array(obj) {
    return obj instanceof _baseRecordArray.default;
  }

  let modelProperties = {
    'urn:user': ['email', 'name', 'picture'],
    'urn:organization': ['name', 'labels', 'description', 'permissions', 'keywords'],
    'urn:integration': ['name', 'description', 'organization', 'dataUpdateInterval', 'permission', 'labels'],
    'urn:group': ['name', 'description', 'permissions'],
    'urn:member': ['name', 'role', 'groups', 'email'],
    'urn:item': ['name', 'description', 'sourceType', 'deltaInterval', 'sampleInterval', 'engUnit', 'type', 'displayOptions', 'enumValues', 'labels', 'visible', 'permissions', 'organization'],
    'urn:signal': ['type', 'deltaInterval', 'description', 'engUnit', 'enumValues', 'labels', 'name', 'permissions', 'sampleInterval', 'sourceType', 'inputID']
  };

  class EmberM3Serializer extends _emberData.default.RESTSerializer {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return payload;
    }

    serializeList(array, options) {
      return array.map(value => this.serializeValue(value, options));
    }

    serializeValue(value, options) {
      if (value instanceof _model.default) {
        return value.serialize(options);
      } else if (isM3Array(value)) {
        return this.serializeList(value, options);
      }

      return value;
    }

    serialize(snapshot, options = {}) {
      let data = {};

      switch (snapshot.modelName) {
        case 'urn:item:display-options':
        case 'urn:item:labels':
        case 'urn:group:labels':
        case 'urn:user:labels':
        case 'urn:organization:labels':
        case 'urn:integration:labels':
        case 'urn:signal:labels':
        case 'urn:item:permissions':
        case 'urn:group:permissions':
        case 'urn:user:permissions':
        case 'urn:organization:permissions':
        case 'urn:integration:permissions':
        case 'urn:signal:permissions':
          let payload = {};
          snapshot.eachAttribute(attr => {
            let attribute = snapshot.attr(attr);
            payload[attr] = this.serializeValue(attribute, {
              id: true
            });
          });
          return payload;

        case 'urn:item':
        case 'urn:integration':
        case 'urn:organization':
        case 'urn:user':
        case 'urn:signal':
        case 'urn:group':
        case 'urn:member':
          if (options.id) {
            return snapshot.id;
          }

          let attributes = snapshot.attributes();
          let properties = modelProperties[snapshot.modelName];
          return properties.reduce((payload, property) => {
            return { ...payload,
              [property]: this.serializeValue(attributes[property], {
                id: true
              })
            };
          }, {});

        default:
          throw new Error(`Missing implementation for: ${snapshot.modelName}`);
      }
    }

  }

  _exports.default = EmberM3Serializer;
});