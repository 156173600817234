define("admin/components/data-grid/fields/label", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (get @model @descriptor.keyPath) as |value|}}
    {{#if value}}
      <div class="data-grid-labels--list">
        {{#each (or value (array)) as |keyword|}}
          <ATag role="listitem" @text={{keyword}} />
        {{/each}}
      </div>
    {{else}}
      –
    {{/if}}
  {{/let}}
  */
  {
    "id": "icMni0wD",
    "block": "[[[44,[[28,[37,1],[[30,1],[30,2,[\"keyPath\"]]],null]],[[[41,[30,3],[[[1,\"    \"],[10,0],[14,0,\"data-grid-labels--list\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[28,[37,5],[[30,3],[28,[37,6],null,null]],null]],null]],null],null,[[[1,\"        \"],[8,[39,7],[[24,\"role\",\"listitem\"]],[[\"@text\"],[[30,4]]],null],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    –\\n\"]],[]]]],[3]]]],[\"@model\",\"@descriptor\",\"value\",\"keyword\"],false,[\"let\",\"get\",\"if\",\"each\",\"-track-array\",\"or\",\"array\",\"a-tag\"]]",
    "moduleName": "admin/components/data-grid/fields/label.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});