define("ember-engines/-private/engine-ext", ["@ember/application", "@ember/engine", "ember-engines/components/link-to-external", "@embroider/macros/runtime"], function (_application, _engine, _linkToExternal, _runtime) {
  "use strict";

  _engine.default.reopen({
    buildRegistry() {
      let registry = this._super(...arguments);

      if (!(this instanceof _application.default)) {
        if ((0, _runtime.macroCondition)(!true)) {
          const EngineScopedLinkComponent = require("ember-engines/components/link-to-component").default;

          registry.register('component:link-to', EngineScopedLinkComponent);
        }

        registry.register('component:link-to-external', _linkToExternal.default);
      }

      return registry;
    }

  });
});