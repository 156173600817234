define("ember-svg-jar/inlined/admin-signal-48", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M24 9.85C16.186 9.85 9.85 16.186 9.85 24c0 5.802 3.491 10.79 8.49 12.973a.85.85 0 11-.68 1.558c-5.596-2.445-9.51-8.03-9.51-14.53 0-8.754 7.097-15.85 15.85-15.85 8.754 0 15.85 7.096 15.85 15.85 0 6.5-3.913 12.085-9.51 14.53a.85.85 0 01-.68-1.558c5-2.184 8.49-7.171 8.49-12.973 0-7.814-6.335-14.15-14.15-14.15zM15.853 24a8.15 8.15 0 1113.583 6.075.85.85 0 001.134 1.266A9.827 9.827 0 0033.853 24c0-5.44-4.41-9.85-9.85-9.85-5.44 0-9.85 4.41-9.85 9.85 0 2.917 1.27 5.539 3.283 7.341a.85.85 0 101.134-1.266A8.128 8.128 0 0115.853 24zm9.247 3.847A4.002 4.002 0 0024 20a4 4 0 00-1.1 7.847V38.9a1.1 1.1 0 002.2 0V27.847z\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});