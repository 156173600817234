define("ember-svg-jar/inlined/admin-item-36", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.514 25L6 23.384l5.039-5.382 3.212 3.43L24.96 10 30 15.382l-1.514 1.616-3.527-3.763L14.25 24.666l-3.212-3.43L7.514 25z\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});