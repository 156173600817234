define("admin/routes/admin/integrations/signals", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "clarify/utilities/resource", "clarify/utilities/rpc/label-signal-labels"], function (_exports, _route, _object, _service, _resource, _labelSignalLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IntegrationSignalsIndex = (_class = class IntegrationSignalsIndex extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "network", _descriptor, this);

      _initializerDefineProperty(this, "resources", _descriptor2, this);
    }

    async model(params) {
      let {
        organization
      } = this.modelFor('admin');
      let integrationRequest = await this.store.findRecord('urn:integration', params.integrationId);

      let signalLabelRequest = _labelSignalLabels.LabelSignalLabelsRequestInfo.create({
        integration: params.integrationId,
        noValues: false
      });

      let signalCategoriesRequest = await this.network.perform('meta/rpc', {}, signalLabelRequest);

      let resource = _resource.default.create({
        store: this.store,
        type: 'urn:signal',
        limit: 20,
        cacheKey: `integrations.${params.integrationId}.signals`,
        adapterOptions: {
          url: `integrations/${params.integrationId}/signals`
        },
        query: {
          sort: '-createdAt'
        }
      });

      let [integration, signalCategories] = await Promise.all([integrationRequest, signalCategoriesRequest, resource.load()]);
      return {
        integration,
        resource,
        itemKeywords: organization.keywords,
        categories: signalCategories
      };
    }

    resetController(controller, isExiting, transition) {
      super.resetController(controller, isExiting, transition);
      this.resources.deleteResource(controller.resource.cacheKey);
    }

    setupController(controller, model) {
      this.resources.setResource(model.resource.cacheKey, model.resource);
      (0, _object.set)(controller, 'integration', model.integration);
      (0, _object.set)(controller, 'resource', model.resource);
      (0, _object.set)(controller, 'categories', model.categories);
      (0, _object.set)(controller, 'itemKeywords', model.itemKeywords);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "resources", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = IntegrationSignalsIndex;
});