define("admin/components/modals/signal-connect/-info", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="signal-connect-info">
    {{svg-jar "#design-library-icon-info-circle-24" width=24 height=24 aria-hidden="true"}}
    <span class="signal-connect-info--description">
      {{@description}}
    </span>
  </div>
  */
  {
    "id": "m/f+lnEo",
    "block": "[[[10,0],[14,0,\"signal-connect-info\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"#design-library-icon-info-circle-24\"],[[\"width\",\"height\",\"aria-hidden\"],[24,24,\"true\"]]]],[1,\"\\n  \"],[10,1],[14,0,\"signal-connect-info--description\"],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@description\"],false,[\"svg-jar\"]]",
    "moduleName": "admin/components/modals/signal-connect/-info.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});