define("admin/routes/admin/groups/new", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GroupNewRoute extends _route.default {
    async model() {
      let {
        organization
      } = this.modelFor('admin');
      return {
        organization
      };
    }

  }

  _exports.default = GroupNewRoute;
});