define("admin/templates/admin/items/show/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "e0Nnn4mQ",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"items-show.browser-title\"],[[\"pane\",\"name\"],[[30,0,[\"pane\"]],[30,0,[\"item\",\"name\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@pane\",\"@signals\",\"@categories\",\"@item\"],[[30,0,[\"pane\"]],[30,0,[\"signals\"]],[30,0,[\"categories\"]],[30,0,[\"item\"]]]],null],[1,\"\\n\"],[46,[28,[37,4],null,null],null,null,null]],[],false,[\"page-title\",\"t\",\"routes/items/show\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/admin/items/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});