define("admin/components/routes/groups/show/-members", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FlatButton::Link
    @size="medium"
    @route={{route "admin.groups.group-edit-members" @group.id}}
    @text={{t "groups-show.members.add-to-group-button"}}
    @style="secondary"
  />
  {{#if @members.length}}
    <div class="users-groups">
      <HeaderContextual @title={{t "groups-show.members.members-list.header"}} />
      <ul class="list-entries">
        {{#each @members as |member|}}
          <ListEntries::User
            class="groups-show-members-list--user"
            @link={{route "admin.users.show" member.id}}
            @user={{member.user}}
          >
            <button
              title={{t "groups-show.members.members-list.remove-button"}}
              class="groups-show-members-list--remove-button"
              type="button"
              {{on "click" (perform @removeFromGroup member)}}
            >
              {{svg-jar "#icon-delete-24" width=24 height=24}}
            </button>
          </ListEntries::User>
        {{/each}}
      </ul>
    </div>
  {{/if}}
  */
  {
    "id": "QGxnoiU1",
    "block": "[[[8,[39,0],null,[[\"@size\",\"@route\",\"@text\",\"@style\"],[\"medium\",[28,[37,1],[\"admin.groups.group-edit-members\",[30,1,[\"id\"]]],null],[28,[37,2],[\"groups-show.members.add-to-group-button\"],null],\"secondary\"]],null],[1,\"\\n\"],[41,[30,2,[\"length\"]],[[[1,\"  \"],[10,0],[14,0,\"users-groups\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@title\"],[[28,[37,2],[\"groups-show.members.members-list.header\"],null]]],null],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"list-entries\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,2]],null]],null],null,[[[1,\"        \"],[8,[39,7],[[24,0,\"groups-show-members-list--user\"]],[[\"@link\",\"@user\"],[[28,[37,1],[\"admin.users.show\",[30,3,[\"id\"]]],null],[30,3,[\"user\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[11,\"button\"],[16,\"title\",[28,[37,2],[\"groups-show.members.members-list.remove-button\"],null]],[24,0,\"groups-show-members-list--remove-button\"],[24,4,\"button\"],[4,[38,8],[\"click\",[28,[37,9],[[30,4],[30,3]],null]],null],[12],[1,\"\\n            \"],[1,[28,[35,10],[\"#icon-delete-24\"],[[\"width\",\"height\"],[24,24]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@group\",\"@members\",\"member\",\"@removeFromGroup\"],false,[\"flat-button/link\",\"route\",\"t\",\"if\",\"header-contextual\",\"each\",\"-track-array\",\"list-entries/user\",\"on\",\"perform\",\"svg-jar\"]]",
    "moduleName": "admin/components/routes/groups/show/-members.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});